import { configureStore } from '@reduxjs/toolkit';
import residenceReducer from './residenceSlice';
import languageReducer from './languageSlice';
import loginOkReducer from './loginOkSlice';
import accessLevelReducer from './accessLevelSlice';

export default configureStore({
    reducer: {
        residence: residenceReducer,
        language: languageReducer,
        loginOk: loginOkReducer,
        accessLevel: accessLevelReducer,
  },
});
