import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AlertDialog from "./alertDialog";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import Center from 'react-center';
import { connect } from "react-redux";
import { text } from "./languageSelection";
import { isMobile } from './common/deviceDetector';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paperMobile: {
        marginTop: 0,
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loader: {
        padding: '200px 0',
    }
});

class Register extends React.Component {
    constructor(props) {
        super(props);

        // This binding is necessary to make `this` work in the callback
        this.submitRegister = this.submitRegister.bind(this);
    }

    state = {
        inProgress: false,
        showRegisterConfirmDialog: false,
        registerFailed: false,
        passwordNotMatch: false,
        emptyFieldsMessage: "",
        login: '',
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        subscribed: true,
        password: '',
        repeatPassword: '',
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleCheckboxChange = event => {
      this.setState({[event.target.name]: event.target.checked });
    };

    gotoWelcome = event => {
        if (event != null) {
            event.preventDefault();
        }
        window.location.hash = "welcome";
    }

    submitRegister(event) {
        if (event != null) {
            event.preventDefault();
        }

        this.setState({ passwordNotMatch: false });
        this.setState({ registerFailed: false });
        this.setState({ emptyFieldsMessage: ""});

        let language = this.props.language.value;

        if (this.state['login'].trim() === "" ||
            this.state['password'].trim() === "" ||
            this.state['email'].trim() === "") {
            this.setState(
                { emptyFieldsMessage:
                  text(language,
                       {"ru": "Следующие поля не могут быть пустыми:",
                        "en": "The following fields cannot be empty:",
                        "es": "Los siguientes campos no pueden estar vacíos:"}) +
                  (this.state['login'].trim() === ""
                   ? " " + text(language,
                                {"ru": "Логин",
                                 "en": "Login",
                                 "es": "Acceso"})
                   : "") +
                  (this.state['password'].trim() === ""
                   ? " " + text(language,
                                {"ru": "Пароль",
                                 "en": "Password",
                                 "es": "Contraseña"})
                   : "") +
                  (this.state['email'].trim() === "" ? " Email" : "")});
            return;
        }

        if (this.state['password'] !== this.state['repeatPassword']) {
            this.setState({ passwordNotMatch: true });
            return;
        }

        this.setState({ inProgress: true });
        const config = { headers: { 'Content-Type': 'application/json',
                                    'X-Requested-With': 'HttpRequest',
                                    'Csrf-Token': 'nocheck'},
                         timeout: 0};
        const data = new FormData();
        data.append('login', this.state['login']);
        data.append('firstName', this.state['firstName']);
        data.append('lastName', this.state['lastName']);
        data.append('middleName', this.state['middleName']);
        data.append('email', this.state['email']);
        data.append('subscribed', this.state['subscribed']);
        data.append('password', this.state['password']);
        axios.post("/register", data, config)
            .then( (response) => {
                this.setState({ inProgress: false });
                if (response.status === 200) {
                    this.setState({ showRegisterConfirmDialog: true });
                } else {
                    this.setState({ registerFailed: true });
                }
            })
            .catch( (error) => {
                this.setState({ inProgress: false });
                this.setState({ registerFailed: true });
            });
    };

    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <Center>
              {!this.state.inProgress
               ?
               <Card className={classes.card}>
                 <CardContent>
                   <Container component="main" maxWidth="xs">
                     <CssBaseline />
                     <div className={isMobile ? classes.paperMobile : classes.paper}>
                       <Avatar className={classes.avatar}>
                         <AccountCircleOutlinedIcon />
                       </Avatar>
                       <Typography component="h1" variant="h5">
                         {text(language,
                               {"ru": "Регистрация",
                                "en": "Registration",
                                "es": "Registro"})}
                       </Typography>
                       <form className={classes.form} noValidate>
                         {this.state.registerFailed
                          ? <Alert severity="error">
                              {text(language,
                                    {"ru": "Пользователь с таким логином или почтой уже зарегистрирован.",
                                     "en": "A user with this username or email is already registered.",
                                     "es": "Un usuario con este nombre de usuario o correo electrónico ya está registrado."})}
                            </Alert>
                          : ""}
                         {this.state.passwordNotMatch
                          ? <Alert severity="error">
                              {text(language,
                                    {"ru": "Пароль не совпадает с подтверждением пароля.",
                                     "en": "The password does not match the password confirmation.",
                                     "es": "La contraseña no coincide con la confirmación de la contraseña."})}
                            </Alert>
                          : ""}
                         {this.state.emptyFieldsMessage !== ""
                          ? <Alert severity="error">
                              {this.state.emptyFieldsMessage}
                            </Alert>
                          : ""}
                         <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           id="login"
                           label={text(language,
                                       {"ru": "Логин",
                                        "en": "Login",
                                        "es": "Acceso"})}
                           name="login"
                           value={this.state.login}
                           onChange={this.handleChange('login')}
                           autoComplete="login" />
                         <TextField
                           variant="outlined"
                           margin="normal"
                           fullWidth
                           id="firstName"
                           label={text(language,
                                       {"ru": "Имя",
                                        "en": "Name",
                                        "es": "Nombre"})}
                           name="firstName"
                           value={this.state.firstName}
                           onChange={this.handleChange('firstName')}
                           autoComplete="firstName" />
                         <TextField
                           variant="outlined"
                           margin="normal"
                           fullWidth
                           id="lastName"
                           label={text(language,
                                       {"ru": "Фамилия",
                                        "en": "Surname",
                                        "es": "Apellido"})}
                           name="lastName"
                           value={this.state.lastName}
                           onChange={this.handleChange('lastName')}
                           autoComplete="lastName" />
                         <TextField
                           variant="outlined"
                           margin="normal"
                           fullWidth
                           id="middleName"
                           label={text(language,
                                       {"ru": "Отчество",
                                        "en": "Middle name",
                                        "es": "Patronímico"})}
                           name="middleName"
                           value={this.state.middleName}
                           onChange={this.handleChange('middleName')}
                           autoComplete="middleName" />
                         <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           id="email"
                           label="Email"
                           name="email"
                           value={this.state.email}
                           onChange={this.handleChange('email')}
                           autoComplete="email" />
                         <FormControlLabel
                            control={
                              <Checkbox
                              checked={this.state.subscribed}
                              onChange={this.handleCheckboxChange}
                              name="subscribed"
                              color="primary"/>
                            }
                            label={text(language,
                                        {"ru": "Подписаться на рассылку",
                                         "en": "Subscribe to newsletters",
                                         "es": "Suscríbete a boletines"})}/>
                         <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           name="password"
                           label={text(language,
                                       {"ru": "Пароль",
                                        "en": "Password",
                                        "es": "Contraseña"})}
                           type="password"
                           id="password"
                           value={this.state.password}
                           onChange={this.handleChange('password')}
                           autoComplete="current-password"/>
                         <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           name="repeatPassword"
                           label={text(language,
                                       {"ru": "Повторите пароль",
                                        "en": "Repeat password",
                                        "es": "Repita la contraseña"})}
                           type="password"
                           id="repeatPassword"
                           value={this.state.repeatPassword}
                           onChange={this.handleChange('repeatPassword')}/>
                         <Button
                           type="submit"
                           fullWidth
                           variant="contained"
                           color="primary"
                           className={classes.submit}
                           onClick={this.submitRegister}>
                           {text(language,
                                 {"ru": "Зарегистрироваться",
                                  "en": "Register",
                                  "es": "Registro"})}
                         </Button>
                       </form>
                     </div>
                   </Container>
                 </CardContent>
                 <AlertDialog
                   open={this.state.showRegisterConfirmDialog}
                   propertyName='showRegisterConfirmDialog'
                   parent={this}
                   title={text(language,
                                 {"ru": "Пользователь успешно зарегистрирован",
                                  "en": "User successfully registered",
                                  "es": "Usuario registrado con éxito"})}
                   message={text(language,
                                 {"ru": "Для завершения регистрации пройдите " +
                                  "по ссылке, отправленной на ваш Email.",
                                  "en": "To complete the registration, follow " +
                                  "the link sent to your Email.",
                                  "es": "Para completar el registro, siga el " +
                                  "enlace enviado a su correo electrónico."})}
                   handler={this.gotoWelcome}/>
               </Card>
               : <div className={classes.loader}>
                   <CircularProgress className={classes.progress} size={90} />
                 </div>}
            </Center>
        );
    }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default withStyles(styles)(
    connect(mapStateToProps)(Register));
