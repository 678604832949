import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    }
});

class HelpTaxOptimization extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>

                <div className={classes.text}>
                  <br/>
                  <HelpHashLink to="/help/TaxOptimization#1">
                    <Typography id="1" variant="h5" component="h2">
                      Налоговая оптимизация
                    </Typography>
                  </HelpHashLink>

                  <p/>
                  Налоговая оптимизация представляет собой по сути 
                  предварительный расчет налоговой декларации, которая 
                  производится в конце года, чтобы понять оценочную сумму 
                  налога к текущему моменту и предлагает перечень вариантов 
                  закрытия позиций, для которых значение PL
                  (прибыль &amp; убыток) соответствует максимальному убытку.
                  Это позволяет сальдировать доход по совершенным ранее
                  прибыльным следкам за счет предлагаемых убыточных, таким
                  образом снижая сумму дохода за год, а следовательно налоговую
                  базу и сумму налога.

                  <p/>
                  <Alert severity="info" className={classes.text}>
                    Позиции на брокерском счету представляют из себя очередь
                    из открытых лотов. Она работает по принципу FIFO (первым
                    пришёл — первым ушёл). Т.е. при закрытии позиции частичной
                    или полной (например, при продаже купленных акций)
                    происходит сначала закрытие тех лотов, что были открыты
                    раньше. Таким образом, не существует возможности закрыть
                    конкретный лот. Можно только самый ранний или цепочку
                    лотов, начиная с самого раннего.
                  </Alert>

                  <p/>
                  Полученный в результате генерации предложений по налоговой
                  оптимизации документ (в формате Excel) является результатом
                  2 вариантов расчетов налоговой декларации:
                  <ol>
                    <li>Текущие совершенные сделки (а также дивиденды, 
                      купоны и прочее).
                    </li>
                    <li>Текущие совершенные сделки + виртуальные сделки, 
                      являющиеся оптимальными с точки зрения максимизации
                      убытка.
                    </li>
                  </ol>

                  Данный формат во многом схож с форматом пояснительной записки.
                  Помимо листов, присутствующих в пояснительной записке, в него
                  добавляются следующие листы:
                  <ul>
                      <li>"Позиции", в котором перечислены цепочки из открытых
                        лотов со значениями стоимости открытого лота (на момент
                        открытия), текущей его стоимости (ликвидационной) и
                        пересчетом данных значений в рубли.
                      </li>
                      <li>"Потенциальные продажи ЦБ" и "Потенциальные сделки с ПФИ",
                        в которых перечислены оптимальные значения количества
                        закрываемых ЦБ или ПФИ, такие что закрываемые лоты
                        максимизируют убыток, если убыток по данной позиции
                        вообще может иметь место быть при какой бы то ни было
                        глубине закрытия цепочки открытых лотов.
                      </li>
                  </ul>

                  <Alert severity="warning" className={classes.text}>
                    Следует иметь в виду, что в некоторых случаях промежуточные
                    лоты могут быть даже прибыльными, но это необходимо, чтобы
                    добраться по цепочке до убыточных лотов. Как бы то ни
                    было, расчет осуществляется с целью поиска такой
                    последовательности лотов, при закрытии которых итоговый
                    убыток будет максимальным.
                  </Alert>

                  <p/>
                  Кроме того, на первой странице документа "Налоговая
                  оптимизация", в дополнение к существующему на настоящий
                  момент расчету налоговой базы и суммы налога, выводится
                  альтернативный расчет, при котором предлагаемые
                  "Потенциальные продажи ЦБ" и "Потенциальные сделки с ПФИ"
                  считаются виртуально совершенными с соответствующими в этом
                  случае значениями налоговой базы и суммы налога.
                </div>

              </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(HelpTaxOptimization);
