import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';
import { Red } from '../colors';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';

const styles = theme => ({
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'column',
    textAlign: 'justify'
  },
  arrow: {
    marginBottom: '-6px'
  },
  text: {
    maxWidth: '800pt',
    textAlign: 'justify'
  },
  imgStyle: {
    height: '300px'
  },
  imgStyleMobile: {
    width: '100%'
  }
});

class HelpCitiReport extends React.Component {
  render() {
    const { classes } = this.props;
    let language = this.props.language.value;

    return (
      <Card className={classes.card}>
        <CardContent>
          <HelpToContentsLink/>
          <div className={classes.text}>
            <br/>
            <HelpHashLink to="/help/CharlesSchwabReport#1">
              <Typography id="1" variant="h5" component="h2">
                {text(language,
                      {"ru": "Алгоритм получения отчета Citibank",
                       "en": "Instructions for getting Citibank report",
                       "es": "Instrucciones para obtener el informe de Citibank"})}
              </Typography>
            </HelpHashLink>
            <p/>
            <ol>
              <li>
                {text(language,
                      {"ru": <span>
                               В разделе <Red>My Profile</Red> нажмите&ensp;
                               <Red>View your E-statement</Red>.
                             </span>,
                       "en": <span>
                               In the <Red>My Profile</Red> section, click
                               on <Red>View your E-statement</Red>.
                             </span>,
                       "es": <span>
                               En la sección <Red>My Profile</Red>, haga clic
                               en <Red>View your E-statement</Red>.
                             </span>})}
              </li>
              <li>
                {text(language,
                      {"ru": <span>
                               Используйте раскрывающиеся меню, чтобы выбрать
                               счет, год и месяц.
                             </span>,
                       "en": <span>
                               Use the drop-down menus to choose the account,
                               year and month.
                             </span>,
                       "es": <span>
                               Utilice los menús desplegables para elegir la
                               cuenta, el año y el mes.
                             </span>})}
              </li>
              <li>
                {text(language,
                      {"ru": <span>
                               Теперь нажмите <Red>GO</Red>.
                             </span>,
                       "en": <span>
                               Now click <Red>GO</Red>.
                             </span>,
                       "es": <span>
                               Ahora haga clic en <Red>GO</Red>.
                             </span>})}
              </li>
              <li>
                {text(language,
                      {"ru": <span>
                               Прочтите предупреждение системы безопасности,
                               нажмите <Red>OK</Red> и откройте PDF-файл с
                               электронной выпиской.
                             </span>,
                       "en": <span>
                               Read the security alert and click&ensp;
                               <Red>OK</Red> and open your e-statement PDF.
                             </span>,
                       "es": <span>
                               Lea la alerta de seguridad, haga clic en&ensp;
                               <Red>OK</Red> y abra el PDF de su estado de
                               cuenta electrónico.
                             </span>})}
              </li>
            </ol>
          </div>
        </CardContent>
      </Card>
    );
  }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpCitiReport));

