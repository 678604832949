import {getCookie, setCookie} from '../cookieLib';

const residenceCountriesList = ["none", "earth", "spain", "russia", "estonia"];

export const langsPerResidenceCountry = {
  "none": ["en", "es", "ru"],
  "earth": ["en", "es", "ru"],
  "spain": ["en", "es", "ru"],
  "russia": ["ru"],
  "estonia": ["en", "ru"]
};

export function splitUrl() {
  let url = window.location.href;
  let parts = url.split("#");
  let afterHashParts = parts.slice(1);
  let beforeLangUrl = parts[0].split(/\/en|\/es|\/ru/)[0];
  let langFromUrl = parts[0].substring(beforeLangUrl.length).split("/")[1];
  let countryFromUrl = parts[0].substring(beforeLangUrl.length).split("/")[2];
  return {
    coreUrl: beforeLangUrl.replace(/\/+$/, ''),
    hashUrl: ((afterHashParts.length > 0)
              ? ("#" + afterHashParts.join("#"))
              : ""),
    language: langFromUrl,
    residenceCountry: countryFromUrl
  };
}

export function getLanguage(newData) {
  const urlData = splitUrl();
  var language = "en";
  if ((newData != undefined) && (newData.language != undefined)) {
    language = newData.language;
  } else if (urlData.language != undefined) {
    language = urlData.language;
  } else if (getCookie("language", "en") != undefined) {
    language = getCookie("language", "en");
  }
  return language;
}

export function getResidenceCountry(newData) {
  const urlData = splitUrl();
  var residenceCountry = "none";
  if ((newData != undefined) && (newData.residenceCountry != undefined)) {
    residenceCountry = newData.residenceCountry;
  } else if (urlData.residenceCountry != undefined) {
    residenceCountry = urlData.residenceCountry;
  } else if (getCookie("residenceCountry", "none") != undefined) {
    residenceCountry = getCookie("residenceCountry", "none");
  }
  return residenceCountriesList.includes(residenceCountry)
    ? residenceCountry
    : "none";
}

export function buildNewUrl(newData) {
  const urlData = splitUrl();
  let residenceCountry = getResidenceCountry(newData);
  let language =
      langsPerResidenceCountry[residenceCountry].includes(
        getLanguage(newData))
      ? getLanguage(newData)
      : langsPerResidenceCountry[residenceCountry][0];
  let newUrl = (language == "en" && residenceCountry == "none")
      ? (urlData.coreUrl + urlData.hashUrl)
      : (urlData.coreUrl + "/" +
         language  + "/" +
         residenceCountry +
         urlData.hashUrl);
  return newUrl;
}
