import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ConfirmationDialog from "../confirmationDialog";
import Center from 'react-center';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Upload from "../upload";
import {setCookie, getCookieBoolean} from '../cookieLib';
import AlertDialog from '../alertDialog';
import LoginDialog from '../loginDialog';
import Progress from '../progress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { inputFormatsList } from '../taxForm';
import { setLoginOk } from '../state/loginOkSlice';
import { connect } from "react-redux";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#F5F5F5',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #BDBDBD',
  },
}))(Tooltip);

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#009688',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#009688',
    },
    '&$checked + $track': {
      backgroundColor: '#009688',
    },
  },
  checked: {},
  track: {},
})(Switch);

const styles = {
    button: {
        margin: 5,
    },
    buttonBottom: {
        margin: 5,
        marginTop: '15px',
        float: 'right'
    },
    buttonFixedWidth: {
        margin: 5,
        width: 200,
    },
    buttonPlus: {
        margin: 5,
        marginTop: 18,
        marginBottom: 0,
        paddingLeft: 6,
        paddingRight: 6,
        minWidth: 32
    },
    buttonPlusNoMargin: {
      paddingLeft: 6,
      paddingRight: 6,
      minWidth: 32
    },
    input: {
        display: 'none',
    },
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        marginBottom: '6px'
    },
    cardWithPaddingBottom: {
        marginBottom: '6px'
    },
    cardWithPaddingBottom2: {
      marginBottom: '2px'
    },
    cardContentWithoutPadding: {
        paddingTop: 0,
        paddingBottom: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: 800,
        flex: 1,
        flexDirection: 'column',
    },
    dateFiled: {
        width: 200,
        margin: 7,
    },
    dateFiled2: {
      width: 270,
      margin: 7,
    },
    textField: {
        width: 200,
        margin: 7,
    },
    textField216: {
        width: 216,
        margin: 7,
    },
    textFieldSmall: {
        width: 100,
        margin: 7,
    },
    textField2: {
        width: 270,
        margin: 7,
    },
    textField25: {
        width: 250,
        margin: 7,
    },
    rigthPosition: {
        marginLeft: '214px'
    },
    fileField: {
        marginTop: '0px',
        marginLeft: '7px',
        marginRight: '7px',
    },
    textMax: {
        width: 418,
        margin: 7,
    },
    textMax2: {
        width: 632,
        margin: 7,
    },
    textMax2T: {
      width: 632,
      marginTop: '0px',
      marginBottom: '7px',
      marginLeft: '7px',
      marginRight: '7px',
    },
    cleanTable: {
        borderCollapse: 'collapse',
        borderSpacing: '0'
    },
    tableColumn: {
        width: 280,
        margin: 7,
    },
    menu: {
        width: 200,
    },
    menuExtended: {
        width: 1400,
    },
    loader: {
        padding: '200px 0',
    },
    link: {
        display: 'inline-block',
    },
    linkLeftMargin: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 7,
    },
    notchedOutline: {
        borderWidth: "2px",
        borderColor: "#3f51b5 !important"
    },
    topAlign: {
      verticalAlign: "top"
    },
    width42: {
      width: "42px"
    },
    width250: {
      width: "250px"
    },
    marginTop: {
      marginTop: '10px'
    },
    tableMinWidth: {
      width: "1%",
      whiteSpace: "nowrap"
    },
    maxWidth480: {
      width: "480pt",
      paddingRight: "10px"
    },
    imgSpan: {
        width:'16px'
    }
};

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderWidth: '1px',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
      padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const year = new Date().getFullYear();

const years = Array.apply(null, Array(13)).map(
    function (_, i) {
        return {
            value: year - i,
            label: year - i,
        };
    });

const contractTypes = {
  0: 'Договор негосударственного пенсионного страхования',
  1: 'Договор добровольного пенсионного страхования',
  2: 'Договор добровольного страхования жизни',
  3: 'Дополнительные страховые взносы на накопительную пенсию'
};

const taxCurrencies = {
  'RUB': 'RUB',
  'EUR': 'EUR'
};

const forexIncomeCodes = {
  1535: '1535 - Доходы по операциям с производными финансовыми инструментами',
  1520: '1520 - Доходы от реализации имущества (доли), кроме ценных бумаг',
  2900: '2900 - Доходы, полученные от операций с иностранной валютой',
};

const foreignExchangeSwapCodes = {
    1535: '1535 - Доходы по операциям с производными финансовыми инструментами',
    1530: '1530 - Доходы, полученные по операциям с ценными бумагами',
};

class TaxFormEe extends React.Component {
    constructor(props) {
        super(props);

        this.setState({ showLoginDialog: !this.props.loginOk.value });

        // This binding is necessary to make `this` work in the callback
        this.sendDataToCreateDeclaration = this.sendDataToCreateDeclaration.bind(this);
        this.handleFillTestData = this.handleFillTestData.bind(this);
        this.loadInspections = this.loadInspections.bind(this);
        this.loadInspections();
        this.addReportFile = this.addReportFile.bind(this);
        this.addDeductionByPrevious = this.addDeductionByPrevious.bind(this);
        this.addDeductionContracts = this.addDeductionContracts.bind(this);
        this.saveTaxpayerData = this.saveTaxpayerData.bind(this);
        this.loadTaxpayerData = this.loadTaxpayerData.bind(this);
        this.handleAttachTaxpayerDataFile = this.handleAttachTaxpayerDataFile.bind(this);
        this.addDeductionChildrenEducation = this.addDeductionChildrenEducation.bind(this);
        this.delDeductionChildrenEducation = this.delDeductionChildrenEducation.bind(this);
        this.requestProgress = this.requestProgress.bind(this);
    }

    loadInspections() {
        this.setState({ inProgress: true });
        return axios.get('/listInspections')
            .then((response) => {
                this.setState({ inProgress: false });
                this.setState({ inspections: response.data });
            })
            .catch((error) => {
                this.setState({ inProgress: false });
                console.log(error);
            });
    }

    maxInputFilesCount = 10;
    submitURL = "/submitIBReportToDecl";

    state = {
        taxpayerDataFile: null,
        lastName: '',
        firstName: '',
        middleName: '',
        index: '',
        regionCode: '',
        inspectionNumber: '',
        district: '',
        city: '',
        street: '',
        house: '',
        housing: '',
        flat: '',
        inn: '',
        oktmo: '',
        birthDate: {selectedDate: function() {
            var date = new Date();
            date.setFullYear( date.getFullYear() - 40);
            return date;
        }()},
        birthLocation: '',
        passpNumber: '',
        passpDate: {selectedDate: function() {
            var date = new Date();
            date.setFullYear( date.getFullYear() - 20);
            return date;
        }()},
        passpIssued: '',
        taxYear: '',
        transferFees: '0',
        managementFees: '0',
        tPlus: true,
        groupByDays: false,
        email: '',
        correctionNumber: 0,
        phone: '',
        ignoreMonthlyMinimumFee: false,
        ignoreForex: getCookieBoolean("ignoreForex", true),
        editablePDFs: getCookieBoolean("editablePDFs", false),
        ignoreWithdrawFee: false,
        ignoreConsultantFee: false,
        ignoreVariousFee: false,
        futuresBasedOnVariationMargin: true,
        roundNumbersForCFR: getCookieBoolean("roundNumbersForCFR", false),

        outputAccountNotice: getCookieBoolean("outputAccountNotice", false),
        outputOpenNotice: getCookieBoolean("outputOpenNotice", true),
        outputXLSX: getCookieBoolean("outputXLSX", false),
        outputExpalnationNotePDF: getCookieBoolean("outputExpalnationNotePDF", false),
        outputXML: getCookieBoolean("outputXML", true),
        outputDC9: getCookieBoolean("outputDCX", false),
        outputFinParser: getCookieBoolean("outputFinParser", false),
        outputPDF: getCookieBoolean("outputPDF", false),
        cfrExcel: getCookieBoolean("cfrExcel", false),
        cfrPDF: getCookieBoolean("cfrPDF", false),
        taxOptimizeXLSX: getCookieBoolean("taxOptimizeXLSX", false),

        inProgress: false,
        documentGeneration: false,

        startStage: "Чтение файлов отчетов",
        progress: {
          id: '',
          percent: 0,
          stage: this.startStage,
          timeout: 1000},

        inputFiles: [{
            fileIndex: 0,
            fileContents: null,
            fileName: '',
            inputFormat: 'ib',
            accountOpeningDate: {selectedDate: null},
            accountClosingDate:  {selectedDate: null}}],

        inspections: [],
        showConfDialog: false,
        showAlertDialog: false,
        showLoginDialog: !this.props.loginOk.value,
        errorShow: false,
        errorMessage: '',
        warningShow: false,
        warningMessages: [],
        warningConfirm: false,
        foreignExchangeSwapCode: 1535,
        forexIncomeCode: 1535,
        taxCurrency: 'RUB',

        // Вычеты
        deductionPhilanthropy: 0,
        deductionTreatment: 0,
        deductionSelfEducation: 0,
        deductionExpensiveTreatment: 0,
        deductionInsurance: 0,
        deductionQualificationAssessment: 0,
        deductionPhilanthropy30: 0,
        deductionMedications: 0,
        deductionChildrenEducation: [],        
        deductionContracts: [],
        deductionByPrevious: []
    };

    handleAlert() {
        this.setState({ showAlertDialog: true });
    };

    checkFormFilled() {
        var result = true;
        this.setState({ innError: false,
                        innErrorText: ''});
        if (this.state.inn === '') {
            this.setState({ innError: true,
                            innErrorText: 'ИНН не может быть пустым.'});
            result = false;
        } else if (this.state.inn.length !== 12) {
            this.setState({ innError: true,
                            innErrorText: 'ИНН должен состоять из 12 цифр.'});
            result = false;
        }

        this.setState({ taxYearError: false,
                        taxYearErrorText: ''});
        if (this.state.taxYear === '') {
            this.setState({ taxYearError: true,
                            taxYearErrorText:
                            'Налоговый год не может быть пустым.'});
            result = false;
        }

        this.state.inputFiles.forEach(f => {
            if (f.fileContents != null) {
                f.fileError = false;
                f.fileErrorText = '';
            } else {
                f.fileError = true;
                f.fileErrorText = 'Добавьте файл отчета.';
                result = false;
            }});

        return result;
    }

    dateFormat(date) {
        if (date instanceof Date) {
            var [month, day, year] = date.toLocaleDateString("en-US").split("/");
            if (day.length < 2) {
                day = '0' + day;
            }
            return day + '.' + month + '.' + year;
        } else {
            return date;
        }
    }

    sendDataToCreateDeclaration(event) {
        if (event != null) {
            event.preventDefault();
        }
        if (!this.checkFormFilled()) {
            this.handleAlert();
            return;
        }

        this.setState({ inProgress: true, documentGeneration: true });
        const config = { headers: { 'Content-Type': 'multipart/form-data',
                                    'X-Requested-With': 'HttpRequest',
                                    'Csrf-Token': 'nocheck'},
                         responseType: 'json',
                         timeout: 0};
        const form = new FormData();
        const data = {};
        ['lastName',
         'firstName',
         'middleName',
         'index',
         'regionCode',
         'district',
         'city',
         'street',
         'house',
         'housing',
         'flat',
         'inspectionNumber',
         'inn',
         'birthDate',
         'birthLocation',
         'passpNumber',
         'passpDate',
         'passpIssued',
         'oktmo',
         'email',
         'correctionNumber',
         'phone'].forEach(f => {
             switch (f) {
             case 'birthDate':
                 data['birthDate'] = this.dateFormat(
                     this.state.birthDate.selectedDate);
                 break;
             case 'passpDate':
                 data['passpDate'] = this.dateFormat(
                     this.state.passpDate.selectedDate);
                 break;
             case 'inspectionNumber':
                 let inspNumberText = this.state.inspectionNumber["name"];
                 if (inspNumberText != null) {
                     let splitted = inspNumberText.split('-');
                     if (splitted.length > 0) {
                         data['inspectionNumber'] = splitted[0].trim();
                         break;
                     }
                 }
                 data['inspectionNumber'] = '';
                 break;
             case 'passpNumber':
                 let pn = this.state.passpNumber.replace(/\s/g,'');
                 data['passpNumber'] =
                     pn.slice(0,2) + ' ' + pn.slice(2,4) + ' ' + pn.slice(4);
                 break;
             default:
                 data[f] = this.state[f];
             }
         });
        form.append(['iserInfoData'], JSON.stringify(data));

        var inputFormats = [];
        var i = 0;
        this.state.inputFiles.forEach(item => {
            form.append('report'+i, item.fileContents.file);

            const inputFormatData = {};
            ['inputFormat',
            'accountOpeningDate',
            'accountClosingDate'].forEach(f => {
              switch (f) {
                case 'accountOpeningDate':
                    inputFormatData['accountOpeningDate'] = this.dateFormat(
                        item.accountOpeningDate.selectedDate);
                    break;
                case 'accountClosingDate':
                    inputFormatData['accountClosingDate'] = this.dateFormat(
                        item.accountClosingDate.selectedDate);
                    break;
                default:
                    inputFormatData[f] = item[f];
              }
            });
            inputFormats[i] = inputFormatData;
            i += 1;
        });
        form.append('inputFormats', JSON.stringify(inputFormats));

        form.append('outputFormats', JSON.stringify({
            'acc_notice_pdf': this.state.outputAccountNotice,
            'openNotice': this.state.outputOpenNotice,
            'decl_xml' : this.state.outputXML,
            'decl_dcx' : this.state.outputDC9,
            'decl_pdf' : this.state.outputPDF,
            'expl_note_xlsx': this.state.outputXLSX,
            'expl_note_pdf': this.state.outputExpalnationNotePDF,            
            'finparser_xlsx' : this.state.outputFinParser,
            'cfr_excel': this.state.cfrExcel && this.isCfrApplicable(),
            'cfr_pdf': this.state.cfrPDF && this.isCfrApplicable(),
            'tax_optimize_xlsx': this.state.taxOptimizeXLSX}));

        const settingsData = {};
        ['tPlus',
         'groupByDays',
         'ignoreMonthlyMinimumFee',
         'ignoreWithdrawFee',
         'ignoreConsultantFee',
         'ignoreVariousFee',
         'futuresBasedOnVariationMargin',
         'roundNumbersForCFR',
         'ignoreForex',
         'editablePDFs',
         'foreignExchangeSwapCode',
         'forexIncomeCode',
         'taxCurrency',
         'taxYear'].forEach(f => {
          settingsData[f] = this.state[f];
         });
        form.append(['settingsData'], JSON.stringify(settingsData));

        ['transferFees',
         'managementFees',
         'warningConfirm'].forEach(f => {
             form.append([f], this.state[f]);
         });

         const deductions = {};
         ['deductionPhilanthropy',
          'deductionTreatment',
          'deductionSelfEducation',
          'deductionExpensiveTreatment',
          'deductionInsurance',
          'deductionQualificationAssessment',
          'deductionPhilanthropy30',
          'deductionMedications',
          'deductionByPrevious'].forEach(f => {deductions[f] = this.state[f];})
        form.append(['deductions'], JSON.stringify(deductions));

        // deductionChildrenEducation: []
        // deductionContracts []

        this.setState({ errorShow: false, errorMessage: '' });
        this.setState({ warningConfirm: false });

        this.setState({progress: {
          id: '',
          percent: 0,
          stage: this.startStage,
          timeout: 1000}}, () => {
        axios.post(this.submitURL, form, config)
            .then( (response) => {
                this.state.progress.id = response.data.progressId;
                setTimeout(this.requestProgress, 100);
            })
            .catch( (error) => {
                this.setState({ inProgress: false, documentGeneration: false});
                let self = this;
                if (error.response.status === 413) {
                    self.setState({
                        errorShow: true,
                        errorMessage: 'Размер файла превышает допустимый лимит'
                    });
                } else if (error.response.status === 401) {
                    this.props.setLoginOk(false);
                    this.setState({ showLoginDialog: !this.props.loginOk.value });
                    this.forceUpdate();
                } else {
                    console.log(error);
                }
            });
        })
    };

    requestProgress = () => {      
        axios.get('/progress/get/' + this.state.progress.id)
          .then((response) => {
              let isStoped = response.data.isStoped;
              let isComplete = response.data.isComplete;
              
              this.state.progress.percent = response.data.percent
              this.state.progress.stage = response.data.stage
              this.forceUpdate();

              if (isStoped) {
                let self = this;
                let warnResp = JSON.parse(response.data.message);
                self.setState({
                    inProgress: false, documentGeneration: false,
                    warningShow: true,                    
                    warningMessages: warnResp
                }, () => {this.forceUpdate()});

              } else if (isComplete) {
                  const config = { headers: { 'Content-Type': 'multipart/form-data',
                                  'X-Requested-With': 'HttpRequest',
                                  'Csrf-Token': 'nocheck'},
                            responseType: 'blob',
                            timeout: 0};
                  axios.post('/progress/result/' + this.state.progress.id, null, config)
                    .then( (response) => {  
                        const fileName = response.headers['content-disposition']
                              .split("attachment; filename=")[1];
                        var download = require('downloadjs');
                        this.setState({ inProgress: false, documentGeneration: false});
                        download(response.data, fileName);
                    })
                    .catch( (error) => {
                        this.setState({ inProgress: false, documentGeneration: false });
                        console.log(error);
                    });
              } else {                                  
                  setTimeout(this.requestProgress, this.state.progress.timeout);
                  this.state.progress.timeout = Math.min(
                    this.state.progress.timeout + 1000, 5000)
              }
          })
          .catch((error) => {
              this.setState({ inProgress: false, documentGeneration: false});
              console.log(error);
        })
    }

    handleItemChange = (item, name) => event => {
        if (name.includes('Date')) {
          item[name] = { selectedDate: event };
        } else {
          var value;
          if (Number.isInteger(event.target.value)) {
              value = event.target.value.toString();
          } else {
              value = event.target.value;
          }
          item[name] = value;
        }
        this.forceUpdate();
    };

    addDeductionByPrevious(event) {
        if (event != null) {
            event.preventDefault();
        }

        var idx = 0;
        if (this.state.deductionByPrevious.length > 0) {
          idx = Math.max.apply(null, this.state.deductionByPrevious.map(i => {
              return i.deductionIndex;
          })) + 1;
        }
        this.state.deductionByPrevious.push(
            {deductionIndex: idx,
             brokerName: '',
             year: '',
             deductionForSecurities: 0,
             deductionForDerivatives: 0});
        this.forceUpdate();
    }

    delDeductionByPrevious = idx => event => {
      if (event != null) {
          event.preventDefault();
      }
      this.setState({ deductionByPrevious: this.state.deductionByPrevious.filter(
          i => {
              return idx !== i.deductionIndex;
          })});
      this.forceUpdate();
    }

    addDeductionContracts(event) {
      var idx = 0;
      if (this.state.deductionContracts.length > 0) {
        idx = Math.max.apply(null, this.state.deductionContracts.map(i => {
            return i.deductionIndex;
        })) + 1;
      }
      this.state.deductionContracts.push(
        {deductionIndex: idx,
         contractType: '',
         orgOrFundName: '',
         inn: '',
         kpp: '',
         contractNumber: '',
         contractDate: new Date(),
         amount: 0});
      this.forceUpdate();
    }

    delDeductionContracts = idx => event => {
      if (event != null) {
          event.preventDefault();
      }
      this.setState({ deductionContracts: this.state.deductionContracts.filter(
          i => {
              return idx !== i.deductionIndex;
          })});
      this.forceUpdate();
    }

    addDeductionChildrenEducation(event) {
      this.state.deductionChildrenEducation.push(0);
      this.forceUpdate();
    }

    delDeductionChildrenEducation(event) {
      this.state.deductionChildrenEducation.pop();
      this.forceUpdate();
    }

    addReportFile(event) {
        if (event != null) {
            event.preventDefault();
        }

        let idx = Math.max.apply(null, this.state.inputFiles.map(i => {
            return i.fileIndex;
        })) + 1;
        this.state.inputFiles.push(
            {fileIndex: idx,
             fileContents: null,
             fileName: '',
             inputFormat: 'ib',
             accountOpeningDate: {selectedDate: null},
             accountClosingDate:  {selectedDate: null}});
        this.forceUpdate();
    }

    delReportFile = idx => event => {
        if (event != null) {
            event.preventDefault();
        }
        this.setState({ inputFiles: this.state.inputFiles.filter(
            i => {
                return idx !== i.fileIndex;
            })});
        this.forceUpdate();
    }

    handleChange = name => event => {
        var value = null;
        if (((typeof this.state[name]) === "number") && 
            ((typeof event.target.value) === "string")) {
          value = parseInt(event.target.value);
        } else {
          value = event.target.value;
        }
        this.setState({
            [name]: value,
        });
        if (name === 'taxYear') {
            this.forceUpdate();
        }
    };

    handleChangeAutocomplete = name => (event, newValue) => {
        this.setState({
            [name]: newValue,
        });
    };

    handleCheckboxChange = event => {
        this.setState({[event.target.name]: event.target.checked });
    };

    handleCheckboxChangeWithCookie = cname => event => {
      this.setState({[event.target.name]: event.target.checked });
      if (cname != null) {
        setCookie(cname, event.target.checked)
      }
    };

    handleDateChange = name => (date) => {
        this.setState({[name]: { selectedDate: date }});
    };

    handleAttachFile = filesListItem => event => {
        filesListItem.fileContents = {
            name: event.target.value,
            file: event.target.files[0]
        };
        filesListItem.fileName = event.target.value;
        this.forceUpdate();
    }

    handleInputFormatChange = filesListItem => event => {
        filesListItem.inputFormat = event.target.value;
        this.forceUpdate();
    };

    handleAccountOpenCloseDateChange = (filesListItem, name) => (date) => {
        filesListItem[name] = { selectedDate: date }
        this.forceUpdate();
    };

    handleFillTestData() {
        this.handleOpen();
    };

    saveTaxpayerData() {
      const config = { headers: { 'Content-Type': 'multipart/form-data',
                                  'X-Requested-With': 'HttpRequest',
                                  'Csrf-Token': 'nocheck'},
                       responseType: 'blob',
                       timeout: 0};
      const data = {};
        ['lastName',
         'firstName',
         'middleName',
         'inspectionNumber',
         'oktmo',
         'inn',
         'birthDate',
         'birthLocation',
         'passpNumber',
         'passpDate',
         'passpIssued'].forEach(f => {
             switch (f) {
             case 'birthDate':
                 data['birthDate'] = this.dateFormat(
                     this.state.birthDate.selectedDate);
                 break;
             case 'passpDate':
                 data['passpDate'] = this.dateFormat(
                     this.state.passpDate.selectedDate);
                 break;
             case 'inspectionNumber':
                 let inspNumberText = this.state.inspectionNumber["name"];
                 if (inspNumberText != null) {
                     let splitted = inspNumberText.split('-');
                     if (splitted.length > 0) {
                         data['inspectionNumber'] = splitted[0].trim();
                         break;
                     }
                 }
                 data['inspectionNumber'] = '';
                 break;
             case 'passpNumber':
                 let pn = this.state.passpNumber.replace(/\s/g,'');
                 data['passpNumber'] =
                     pn.slice(0,2) + ' ' + pn.slice(2,4) + ' ' + pn.slice(4);
                 break;
             default:
                 data[f] = this.state[f];
             }
         });

         const form = new FormData();
         form.append(['taxpayerData'], JSON.stringify(data));
         this.setState({ inProgress: true });
            axios.post('/taxpayer/save', form, config)
                .then( (response) => {
                    this.setState({ inProgress: false });
                    const fileName = response.headers['content-disposition']
                          .split("attachment; filename=")[1];
                    var download = require('downloadjs');
                    download(response.data, fileName);
                })
                .catch( (error) => {
                    this.setState({ inProgress: false });
                    console.log(error);
                });
    }

    handleAttachTaxpayerDataFile = event => {
      this.setState({ taxpayerDataFile: event.target.files[0],
                      inProgress: true }, () => 
        {
          const config = { headers: { 'Content-Type': 'multipart/form-data',
                                      'X-Requested-With': 'HttpRequest',
                                      'Csrf-Token': 'nocheck'},
                                responseType: 'json',
                                timeout: 0};
          const form = new FormData();
          form.append(['taxpayerDataFile'], this.state.taxpayerDataFile);
          this.inputTaxpayerDataFile.value = null;
          axios.post('/taxpayer/load', form, config)
          .then( (response) => {
              this.setState({ inProgress: false });
              Object.keys(response.data).map(key => {
                  this.setState({
                      [key]: response.data[key]
                  });
                  return null;
              });
              this.handleDateChange('birthDate')(response.data['birthDate']);
              this.handleDateChange('passpDate')(response.data['passpDate']);
              let self = this;
              this.handleChangeAutocomplete('inspectionNumber')(
                  null, function() {
                      let item = self.state.inspections.find(function(i) {
                          return i["kod"] === response.data['inspectionNumber'];
                      });
                      return self.createInspectionSelectionItem(item);
                  }());
          })
          .catch( (error) => {
              this.setState({ inProgress: false });
              console.log(error);
          });
      });
    }

    loadTaxpayerData() {
      this.inputTaxpayerDataFile.click();
    };

    handleConfirmWarning = value => {
        let confirm = (value === 'ok' ? true : false);
        this.setState({ warningShow: false }, () => {
            this.setState({warningMessages: [],
                           warningConfirm: confirm },
                          () => {
                              if (confirm) {
                                  this.sendDataToCreateDeclaration(null);
                              }
                          });
        });
    }

    handleOpen = () => {
        this.setState({ showConfDialog: true });
    };

    createInspectionSelectionItem = i => {
        return {name: i["kod"] + ' - ' + i["name"]};
    }

    handleClose = value => {
        this.setState({ showConfDialog: false });
        if (value === 'ok') {
            this.setState({ inProgress: true });
            axios.get('/fooUserInfo')
                .then( (response) => {
                    this.setState({ inProgress: false });
                    Object.keys(response.data).map(key => {
                        this.setState({
                            [key]: response.data[key]
                        });
                        return null;
                    });
                    this.handleDateChange('birthDate')(response.data['birthDate']);
                    this.handleDateChange('passpDate')(response.data['passpDate']);
                    let self = this;
                    this.handleChangeAutocomplete('inspectionNumber')(
                        null, function() {
                            let item = self.state.inspections.find(function(i) {
                                return i["kod"] === response.data['inspectionNumber'];
                            });
                            return self.createInspectionSelectionItem(item);
                        }());
                })
                .catch( (error) => {
                    this.setState({ inProgress: false });
                    console.log(error);
                });
        }
    };

    getDeclarationExtension() {
        let yearStr = this.state.taxYear.toString();
        return "DC" + (yearStr.length < 1
                       ? "X"
                       : yearStr.substr(yearStr.length - 1));
    }

    isCfrApplicable() {
      return (this.state.taxYear === '' || parseInt(this.state.taxYear) >= 2020);
    }

    zipBy3(arr) {
      let result = [];
      arr.map(a => {
        if ((result.length === 0) || (result[result.length - 1].length === 3)) {
          return result.push([a]);
        } else {
          return result[result.length - 1].push(a);
        }
      });
      return result;
    }

    zipWithIndex = arr => {
      let i = 0;
      return arr.map(a => {
        let v = [i, a];
        i++;
        return v;
      });
    }

    render() {
        const { classes } = this.props;
        const renderInspections = this.state.inspections.map(
            this.createInspectionSelectionItem);

        return (
            <Center>
              <input
                hidden={true}
                accept=".json"
                ref={input => this.inputTaxpayerDataFile = input}
                type="file" onChange={this.handleAttachTaxpayerDataFile} />
              {!this.state.inProgress
               ? <form className={classes.container}
                       noValidate autoComplete="off"
                       id="taxForm">

                      {this.state.inputFiles.map(f => (
                       <Card className={classes.card}>
                         <CardContent>
                           <table className="cleanTable" cellspacing="0">
                             <tr>
                               <td>
                               <span className={classes.fileField}>
                                  <Upload label='Файл отчета'
                                          accept={inputFormatsList("en")[f.inputFormat].extensionAccept}
                                          error={f.fileError}
                                          helperText={f.fileErrorText}
                                          parent={this}
                                          dataFromParent = {f.fileName}
                                          handleChange={this.handleAttachFile(f)}/>
                                </span>
                               </td>
                               <td>
                                <TextField
                                    id="inputFormat"
                                    select
                                    label="Формат исходных данных"
                                    value={f.inputFormat}
                                    onChange={this.handleInputFormatChange(f)}
                                    className={classes.textMax}
                                    margin="normal"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}>
                                    {Object.keys(inputFormatsList("en")).map(k => (
                                        <MenuItem key={k} value={k}>
                                          <span className={classes.imgSpan}>
                                            <img src={inputFormatsList("en")[k].img}
                                                 height='16px'
                                                 style={{ maxWidth: '18px'}}
                                                 alt='logo'>
                                            </img>
                                          </span>
                                          &nbsp;
                                          {inputFormatsList("ru")[k].name}
                                        </MenuItem>
                                    ))}
                                  </TextField>
                               </td>
                               <td>
                               {f.fileIndex === 0
                                ? (<HtmlTooltip
                                              title={
                                                  <React.Fragment>
                                                    Добавить отчет.<p/>
                                                    В случае, если требуется составить декларацию
                                                    на основе нескольких отчетов (возможно различных)
                                                    брокеров.
                                                  </React.Fragment>
                                              }
                                              arrow={true}
                                              placement="left" >
                                    <Button className={classes.buttonPlus}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.inputFiles.length >=
                                                      this.maxInputFilesCount}
                                            onClick={this.addReportFile}>
                                      <AddIcon/>
                                    </Button>
                                    </HtmlTooltip>)
                                : (<Button className={classes.buttonPlus}
                                          variant="contained"
                                          color="secondary"
                                          onClick={this.delReportFile(f.fileIndex)}>
                                    <RemoveIcon/>
                                  </Button>)}
                               </td>
                             </tr>
                             <tr>
                              <td></td>
                              <td>
                                <div className={classes.linkLeftMargin}>
                                  <Link to={inputFormatsList("en")[f.inputFormat].helpURL}
                                      component={NavLink}
                                      className={classes.link}>
                                      Как получить данный отчет
                                  </Link>
                                </div>
                              </td>
                              <td></td>
                             </tr>
                           </table>
                         </CardContent>
                       </Card>
                   ))}

                   <Card className={classes.card}>
                     <CardContent>
                       Получить результат в виде:
                       <FormGroup className={classes.marginTop}>
                        <table>                        
                          <tr>
                            <td className={classes.width42}>
                              <CreateOutlinedIcon style={{ fontSize: 32 }}/>
                            </td>
                            <td className={classes.width250}>
                              <Typography>
                                Пояснительная записка к налоговой декларации
                              </Typography>                            
                            </td>
                            <td>
                              <FormControlLabel
                                control={
                                    <Checkbox
                                      checked={this.state.outputXLSX}
                                      onChange={this.handleCheckboxChangeWithCookie("outputXLSX")}
                                      name="outputXLSX"
                                      color="primary"/>
                                }
                                label="Excel"/>
                            </td>
                            <td className={classes.tableMinWidth} colspan="2">
                              <FormControlLabel
                                control={
                                    <Checkbox
                                      checked={this.state.outputExpalnationNotePDF}
                                      onChange={this.handleCheckboxChangeWithCookie("outputExpalnationNotePDF")}
                                      name="outputExpalnationNotePDF"
                                      color="primary"/>
                                }
                                label="PDF"/>
                           </td>
                           <td></td>
                          </tr>
                          <tr>
                            <td className={classes.width42}>
                              <DescriptionOutlinedIcon style={{ fontSize: 32 }}/>
                            </td>
                            <td className={classes.width250}>
                              <Typography>
                                Налоговая декларация
                              </Typography>                            
                            </td>
                            <td className={classes.tableMinWidth}>
                              <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                          Формат подачи электронной декларации
                                        </React.Fragment>
                                    }
                                    arrow={true}
                                    placement="top">
                                <FormControlLabel
                                  control={
                                      <Checkbox
                                        checked={this.state.outputXML}
                                        onChange={this.handleCheckboxChangeWithCookie("outputXML")}
                                        name="outputXML"
                                        color="primary"/>
                                  }
                                  label="XML"/>
                              </HtmlTooltip>
                            </td>
                            <td className={classes.tableMinWidth} colspan="2">
                              <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                          Формат программы Декларация
                                        </React.Fragment>
                                    }
                                    arrow={true}
                                    placement="top">
                                <FormControlLabel
                                  control={
                                      <Checkbox
                                        checked={this.state.outputDC9}
                                        onChange={this.handleCheckboxChangeWithCookie("outputDCX")}
                                        name="outputDC9"
                                        color="primary"/>}
                                  label={this.getDeclarationExtension()}/>
                                </HtmlTooltip>
                              </td>
                              {/* <td className={classes.tableMinWidth}>
                                <HtmlTooltip
                                  title={
                                      <React.Fragment>
                                        Документ для печати<br/>
                                        За данный формат взимается дополнительная
                                        плата (500 руб.)
                                      </React.Fragment>
                                  }
                                  arrow={true}
                                  placement="top">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.outputPDF}
                                        onChange={this.handleCheckboxChangeWithCookie("outputPDF")}
                                        name="outputPDF"/>}
                                        label="PDF"/>
                                </HtmlTooltip>
                           </td> */}
                          </tr>
                          <tr>
                            <td className={classes.width42}>
                            <img src="/images/finParserLogo.svg"
                               height='30px'
                               width='30px'
                               alt='logo'/>
                            </td>
                            <td className={classes.width250}>
                              <Typography>
                                Сделки и позиции в формате FinParser
                              </Typography>
                            </td>
                            <td className={classes.tableMinWidth} colspan="3">
                            <FormControlLabel
                              control={
                                  <Checkbox
                                    checked={this.state.outputFinParser}
                                    onChange={this.handleCheckboxChangeWithCookie("outputFinParser")}
                                    name="outputFinParser"
                                    color="primary"/>}
                              label="Excel"/>
                           </td>
                           <td></td>
                           <td></td>
                          </tr>
                        </table>
                       </FormGroup>                       
                     </CardContent>
                   </Card>

                   <Card className={classes.card}>
                     <CardContent>
                       <TextField
                         id="lastName"
                         label="Фамилия"
                         className={classes.textField}
                         value={this.state.lastName}
                         onChange={this.handleChange('lastName')}
                         margin="normal"/>
                       <TextField
                         id="firstName"
                         label="Имя"
                         className={classes.textField}
                         value={this.state.firstName}
                         onChange={this.handleChange('firstName')}
                         margin="normal"/>
                       <TextField
                         id="middleName"
                         label="Отчество"
                         className={classes.textField}
                         value={this.state.middleName}
                         onChange={this.handleChange('middleName')}
                         margin="normal"/>
                       <TextField
                         id="inn"
                         error={this.state.innError}
                         helperText={this.state.innErrorText}
                         label="ИНН*"
                         value={this.state.inn}
                         onChange={this.handleChange('inn')}
                         type="number"
                         className={classes.textField}
                         margin="normal"/>
                       <HtmlTooltip
                         title={
                             <React.Fragment>
                               Должен соответствовать периоду в отчете.<br/>
                               <b><u>Иначе Декларация будет пустая</u></b>.
                             </React.Fragment>
                         }
                         arrow={true}
                         placement="right">
                         <TextField
                           id="taxYear"
                           select
                           error={this.state.taxYearError}
                           helperText={this.state.taxYearErrorText}
                           label="Налоговый год*"
                           value={this.state.taxYear}
                           onChange={this.handleChange('taxYear')}
                           className={classes.textField}
                           variant="outlined"
                           margin="normal"
                           InputProps={{
                               classes: {
                                   notchedOutline: classes.notchedOutline
                               }
                           }}
                           SelectProps={{
                               MenuProps: {
                                   className: classes.menu,
                               },
                           }}>
                           {years.map(option => (
                               <MenuItem key={option.value} value={option.value}>
                                 {option.label}
                               </MenuItem>
                           ))}
                         </TextField>
                       </HtmlTooltip>
                       <HtmlTooltip
                         title={
                             <React.Fragment>
                               <b>0</b> - <u>первичный документ</u>.<p/>
                               В случае, если эта декларация уточняет другую,
                               уже поданную за этот год декларацию, в номере
                               корректировки должно быть
                               указано <b>1, 2, 3</b> и так далее -
                               <u>уточненный документ</u>.<p/>
                               Для уточненного документа значение должно
                               быть на 1 больше ранее принятого налоговым
                               органом документа.
                             </React.Fragment>
                         }
                         arrow={true}
                         placement="right" >
                         <TextField
                           id="correctionNumber"
                           label="Номер корректировки"
                           value={this.state.correctionNumber}
                           onChange={this.handleChange('correctionNumber')}
                           type="number"
                           className={classes.textField}
                           margin="normal"/>
                       </HtmlTooltip>
                       <TextField
                         id="phone"
                         label="Телефон"
                         className={classes.textField}
                         value={this.state.phone}
                         onChange={this.handleChange('phone')}
                         margin="normal"/>
                       <TextField
                         id="oktmo"
                         label="ОКТМО"
                         value={this.state.oktmo}
                         onChange={this.handleChange('oktmo')}
                         className={classes.textMax}
                         margin="normal"/>
                       <Link href="https://fias.nalog.ru/"
                             className={classes.link}
                             target="_blank">
                         Уточнить<br/>ОКТМО
                       </Link>
                       <table className="cleanTable">
                         <tr>
                           <td>
                             <Autocomplete
                               id="inspectionNumber"
                               value={this.state.inspectionNumber}
                               options={renderInspections}
                               getOptionLabel={(option) => option.name}
                               className={classes.textMax2}
                               autoHighlight={true}
                               onChange={this.handleChangeAutocomplete(
                                   'inspectionNumber')}
                               renderInput={(params) =>
                                   <TextField {...params}
                                              label="Номер налоговой инспекции"
                                              margin="normal"/>}
                             />
                           </td>
                           <td>
                             <Link href="https://service.nalog.ru/addrno.do"
                                   className={classes.link}
                                   target="_blank">
                               Уточнить №<br/>налоговой
                             </Link>
                           </td>
                         </tr>
                       </table>
                       <KeyboardDatePicker
                         id="birthDate"
                         keyboard
                         label="Дата рождения"
                         format="dd.MM.yyyy"
                         placeholder="10.10.2018"
               // handle clearing outside => pass plain array
               // if you are not controlling value outside
               // mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                         value={this.state.birthDate.selectedDate}
                         onChange={this.handleDateChange('birthDate')}
                         animateYearScrolling={false}
                         className={classes.dateFiled}/>
                       <TextField
                         id="birthLocation"
                         label="Место рождения"
                         value={this.state.birthLocation}
                         onChange={this.handleChange('birthLocation')}
                         className={classes.textField}
                         margin="normal"/>
                       <TextField
                         id="passpNumber"
                         label="Номер паспорта"
                         value={this.state.passpNumber}
                         onChange={this.handleChange('passpNumber')}
                         className={classes.textField}
                         margin="normal"/>
                       <KeyboardDatePicker
                         id="passpDate"
                         keyboard
                         label="Дата выдачи паспорта"
                         format="dd.MM.yyyy"
                         placeholder="10.10.2018"
               // handle clearing outside => pass plain array
               // if you are not controlling value outside
               // mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                         value={this.state.passpDate.selectedDate}
                         onChange={this.handleDateChange('passpDate')}
                         animateYearScrolling={false}
                         className={classes.dateFiled}/>
                       <TextField
                         id="passpIssued"
                         label="Кем выдан паспорт"
                         value={this.state.passpIssued}
                         onChange={this.handleChange('passpIssued')}
                         className={classes.textMax}
                         margin="normal"/>
                       <Button className={classes.buttonBottom}
                               variant="contained"
                               color="secondary"
                               onClick={this.handleFillTestData}>
                         Заполнить тестовыми данными
                       </Button>
                       <HtmlTooltip
                           title={
                               <React.Fragment>
                                 Загрузить данные о налогоплательщике из файла
                               </React.Fragment>
                           }
                           arrow={true}
                           placement="bottom">
                        <Button className={classes.buttonBottom}
                                variant="contained"
                                onClick={this.loadTaxpayerData}>
                          Загрузить
                        </Button>
                       </HtmlTooltip>
                       <HtmlTooltip
                           title={
                               <React.Fragment>
                                 Сохранить данные о налогоплательщике в файл
                               </React.Fragment>
                           }
                           arrow={true}
                           placement="bottom">                       
                        <Button className={classes.buttonBottom}
                                variant="contained"
                                onClick={this.saveTaxpayerData}>
                          Сохранить
                        </Button>
                       </HtmlTooltip>
                     </CardContent>
                   </Card>

                   <Card className={classes.cardWithPaddingBottom}>
                       <Accordion onChange={(event, expanded) => {setCookie("expandReduce", expanded); this.forceUpdate();}}
                                  expanded={getCookieBoolean("expandReduce")}>
                         <HtmlTooltip
                           title={
                               <React.Fragment>
                                 Инвестиционные убытки прошлых лет (для зачета),
                                 имущественные и социальные вычеты.
                               </React.Fragment>
                           }
                           arrow={true}
                           placement="top">
                           <AccordionSummary
                             expandIcon={<ExpandMoreIcon />}
                             aria-controls="panel1a-content"
                             id="panel1a-header" >
                             <Typography>
                               Вычеты
                             </Typography>
                           </AccordionSummary>
                         </HtmlTooltip>
                         <AccordionDetails>
                          <CardContent className={classes.cardContentWithoutPadding}>
                            <table className="cleanTable">
                              <tr><td>
                            <table className="cleanTable">
                              {/* <tr>
                                <td colspan="3">
                                  Социальные налоговые вычеты:
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <TextField
                                    label="благотворительность"
                                    value={this.state.deductionPhilanthropy}                                    
                                    onChange={this.handleChange('deductionPhilanthropy')}
                                    type="number"
                                    className={classes.textField}
                                    margin="normal"/>
                                </td>
                                <td>
                                  <TextField
                                    label="лечение"
                                    value={this.state.deductionTreatment}
                                    onChange={this.handleChange('deductionTreatment')}
                                    type="number"
                                    className={classes.textField}
                                    margin="normal"/>
                                </td>
                                <td>
                                  <TextField
                                    label="свое обучение"
                                    value={this.state.deductionSelfEducation}
                                    onChange={this.handleChange('deductionSelfEducation')}
                                    type="number"
                                    className={classes.textField}
                                    margin="normal"/>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <TextField
                                    label="дорогостоящее лечение"
                                    value={this.state.deductionExpensiveTreatment}
                                    onChange={this.handleChange('deductionExpensiveTreatment')}
                                    type="number"
                                    className={classes.textField}
                                    margin="normal"/>
                                </td>
                                <td>
                                  <TextField
                                    label="добровольное страхование"
                                    value={this.state.deductionInsurance}
                                    onChange={this.handleChange('deductionInsurance')}
                                    type="number"
                                    className={classes.textField}
                                    margin="normal"/>
                                </td>
                                <td>
                                  <TextField
                                    label="оценка квалификации"
                                    value={this.state.deductionQualificationAssessment}
                                    onChange={this.handleChange('deductionQualificationAssessment')}
                                    type="number"
                                    className={classes.textField}
                                    margin="normal"/>
                                </td>
                                <td></td> 
                              </tr>
                              <tr>
                                <td>
                                  <TextField
                                    label="благотворительность до 30%"
                                    value={this.state.deductionPhilanthropy30}
                                    onChange={this.handleChange('deductionPhilanthropy30')}
                                    type="number"
                                    className={classes.textField}
                                    margin="normal"/>
                                </td>
                                <td>
                                  <TextField
                                    label="лекарственные препараты"
                                    value={this.state.deductionMedications}
                                    onChange={this.handleChange('deductionMedications')}
                                    type="number"
                                    className={classes.textField}
                                    margin="normal"/>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  Суммы, уплаченные за обучение детей
                                </td>
                                <td>
                                  <HtmlTooltip
                                      title={
                                          <React.Fragment>
                                            Добавить сумму, уплаченную за обучение детей.
                                          </React.Fragment>
                                      }
                                      arrow={true}
                                      placement="left" >
                                    <Button className={classes.buttonPlusNoMargin}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.deductionChildrenEducation.length >= 12}
                                            onClick={this.addDeductionChildrenEducation}>
                                      <AddIcon/>
                                    </Button>
                                  </HtmlTooltip>
                                </td>
                              </tr> */}
                              {/* {this.zipWithIndex(this.zipBy3(
                                this.zipWithIndex(this.state.deductionChildrenEducation))).map(d3 => (
                                <tr>
                                  <td colspan="3">
                                    {d3[1].map(d => 
                                      // d[0] -- index
                                      // d[1] -- value
                                      <TextField
                                        value={d[1]}
                                        onChange={this.handleItemChange(this.state.deductionChildrenEducation, d[0])}
                                        type="number"
                                        className={classes.textField}
                                        margin="normal"/>
                                    )}
                                  </td>                      
                                  <td>
                                    {d3[0] === 0
                                    ? <HtmlTooltip
                                          title={
                                              <React.Fragment>
                                                Удалить сумму, уплаченную за обучение детей.
                                              </React.Fragment>
                                          }
                                          arrow={true}
                                          placement="left" >
                                        <Button className={classes.buttonPlusNoMargin}
                                                variant="contained"
                                                color="secondary"
                                                onClick={this.delDeductionChildrenEducation}>
                                          <RemoveIcon/>
                                        </Button>
                                      </HtmlTooltip>
                                    : ""
                                    }
                                  </td>
                                </tr>
                              ))} */}
                              {/* <tr>
                                <td className={classes.maxWidth480} colspan="3">
                                  Договоры добровольного страхования жизни,
                                  пенсионного страхования, негосударственного 
                                  пенсионного обеспечения и дополнительные взносы
                                  на накопительную пенсию
                                </td>
                                <td>
                                  <HtmlTooltip
                                      title={
                                          <React.Fragment>
                                            Добавить договор.
                                          </React.Fragment>
                                      }
                                      arrow={true}
                                      placement="left" >
                                    <Button className={classes.buttonPlusNoMargin}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.deductionContracts.length >= 12}
                                            onClick={this.addDeductionContracts}>
                                      <AddIcon/>
                                    </Button>
                                  </HtmlTooltip>
                                </td>
                              </tr> */}
                              {/* {this.state.deductionContracts.map(d =>
                              <><tr>
                                <td colspan="3">
                                  <TextField
                                    select
                                    label="Вид договора"
                                    value={d.contractType}
                                    onChange={this.handleItemChange(d, 'contractType')}
                                    className={classes.textMax2}
                                    margin="normal"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}>
                                    {Object.keys(contractTypes).sort().map(k => (
                                        <MenuItem key={k} value={k}>
                                          {contractTypes[k]}
                                        </MenuItem>
                                    ))}
                                  </TextField>
                                </td>
                                <td>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                              Удалить договор.
                                            </React.Fragment>
                                        }
                                        arrow={true}
                                        placement="left" >
                                      <Button className={classes.buttonPlusNoMargin}
                                              variant="contained"
                                              color="secondary"
                                              onClick={this.delDeductionContracts(d.deductionIndex)}>
                                        <RemoveIcon/>
                                      </Button>
                                    </HtmlTooltip>
                                </td>
                              </tr>  
                              <tr>
                                <td colspan="3">
                                    <TextField
                                      label="Наименование организации или фонда"
                                      value={d.orgOrFundName}
                                      onChange={this.handleItemChange(d, 'orgOrFundName')}
                                      className={classes.textMax2}
                                      margin="normal"/>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                    <TextField
                                      label="ИНН"
                                      value={d.inn}
                                      onChange={this.handleItemChange(d, 'inn')}
                                      type="number"
                                      className={classes.textField}
                                      margin="normal"/>
                                    <TextField
                                      label="КПП"
                                      value={d.kpp}
                                      onChange={this.handleItemChange(d, 'kpp')}
                                      type="number"
                                      className={classes.textField}
                                      margin="normal"/>
                                    <TextField
                                      label="Номер договора"
                                      value={d.contractNumber}
                                      onChange={this.handleItemChange(d, 'contractNumber')}
                                      type="number"
                                      className={classes.textField}
                                      margin="normal"/>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                    <KeyboardDatePicker
                                      keyboard
                                      label="Дата договора"
                                      format="dd.MM.yyyy"
                                      placeholder="10.10.2018"                                      
                                      value={d.contractDate.selectedDate}
                                      onChange={this.handleItemChange(d, 'contractDate')}
                                      animateYearScrolling={false}
                                      className={classes.textField}/>
                                    <TextField
                                      label="Сумма взноса"
                                      value={d.amount}
                                      onChange={this.handleItemChange(d, 'amount')}
                                      type="number"
                                      className={classes.textField}
                                      margin="normal"/>
                                </td>                     
                                <td></td>
                              </tr></>)} */}
                              <tr>
                                <td colspan="3">
                                  Инвестиционные убытки прошлых лет (для зачета)
                                </td>
                                <td>
                                  <HtmlTooltip
                                      title={
                                          <React.Fragment>
                                            Добавить убыток за прошлый период.<p/>
                                            В случае, если требуется учесть убытки
                                            за несколько прошлых лет и/или на
                                            нескольких брокерских/банковских счетах.
                                          </React.Fragment>
                                      }
                                      arrow={true}
                                      placement="left" >
                                      <Button className={classes.buttonPlusNoMargin}
                                              variant="contained"
                                              color="primary"
                                              disabled={this.state.deductionByPrevious.length >= 10}
                                              onClick={this.addDeductionByPrevious}>
                                        <AddIcon/>
                                      </Button>
                                  </HtmlTooltip>
                                </td>
                              </tr>
                              {this.state.deductionByPrevious.map(d => (                              
                                  <><tr>
                                  <td colspan="3">
                                    <TextField
                                      label="Источник (брокерский/банковский счет)"
                                      value={d.brokerName}
                                      onChange={this.handleItemChange(d, 'brokerName')}
                                      className={classes.textMax2T}
                                      margin="normal" />
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                      <TextField
                                        select
                                        error={this.state.taxYearError &&
                                                (d.deductionForSecurities !== 0 || 
                                                d.deductionForDerivatives !== 0 )}
                                        helperText={(d.deductionForSecurities !== 0 || 
                                                      d.deductionForDerivatives !== 0 )
                                                    ? this.state.taxYearErrorText
                                                    : ""}
                                        label="Год"
                                        value={d.year}
                                        onChange={this.handleItemChange(d, 'year')}
                                        className={classes.textFieldSmall}
                                        margin="normal"
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}>
                                        {years.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                        ))}
                                      </TextField>
                                      <HtmlTooltip
                                        title={"Убыток за указанный год по "+
                                                "обращаемым ценным бумагам к " +
                                                "зачету в данный налоговый год (руб.)."}
                                        arrow={true}
                                        placement="top">
                                        <TextField
                                          label="Убыток по операциям с ЦБ"
                                          value={d.deductionForSecurities}
                                          onChange={this.handleItemChange(d, 'deductionForSecurities')}
                                          type="number"
                                          className={classes.textField25}
                                          margin="normal"/>
                                      </HtmlTooltip>
                                      <HtmlTooltip
                                        title={"Убыток за указанный год по "+
                                                "обращаемым производным финансовым " +
                                                "инструментам к зачету в данный " +
                                                "налоговый год (руб.)."}
                                        arrow={true}
                                        placement="top">
                                        <TextField
                                          label="Убыток по операциям с ПФИ"
                                          value={d.deductionForDerivatives}
                                          onChange={this.handleItemChange(d, 'deductionForDerivatives')}
                                          type="number"
                                          className={classes.textField25}
                                          margin="normal"/>
                                      </HtmlTooltip>
                                      </td>
                                      <td>
                                      <Button className={classes.buttonPlusNoMargin}
                                        variant="contained"
                                        color="secondary"
                                        onClick={this.delDeductionByPrevious(d.deductionIndex)}>
                                        <RemoveIcon />
                                      </Button>
                                    </td>
                                  </tr></>
                              ))}
                            </table>
                            </td></tr>
                            <tr><td>                          
                          </td></tr>
                          </table>
                          </CardContent>
                         </AccordionDetails>
                      </Accordion>
                   </Card>

                   <div>
                      <Button className={classes.buttonBottom}
                               variant="contained"
                               color="primary"
                               disabled={!this.props.loginOk.value}
                               onClick={this.sendDataToCreateDeclaration}>
                         Сгенерировать отчеты
                      </Button>
                  </div>

                   <ConfirmationDialog
                     open={this.state.showConfDialog}
                     onClose={this.handleClose}
                     title='Заполнение формы'
                     message={'Форма будет заполнена тестовой информацией. ' +
                              'Все уже введенные данные будут перезаписаны. Продолжить?'}/>
                   <ConfirmationDialog
                     open={this.state.warningShow}
                     onClose={this.handleConfirmWarning}
                     messages={this.state.warningMessages}
                     title='Подтвердите генерацию отчетов'
                     ok='Подтвердить'
                     cancel='Отмена'/>
                   <AlertDialog
                     open={this.state.errorShow}
                     propertyName='errorShow'
                     parent={this}
                     title='Ошибка обработки отчета'
                     severity='error'
                     message={this.state.errorMessage}/>
                   <AlertDialog
                     open={this.state.showAlertDialog}
                     propertyName='showAlertDialog'
                     parent={this}
                     title='Ошибка заполнения данных'
                     message='Заполните необходимые поля формы.'/>
                   <LoginDialog
                     open={this.state.showLoginDialog &&
                           !this.props.loginOk.value}
                     propertyName='showLoginDialog'
                     text="генерация отчетов будет недоступна"
                     parent={this}/>
                 </form>
               : <div className={classes.loader}>
                   {this.state.documentGeneration
                    ? <Progress
                        parent={this}
                        context={this.context}/>
                    : <CircularProgress size={90}/>
                  }
                 </div>
              }
            </Center>
        );
    }
}

function mapStateToProps(state) {
    const loginOk = state.loginOk;
    return {
        loginOk
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
      setLoginOk: (value) => dispatch(setLoginOk(value)),
  };
};

TaxFormEe.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(TaxFormEe));
