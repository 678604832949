import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

class ConfirmationDialog extends React.Component {

    state = {};

    handleCancel = () => {
        this.props.onClose('cancel');
    };

    handleOk = () => {
        this.props.onClose('ok');
    };

    handleAction = () => {
        this.props.onAction('ok');
    };

    groupBy3 = (a) => {
      var b = []
      for (var i = 0; i < a.length; ++i) {
        if (i % 3 === 0) {
          b.push([a[i]])
        } else {
          b[b.length - 1].push(a[i])
        }
      }
      return b;
    }

    componentDidUpdate() {
      if (this.props.messages != null) {
        var i = 0;
        this.props.messages.map(msg => {
          var element = document.getElementById('message-body-' + i++);
          if (element != null) {
            element.innerHTML = msg.message;
          }
        })
      }
    }

    render() {
        const { ...other } = this.props;

        const hasError = (this.props.messages != null
          ? this.props.messages.find(m => m.severity === 'error')
          : false);

        var i = 0;
        return (
            <Dialog {...other}>
              {this.props.title != null
               ? <DialogTitle id="confirmation-dialog-title">
                   {this.props.title}
                 </DialogTitle>
               : ""}
              <DialogContent>
                {this.props.messages != null
                 ? (this.props.messages.map(m => (
                     <Alert severity={m.severity}>
                       <div id={"message-body-" + i++}></div>
                     </Alert>)))
                 : (this.props.severity == null
                    ? this.props.message
                    : <Alert severity={this.props.severity}>
                        {this.props.message}
                      </Alert>)}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCancel} color="default">
                  {this.props.cancel != null
                   ? this.props.cancel
                   : "Cancel"}
                </Button>
                {hasError
                  ? ""
                  : (<Button onClick={this.handleOk} color="primary" autoFocus>
                      {this.props.ok != null
                        ? this.props.ok
                        : "Ok"}
                     </Button>)}
                {(this.props.isAction === undefined || !this.props.isAction)
                 ? ""
                 : (<Button onClick={this.handleAction} color="primary" autoFocus>
                      {this.props.actionText != null
                       ? this.props.actionText
                       : "Action"}
                    </Button>)}
              </DialogActions>
            </Dialog>
        );
    }
}

const styles = theme => ({
  root: {
    width: '100%',
  },
});

export default withStyles(styles)(ConfirmationDialog);
