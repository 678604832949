import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';

export default memo(({ data, isConnectable }) => {
    return (
      <div style = {{ height: "1px"}}>
        <Handle
          type="target"
          position="bottom"
          style={{ height: "0px",
                   opacity: 0 }}
          isConnectable={isConnectable}
        />
        <div style= {{
             padding: "0px",             
             height: "1px",}}>
            &nbsp;
        </div>
        <Handle
          type="source"
          position="bottom"
          style={{ height: "0px",
                   opacity: 0 }}
          isConnectable={isConnectable}
        />
      </div>
    );
  });