import React from 'react';
import Button from '@material-ui/core/Button';
import {
    Dialog,
    DialogActions,
    DialogContent
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { NavLink } from 'react-router-dom';
import { text } from "./languageSelection";
import { connect } from "react-redux";

class LoginDialog extends React.Component {
    handleClose = () => {
        this.props.parent.setState({
            [this.props.propertyName]: false
        });
    };

    render() {
        const { ...other } = this.props;
        let language = this.props.language.value;

        return (
            <div>
              <Dialog
                onClose={this.handleClose}
                { ...other }>
                <DialogContent>
                  {text(language,
                        {"ru": "Без прохождения",
                         "en": "Without",
                         "es": "Sin"})}&nbsp;
                  <Link href="#" component={NavLink} to="/login">
                    {text(language,
                          {"ru": "аутентификации",
                           "en": "authentication",
                           "es": "autenticación"})}&nbsp;
                  </Link>
                  <Link href="#" component={NavLink} to="/login">
                    <LockOutlinedIcon fontSize="small"/>
                  </Link>
                  &nbsp;{text(language,
                          {"ru": "или",
                           "en": "or",
                           "es": "o"})}&nbsp;
                  <Link href="#" component={NavLink} to="/register">
                    {text(language,
                          {"ru": "регистрации",
                           "en": "registration",
                           "es": "registro"})}&nbsp;
                  </Link>
                  <Link href="#" component={NavLink} to="/register">
                    <PersonOutlineIcon fontSize="small"/>
                  </Link>
                  {text(language,
                          {"ru": "пользователя",
                           "en": "of user",
                           "es": "de usuario"}) + " " + this.props.text + "."}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default connect(mapStateToProps)(LoginDialog);
