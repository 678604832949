import React from 'react';
import { switchCountry } from "../countrySelection";
import { text } from "../languageSelection";

function ffbzFlowData(language, residence) {
    return [
  {
    id: 'ff__bz',
    data: {
      label: (
        <>
          Freedom Finance
        </>
      ),
    },
    position: { x: 250, y: 0 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #009688',
    },
  },
  {
    id: 'ff__finparser',
    data: {
      label: (
        <>
          FinParser
        </>
      ),
    },
    position: { x: 250, y: 150 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #3f51b5',
    },
  },
  {
    id: 'ff__nalog.ru',    
    data: {
      label: switchCountry(
          residence,
          {"es": 'agenciatributaria.gob.es',
           "ru": 'nalog.ru',
           "ee": 'emta.ee',
           "earth": 'Tax Agency',
           "none": 'Tax Agency'})
    },
    position: { x: 600, y: 225 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #3f51b5',
    },
  },
  {
    id: 'ff__finparser2',
    data: {
      label: (
        <>
          FinParser
        </>
      ),
    },
    position: { x: 250, y: 300 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #3f51b5',
    },
  },
  {
    id: 'ff__...',
    data: { label: '' },
    position: { x: 324, y: 450 },
    type: 'emptyNode',
  },
  // edges
  {
    id: 'ff__bz-b1->finparser',
    source: 'ff__bz',
    sourceHandle: 'bottom-left',
    target: 'ff__finparser',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'ff__bz-b2->finparser',
    source: 'ff__bz',
    sourceHandle: 'bottom-right',
    target: 'ff__finparser',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'ff__bz-b3->finparser',
    source: 'ff__bz',
    target: 'ff__finparser',
    label: text(language,
                {"ru": 'Отчеты за 2018, 2019 и 2020 годы (Excel)',
                 "en": 'Reports for 2018, 2019 and 2020 (Excel)',
                 "es": 'Informes de 2018, 2019 y 2020 (Excel)'}),
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'ff__finparser->nalog.ru',
    source: 'ff__finparser',
    sourceHandle: 'r',
    target: 'ff__nalog.ru',
    targetHandle: 'l',
    data: { label: text(language,
                        {"ru": (<div>
                                  Декларация и<br />
                                  пояснительная записка<br />
                                  за 2020 год
                                </div>),
                         "en": (<div>
                                  Declaration and<br />
                                  explanation note<br />
                                  for 2020
                                </div>),
                         "es": (<div>
                                  Declaración y<br />
                                  nota explicativa<br />
                                  para 2020
                                </div>)}),
            height: 42,
            width: 120},
    type: 'labelEdge',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'ff__finparser->finparser2',
    source: 'ff__finparser',
    sourceHandle: 'bottom',
    target: 'ff__finparser2',
    targetHandle: 'top',
    data: { label: text(language,
                        {"ru": (<div>
                                  Сделки и позиции в формате<br />
                                  FinParser по состоянию<br />
                                  на конец 2020 года (Excel)
                                </div>),
                         "en": (<div>
                                  Deals and positions in the FinParser<br />
                                  format by state<br />
                                  at the end of 2020 (Excel)
                                </div>),
                         "es": (<div>
                                  Ofertas y posiciones en el formato<br />
                                  FinParser por estado<br />
                                  a finales de 2020 (Excel)
                                </div>)}),
            height: 56,
            width: 140},
    type: 'labelEdge',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'ff__finparser2->nalog.ru',
    source: 'ff__finparser2',
    sourceHandle: 'r',
    target: 'ff__nalog.ru',
    targetHandle: 'l',
    data: { label: text(language,
                        {"ru": (<div>
                                  Декларация и<br />
                                  пояснительная записка<br />
                                  за 2021 год
                                </div>),
                         "en": (<div>
                                  Declaration and<br />
                                  explanation note<br />
                                  for 2021
                                </div>),
                         "es": (<div>
                                  Declaración y<br />
                                  nota explicativa<br />
                                  para 2020
                                </div>)}),
            height: 42,
            width: 120},
    type: 'labelEdge',
    style: { stroke: '#000000' },
    animated: true,
  },  
  {
    id: 'ff__finparser2->...',
    source: 'ff__finparser2',
    sourceHandle: 'bottom',
    target: 'ff__...',
    data: { label: text(language,
                        {"ru": (<div>
                                  Сделки и позиции в формате<br />
                                  FinParser по состоянию<br />
                                  на конец 2021 года (Excel)
                                </div>),
                         "en": (<div>
                                  Deals and positions in the FinParser<br />
                                  format by state<br />
                                  at the end of 2020 (Excel)
                                </div>),
                         "es": (<div>
                                  Ofertas y posiciones en el formato<br />
                                  FinParser por estado<br />
                                  a finales de 2020 (Excel)
                                </div>)}),
            height: 56,
            width: 140},
    type: 'labelEdge',
    style: { stroke: '#000000' },
    animated: true,
  },
  // ib - ib-a - Отчет за 2021 год (Excel) - ib-b -> FinParser
  {
    id: 'ff__bz-a',
    data: { label: '' },
    type: 'emptyNode',
    position: { x:160, y: 110 },
  },
  {
    id: 'ff__bz-b',
    data: { label: '' },
    position: { x: 160, y: 230 },
    type: 'emptyNode',
  },
  {
    id: 'ff__bz->ib-a',
    source: 'ff__bz',
    target: 'ff__bz-a',
    sourceHandle: 'l',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'ff__bz-a->ib-b',
    source: 'ff__bz-a',
    target: 'ff__bz-b',
    label: text(language,
                {"ru": 'Отчет за 2021 год (Excel)',
                 "en": 'Report for 2021 (Excel)',
                 "es": 'Informe para 2021 (Excel)'}),
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'ff__bz-b-finparser2',
    source: 'ff__bz-b',
    target: 'ff__finparser2',
    targetHandle: 'l',
    style: { stroke: '#000000' },
    animated: true,
  },
];
}

export default ffbzFlowData;
