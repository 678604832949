import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Center from 'react-center';
import { connect } from "react-redux";
import { text } from "./languageSelection";
import { isMobile } from './common/deviceDetector';
import {getCookieBoolean, setCookie, deleteCookie} from './cookieLib';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  card: {
    width: '500px',
    maxWidth: '500px',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'column',
  },
  cardMobile: {
    width: '500px',
    maxWidth: '500px',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'column',
    marginBottom: '60px'
  }
});

class CookieSettingsForm extends React.Component {
  constructor(props) {
    super(props);
  }

  handleCookiesChange = event => {
    let accept = event.target.checked;
    if (accept) {
      setCookie("acceptCookies", "true", true);
    } else {
      setCookie("acceptCookies", "false", true);
      const cookiesList =
            ["ignoreForex", "editablePDFs",
             "roundNumbersForCFR", "outputAccountNotice", "outputOpenNotice",
             "takeIntoAccountSuspensionSIODN",
             "outputXLSX", "outputExpalnationNotePDF", "outputXML",
             "outputDCX", "outputFinParser", "cfrXML", "cfrExcel",
             "cfrPDF", "taxOptimizeXLSX", "expandReduce",
             "futuresBasedOnVariationMargin", "enable2MonthsRule",
             "enable2MonthsRuleShorts", "consider2or12MonthsDiff",
             "useLastTradingDayExRates", "useOnlyECBExRates", "outputDetailXLSX",
             "outputExpalnationNoteDetailPDF", "outputForm_720",
             "outputForm_721",
             "expandTaxpayerDataSection", "expandIgnoresSection",
             "language", "residenceCountry"];
      cookiesList.map(c => deleteCookie(c));
    }
    this.forceUpdate();
  };

  render() {
    const { classes } = this.props;
    let language = this.props.language.value;

    return (
      <Center>
        <Card className={isMobile ? classes.cardMobile : classes.card}>
          <CardContent>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                  {text(
                    language,
                    {"ru": "Настройки файлов cookie",
                     "en": "Cookie settings",
                     "es": "Configuración de cookies"})}
                </Typography>
                <form className={classes.form} noValidate>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={getCookieBoolean("acceptCookies")}
                        onChange={this.handleCookiesChange}
                        name="subscribed"
                        color="primary"/>
                    }
                    label={text(language,
                                {"ru": "Включить необязательные cookie",
                                 "en": "Enable optional cookies",
                                 "es": "Enable optional cookies"})}/>
                </form>
              </div>
            </Container>
          </CardContent>
        </Card>
      </Center>
    );
  }
}

function mapStateToProps(state) {
  const language = state.language;
  return {
    language,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(CookieSettingsForm));
