import React from 'react';

export default [
  {
    id: 'ff_bz',
    data: {
      label: (
        <>
          Freedom Finance
        </>
      ),
    },
    position: { x: 80, y: 120 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #009688',
    },
  },
  {
    id: 'ib',
    data: {
      label: (
        <>
          Interactive Brokers
        </>
      ),
    },
    position: { x: 250, y: 120 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #e91e63',
    },
  },
  {
    id: 'excel',
    data: {
      label: (
        <>
          Заполнение в Excel
        </>
      ),
    },
    position: { x: 420, y: 120 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #009688',
    },
  },
  {
    id: 'unknown',
    data: {
      label: (
        <>
          Другой<br/>
          брокер или банк
        </>
      ),
    },
    position: { x: 420, y: 0 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #000000',
    },
  },
  {
    id: 'finparser',
    data: {
      label: (
        <>
          FinParser
        </>
      ),
    },
    position: { x: 250, y: 270 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #3f51b5',
    },
  },
  {
    id: 'declSoft',
    data: {
      label: 'Программа Декларация',
    },    
    position: { x: 400, y: 370 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #000000',
    },
  },
  {
    id: 'nalog.ru',    
    data: {
      label: 'nalog.ru',
    },    
    position: { x: 250, y: 470 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #3f51b5',
    },
  },
  // edges
  {
    id: 'ff_bz->finparser',
    source: 'ff_bz',
    target: 'finparser',
    label: 'Отчет (Excel)',
    style: { stroke: '#000000', strokeWidth: 1.5 },
    animated: true,
  },
  {
    id: 'ib->finparser',
    source: 'ib',
    target: 'finparser',
    label: 'Отчет (CSV)',
    style: { stroke: '#000000', strokeWidth: 1.5 },
    animated: true,
  },
  {
    id: 'finparser->declSoft',
    source: 'finparser',
    target: 'declSoft',
    label: 'Декларация (DCX)',
    style: { stroke: '#000000', strokeWidth: 1.5 },
    animated: true,
  },
  {
    id: 'declSoft->nalog.ru',
    source: 'declSoft',
    target: 'nalog.ru',
    label: 'Декларация (XML)',
    style: { stroke: '#000000', strokeWidth: 1.5 },
    animated: true,
  },
  {
    id: 'finparser->nalog.ru',
    source: 'finparser',
    target: 'nalog.ru',
    label: 'Декларация (XML)',
    animated: true,
  },
  // finparser - finparser-a - Пояснительная записка (PDF) - finparser-b -> nalog.ru
  {
    id: 'finparser-a',
    data: { label: '' },
    position: { x: 200, y: 330 },
    type: 'emptyNode',
  },
  {
    id: 'finparser-b',
    data: { label: '' },
    position: { x: 200, y: 450 },
    type: 'emptyNode',
  },
  {
    id: 'finparser->finparser-a',
    source: 'finparser',
    target: 'finparser-a',
    sourceHandle: 'l',
    animated: true,
  },
  {
    id: 'finparser-a->finparser-b',
    source: 'finparser-a',
    target: 'finparser-b',
    data: { label: (<div>
                      Пояснительная<br />
                      записка (PDF)
                    </div>),
            height: 40,
            width: 100},
    type: 'labelEdge',
    animated: true,
  },
  {
    id: 'finparser-b-nalog.ru',
    source: 'finparser-b',
    target: 'nalog.ru',
    targetHandle: 'l',
    animated: true,
  },
  // ib - ib-a - Отчет (PDF) - ib-b -> nalog.ru
  {
    id: 'ib-a',
    data: { label: '' },
    type: 'emptyNode',
    position: { x: 580, y: 300 },
  },
  {
    id: 'ib-b',
    data: { label: '' },
    position: { x: 580, y: 350 },
    type: 'emptyNode',
  },
  {
    id: 'ib->ib-a',
    source: 'ib',
    target: 'ib-a',
    // sourceHandle: 'r',
    animated: true,
  },
  {
    id: 'ib-a->ib-b',
    source: 'ib-a',
    target: 'ib-b',
    label: 'Отчет (PDF)',
    animated: true,
  },
  {
    id: 'ib-b-nalog.ru',
    source: 'ib-b',
    target: 'nalog.ru',
    targetHandle: 'r',
    animated: true,
  },
  // ff - ff-a - Отчет (PDF) - ff-b -> nalog.ru
  {
    id: 'ff-a',
    data: { label: '' },
    type: 'emptyNode',
    position: { x: 120, y: 300 },
  },
  {
    id: 'ff-b',
    data: { label: '' },
    position: { x: 120, y: 420 },
    type: 'emptyNode',
  },
  {
    id: 'ff->ff-a',
    source: 'ff_bz',
    target: 'ff-a',
    animated: true,
  },
  {
    id: 'ff-a->ff-b',
    source: 'ff-a',
    target: 'ff-b',
    label: 'Отчет (PDF)',
    animated: true,
  },
  {
    id: 'ff-b-nalog.ru',
    source: 'ff-b',
    target: 'nalog.ru',
    targetHandle: 'r',
    animated: true,
  },
  //
  {
    id: 'unknown->excel',
    source: 'unknown',
    target: 'excel',
    data: { label: (<div>
                      Отчет в любом<br />
                      читаемом формате
                    </div>),
            height: 40,
            width: 120},
    type: 'labelEdge',
    style: { stroke: '#000000', strokeWidth: 1.5 },
    animated: true,
  },
  // excel - excel-a - Универсальный формат (Excel) - excel-b -> finparser
  {
    id: 'excel-a',
    data: { label: '',
    height: 40,
    width: 100},
    type: 'emptyNode',
    position: { x: 493, y: 180 },
  },
  {
    id: 'excel-b',
    data: { label: '' },
    position: { x: 493, y: 200 },
    type: 'emptyNode',
  },
  {
    id: 'excel->excel-a',
    source: 'excel',
    target: 'excel-a',
    style: { stroke: '#000000', strokeWidth: 1.5 },
    animated: true,
  },
  {
    id: 'excel-a->excel-b',
    source: 'excel-a',
    target: 'excel-b',
    data: { label: (<div>
                      Универсальный формат<br />
                      FinParser (Excel)
                    </div>),
            height: 40,
            width: 120},
    type: 'labelEdge',
    style: { stroke: '#000000', strokeWidth: 1.5 },
    animated: true,
  },
  {
    id: 'excel-b-finparser',
    source: 'excel-b',
    target: 'finparser',
    style: { stroke: '#000000', strokeWidth: 1.5 },
    animated: true,
  }
];