import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Timeline from '@material-ui/lab/Timeline';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import HelpIcon from '@material-ui/icons/Help';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { isMobile } from '../common/deviceDetector';
import { text } from "../languageSelection";
import { connect } from "react-redux";

const styles = () => ({
    paper: {
        padding: '6px 16px',
    },
    justify: {
        textAlign: 'justify'
    },
    buttonMobile: {
        height: '54px',
        width: '240px',
        marginBottom: '10px',
        marginRight: '10px'
    },
    buttons: {
        height: '54px',
        width: '240px',
        marginBottom: '15px',
        marginRight: '50px'
    },
    timelineMobile: {
        marginTop: "0px",
        paddingTop: "0px",
        paddingLeft: "2px",
        paddingRight: "4px"
    },
    timelineItemMobile: {
        marginTop: '0px',
        marginBottom: '0px'
    },
    timelineContentMobile: {
        paddingRight: "0px"
    },
    right: {
        position: 'absolute',
        right: '40px',
    }
});

class WelcomeRu extends React.Component {

    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <div className={classes.root}>
              <Typography variant={isMobile ? "h5" : "h4"} align="center"
                          gutterBottom>
                Цены
              </Typography>
              <Box m={isMobile ? 0 : 4} className={classes.justify}>
                Цена составляет от <b>4000</b> до <b>8000</b> руб. за
                декларацию в зависимости от количества сделок в отчете<b>*</b>.
                <br/>
                Отчет о движении денежных средств <b>1000</b> руб.
                <br/>
                Предложения по налоговой оптимизации от <b>1000</b> до
                &ensp;<b>2000</b> руб. в зависимости от количества сделок в
                отчете.
                <p/>
                <b>*</b> Простая декларация (за 4000 руб.) подразумевает
                до <b>50</b> &ensp;операций на брокерском счете включительно.
                В качестве операций учитываются получение дивидендов,
                купонов, процентов на остаток, а также совершение сделок,
                закрывающих позции. Так, закрытие длинных позиций продажей
                или коротких позиций покупкой считается сделкой, просто
                покупка длинных позиций - нет. Расчет налогов для
                большинства индексных стратегий при пассивном
                инвестировании часто оценивается как "простая" декларация.
                <br/>
                <div className={classes.right}
                     style= {isMobile
                             ? {display: "contents"}
                             : {}}>
                  <Grid container justify="flex-end">
                    <Button component={NavLink} to="/payment"
                            className={isMobile
                                       ? classes.buttonMobile
                                       : classes.buttons}
                            variant="contained"
                            color="primary">
                      Оплатить
                    </Button>
                  </Grid>
                  <Grid container justify="flex-end">
                    <Button component={NavLink} to="/leaveFeedback"
                            className={isMobile
                                       ? classes.buttonMobile
                                       : classes.buttons}
                            variant="contained"
                            color="primary">
                      {text(language,
                            {"ru": "Оставить отзыв",
                             "en": "Leave feedback",
                             "es": "Deja un comentario"})}
                    </Button>
                  </Grid>
                </div>
              </Box>
              <p/>
              {/* -------------------------------------------------- */}
              <Typography variant={isMobile ? "h5" : "h4"} align="center"
                          style={isMobile ? {} : {paddingTop: 80}}
                          gutterBottom>
                Как сделать декларацию
              </Typography>

              <Timeline className={isMobile ? classes.timelineMobile : ""}
                        align={isMobile ? "left" : "alternate"}>
                <TimelineItem className={isMobile ? classes.timelineItemMobile : ""}>
                  {isMobile
                   ? <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
                   : ""}
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      <GetAppIcon />
                    </TimelineDot>
                    <TimelineConnector className={classes.primaryTail}/>
                  </TimelineSeparator>
                  <TimelineContent className={isMobile ? classes.timelineContentMobile : ""}>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant={isMobile ? 'subtitle1' : "h6"} component="h1">
                        Скачайте отчет брокера
                      </Typography>
                      <Typography variant={isMobile ? 'body2' : 'body1'}>
                        Описание процедуры приведено в&ensp;
                        <Link component={NavLink} to="/help">
                          справочном руководстве&ensp;
                        </Link>
                        <Link component={NavLink} to="/help">
                          <HelpIcon fontSize="small"/>
                        </Link>
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem className={isMobile ? classes.timelineItemMobile : ""}>
                  {isMobile
                   ? <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
                   : ""}
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      <img src="/images/finParserLogo.svg"
                           height='27px'
                           width='27px'
                           alt='logo'/>
                    </TimelineDot>
                    <TimelineConnector className={classes.primaryTail}/>
                  </TimelineSeparator>
                  <TimelineContent className={isMobile ? classes.timelineContentMobile : ""}>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant={isMobile ? 'subtitle1' : "h6"} component="h1">
                        Сгенерируйте файл налоговой декларации
                      </Typography>
                      <Typography variant={isMobile ? 'body2' : 'body1'}>
                        Воспользовавшись разделом&ensp;
                        <Link component={NavLink} to="/tax">
                          расчет налогов
                        </Link>
                        &ensp;данного сервиса
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem className={isMobile ? classes.timelineItemMobile : ""}>
                  {isMobile
                   ? <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
                   : ""}
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      <EditIcon />
                    </TimelineDot>
                    <TimelineConnector className={classes.primaryTail} />
                  </TimelineSeparator>
                  <TimelineContent className={isMobile ? classes.timelineContentMobile : ""}>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant={isMobile ? 'subtitle1' : "h6"} component="h1">
                        Внесите в декларацию другие данные
                      </Typography>
                      <Typography variant={isMobile ? 'body2' : 'body1'}>
                        Помимо данных из отчета брокера в декларацию
                        бывает необходимо добавить прочие доходы
                        и налоговые вычеты. Это можно сделать,
                        например, с помошью программы&ensp;
                        <Link href="https://www.nalog.ru/rn77/program/5961249/"
                              className={classes.link}
                              target="_blank">
                          Декларация
                        </Link>
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem className={isMobile ? classes.timelineItemMobile : ""}>
                  {isMobile
                   ? <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
                   : ""}
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      <DoneIcon />
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent className={isMobile ? classes.timelineContentMobile : ""}>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant={isMobile ? 'subtitle1' : "h6"} component="h1">
                        Подайте налоговую декларацию
                      </Typography>
                      <Typography variant={isMobile ? 'body2' : 'body1'}>
                        Загрузите полученную декларацию в личном
                        кабинете на&ensp;
                        <Link href="https://www.nalog.ru/"
                              className={classes.link}
                              target="_blank">
                          nalog.ru
                        </Link>
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const language = state.language;
    return {
        language,
    };
}

export default withStyles(styles)(
    connect(mapStateToProps)(WelcomeRu));
