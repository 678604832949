import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ImageZoom from 'react-medium-image-zoom';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';
import ffbzFlowData from './ffbzFlowData';
import FlowGraphRu from '../flow/flowGraph';
import FlowGraphEn from '../flow/flowGraphEn';
import FlowGraphEs from '../flow/flowGraphEs';
import { switchCountry } from "../countrySelection";
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    table: {
      minWidth: 650,
    },
    arrow: {
        marginBottom: '-6px'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    },
    ulMobile: {
        paddingInlineStart: "14px"
    },
    olMobile: {
        paddingInlineStart: "14px"
    },
    imgStyle: {
        width: '300px'
    },
    imgStyleMobile: {
        width: '100%'
    },
    diagram: {
        height: 650,
        width: 1100
    },
    diagramMobile: {
        zoom: "0.3",
        height: 650,
        width: 1100
    }
});

class HelpFFReport extends React.Component {
    render() {
        const { classes } = this.props;
        let language = this.props.language.value;
        let residence = this.props.residence.value;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>
                <div className={classes.text}>
                  <br/>
                  <HelpHashLink to="/help/FFReport#1">
                    <Typography id="1" variant="h5" component="h2">
                      {text(language,
                            {"ru": "Алгоритм получения отчета отчета Freedom Securities Trading Inc. (Freedom Finance Белиз)",
                             "en": "Instructions for getting Freedom Securities Trading Inc. (Freedom Finance Belize) report",
                             "es": "Instrucciones para obtener el informe de Freedom Securities Trading Inc. (Freedom Finance Belice)"})}
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  <Alert severity="info" className={classes.text}>
                    {text(language,
                          {"ru": <span>
                                   Для получения отчета в формате Excel,
                                   необходимого для автоматической обработки в
                                   FinParser, обратитесь к вашему менеджеру.
                                 </span>,
                           "en": <span>
                                   To obtain an Excel report required for
                                   automatic processing in FinParser, please
                                   contact your manager.
                                 </span>,
                           "es": <span>
                                   Para obtener un informe de Excel requerido
                                   para el procesamiento automático en
                                   FinParser, comuníquese con su gerente.
                                 </span>})}
                  </Alert>
                  <p/>
                  {text(language,
                          {"ru": <span>
                                   Информация об открытых позициях в отчете
                                   Freedom Securities Trading Inc. (Freedom
                                   Finance Белиз) не содержит даты открытия
                                   позиции и размеров лотов. Это делает
                                   невозможным построение налоговой декларации
                                   на основе отчета за один налоговый
                                   год. Закрываемые в каждой конкретной сделке
                                   лоты могут быть получены только на основе
                                   анализа истории сделок. Таким образом, для
                                   расчета налоговой декларации для Freedom
                                   Finance, требуется информация за всю историю
                                   торгов, начиная с момента открытия счета.
                                 </span>,
                           "en": <span>
                                   Information about open positions in the
                                   Freedom Securities Trading
                                   Inc. report. (Freedom Finance Belize) does
                                   not contain the position opening date and lot
                                   sizes. This makes it impossible to build a
                                   tax return based on a single tax year
                                   report. Lots closed in each specific
                                   transaction can be obtained only on the basis
                                   of the analysis of the history of
                                   transactions. Thus, in order to calculate the
                                   tax return for Freedom Finance, information
                                   is required for the entire history of
                                   trading, starting from the moment the account
                                   was opened.
                                 </span>,
                           "es": <span>
                                   Información sobre posiciones abiertas en el
                                   informe Freedom Securities Trading
                                   Inc. (Freedom Finance Belize) no contiene la
                                   fecha de apertura de la posición ni el tamaño
                                   de los lotes. Esto hace que sea imposible
                                   construir una declaración de impuestos basada
                                   en un solo informe de año fiscal. Los lotes
                                   cerrados en cada transacción específica solo
                                   se pueden obtener sobre la base del análisis
                                   del historial de transacciones. Por lo tanto,
                                   para calcular la declaración de impuestos de
                                   Freedom Finance, se requiere información de
                                   todo el historial de negociación, a partir
                                   del momento en que se abrió la cuenta.
                                 </span>})}
                  <p/>
                  {text(language,
                        {"ru": <span>
                                 Этого можно достичь одним из двух способов:
                               </span>,
                         "en": <span>
                                 This can be achieved in one of two ways:
                               </span>,
                         "es": <span>
                                 Esto se puede lograr de una de dos maneras:
                               </span>})}
                  <ul className={isMobile ? classes.ulMobile : ""}>
                    <li>{text(language,
                              {"ru": <span>
                                       Загрузив в FinParser отчеты FF в формате
                                       Excel за весь период с момента открытия
                                       брокерского счета до конца отчетного
                                       периода.
                                     </span>,
                               "en": <span>
                                       By uploading FF reports in Excel format
                                       to FinParser for the entire period from
                                       the moment of opening a brokerage account
                                       until the end of the reporting period.
                                     </span>,
                               "es": <span>
                                       Subiendo informes FF en formato Excel a
                                       FinParser para todo el período desde el
                                       momento de abrir una cuenta de corretaje
                                       hasta el final del período de informe.
                                     </span>})}
                    </li>
                    <li>{text(language,
                              {"ru": <span>
                                       Загрузив в FinParser отчет FF в формате
                                       Excel за отчетный период + список
                                       открытых позиций на момент начала
                                       отчетного периода в формате FinParser
                                       (Excel).
                                     </span>,
                               "en": <span>
                                       By uploading to FinParser the FF report
                                       in Excel format for the reporting period
                                       + the list of open positions at the
                                       beginning of the reporting period in
                                       FinParser (Excel) format.
                                     </span>,
                               "es": <span>
                                       Subiendo a FinParser el informe FF en
                                       formato Excel para el período de informe
                                       + la lista de posiciones abiertas al
                                       comienzo del período de informe en
                                       formato FinParser (Excel).
                                     </span>})}
                    </li>
                  </ul>
                  <p/>
                  <HelpHashLink to="/help/FFReport#2">
                    <Typography id="2" variant="h5" component="h2">
                      {text(language,
                            {"ru": "Схема генерации декларации для Freedom Finance",
                             "en": "Declaration generation scheme for Freedom Finance",
                             "es": "Esquema de generación de declaraciones para Freedom Finance"})}
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  {isMobile
                   ? <TransformWrapper>
                       <TransformComponent>
                         <div className={classes.diagramMobile}>
                           {text(language,
                                 {"ru": <FlowGraphRu data={
                                     ffbzFlowData(language, residence)}/>,
                                  "en": <FlowGraphEn data={
                                      ffbzFlowData(language, residence)}/>,
                                  "es": <FlowGraphEs data={
                                      ffbzFlowData(language, residence)}/>})}
                         </div>
                       </TransformComponent>
                     </TransformWrapper>
                   : <div className={classes.diagram}>
                       {text(language,
                             {"ru": <FlowGraphRu data={
                                 ffbzFlowData(language, residence)}/>,
                              "en": <FlowGraphEn data={
                                  ffbzFlowData(language, residence)}/>,
                              "es": <FlowGraphEs data={
                                  ffbzFlowData(language, residence)}/>})}
                     </div>
                  }
                  <p/>
                  {text(language,
                        {"ru": <span>
                                 Второй способ требует более подробных
                                 пояснений. Например, счет был открыт в 2018
                                 году, а декларация подается за
                                 2020-й. Необходимо загрузить в FinParser отчет
                                 за 2018 год (с момента открытия счета до конца
                                 года) + отчеты за 2019 и за 2020 годы и
                                 сгенерировать файл налоговой декларации (в том
                                 или ином формате), а также формат
                                 <b> "FinParser (Универсальный формат в Excel)"</b>
                               </span>,
                         "en": <span>
                                 The second method requires more detailed
                                 explanations. For example, the account was
                                 opened in 2018, and the declaration is
                                 submitted for 2020. It is necessary to upload a
                                 report for 2018 (from the moment of opening an
                                 account until the end of the year) + reports
                                 for 2019 and 2020 to FinParser and generate a
                                 tax return file (in one format or another), as
                                 well as the format
                                 <b> "FinParser (Universal format in Excel)"</b>
                               </span>,
                         "es": <span>
                                 El segundo método requiere explicaciones más
                                 detalladas. Por ejemplo, la cuenta se abrió en
                                 2018 y la declaración se presenta para 2020. Es
                                 necesario subir un informe de 2018 (desde el
                                 momento de abrir una cuenta hasta el final del
                                 año) + informes de 2019 y 2020 a FinParser y
                                 generar un archivo de declaración de impuestos
                                 (en un formato u otro), así como el formato
                                 <b> "FinParser (formato universal en Excel)"</b>
                               </span>})}&ensp;
                  (<Link component={NavLink} to="/help/FinParserFormat">
                     {text(language,
                           {"ru": "Подробнее",
                            "en": "More",
                            "es": "Más"})}
                   </Link>
                  <Link component={NavLink} to="/help/FinParserFormat">
                    <ArrowForwardIcon className={classes.arrow}
                                      fontSize="small"/>
                  </Link>).
                  <p/>
                  {text(language,
                        {"ru": <span>
                                 В следующем году полученный файл в формате
                                 FinParser загружается совместно с отчетом за
                                 2021 год. В свою очередь, уже на основе этих
                                 входных данных могут быть получены файл
                                 налоговой декларации + сделки и позиции в
                                 формате FinParser по итогам  2021 года, который
                                 далее может быть использован в качестве
                                 исходных данных для получения налоговой
                                 декларации за 2022 год (совместно с отчетом
                                 брокера за этот период) и т.д.
                               </span>,
                         "en": <span>
                                 The following year, the resulting file in
                                 FinParser format is uploaded together with the
                                 report for 2021. In turn, already on the basis
                                 of these input data, a tax return file +
                                 transactions and positions in the FinParser
                                 format based on the results of 2021 can be
                                 obtained, which can then be used as input data
                                 for obtaining a tax return for 2022 (together
                                 with the broker's report for this period),
                                 etc.
                               </span>,
                         "es": <span>
                                 Al año siguiente se sube el archivo resultante
                                 en formato FinParser junto con el informe de
                                 2021. A su vez, ya en base a estos datos de
                                 entrada, se puede obtener un archivo de
                                 declaración + transacciones y posiciones en
                                 formato FinParser en base a los resultados de
                                 2021, que luego se pueden utilizar como datos
                                 de entrada para la obtención de una declaración
                                 de impuestos para 2022 ( junto con el informe
                                 del bróker para este período), etc.
                               </span>})}
                  <p/>
                  <HelpHashLink to="/help/FFReport#3">
                    <Typography id="3" variant="h5" component="h2">
                      {text(language,
                            {"ru": "Инструкции для получения отчета в формате PDF",
                             "en": "Instructions for obtaining a PDF report",
                             "es": "Instrucciones para obtener un informe en PDF"})}
                    </Typography>
                  </HelpHashLink>
                  <br/>
                  {text(language,
                        {"ru": <span>
                                 Данный отчет необходимо прилагать как
                                 сопутствующий документ при подаче налоговой
                                 декларации на
                               </span>,
                         "en": <span>
                                 This report must be attached as an accompanying
                                 document when filing a tax return for
                               </span>,
                         "es": <span>
                                 Este informe deberá adjuntarse como documento
                                 adjunto a la presentación de la declaración de
                               </span>})}&ensp;
                  {switchCountry(
                      residence,
                      {"spain": <Link href="https://sede.agenciatributaria.gob.es/"
                                      className={classes.link}
                                      target="_blank">
                                  agenciatributaria.gob.es
                                </Link>,
                       "russia": <Link href="https://www.nalog.ru"
                                       className={classes.link}
                                       target="_blank">
                                   nalog.ru
                                 </Link>,
                       "estonia": <Link href="https://www.emta.ee/"
                                        className={classes.link}
                                        target="_blank">
                                    emta.ee
                                  </Link>,
                       "earth": 'the Tax Agency',
                       "none": 'the Tax Agency'})}.&ensp;
                  {text(language,
                        {"ru": <span>
                                 Использование его для автоматической обработки
                                 в FinParser приведет к ошибке.
                               </span>,
                         "en": <span>
                                 Using it for automatic processing in FinParser
                                 will result in an error.
                               </span>,
                         "es": <span>
                                 Usarlo para el procesamiento automático en
                                 FinParser dará como resultado un error.
                               </span>})}
                  <ol className={isMobile ? classes.olMobile : ""}>
                    <li>{text(language,
                            {"ru": "Входите в в приложение Tradernet",
                             "en": "Log in to the Tradernet app",
                             "es": "Inicie sesión en la aplicación Tradernet"})}
                    </li>
                    <li>Menu <ArrowForwardIcon className={classes.arrow}
                                        fontSize="small"/> Brokerage reports
                      <br/>
                      <ImageZoom
                        image={{
                            src: '../images/ff_bz_create_statement1.jpg',
                            alt: '',
                            className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                        }}
                        zoomImage={{
                            src: '../images/ff_bz_create_statement1.jpg',
                            alt: ''
                        }}/>
                    </li>
                    <li>{text(language,
                              {"ru": "Выбираете период",
                               "en": "Choose a period",
                               "es": "Elige un periodo"})}
                    </li>
                    <li>{text(language,
                              {"ru": <span>
                                       Получить отчет по почте - Send report by
                                       email, вводите адрес электронной почты
                                     </span>,
                               "en": <span>
                                       Receive a report by mail - Send report by
                                       email, enter your email address
                                     </span>,
                               "es": <span>
                                       Reciba un informe por correo: envíe un
                                       informe por correo electrónico, ingrese
                                       su dirección de correo electrónico
                                     </span>})}
                      <br/>
                      <ImageZoom
                        image={{
                            src: '../images/ff_bz_create_statement2.jpg',
                            alt: '',
                            className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                        }}
                        zoomImage={{
                            src: '../images/ff_bz_create_statement2.jpg',
                            alt: ''
                        }}/>
                    </li>
                    <li>{text(language,
                              {"ru": "Нажимаете ",
                               "en": "Click ",
                               "es": "Hacer clic "})}
                      Create report
                    </li>
                  </ol>
                </div>
              </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
    const language = state.language;
    const residence = state.residence;
    return {
        residence,
        language
    };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpFFReport));
