import { createSlice } from '@reduxjs/toolkit';

export const accessLevelSlice = createSlice({
  name: 'accessLevel',
  initialState: {
      value: "none",
  },
  reducers: {
      setAccessLevel: (state, action) => {
        state.value = action.payload;
    },
  },
});

export const { setAccessLevel } = accessLevelSlice.actions;

export default accessLevelSlice.reducer;
