import { createSlice } from '@reduxjs/toolkit';

export const loginOkSlice = createSlice({
  name: 'loginOk',
  initialState: {
      value: false,
  },
  reducers: {
      setLoginOk: (state, action) => {
        state.value = action.payload;
    },
  },
});

export const { setLoginOk } = loginOkSlice.actions;

export default loginOkSlice.reducer;
