import React from 'react';
import { render } from 'react-dom';
import MainMenu from './mainMenu';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import store from './state/store';
import { Provider } from 'react-redux';

const rootElement = document.querySelector('#root');
if (rootElement) {
    render(
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MainMenu/>
        </MuiPickersUtilsProvider>
      </Provider>,
        rootElement);
}
