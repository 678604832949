import { createSlice } from '@reduxjs/toolkit';
import {setCookie, getCookie} from '../cookieLib';

export const residenceSlice = createSlice({
  name: 'residenceCountry',
  initialState: {
      value: getCookie("residenceCountry", "none"),
  },
  reducers: {
      setResidence: (state, action) => {
        state.value = action.payload;
    },
  },
});

export const { setResidence } = residenceSlice.actions;

export default residenceSlice.reducer;
