import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AdminConsoleActivity from './adminConsoleActivity';
import AdminConsolePayments from './adminConsolePayments';
import AdminConsoleUsers from './adminConsoleUsers';
import AdminConsoleFeedback from './adminConsoleFeedback';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ paddingTop: 2 }}
      {...other}
    >
      {value === index && (
          <Card style={{
              display: 'flex',
              flexWrap: 'wrap',
              flex: 1,
              flexDirection: 'column',
          }}>
            <CardContent>
              {children}
            </CardContent>
          </Card>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    }
}));

export default function AdminConsoleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered>
          <Tab label="Все действия" {...a11yProps(0)} />
          <Tab label="Платежи" {...a11yProps(1)} />
          <Tab label="Пользователи" {...a11yProps(2)} />
          <Tab label="Отзывы" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AdminConsoleActivity/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AdminConsolePayments/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AdminConsoleUsers/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AdminConsoleFeedback/>
      </TabPanel>
    </div>
  );
}
