import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ImageZoom from 'react-medium-image-zoom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Alert from '@material-ui/lab/Alert';
import '../styles.css';
import {Code} from '../colors';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';
import HelpIBDate from './helpIBDate';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    },
    arrow: {
      marginBottom: '-6px'
    },
    olMobile: {
        paddingInlineStart: "14px"
    },
    imgStyle: {
        width: '700px'
    },
    imgStyleMobile: {
        width: '100%'
    }
});

class HelpIBDeclarationReport extends React.Component {
    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <Card className={classes.card}>
            <CardContent>
              <HelpToContentsLink/>
              <div className={classes.text}>
                <HelpHashLink to="/help/IBDeclarationReport#1">
                  <Typography id="1" variant="h5" component="h2">
                    {text(language,
                          {"ru": "Алгоритм получения отчета брокера Interactive Brokers",
                           "en": "Instructions for obtaining an Interactive Brokers report",
                           "es": "Instrucciones para obtener un informe de Interactive Brokers"})}
                  </Typography>
                </HelpHashLink>
                <br/>
                <ol className={isMobile ? classes.olMobile : ""}>
                  <li>{text(language,
                            {"ru": "Входите в портал клиентов",
                             "en": "Login to customer portal",
                             "es": "Iniciar sesión en el portal del cliente"})}
                    &ensp;
                    <Link href="https://www.interactivebrokers.com"
                          className={classes.link}
                          target="_blank">
                      Interactive Brokers </Link>
                  </li>
                  <li>{text(language,
                          {"ru": "В меню выбираете",
                           "en": "Choose from the menu",
                           "es": "Elige del menú"})}
                    &ensp;
                    <Code className={classes.Code}>
                      Perfomance &amp; Reports
                      <ArrowForwardIcon className={classes.arrow} fontSize="small"/>
                      Statements
                    </Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;
                                   <Code>(<i>
                                            Доходность и отчеты
                                            <ArrowForwardIcon className={classes.arrow} fontSize="small"/>
                                            Выписки
                                          </i>)</Code>
                                 </span>
                          })}
                    <br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement1.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement1.png',
                          alt: ''
                      }}
                    />
                  </li>
                  <li>{text(language,
                          {"ru": "В разделе",
                           "en": "In chapter",
                           "es": "En el capitulo"})} <Code>Custom Statements</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;
                                   <Code>(<i>Пользовательские отчеты</i>)</Code>
                                 </span>})}&ensp;
                    {text(language,
                          {"ru": "нажимаете",
                           "en": "press",
                           "es": "prensa"})}&ensp;<Code>+</Code>
                    <br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement2.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement2.png',
                          alt: ''
                      }}
                    />
                  </li>
                  <li>{text(language,
                          {"ru": "Вводите имя, например,",
                           "en": "Enter a name, for example,",
                           "es": "Introduzca un nombre, por ejemplo,"})}&ensp;
                    <Code>All CSV</Code>.<br/>
                    {text(language,
                          {"ru": "Выбираете",
                           "en": "Choose",
                           "es": "Elegir"})}&ensp;
                    <Code>Sections - All</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;
                                   <Code> (<i>Разделы - Все</i>)</Code>
                                 </span>})}
                    .<br/>
                    {text(language,
                          {"ru": "В разделе",
                           "en": "In chapter",
                           "es": "En el capitulo"})}&ensp;
                    <Code>Profit and Loss - MTM and Realized PL</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;
                                   <Code>(<i>Прибыль и убытки (П/У) - MТМ и реализованная П/У</i>)</Code>
                                 </span>})}
                    .<br/>
                    {text(language,
                          {"ru": "Все переключатели \"да/нет\" переводите в положение",
                           "en": "Set all \"yes/no\" switches to",
                           "es": "Establezca todos los interruptores \"sí/no\" en"})}
                    &ensp;
                    <Code>No</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;<Code>(<i>Нет</i>)</Code>
                                 </span>})}
                    .<br/>
                    <Code>Format - CSV</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;<Code>(<i>Формат - CSV</i>)</Code>
                                 </span>})}
                    .<br/>
                    <Code>Period - Daily</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;<Code>(<i>Период - Дневной</i>)</Code>
                                 </span>})}
                    .<br/>
                    {text(language,
                          {"ru": "Нажимаете",
                           "en": "Click",
                           "es": "Hacer clic"})}&ensp;
                    <Code>CONTINUE</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;<Code>(<i>ДАЛЕЕ</i>)</Code>
                                 </span>})}
                    .<br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement3a.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement3a.png',
                          alt: '',
                          style: { height: '2446px' }
                      }}
                      zoomMargin={0}
                    /><br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement3b.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement3b.png',
                          alt: '',
                          style: { height: '2446px' }
                      }}
                      zoomMargin={0}
                    /><br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement3c.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement3c.png',
                          alt: '',
                          style: { height: '2446px' }
                      }}
                      zoomMargin={0}
                    />
                  </li>
                  <li>{text(language,
                          {"ru": "Далее",
                           "en": "Next",
                           "es": "Más"})}&ensp;- <Code>CREATE</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;<Code>(<i>СОЗДАТЬ</i>)</Code>
                                 </span>})}
                    <br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement4.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement4.png',
                          alt: ''
                      }}
                    />
                  </li>
                  <li>{text(language,
                          {"ru": "Далее",
                           "en": "Next",
                           "es": "Más"})}&ensp;- <Code>OK</Code>
                    <br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement5.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement5.png',
                          alt: ''
                      }}
                    />
                  </li>
                  <li>{text(language,
                            {"ru": <span>
                                     Нажимаете стрелку вправо <Code>-&gt;</Code>
                                     &ensp;напротив созданного отчета
                                   </span>,
                             "en": <span>
                                     Press right arrow <Code>-&gt;</Code>
                                     &ensp;near to the created report
                                   </span>,
                             "es": <span>
                                     Presione la flecha derecha <Code>-&gt;</Code>
                                     &ensp;cerca del informe creado
                                   </span>})}
                    <br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement6.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement6.png',
                          alt: ''
                      }}
                    />
                  </li>
                  <li>{text(language,
                          {"ru": "Выбираете",
                           "en": "Choose",
                           "es": "Elegir"})}&ensp;
                    <Code>Period - Custom Date Range</Code>
                    <br/>
                    {text(language,
                          {"ru": <span>
                                   <Code>(<i>Период - Индивидуальный срок</i>)</Code>
                                   <br/>
                                   <HelpIBDate/>
                                   <p/>
                                 </span>})}
                    <Code>Format - CSV (<i>Формат - CSV</i>)</Code>.<br/>
                    {text(language,
                          {"ru": "Язык может быть как английский, так и русский.",
                           "en": "English language",
                           "es": "Idioma en Inglés"})}
                    <br/>
                    {text(language,
                          {"ru": "Нажимаете",
                           "en": "Click",
                           "es": "Hacer clic"})}&ensp;
                    <Code>Run</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;<Code>(<i>Запустить</i>)</Code>
                                   <br/>
                                   <HelpIBDate/>
                                 </span>})}
                    .<br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement7.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement7.png',
                          alt: ''
                      }}
                    />
                  </li>
                  <li>{text(language,
                            {"ru": "В списке",
                             "en": "In the list of",
                             "es": "En la lista de"})}&ensp;
                    <Code>Batch Statements</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;<Code>(<i>Пакет отчетов</i>)</Code>
                                   <br/>
                                   <HelpIBDate/>
                                 </span>})}&ensp;
                    {text(language,
                          {"ru": "появится информация о генерируемом отчете",
                           "en": "information about the generated report will appear",
                           "es": "aparecerá información sobre el informe generado"})}
                    .<br/>
                    {text(language,
                          {"ru": "Вы можете нажимать",
                           "en": "You can click",
                           "es": "Puedes hacer clic"})}&ensp;
                    <Code>Refresh</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;<Code>(<i>Обновить</i>)</Code>
                                 </span>})}&ensp;
                    {text(language,
                          {"ru": "время от времени, чтобы узнать готов ли отчет",
                           "en": "from time to time to see if the report is ready",
                           "es": "de vez en cuando para ver si el informe está listo"})}
                    .<br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement8.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement8.png',
                          alt: ''
                      }}
                    />
                  </li>
                  <li>{text(language,
                            {"ru": "После окончания генерации нажимаете стрелку вправо",
                             "en": "After the generation is completed, press the right arrow",
                             "es": "Una vez completada la generación, presione la flecha derecha"})}
                    &ensp;<Code>-&gt;</Code>&ensp;
                    {text(language,
                          {"ru": "напротив данного отчета в списке",
                           "en": "near to this report in the list of",
                           "es": "cerca de este informe en la lista de"})}&ensp;
                    <Code>Batch Statements</Code>
                    {text(language,
                          {"ru": <span>
                                   &ensp;<Code>(<i>Пакет отчетов</i>)</Code>
                                 </span>})}
                    .<br/>
                    {text(language,
                          {"ru": "Сохраняете полученный CSV файл",
                           "en": "Save the resulting CSV file",
                           "es": "Guarde el archivo CSV resultante"})}
                    .<br/>
                    <ImageZoom
                      image={{
                          src: '../images/ibCreateStatement/ib_create_statement9.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle,
                      }}
                      zoomImage={{
                          src: '../images/ibCreateStatement/ib_create_statement9.png',
                          alt: ''
                      }}
                    />
                  </li>
                </ol>
              </div>
            </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpIBDeclarationReport));
