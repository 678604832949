import React from 'react';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import Center from 'react-center';
import { text } from "../languageSelection";
import { connect } from "react-redux";

class HelpToContentsLink extends React.Component {
    render() {
        let language = this.props.language.value;
        return (
            <span>
              <Center>
                <Link component={NavLink} to="/help">
                  <HelpIcon/>
                </Link>
              </Center>
              <Center>
                <Link component={NavLink} to="/help">
                  {text(language,
                            {"ru": "К разделам справки",
                             "en": "Back to help topics",
                             "es": "Volver a los temas de ayuda"})}
                </Link>
              </Center>
            </span>
        );
    }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default connect(mapStateToProps)(HelpToContentsLink);
