import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    },
    cite: {
        textAlign: 'right'
    }
});

class HelpAlgorithm extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>
                <br/>
                <div className={classes.text}>
                  <HelpHashLink to="/help/HelpAlgorithm#0" >
                    <Typography id="1" variant="h5" component="h2">
                        Алгоритм работы сервиса
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  Содержание
                  <ul>
                    <li>Парсинг отчетов Interactive Brokers LLC
                        <ul>
                            <li>Дивиденды</li>
                            <li>Продажа ценных бумаг Long</li>
                            <li>Продажа ценных бумаг Short</li>                            
                            <li>Опционы</li>
                            <li>Фьючерсы</li>
                            <li>Форекс</li>
                        </ul>
                    </li>
                    <li>Парсинг отчетов Freedom Securities Trading Inc.</li>
                    <li>Парсинг универсального формата FinParser</li>
                    <li>Надежность</li>
                  </ul>
                  <p/>
                  <HelpHashLink to="/help/HelpAlgorithm#1" >
                    <Typography id="2" variant="h5" component="h3">
                        Парсинг отчетов Interactive Brokers LLC
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  <HelpHashLink to="/help/HelpAlgorithm#1.1" >
                    <Typography id="2" variant="h6" component="h4">
                      Опционы
                    </Typography>
                  </HelpHashLink>
                  При расчете налогов, возникающих в результате торговли 
                  опционами, возможны следующие ситуации:
                  <ol>
                      <li>Закрытие открытой ранее позиции по опциону до 
                        истечения срока экспирации. В этом случае, прибылью 
                        (а, следовательно, налогооблагаемой базой) будет 
                        являться только изменение премии (цены) опциона.</li>
                        <li>Экспирация - завершение обращения срочных 
                          контрактов на бирже:
                          <ul>
                            <li>Истечение срока действия без исполнения опциона.
                              В этом случае цена купленного опциона становится
                              убытком, а проданного - прибылью.</li>
                            <li>Исполнение опциона. По америаканским правилам,
                              позиция будет считается прибыльной или убыточной
                              только после закрытия как самого опциона, так
                              и позиции в базовом  активе (например, в акциях, 
                              полученных в результате  исполнения опциона), а
                              в РФ премия по опциону учитывается, сразу, а 
                              последующие сделки с базовым активом -
                              учитываются как отдельные самостоятельные сделки.
                              </li>
                          </ul>
                        </li>
                  </ol>
                  Как и в случае с ЦБ, по опционам полученная премия 
                  сальдируется с премией уплаченной в рамках соответствующих 
                  кодов доходов.
                </div>
              </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(HelpAlgorithm);
