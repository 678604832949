import { createSlice } from '@reduxjs/toolkit';
import {setCookie, getCookie} from '../cookieLib';

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
      value: getCookie("language", "en"),
  },
  reducers: {
      setLanguage: (state, action) => {
        state.value = action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
