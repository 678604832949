import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

class Progress extends React.Component {

  render() {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress 
          variant="static"
          size={200}
          value={this.props.parent.state.progress.percent}/>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center">          
          <Typography component="div" color="textSecondary" align="center">
              {`${Math.round(
                this.props.parent.state.progress.percent
              )}%`}
              <br/>
              <Typography variant="caption" component="div" color="textSecondary" align="center">
                {this.props.parent.state.progress.stage}
              </Typography>
          </Typography>          
        </Box>
      </Box>
    );
  }
}

export default Progress;
