
export function getCookieBoolean(cname, defaultValue = "") {
    let value = getCookie(cname, defaultValue);
    if (value === "") {
      return undefined;
    }
    if (!value) {
        return false;
    }
    if (typeof value == "boolean") {
        return value;
    }
    return value === "true";
}

export function getCookie(cname, defaultValue = "") {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return defaultValue;
}

export function setCookie(cname, cvalue, caccept = false, exdays = 356) {
  if (caccept || getCookieBoolean("acceptCookies")) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
}

export function deleteCookie(name) {
  document.cookie = name +'=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
