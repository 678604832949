import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    helpText: {
        fontSize: theme.typography.pxToRem(15),
    },
});

class HelpIBDate extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Typography className={classes.helpText}>
                Для генерации <u>налоговой декларации</u> выбор диапазона дат 
                будет зависеть от того будете ли вы включать опцию (Т+) при
                расчете налогов (по умолчанию она включена).
                Если вы выбираете опцию <b>(Т+)</b>, то сделки по акциям,
                совершенные 30 декабря года, предыдущего отчетному, будут засчитаны
                1 января отчетного года, а 31 декабря - 2 января. В свою очередь,
                сделки с ПФИ 31 декабря отчетного года попадут в следующий год.
                <p/>
                Кроме того, следует иметь в виду, что дивиденды учитываются
                день в день, независимо от выбора настройки (Т+).
                <p/>
                Таким образом для опции <b>(Т+)</b> и для отчетного года,
                например, 2020, даты должны быть <b>30.12.2019 -
                31.12.2020</b> включительно.
                <p/>
                Для выключенной опции (Т+) и для отчетного года, например, 2020,
                даты должны быть <b>01.01.2020 - 31.12.2020</b> включительно.
                <p/>
                В ряде случаев, именно эти даты выбрать невозможно, соответственно,
                необходимо выбирать ближайшие, охватывающие этот период. Если
                в итоге получается, что период превышает 365 дней (на
                сегодняйшний момент нельзя выгружать отчет на больший срок),
                то можно разбить на 2 отчета, чтобы при этом не было
                пересечения дат (например, первый отчет&ensp;
                <b>30.12.2019 - 31.12.2019</b>, затем второй&ensp;
                <b>01.01.2020 - 31.12.2020</b>). В этом случае, в FinParser
                загружается 2 отчета.
                <p/>
                Для генерации <u>отчета о движении денежных средств</u> в
                некоторых случаях может использоваться другой диапазон дат
                при выгрузке файлов.
                <br/>
                Прежде всего, для каждого счета IB необходимо использовать
                только <b>один</b> файл отчета. Диапазон дат независимо от 
                выбора опции (Т+) должен быть в идеале, например, для 2020 
                налогового года <b>01.01.2020 - 31.12.2020</b>. Если 01.01.2020
                выбрать невозможно, то необходимо выбирать максимально близкую
                из доступных дат после этого числа, например 04.01.2020.
                <p/>
                Если счет был открыт в год, за который подается налоговая
                декларация, в качестве начала периода необходимо выбрать самую
                раннюю из доступных для выбора дат.
          </Typography>
        );
    }
}

export default withStyles(styles)(HelpIBDate);
