import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { connect } from "react-redux";
import { setLoginOk } from './state/loginOkSlice';
import { text } from "./languageSelection";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
      }}
      transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
      }}
      {...props}
    />
));

class UserMenu extends React.Component {
    state = {
        anchorEl: null
    };

    setAnchorEl = value => {
        this.setState({ anchorEl: value });
    };

    handleClick = (event) => {
        this.setAnchorEl(event.currentTarget);
    };

    handleClose = () => {
        this.setAnchorEl(null);
    };

    gotoWelcome() {
        window.location.hash = "welcome";
    }

    logout = (event) => {
        event.preventDefault();
        const config = { headers: { 'Content-Type': 'application/json',
                                    'X-Requested-With': 'HttpRequest',
                                    'Csrf-Token': 'nocheck'},
                         timeout: 0};
        axios.post("/logout", null, config)
            .then( (response) => {
                this.gotoWelcome();
                this.props.setLoginOk(false);
                this.setAnchorEl(null);
                this.props.parent.forceUpdate();
            })
            .catch( (error) => {
                this.gotoWelcome();
                this.props.setLoginOk(false);
                this.setAnchorEl(null);
                this.props.parent.forceUpdate();
            });
    };

    render() {
        let language = this.props.language.value;

        return (
            <div>
              <IconButton color="inherit"
                          onClick={this.handleClick} >
                <AccountCircleOutlinedIcon />
              </IconButton>
              <StyledMenu
                id="customized-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose} >
                {!this.props.loginOk.value
                 ? <MenuItem component={NavLink} to="/login">
                     <ListItemIcon>
                       <LockOutlinedIcon fontSize="small" />
                     </ListItemIcon>
                     <ListItemText primary={text(
                         language,
                         {"ru": "Аутентификация",
                          "en": "Authentication",
                          "es": "Autenticación"})} />
                   </MenuItem>
                 : <div>
                     <MenuItem component={NavLink} to="/userData">
                       <ListItemIcon>
                         <PersonIcon fontSize="small" />
                       </ListItemIcon>
                       <ListItemText primary={text(
                         language,
                         {"ru": "Личный кабинет",
                          "en": "Account info",
                          "es": "Informacion de cuenta"})} />
                     </MenuItem>
                     <MenuItem onClick={this.logout}>
                       <ListItemIcon>
                         <ExitToAppIcon fontSize="small" />
                       </ListItemIcon>
                       <ListItemText primary={text(
                         language,
                         {"ru": "Выход",
                          "en": "Exit",
                          "es": "Salida"})} />
                     </MenuItem>
                   </div>}
              </StyledMenu>
            </div>
        );
    };
}

function mapStateToProps(state) {
    const loginOk = state.loginOk;
    const language = state.language;
    return {
        loginOk,
        language,
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
      setLoginOk: (value) => dispatch(setLoginOk(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
