import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#009688',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default GreenCheckbox;
