import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';
import SupportEmail from '../common/supportEmail';
import HelpToContentsLink from './helpToContentsLink';
import HelpIBDate from './helpIBDate';

const styles = theme => ({
  root: {
      marginTop: '20px',
      width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  helpText: {
    fontSize: theme.typography.pxToRem(15),
  },
  arrow: {
    marginBottom: '-6px'
  }
});

class SimpleAccordion extends React.Component {    

  getActiveFAQ() {
      let hashes = window.location.hash.split("#");
      if (hashes.length === 3) {
          return hashes[2];
      } else {
          return null;
      }
  }

  faqChange = name => (event, expanded) => {
      let hashes = window.location.hash.split("#");
      if (expanded === true) {
          // Expand.
          if (hashes.length < 3) {
              window.location.hash = window.location.hash + "#" + name;
          } else {
              window.location.hash = "#" + hashes[1] + "#" + name;
          }
      } else if (hashes.length === 3) {
          // Collapse.
          window.location.hash = "#" + hashes[1];
      }
  }

  render() {
    const { classes } = this.props;

  return (
    <div className={classes.root}>
      <HelpToContentsLink/>
      <div style={{paddingBottom: 6}}/>
      <Accordion expanded={this.getActiveFAQ() === "1"}
                 onChange={this.faqChange("1")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Сколько стоит использование сервиса?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.helpText}>
              До <b>50</b> операций на брокерском счете включительно считается 
              Простой декларацией, цена <b>4000</b> руб. Все прочие
              &ensp;<b>8000</b> руб.
              <p/>
              В качестве операций учитываются получение дивидендов, купонов,
              процентов на остаток, а также совершение сделок, закрывающих 
              позции. Так, закрытие длинных позиций продажей или коротких 
              позиций покупкой считается сделкой, просто покупка длинных 
              позиций - нет. Таким образом, расчет налогов для большинства
              индексных стратегий при пассивном инвестировании может быть
              оценен как "простая" декларация.
              <p/>
              Узнать учитываемое при расчете цены количество сделок можно и до 
              оплаты. Достаточно просто зарегистрироваться и попробовать 
              сгенерировать декларацию на основе вашего отчета.
              Вы получите расчетное количество сделок и стоимость для данного 
              отчета.
              <p/>
              Отчет о движении денежных средств оплачивается отдельно. 
              Цена <b>1000</b> руб.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={this.getActiveFAQ() === "2"}
                 onChange={this.faqChange("2")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Как произвести оплату?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.helpText}>
          Во-первых, необходимо зарегистрироваться. Далее открываете главную 
          страницу сервиса (<Link href="#" component={NavLink} to="/">
                    finparser.com
                  </Link>), в разделе Цены выбираете необходимое количество 
            деклараций (простых или обычных в зависимости от количества сделок) 
            и нажимаете на кнопку Оплатить.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={this.getActiveFAQ() === "3"}
                 onChange={this.faqChange("3")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Как им пользоваться?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.helpText}>
            Сервис предоставляет 3 вида форматов на основе файла отчета брокера (формат CSV):
            <ul>
              <li><b>Excel</b> - пояснительная записка с описанием расчетов.</li>
              <li><b>XML</b> - файл электронной налоговой декларации, которую можно
                непосредственно загрузить на сайт <Link href="https://www.nalog.ru/" 
                className={classes.link} target="_blank">nalog.ru</Link></li>
              <li><b>DCX</b> - файл для программы <Link href=
                "https://www.nalog.ru/rn77/program/5961249/"
                className={classes.link} target="_blank"> Декларация </Link>, 
                (где X - последняя цифра года, за коррый подается декларация, 
                так файл декларации за 2020 год имеет расширение <b>DC0</b>, за 
                2019-й - <b>DC9</b>, за 2018 - <b>DC8</b> и т.д.) 
                с помощью которой можно ввести другие расходы и доходы, 
                полученные вне брокерского счета, затем уже сгенерировать&ensp;
                <b>XML</b>-файл электронной налоговой декларации и уже его подать 
                на <Link href="https://www.nalog.ru/" className={classes.link} target="_blank">
                nalog.ru</Link></li>
            </ul>
            Алгоритм действий может быть следующим:
            <ol>
              <li>Получить отчет брокера (описание процедуры приведено в&ensp;
                <Link href="#" component={NavLink} to="/help/IBDeclarationReport">
                справочном руководстве</Link>).</li>
              <li>Сгенерировать файл <b>DC0</b> через <Link href="#" component={NavLink} to="/"> 
                finparser</Link>.</li>
              <li>Открыть его в программе <Link href=
                "https://www.nalog.ru/rn77/program/5961249/"
                className={classes.link} target="_blank"> Декларация</Link>.</li>
              <li>Убедиться, что там есть сделки.</li>
              <li>Дополнить своими данными (налоговые вычеты, прочие доходы, 
                полученные вне брокерского счета и т.д.)</li>
              <li>Нажать Декларация/экспорт в <b>XML</b>.</li>
              <li>Полученный <b>XML</b> файл вместе с отчетом брокера в формате
                &ensp;<b>PDF</b> (выгружается отдельно) и другими документами,
                подтверждающими доходы и расходы, загрузить на&ensp;
                <Link href="https://www.nalog.ru/" 
                className={classes.link} target="_blank">nalog.ru</Link>.</li>
            </ol>

            <Link component={NavLink}
                  to="/help/TaxInstructions">
                  Подробнее
            </Link>
            <Link component={NavLink} to="/help/TaxInstructions">
              <ArrowForwardIcon className={classes.arrow}
                                fontSize="small"/>
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={this.getActiveFAQ() === "4"}
                 onChange={this.faqChange("4")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Как учитываются позиции, купленные в предыдущие годы?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.helpText}>
            <Link href="https://www.interactivebrokers.com"
                          className={classes.link}
                          target="_blank">
                      Interactive Brokers
            </Link>&ensp;
            выгружает данные о дате и сумме открытия позиций, если позиция 
            была частично или полностью закрыта в выгружаемом периоде, даже 
            если она была открыта в предыдущие годы (поэтому не нужно 
            прикладывать отчеты за все годы покупки).
            <p/>
            <Link href="https://ffin.bz"
                          className={classes.link}
                          target="_blank">
                      Freedom Finance
            </Link>&ensp;-
            информация об открытых позициях в отчете не содержит даты открытия 
            позиции и размеров лотов. Это делает невозможным построение 
            налоговой декларации на основе отчета за один налоговый год.
            Закрываемые в каждой конкретной сделке лоты могут быть получены
            только на основе анализа истории сделок. Таким образом, для
            расчета налоговой декларации для Freedom Finance, требуется
            информация за всю историю торгов, начиная с момента открытия счета
            (<Link component={NavLink} to="/help/FFReport"
                          className={classes.link}>
                      подробнее
            </Link>).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={this.getActiveFAQ() === "5"}
                 onChange={this.faqChange("5")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            За какой период необходимо выгружать отчет Interactive Brokers ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HelpIBDate/>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={this.getActiveFAQ() === "6"}
                 onChange={this.faqChange("6")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Какие сделки учитывает сервис?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.helpText}>
            В настоящий момент учитываются следующие виды сделок.
            <p/>
            Для Interactive Brokers:
            <ul>
              <li>Дивиденды (выплаты по ETF)  в том числе с учетом уже 
                удержанных налогов</li>
              <li>Закрытие длинных позиций</li>
              <li>Закрытие коротких позиций</li>
              <li>Сделки с опционами (в том числе с учетом экспирации)</li>
              <li>Сделки с фьючерсами</li>
              <li>Контракты на разницу (CFD)</li>
              <li>Проценты на остаток</li>
              <li>Купоны по облигациям</li>
              <li>Комиссии брокера (за неактивность, подписку на рыночные 
                данные и т.д.)</li>
              <li>Варранты</li>
              <li>Операции Forex</li>
              <li>Корпоративные действия (выкупы, акции в качестве дивидендов,
                погашение облигаций)</li>
            </ul>
            Для Freedom Finance:
            <ul>
              <li>Дивиденды (выплаты по ETF)  в том числе с учетом уже 
                удержанных налогов</li>
              <li>Закрытие длинных позиций</li>
              <li>Закрытие коротких позиций</li>
              <li>Сделки с IPO</li>
              <li>Купоны по облигациям</li>              
            </ul>
            Список поддерживаемых в настоящий момент валют:
            <ul>
              <li>USD (Доллар США)</li>
              <li>EUR (Евро)</li>
              <li>GBP (Фунт стерлингов)</li>
              <li>CHF (Швейцарский франк)</li>
              <li>JPY (Иена)</li>
              <li>RUB (Российский рубль)</li>
              <li>DKK (Датская крона)</li>
              <li>AUD (Австралийский доллар)</li>
              <li>CAD (Канадский доллар)</li>
              <li>HKD (Гонконгский доллар)</li>
              <li>CZK (Чешская крона)</li>
              <li>PLN (Польский злотый)</li>
              <li>SEK (Шведская крона)</li>
              <li>ZAR (Южноафриканский рэнд)</li>
              <li>TRY (Турецкая лира)</li>
              <li>SGD (Сингапурский доллар)</li>
              <li>CNH (Китайский юань)</li>              
              <li>NOK (Норвежская крона)</li>
              <li>NZD (Новозеландский доллар)</li>
              <li>ILS (Новый израильский шекель)</li>
              <li>MXN (Мексиканское песо)</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={this.getActiveFAQ() === "7"}
                 onChange={this.faqChange("7")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            Почему в полученной из отчета IB декларации нет сделок, но есть дивиденды?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.helpText}>
            Если в действительности вы совершали сделки, закрывающие позиции
            в течение отчетного периода, то это значит, что
            вы выгрузили отчет активностей (Activity report) вместо ожидаемого
            пользовательского отчета (Customer report). Данные отчеты 
            по-разному представляют соверешнные сделки. Инструкция как 
            получить ожидаемый формат приведена в&ensp;
            <Link component={NavLink} 
                  to="/help/IBDeclarationReport">
              соответствующем разделе справки
            </Link>
            <Link component={NavLink} 
                  to="/help/IBDeclarationReport">
              <ArrowForwardIcon className={classes.arrow} fontSize="small"/>
            </Link>.
            К сожалению, вы не
            сможете теперь загрузить на сервис правильный отчет, т.к. оплата
            уже произошла для загруженного ранее отчета активностей.
            <p/>
            Данная ситуация разрешается в частном порядке. Для этого напишите
            пожалуйста сообщение на почту поддержки&ensp;
            <SupportEmail/>
            &ensp;с указанием логина под которым вы зарегистрированы на&ensp;
            <Link href="#" component={NavLink} to="/">
              finparser.com
            </Link>.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={this.getActiveFAQ() === "8"}
                 onChange={this.faqChange("8")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            Как открыть файл в формате DCX для отчета, содержащего более 1000 сделок?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.helpText}>
            Если в отчете брокера, за декларируемый год содержится более&ensp;
            <b>1000</b> расчетных сделок (сделок на закрытие позиций, 
            дивидендов и т.д.), то при генерации файла для программы 
            Декларация - формат <b>DCX</b> (например, для 2020 года это&ensp;
            <b>DC0</b>), данная программа не сможет открыть полученный&ensp;
            <b>DCX</b> файл и выдаст ошибку открытия файла "Неправильный формат 
            файла декларации, возможно он поврежден". Это не ошибка записи в 
            файл сервисом. Можно попробовать внести вручную 1001-ю сделку 
            посредством самой программы Декларация и затем сохранить результат 
            в <b>DCX</b>. При попытке его открытия возникнет та же самая ошибка.
            <p/>
            Решение: использовать группировку сделок по дням. Все сделки по 
            итогам каждого дня в году группируются в одну с указанием торгуемых 
            инструментов. Таким образом, максимальное количество сделок с 
            ценными бумагами не может превышать 365, аналогично с производными 
            финансовыми инструментами. 
            <p/>
            Для этого необходимо установить переключатель "Сгруппировать по 
            дням" в разделе "Дополнительные параметры расчета и комиссии" сервиса при генерации формата&ensp;
            <b>DCX</b>.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={this.getActiveFAQ() === "9"}
                 onChange={this.faqChange("9")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            Как установить программу Декларация в Linux или macOS?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.helpText}>
            Прежде всего, необходимо установить <b>Wine</b>.
            <p/>
            Для Debian/Ubuntu:
            <p/>
            <tt>sudo apt-get install wine</tt>
            <p/>
            Для Fedora:
            <p/>
            <tt>sudo dnf install wine</tt>
            <p/>
            Для macOS:
            <p/>
            <tt>brew cask install wine-stable</tt>
            <p/>
            Вы можете скачать программу Декларация с&ensp;
            <Link href="https://www.nalog.ru/rn77/program/5961249/" target="_blank">
                    официального сайта налоговой
            </Link> и установить ее, например, для 2020 года:
            <p/>
            <tt>wine msiexec /i InsD2020.msi</tt>            
            <p/>
            Либо же, вы можете скачать уже распакованные заархивированные 
            версии с данного сервиса:
            <ul>
              <li><Link href="assets/declSoft/Декларация 2017.zip">Декларация 2017</Link></li>
              <li><Link href="assets/declSoft/Декларация 2018.zip">Декларация 2018</Link></li>
              <li><Link href="assets/declSoft/Декларация 2019.zip">Декларация 2019</Link></li>
              <li><Link href="assets/declSoft/Декларация 2020.zip">Декларация 2020</Link></li>
            </ul>
            Далее, необходимо разархивировать 
            полученный архив, переключится в директорию с программой и 
            выполнить команду (например, для декларации 2020):
            <p/>
            <tt>LANG=ru_RU wine Decl2020.exe</tt>
            <p/>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={this.getActiveFAQ() === "10"}
                 onChange={this.faqChange("10")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            Что делать, если при нажатии "Сгенерировать отчеты" ничего не происходит?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.helpText}>
            Если после нажатии на кнопку "Сгенерировать отчеты" в ответ совсем
            ничего не происходит, ни ожидаемого результата, ни ошибки, то 
            первое, что следует сделать - просто обновить окно браузера (по F5).
            <p/>
            Возможно, перед генерацией вкладка с сервисом была открыта 
            достаточно давно (иногда до нескольких дней назад) и с тех
            не обновлялась. Это может привести к рассинхронизации серверного 
            кода и кода, загруженного в браузер пользователя.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={this.getActiveFAQ() === "11"}
                 onChange={this.faqChange("11")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            Письмо с подтверждением регистрации не пришло на Email
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.helpText}>
            Проверьте в почте папку спам или раздел промоакции.
          </Typography>
        </AccordionDetails>
      </Accordion>

     </div>
  );
}
}

export default withStyles(styles)(SimpleAccordion);
