import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Center from 'react-center';
import axios from 'axios';
import AlertDialog from '../alertDialog';
import LoginDialog from '../loginDialog';
import {formatNumber} from '../paymentsLib';
import {isBlank} from '../miscLib';
import { setLoginOk } from '../state/loginOkSlice';
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';
import GreenCheckbox from '../common/greenCheckbox';
import PurpleCheckbox from '../common/purpleCheckbox';
import YellowCheckbox from '../common/yellowCheckbox';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#F5F5F5',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #BDBDBD',
  },
}))(Tooltip);

const styles = theme => ({
  textField: {
    width: 150,
    margin: 7,
  },
  justify: {
    width: '968px',
    textAlign: 'justify'
  },
  justifyMobile: {
    textAlign: 'justify'
  },
  paper: {
    maxWidth: 1000,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  width42: {
    width: "42px"
  },
  width250: {
    width: "250px"
  },
  width100: {
    width: "110px",
  },
  notchedOutline: {
    borderWidth: "2px",
    borderColor: "#757575 !important"
  },
  marginTop: {
    marginTop: '10px'
  },
  tableMinWidth: {
    width: "1%",
    whiteSpace: "nowrap"
  },
  totalTable: {
    marginTop: '10px',
  },
  totalText: {
    fontSize: "large",
  },
  totalAmount: {
    fontSize: "large",
    textAlign: 'right',
    width: "120px"
  },
  payButton: {
    margin: 5,
    marginLeft: '35px'
  },
  buttonAddDiv: {
    maxWidth: 1000,
    margin: `${theme.spacing(1)}px auto`,
    padding: 0,
  },
  buttonAdd: {
    margin: 0,
    marginTop: '5px',
    float: "right"
  },
  buttonDel: {
    margin: 5,
    marginTop: 5,
    marginBottom: 0,
    paddingLeft: 6,
    paddingRight: 6,
    minWidth: 32,
    float: "right"
  },
  loader: {
    padding: '200px 0',
  },
  formControlMobile: {
    height: "20px",
    marginBottom: "10px"
  }
});

class PaymentRu extends React.Component {
  constructor(props) {
    super(props);

    this.updateTotalPrice = this.updateTotalPrice.bind(this);
    this.getDescription = this.getDescription.bind(this);
    this.handleStartPayment = this.handleStartPayment.bind(this);
  }

  isEndOfYear() {
    return new Date().getMonth() >= 10;
  }

  state = {
    toPay: 0,
    declarations: [{
      index: 0,
      brokersCount: 1,
      greenSelected: true,
      blueSelected: this.isEndOfYear() ? false : true,
      redDeclPDFSelected: false,
      redCfrPDFSelected: this.isEndOfYear() ? false : true,
      redTaxOptimizeXLSXSelected: this.isEndOfYear() ? true : false,
      isSimpleDecl: false,
      customPayment: false,
      customPaymentAmount: 0,
      customPaymentText: 'Пользовательский платеж',
    }],
    brokersCount: 1,
    declarationsNumber: 0,
    simpleDeclarationsNumber: 0,
    cashFlowReportsNumber: 0,
    taxOptimizeNumber: 0,
    simpleTaxOptimizeNumber: 0,
    phoneCallsNumber: 0,
    inProgress: false,
    disablePaymentButton: false,
    showLoginDialog: !this.props.loginOk.value
  };

  updateTotalPrice = (updateUI = true) => {
    var totalPrice = 0;
    var dn = 0;
    var sdn = 0;
    var cfrn = 0;
    var taxOpt = 0;
    var staxOpt = 0;
    var phone = 0;
    var multiplier = 0;

    this.state.declarations.forEach(d => {
      if (d.blueSelected) {
        if (d.isSimpleDecl) {
          totalPrice += 4000;
          sdn += 1;
        } else {
          totalPrice += 8000;
          dn += 1;
        }
      }
      if (d.redDeclPDFSelected) {
        totalPrice += 1000;
      }
      if (d.redCfrPDFSelected) {
        totalPrice += 1000;
        cfrn += 1;
      }
      if (d.redTaxOptimizeXLSXSelected) {
        if (d.isSimpleDecl) {
          totalPrice += 1000;
          staxOpt += 1;
        } else {
          totalPrice += 2000;
          taxOpt += 1;
        }
      }
      multiplier = 1 + ((d.brokersCount - 1) * 0.75);
      totalPrice = totalPrice * multiplier;
      if (d.customPayment) {
        totalPrice += d.customPaymentAmount;
      }
    });
    let bcs = this.state.declarations.map(d => d.brokersCount);
    this.setState({
      toPay: totalPrice,
      brokersCount: Math.max(...bcs),
      simpleDeclarationsNumber: sdn,
      declarationsNumber: dn,
      cashFlowReportsNumber: cfrn,
      taxOptimizeNumber: taxOpt,
      simpleTaxOptimizeNumber: staxOpt,
      phoneCallsNumber: phone});
    if (updateUI) {
      this.forceUpdate();
    }
  }

  updateCustomPriceAmount = declaration => event => {
    let value = parseInt(event.target.value);
    declaration.customPaymentAmount = value;
    this.updateTotalPrice();
  }

  updateCustomPriceText = declaration => event => {
    let value = event.target.value;
    declaration.customPaymentText = value;
    this.forceUpdate();
  }

  getSum = () => {
    return Math.round(this.state.toPay).toString() + '.00';
  }

  getDescription = () => {
    let dn = this.state.declarationsNumber;
    let sdn = this.state.simpleDeclarationsNumber;
    let cfrn = this.state.cashFlowReportsNumber;
    let taxOptn = this.state.taxOptimizeNumber + this.state.simpleTaxOptimizeNumber;
    let phone = this.state.phoneCallsNumber;
    let dn1 = formatNumber(dn).slice(-1) === '1';
    let sdn1 = formatNumber(sdn).slice(-1) === '1';
    let cfrn1 = formatNumber(cfrn).slice(-1) === '1';
    let taxOptn1 = formatNumber(taxOptn).slice(-1) === '1';
    let phone1 = formatNumber(phone).slice(-1) === '1';
    let bc = this.state.brokersCount;
    if (this.state.declarations.find(d => (
      d.customPayment === true && d.customPaymentAmount > 0))) {
      let text = this.state.declarations.find(d => (
        d.customPayment === true && d.customPaymentAmount > 0))
          .customPaymentText;
      if (!isBlank(text)) {
        return text;
      } else {
        return 'Пользовательский платеж';
      }
    } else if (dn === 0 && sdn === 0 && cfrn === 0 && taxOptn === 0 && phone === 0) {
      return "Ничего не выбрано";
    } else {
      return ('Вы оплачиваете генерацию ' +
              (bc > 1 ? '(' + bc + ' брокера) ' : '') +
              (dn > 0
               ? dn + ' налогов' + (dn1 ? 'ой': 'ых') +
               ' декларац' + (dn1 ? 'ии': 'ий') +
               (sdn + cfrn + taxOptn + phone > 1
                ? ', '
                : (sdn > 0 || cfrn > 0 || taxOptn > 0 || phone > 0 ? ' и ' : ''))
               : '') +
              (sdn > 0
               ? sdn + ' прост' + (sdn1 ? 'ой': 'ых') +
               ' налогов' + (sdn1 ? 'ой': 'ых') +
               ' декларац' + (sdn1 ? 'ии': 'ий') +
               (cfrn + taxOptn + phone > 1
                ? ', '
                : (cfrn > 0 || taxOptn > 0 || phone > 0 ? ' и ' : ''))
               : '') +
              (cfrn > 0
               ? cfrn + ' отчет' + (cfrn1 ? 'а': 'ов') +
               ' о движении денежных средств' +
               (taxOptn + phone > 1
                ? ', '
                : (taxOptn > 0 || phone > 0 ? ' и ' : ''))
               : '') +
              (taxOptn > 0
               ? taxOptn + ' предложен' + (taxOptn1 ? 'ия': 'ий') +
               ' по налоговой оптимизации' +
               (phone > 0 ? ' и ' : '')
               : '') +
              (phone > 0
               ? phone + ' телефонн' + (phone1 ? 'ой': 'ых') +
               ' консультац' + (phone1 ? 'ии': 'ий')
               : '')
             );
    }
  }

  handleBrokersCount = declaration => event => {
    let value = parseInt(event.target.value);
    declaration.brokersCount = value;
    this.updateTotalPrice();
  }

  handleGreen = declaration => event => {
    let selected = event.target.checked;
    declaration.greenSelected = selected;
    this.updateTotalPrice();
  }

  handleBlue = declaration => event => {
    let selected = event.target.checked;
    if (selected) {
      declaration.customPayment = false;
      declaration.customPaymentAmount = 0;
    }
    declaration.blueSelected = selected;
    declaration.redDeclPDFSelected = selected && declaration.redDeclPDFSelected;
    this.updateTotalPrice();
  }

  handleSetSimple = declaration => event => {
    let selected = event.target.checked;
    declaration.isSimpleDecl = !selected;
    this.updateTotalPrice();
  }

  handleRedDeclPDF = declaration => event => {
    let selected = event.target.checked;
    if (selected) {
      declaration.customPayment = false;
      declaration.customPaymentAmount = 0;
    }
    declaration.redDeclPDFSelected = selected;
    declaration.blueSelected = selected || declaration.blueSelected;
    this.updateTotalPrice();
  }

  handleRedCfrPDF = declaration => event => {
    let selected = event.target.checked;
    if (selected) {
      declaration.customPayment = false;
      declaration.customPaymentAmount = 0;
    }
    declaration.redCfrPDFSelected = selected;
    this.updateTotalPrice();
  }

  handleTaxOptimizeXLSX = declaration => event => {
    let selected = event.target.checked;
    if (selected) {
      declaration.customPayment = false;
      declaration.customPaymentAmount = 0;
    }
    declaration.redTaxOptimizeXLSXSelected = selected;
    this.updateTotalPrice();
  }

  handleCustom = declaration => event => {
    let selected = event.target.checked;
    declaration.customPayment = selected;
    if (selected) {
      declaration.blueSelected = false;
      declaration.redDeclPDFSelected = false;
      declaration.redCfrPDFSelected = false;
      declaration.redTaxOptimizeXLSXSelected = false;
      declaration.isSimpleDecl = false;
    }
    this.updateTotalPrice();
  }

  handleAddDeclaration = event => {
    let idx = Math.max.apply(null, this.state.declarations.map(i => {
      return i.index;
    })) + 1;
    this.state.declarations.push({
      index: idx,
      brokersCount: 1,
      greenSelected: true,
      blueSelected: true,
      redDeclPDFSelected: false,
      redCfrPDFSelected: true,
      isSimpleDecl: false});
    this.updateTotalPrice();
  }

  scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  delDeclaration = index => event => {
    this.setState({ declarations: this.state.declarations.filter(
      i => {
        return index !== i.index;
      })},
                  () => {this.updateTotalPrice();});
  }

  handleStartPayment = event => {
    const form = new FormData();
    form.append('currency', "RUB");
    form.append('sum', this.getSum());
    form.append('description', this.getDescription());
    this.setState({ disablePaymentButton: true,
                    inProgress: true });

    const config = {headers: {'Content-Type': 'application/json',
                              'X-Requested-With': 'HttpRequest',
                              'Csrf-Token': 'nocheck'},
                    timeout: 0};
    axios.post('/yookassa/payment', form, config)
      .then( (response) => {
        const url = response.data;
        document.write(
          "<a style='display: none' id='payLink' " +
            "href='" + url + "'/>");
        document.getElementById('payLink').click();
      })
      .catch( (error) => {
        if (error.response.status === 401) {
          this.props.setLoginOk(false);
        } else {
          console.log(error);
        }
        this.setState({ inProgress: false,
                        disablePaymentButton: false,
                        showLoginDialog: !this.props.loginOk.value });
        this.forceUpdate();
      });
  }

  componentDidMount = () => {
    this.updateTotalPrice(false);
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {!this.state.inProgress
         ? <div>
             <Paper className={classes.paper}>
               Выберите форматы результата для последующей генерации на основе
               отчетов брокеров:
               <FormControlLabel
                 control={
                   <GreenCheckbox
                     checked={true}/>
                 }
                 label="Уведомление об открытии и закрытии брокерского счета (является бесплатным)"/>
               <br/>
               <FormControlLabel
                 control={
                   <Checkbox
                     checked={true}
                     color="primary"/>
                 }
                 label="Форматы налоговой декларации"/>
               <br/>
               <FormControlLabel
                 control={
                   <PurpleCheckbox
                     checked={true}/>
                 }
                 label="Форматы отчета о движении денежных средств"/>
               <br/>
               <FormControlLabel
                 control={
                   <YellowCheckbox
                     checked={true}/>
                 }
                 label="Формат предложения по налоговой оптимизации"/>
               <br/>
               <FormControlLabel
                 control={
                   <Checkbox
                     checked={true}/>
                 }
                 label="Ввод произвольной суммы"/>
               <br/>
             </Paper>

             <Paper className={classes.paper}>
               <table className={classes.totalTable}>
                 <tr>
                   <td className={classes.totalText}>
                     {this.getDescription()}
                   </td>
                 </tr>
               </table>
               <table>
                 <tr>
                   <td valign="center" className={classes.totalText}>
                     Сумма:
                   </td>
                   <td valign="center" className={classes.totalAmount}>
                     {this.state.toPay} ₽
                   </td>
                   <td valign="center">
                     <Button className={classes.payButton}
                             disabled={this.state.disablePaymentButton ||
                                       !this.props.loginOk.value}
                             onClick={this.handleStartPayment}
                             variant="contained"
                             color="primary">
                       Оплатить
                     </Button>
                   </td>
                 </tr>
               </table>
             </Paper>

             {this.state.declarations.map(d => (
               <Paper className={classes.paper}>
                 {d.index > 0
                  ? <Button className={classes.buttonDel}
                            variant="contained"
                            color="secondary"
                            onClick={this.delDeclaration(d.index)}>
                      <RemoveIcon/>
                    </Button>
                  : ""}
                 Получить результат в виде:
                 {isMobile
                  // ------------------------------------------------------------
                  //                          Mobile
                  // ------------------------------------------------------------
                  ? <FormGroup className={classes.marginTop}>
                      <table>
                        <tr>
                          <td className={classes.width42}>
                            <img src='/images/stacked_line_chart_black_48dp.svg'
                                 width='32'
                                 alt=''/>
                          </td>
                          <td>
                            <Typography>
                              Количество различных брокеров/банков
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <TextField
                              className={classes.width100}
                              size="small"
                              select
                              variant="outlined"
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline
                                }
                              }}
                              value={d.brokersCount}
                              onChange={this.handleBrokersCount(d)}>
                              {[{value: 1, label: "1"},
                                {value: 2, label: "2"},
                                {value: 3, label: "3"},
                                {value: 4, label: "4"},
                                {value: 5, label: "5"},
                                {value: 6, label: "6"},
                                {value: 7, label: "7"},
                                {value: 8, label: "8"},
                                {value: 9, label: "9"},
                                {value: 10, label: "10"}
                               ].map((option) => (
                                 <MenuItem key={option.value} value={option.value}>
                                   {option.label}
                                 </MenuItem>
                               ))}
                            </TextField>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td className={classes.width42}>
                            <AssignmentTurnedInOutlinedIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td>
                            <Typography>
                              Уведомление об открытии и закрытии брокерского счета
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <GreenCheckbox
                                  checked={d.greenSelected}
                                  onChange={this.handleGreen(d)}
                                  name="outputAccountNotice"
                                  className={classes.green}/>
                              }
                              label="PDF"/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td className={classes.width42}>
                            <CreateOutlinedIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td>
                            <Typography>
                              Пояснительная записка к налоговой декларации
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Checkbox
                                  checked={d.blueSelected}
                                  onChange={this.handleBlue(d)}
                                  name="outputXLSX"
                                  color="primary"/>
                              }
                              label="Excel"/>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Checkbox
                                  checked={d.blueSelected}
                                  onChange={this.handleBlue(d)}
                                  name="outputExpalnationNotePDF"
                                  color="primary"/>
                              }
                              label="PDF"/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td className={classes.width42}>
                            <DescriptionOutlinedIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td>
                            <Typography>
                              Налоговая декларация
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Checkbox
                                  checked={d.blueSelected}
                                  onChange={this.handleBlue(d)}
                                  name="outputXML"
                                  color="primary"/>
                              }
                              label="XML"/>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Checkbox
                                  checked={d.blueSelected}
                                  onChange={this.handleBlue(d)}
                                  name="outputDC9"
                                  color="primary"/>}
                              label="DCX"/>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Switch
                                  disabled={!d.blueSelected}
                                  checked={!d.isSimpleDecl}
                                  onChange={this.handleSetSimple(d)}
                                  name="outputOpenNotice"
                                  color="primary"/>
                              }
                              label={<div>
                                       {(!d.isSimpleDecl)
                                        ? <Typography>более 50 сделок<b>*</b></Typography>
                                        : <Typography>не более 50 сделок<b>*</b></Typography>}
                                     </div>}/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td className={classes.width42}>
                            <SwapHorizOutlinedIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td>
                            <Typography>
                              Отчет о движении денежных средств
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <PurpleCheckbox
                                  checked={d.redCfrPDFSelected}
                                  onChange={this.handleRedCfrPDF(d)}
                                  name="cfrPDF"/>}
                              label="XML"/>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <PurpleCheckbox
                                  checked={d.redCfrPDFSelected}
                                  onChange={this.handleRedCfrPDF(d)}
                                  name="cfrPDF"/>}
                              label="Excel"/>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <PurpleCheckbox
                                  checked={d.redCfrPDFSelected}
                                  onChange={this.handleRedCfrPDF(d)}
                                  name="cfrPDF"/>}
                              label="PDF"/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td className={classes.width42}>
                            <img src='/images/idea.png'
                                 width='32'
                                 alt=''/>
                          </td>
                          <td>
                            <Typography>
                              Предложения по налоговой оптимизации
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <YellowCheckbox
                                  checked={d.redTaxOptimizeXLSXSelected}
                                  onChange={this.handleTaxOptimizeXLSX(d)}
                                  name="taxOptXLSX"/>}
                              label="Excel"/>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Switch
                                  disabled={!d.redTaxOptimizeXLSXSelected}
                                  checked={!d.isSimpleDecl}
                                  onChange={this.handleSetSimple(d)}
                                  name="outputOpenNotice"
                                  color="primary"/>
                              }
                              label={<div>
                                       {(!d.isSimpleDecl)
                                        ? <Typography>более 50 сделок<b>*</b></Typography>
                                        : <Typography>не более 50 сделок<b>*</b></Typography>}
                                     </div>}/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td className={classes.width42}>
                            <img src="/images/finParserLogo.svg"
                                 height='30px'
                                 width='30px'
                                 alt='logo'/>
                          </td>
                          <td>
                            <Typography>
                              Сделки и позиции в формате FinParser
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Checkbox
                                  checked={d.blueSelected}
                                  onChange={this.handleBlue(d)}
                                  name="outputFinParser"
                                  color="primary"/>}
                              label="Excel"/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td className={classes.width42}>
                            <KeyboardIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td>
                            <Typography>
                              Произвольная сумма<b>**</b>
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.customPayment}
                                  onChange={this.handleCustom(d)}
                                  name="phone"/>}
                              label=""/>
                            <TextField
                              size="small"
                              className={classes.width100}
                              disabled={!d.customPayment}
                              onChange={this.updateCustomPriceAmount(d)}
                              id="customPaymentAmount"
                              label="Сумма"
                              name="customPaymentAmount"
                              value={d.customPaymentAmount}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              size="small"
                              disabled={!d.customPayment}
                              fullWidth
                              id="customPaymentText"
                              label="Пояснение платежа"
                              name="customPaymentText"
                              onChange={this.updateCustomPriceText(d)}
                              value={d.customPaymentText}/>
                          </td>
                        </tr>
                      </table>
                    </FormGroup>
                  // ------------------------------------------------------------
                  //                          Desktop
                  // ------------------------------------------------------------
                  : <FormGroup className={classes.marginTop}>
                      <table>
                        <tr>
                          <td className={classes.width42}>
                            <img src='/images/stacked_line_chart_black_48dp.svg'
                                 width='32'
                                 alt=''/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              Количество различных брокеров/банков
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth} colspan="3">
                            <TextField
                              className={classes.width100}
                              size="small"
                              select
                              variant="outlined"
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline
                                }
                              }}
                              value={d.brokersCount}
                              onChange={this.handleBrokersCount(d)}>
                              {[{value: 1, label: "1"},
                                {value: 2, label: "2"},
                                {value: 3, label: "3"},
                                {value: 4, label: "4"},
                                {value: 5, label: "5"},
                                {value: 6, label: "6"},
                                {value: 7, label: "7"},
                                {value: 8, label: "8"},
                                {value: 9, label: "9"},
                                {value: 10, label: "10"}
                               ].map((option) => (
                                 <MenuItem key={option.value} value={option.value}>
                                   {option.label}
                                 </MenuItem>
                               ))}
                            </TextField>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <AssignmentTurnedInOutlinedIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              Уведомление об открытии и закрытии брокерского счета
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth} colspan="3">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={d.greenSelected}
                                  onChange={this.handleGreen(d)}
                                  name="outputAccountNotice"
                                  className={classes.green}/>
                              }
                              label="PDF"/>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <CreateOutlinedIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              Пояснительная записка к налоговой декларации
                            </Typography>
                          </td>
                          <td>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.blueSelected}
                                  onChange={this.handleBlue(d)}
                                  name="outputXLSX"
                                  color="primary"/>
                              }
                              label="Excel"/>
                          </td>
                          <td className={classes.tableMinWidth} colspan="2">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.blueSelected}
                                  onChange={this.handleBlue(d)}
                                  name="outputExpalnationNotePDF"
                                  color="primary"/>
                              }
                              label="PDF"/>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <DescriptionOutlinedIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              Налоговая декларация
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  Формат подачи электронной декларации
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="top">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={d.blueSelected}
                                    onChange={this.handleBlue(d)}
                                    name="outputXML"
                                    color="primary"/>
                                }
                                label="XML"/>
                            </HtmlTooltip>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  Формат программы Декларация
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="top">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={d.blueSelected}
                                    onChange={this.handleBlue(d)}
                                    name="outputDC9"
                                    color="primary"/>}
                                label="DCX"/>
                            </HtmlTooltip>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <FormControlLabel
                              className={this.textField}
                              control={
                                <Switch
                                  disabled={!d.blueSelected}
                                  checked={!d.isSimpleDecl}
                                  onChange={this.handleSetSimple(d)}
                                  name="outputOpenNotice"
                                  color="primary"/>
                              }
                              label={<div>
                                       {(!d.isSimpleDecl)
                                        ? <Typography>более 50 сделок<b>*</b></Typography>
                                        : <Typography>не более 50 сделок<b>*</b></Typography>}
                                     </div>}/>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <SwapHorizOutlinedIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              Отчет о движении денежных средств
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  Отчет о движении денежных средств
                                  подается начиная с <b>2020</b> года.<br/>
                                  За данный формат взимается дополнительная
                                  плата (1000 руб.)
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="top">
                              <FormControlLabel
                                control={
                                  <PurpleCheckbox
                                    checked={d.redCfrPDFSelected}
                                    onChange={this.handleRedCfrPDF(d)}
                                    name="cfrPDF"/>}
                                label="XML"/>
                            </HtmlTooltip>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  Отчет о движении денежных средств
                                  подается начиная с <b>2020</b> года.<br/>
                                  За данный формат взимается дополнительная
                                  плата (1000 руб.)
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="top">
                              <FormControlLabel
                                control={
                                  <PurpleCheckbox
                                    checked={d.redCfrPDFSelected}
                                    onChange={this.handleRedCfrPDF(d)}
                                    name="cfrPDF"/>}
                                label="Excel"/>
                            </HtmlTooltip>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  Отчет о движении денежных средств
                                  подается начиная с <b>2020</b> года.<br/>
                                  За данный формат взимается дополнительная
                                  плата (1000 руб.)
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="top">
                              <FormControlLabel
                                control={
                                  <PurpleCheckbox
                                    checked={d.redCfrPDFSelected}
                                    onChange={this.handleRedCfrPDF(d)}
                                    name="cfrPDF"/>}
                                label="PDF"/>
                            </HtmlTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <img src='/images/idea.png'
                                 width='32'
                                 alt=''/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              Предложения по налоговой оптимизации
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  За данный формат взимается дополнительная
                                  плата (2000 руб.)
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="top">
                              <FormControlLabel
                                control={
                                  <YellowCheckbox
                                    checked={d.redTaxOptimizeXLSXSelected}
                                    onChange={this.handleTaxOptimizeXLSX(d)}
                                    name="taxOptXLSX"/>}
                                label="Excel"/>
                            </HtmlTooltip>
                          </td>
                          <td className={classes.tableMinWidth} colspan="2">
                            <FormControlLabel
                              className={this.textField}
                              control={
                                <Switch
                                  disabled={!d.redTaxOptimizeXLSXSelected}
                                  checked={!d.isSimpleDecl}
                                  onChange={this.handleSetSimple(d)}
                                  name="outputOpenNotice"
                                  color="primary"/>
                              }
                              label={<div>
                                       {(!d.isSimpleDecl)
                                        ? <Typography>более 50 сделок<b>*</b></Typography>
                                        : <Typography>не более 50 сделок<b>*</b></Typography>}
                                     </div>}/>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <img src="/images/finParserLogo.svg"
                                 height='30px'
                                 width='30px'
                                 alt='logo'/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              Сделки и позиции в формате FinParser
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth} colspan="3">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.blueSelected}
                                  onChange={this.handleBlue(d)}
                                  name="outputFinParser"
                                  color="primary"/>}
                              label="Excel"/>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <KeyboardIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              Произвольная сумма<b>**</b>
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.customPayment}
                                  onChange={this.handleCustom(d)}
                                  name="phone"/>}
                              label=""/>
                          </td>
                          <td className={classes.tableMinWidth} colspan="2">
                            <TextField
                              size="small"
                              className={classes.width100}
                              disabled={!d.customPayment}
                              onChange={this.updateCustomPriceAmount(d)}
                              id="customPaymentAmount"
                              label="Сумма"
                              name="customPaymentAmount"
                              value={d.customPaymentAmount}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              size="small"
                              disabled={!d.customPayment}
                              fullWidth
                              id="customPaymentText"
                              label="Пояснение платежа"
                              name="customPaymentText"
                              onChange={this.updateCustomPriceText(d)}
                              value={d.customPaymentText}/>
                          </td>
                        </tr>
                      </table>
                    </FormGroup>
                 }
                 <div className={isMobile ? classes.justifyMobile : classes.justify}>
                   <b>*</b> В качестве операций учитываются получение дивидендов,
                   купонов, процентов на остаток, а также совершение сделок,
                   закрывающих позции. Так, закрытие длинных позиций продажей
                   или коротких позиций покупкой считается сделкой, просто
                   покупка длинных позиций - нет. Расчет налогов для
                   большинства индексных стратегий при пассивном
                   инвестировании часто оценивается как "простая" декларация.
                 </div>
                 <div className={isMobile ? classes.justifyMobile : classes.justify}>
                   <b>**</b> В случае, если, например, у пользователя имеется
                   неиспользованный остаток денежных средств из прошлых
                   платежей, можно воспользоваться ими, доплатив разницу.
                 </div>
               </Paper>
             ))}
             {isMobile
              ? <Center>
                  <Button style={{ borderRadius: "64px",
                                   margin: 5,
                                   marginTop: 0,
                                   marginBottom: 20,
                                   paddingLeft: 6,
                                   paddingRight: 6,
                                   minWidth: 60,
                                   minHeight: 60 }}
                          variant="contained"
                          color="primary"
                          onClick={this.scrollTop}>
                    <ArrowUpwardIcon/>
                  </Button>
                </Center>
              : <div className={classes.buttonAddDiv}>
                  <Button className={classes.buttonAdd}
                          variant="contained"
                          color="primary"
                          onClick={this.handleAddDeclaration}>
                    <AddIcon/>
                    Добавить декларацию
                  </Button>
                </div>
             }
             <LoginDialog
               open={this.state.showLoginDialog &&
                     !this.props.loginOk.value}
               propertyName='showLoginDialog'
               text="оплата будет недоступна"
               parent={this}/>
           </div>
         : <Center>
             <div className={classes.loader}>
               <CircularProgress size={90} />
             </div>
           </Center>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const loginOk = state.loginOk;
  return {
    loginOk
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginOk: (value) => dispatch(setLoginOk(value)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PaymentRu));
