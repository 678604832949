import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';
import Link from '@material-ui/core/Link';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import { NavLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Progress from '../progress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import axios from 'axios';
import { isMobile } from '../common/deviceDetector';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    table: {
      minWidth: 650,
    },
    tableMobile: {
      width: "92vw",
    },
    tableContainerMobile: {
      width: "92vw",
    },
    row: {
      height: 33,
    },
    arrow: {
        marginBottom: '-6px'
    },
    text: {
      maxWidth: '800pt'
    },
    loader: {
        padding: '200px 0',
    }
});

class HelpFinParserFormat extends React.Component {
    constructor(props) {
        super(props);
        // This binding is necessary to make `this` work in the callback
        this.loadFinParserData = this.loadFinParserData.bind(this);
        this.caption = this.caption.bind(this);
        this.columns = this.columns.bind(this);
        this.loadFinParserData();
    }

    state = {
        inProgress: false,
        finParserFormatData: {}
    }

    loadFinParserData() {
        this.setState({ inProgress: true });
        axios.get('/finParserFormatData')
            .then( (response) => {
                this.setState({ inProgress: false });
                Object.keys(response.data).map(key => {
                    this.setState({
                        finParserFormatData: response.data
                    });
                });
            })
            .catch( (error) => {
                this.setState({ inProgress: false });
                console.log(error);
            });
    }

    caption(value, lang) {
        return this.state.finParserFormatData[value] === undefined
            ? ""
            : this.state.finParserFormatData[value].caption[lang];
    }

    columns(value, lang) {
        return this.state.finParserFormatData[value] === undefined
            ? []
            : this.state.finParserFormatData[value].columns.map(v => {
                return v.name[lang];
            });
    }

    values(value, lang) {
        return this.state.finParserFormatData[value] === undefined
            ? []
            : this.state.finParserFormatData[value].map(v => {
                return v.name[lang];
            });
    }

    validation(value, lang) {
        return this.state.finParserFormatData[value] === undefined
            ? []
            : (this.state.finParserFormatData[value]
               .map(v => {
                   return v[lang];
               })
               .filter(f => {
                   return f != '';
               }));
    }

    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <>
            {!this.state.inProgress
            ?<Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>

                <HelpHashLink to="/help/FinParserFormat#1">
                  <Typography id="1" variant="h5" component="h2">
                    {text(language,
                          {"ru": "Назначение формата",
                           "en": "Purpose of the format",
                           "es": "Propósito del formato"})}
                  </Typography>
                </HelpHashLink>
                <br/>
                <div className={classes.text}>
                  {text(language,
                        {"ru": <span>
                                 Универсальный формат FinParser
                                 используется для того, чтобы можно
                                 было вручную вводить совершаемые
                                 сделки на любом брокерском или
                                 банковском счете, если для данного
                                 брокера или банка нет автоматического
                                 парсера отчета.
                               </span>,
                         "en": <span>
                                 The universal FinParser format is
                                 used to allow you to manually type
                                 transactions on any
                                 brokerage or bank account if there is
                                 no automatic report parser for that
                                 broker or bank.
                               </span>,
                         "es": <span>
                                 El formato universal FinParser se
                                 utiliza para permitirle escribir
                                 manualmente transacciones en
                                 cualquier bróker o cuenta bancaria
                                 si no hay un analizador de informes
                                 automático para ese bróker o
                                 banco.
                               </span>})}
                </div>
                <p/>
                <div className={classes.text}>
                  {text(language,
                        {"ru": <span>
                                 Кроме того, существует возможность
                                 выводить в данный формат любой
                                 прочитанный.
                               </span>,
                         "en": <span>
                                 In addition, it is possible to output
                                 any parsed data into this format.
                               </span>,
                         "es": <span>
                                 Además, es posible generar cualquier
                                 dato analizado en este formato.
                               </span>})}
                </div>
                <p/>
                <div className={classes.text}>
                  {text(language,
                        {"ru": <span>
                                 В случае необходимости расчета
                                 налогов от сделок Forex для отчетов
                                 Interactive Brokers можно
                                 использовать универсальный формат
                                 FinParser для хранения данных об
                                 открытых позициях Forex.
                               </span>,
                         "en": <span>
                                 If you need to calculate taxes from
                                 Forex transactions for Interactive
                                 Brokers reports, you can use the
                                 universal FinParser format to store
                                 data for open Forex positions.
                               </span>,
                         "es": <span>
                                 Si necesita calcular los impuestos de
                                 las transacciones de Forex para los
                                 informes de Interactive Brokers,
                                 puede utilizar el formato universal
                                 FinParser para almacenar datos de las
                                 posiciones abiertas de Forex.
                               </span>})}&ensp;
                  <Link component={NavLink}
                        to="/help/IBForex">
                    {text(language,
                           {"ru": "Подробнее",
                            "en": "More",
                            "es": "Más"})}
                  </Link>
                  <Link component={NavLink} to="/help/IBForex">
                    <ArrowForwardIcon className={classes.arrow}
                                      fontSize="small"/>
                  </Link>
                </div>
                <Alert severity="warning" className={classes.text}>
                  {text(language,
                        {"ru": <span>
                                 При загрузке файла универсального
                                 формата FinParser одновременно с
                                 отчетом Interactive Brokers в случае,
                                 если данные листа "Брокер или Банк"
                                 будут совпадать с данными отчета
                                 Interactive Brokers (название
                                 финансовой организации и номер
                                 счета), то все данные универсального
                                 формата FinParser будут
                                 проигнорированы за исключением листа
                                 "Позиции Forex". В случае корректной
                                 выгрузки, в отчете Interactive
                                 Brokers уже содержатся все данные о
                                 сделках и открытых позициях на момент
                                 выгрузки, за исключением позиций
                                 Forex, поэтому их дублирование могло
                                 бы привести к некорректному расчету.
                               </span>,
                         "en": <span>
                                 When loading a file of the universal
                                 FinParser format simultaneously with
                                 an Interactive Brokers report, if the
                                 data in the "Broker or Bank" sheet
                                 matches the data in the Interactive
                                 Brokers report (name of financial
                                 institution and account number), then
                                 all data in the universal FinParser
                                 format will be ignored except for the
                                 "Forex Positions" sheet. The Interactive
                                 Brokers report already contains all
                                 data on transactions and open
                                 positions at the time of report generation,
                                 with the exception of Forex
                                 positions, so duplicating them could
                                 lead to incorrect calculation.
                               </span>,
                         "es": <span>
                                 Al cargar un archivo del formato
                                 universal FinParser simultáneamente
                                 con un informe de Interactive
                                 Brokers, si los datos de la hoja
                                 "Broker o Banco" coinciden con los
                                 datos del informe de Interactive
                                 Brokers (nombre de la institución
                                 financiera y número de cuenta),
                                 entonces todos los datos del El
                                 formato universal de FinParser se
                                 ignorará excepto en la hoja
                                 "Posiciones Forex". El informe de
                                 Interactive Brokers ya contiene todos
                                 los datos sobre transacciones y
                                 posiciones abiertas en el momento de
                                 la generación del informe, con la
                                 excepción de las posiciones de Forex,
                                 por lo que duplicarlos podría dar
                                 lugar a cálculos incorrectos.
                               </span>})}
                </Alert>
                <p/>
                {text(language,
                      {"ru": "Шаблон файла доступен по ссылке:",
                       "en": "The file template is available here:",
                       "es": "La plantilla de archivo está disponible aquí:"})}&ensp;
                <Link href={"/finParserTemplate/" + language}>
                  <NoteAddOutlinedIcon
                    className={classes.arrow}
                    style={{ fontSize: 32 }}/>
                  finParserFormat_Template
                </Link>.
                <p/>
                <HelpHashLink to="/help/FinParserFormat#2" >
                  <Typography id="2" variant="h5" component="h2">
                    {text(language,
                          {"ru": "Описание формата",
                           "en": "Format Description",
                           "es": "Descripción del formato"})}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Ниже перечислены названия листов в
                               Excel и перечень колонок с примерами
                               значений.
                             </span>,
                       "en": <span>
                               Here are the names of sheets in Excel
                               and a list of columns with example
                               values.
                             </span>,
                       "es": <span>
                               Aquí están los nombres de las hojas en
                               Excel y una lista de columnas con
                               valores de ejemplo.
                             </span>})}
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.1">
                  <Typography id="2.1" variant="h6" component="h3">
                    {/* Брокер или Банк */}
                    {this.caption("brokerOrBankColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием финансовой организации.
                               В качестве типа организации указывается
                               одно из двух значений:
                             </span>,
                       "en": <span>
                               Sheet with a description of the
                               financial organization. One of two
                               values ​​is specified as the organization
                               type:
                             </span>,
                       "es": <span>
                               Hoja con descripción de la entidad
                               financiera. Se especifica uno de dos
                               valores como tipo de organización:
                             </span>})}
                <ul>
                  {this.values("brokerOrBankValues", language).map(v => {
                      return <li>{v}</li>;
                  })}
                </ul>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableBody>
                      {[
                          /* ['Тип организации', 'Брокер'], */
                          [this.columns("brokerOrBankColumns", language)[0], this.values("brokerOrBankValues", language)[0]],
                          /* ['Название', 'Interactive Brokers LLC'], */
                          [this.columns("brokerOrBankColumns", language)[1], 'Interactive Brokers LLC'],
                          /* ['Адрес', 'One Pickwick Plaza, Greenwich,'], */
                          [this.columns("brokerOrBankColumns", language)[2], 'One Pickwick Plaza, Greenwich,'],
                          [' ', 'CT 06830 USA'],
                          /* ['SWIFT код или БИК', ''], */
                          [this.columns("brokerOrBankColumns", language)[3], ''],
                          /* ['Номер налогоплательщика', '13-3863700'], */
                          [this.columns("brokerOrBankColumns", language)[4], '13-3863700'],
                          /* ['Код страны', '840'], */
                          [this.columns("brokerOrBankColumns", language)[5], '840'],
                          /* ['Номер счета', '123456789'], */
                          [this.columns("brokerOrBankColumns", language)[6], '123456789'],
                          /* ['Базовая валюта', 'USD'], */
                          [this.columns("brokerOrBankColumns", language)[7], 'USD'],
                          /* ['Отчет за период', '01.01.2021 - 31.12.2021'] */
                          [this.columns("brokerOrBankColumns", language)[8], '01.01.2021 - 31.12.2021']
                        ].map((row) => (
                        <TableRow key={row[0]} className={classes.row}>
                          <TableCell component="th" scope="row">
                            {row[0]}
                          </TableCell>
                          <TableCell align="left">{row[1]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.2">
                  <Typography id="2.2" variant="h6" component="h3">
                    {/* Позиции ДС */}
                    {this.caption("moneyPositionsColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием позиций в денежных
                               средствах на начало и конец периода. В
                               качестве типа позиции указывается одно
                               из следующих значений:
                             </span>,
                       "en": <span>
                               A sheet describing cash positions at
                               the beginning and end of the
                               period. One of the following values ​​is
                               specified as the position type:
                             </span>,
                       "es": <span>
                               Una hoja que describe las posiciones de
                               efectivo al principio y al final del
                               período. Se especifica uno de los
                               siguientes valores como tipo de
                               posición:
                             </span>})}
                <ul>
                  {this.values("moneyPositionTypes", language).map(v => {
                      return <li>{v}</li>;
                  })}
                </ul>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      {[
                          /* Валюта */
                          {text: this.columns("moneyPositionsColumns", language)[0], align: 'left'},
                          /* Тип позиции */
                          {text: this.columns("moneyPositionsColumns", language)[1], align: 'left'},
                          /* Количество */
                          {text: this.columns("moneyPositionsColumns", language)[2], align: 'left'}
                        ].map((h) => <TableCell align={h.align}>{h.text}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                          /* Остаток средств на начало периода */
                          ['USD', this.values("moneyPositionTypes", language)[0], '1224,11'],
                          /* Остаток средств на конец периода */
                          ['USD', this.values("moneyPositionTypes", language)[1], '978,12'],
                          /* Остаток средств на начало периода */
                          ['EUR', this.values("moneyPositionTypes", language)[0], '3000,00'],
                          /* Остаток средств на конец периода */
                          ['EUR', this.values("moneyPositionTypes", language)[1], '0']
                        ].map((row) => (
                        <TableRow>
                          {row.map((cell) =>
                            <TableCell align="left">{cell}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.3">
                  <Typography id="2.3" variant="h6" component="h3">
                    {/* Движение ДС */}
                    {this.caption("moneyTransactionsColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием движения денежных средств.
                               Дата и Описание - необязательные поля.
                             </span>,
                       "en": <span>
                               Sheet describing cash flows.
                               Date and Description are optional fields.
                             </span>,
                       "es": <span>
                               Hoja que describe los flujos de efectivo.
                               Fecha y Descripción son campos opcionales.
                             </span>})}
                <p/>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      {[
                          /* Валюта */
                          {text: this.columns("moneyTransactionsColumns", language)[0], align: 'left'},
                          /* Дата */
                          {text: this.columns("moneyTransactionsColumns", language)[1], align: 'left'},
                          /* Количество */
                          {text: this.columns("moneyTransactionsColumns", language)[2], align: 'left'},
                          /* Описание */
                          {text: this.columns("moneyTransactionsColumns", language)[3], align: 'left'}
                        ].map((h) => <TableCell align={h.align}>{h.text}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                          ['USD', '', '1385,12', ''],
                          ['USD', '', '2190,00', ''],
                          ['USD', '', '-3821,11', ''],
                          ['EUR', '', '-3000,00', '']
                        ].map((row) => (
                        <TableRow>
                          {row.map((cell) =>
                            <TableCell align="left">{cell}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.4">
                  <Typography id="2.4" variant="h6" component="h3">
                    {/* Позиции ЦБ */}
                    {this.caption("stocksPositionsColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием позиций в ценных
                               бумагах на начало периода. В качестве
                               класса актива указывается одно из
                               следующих значений:
                             </span>,
                       "en": <span>
                               A sheet describing positions in
                               securities at the beginning of the
                               period. One of the following values ​​is
                               specified as the asset category:
                             </span>,
                       "es": <span>
                               Una hoja que describe las posiciones en
                               valores al comienzo del período. Se
                               especifica uno de los siguientes
                               valores como categoría de activo:
                             </span>})}
                <ul>
                  {this.validation("stocksOrBondsValidation", language).map(v => {
                      return <li>{v}</li>;
                  })}
                </ul>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      {[
                          /* Тикер */
                          {text: this.columns("stocksPositionsColumns", language)[0], align: 'left'},
                          /* Тип позиции */
                          {text: this.columns("stocksPositionsColumns", language)[1], align: 'left'},
                          /* Валюта */
                          {text: this.columns("stocksPositionsColumns", language)[2], align: 'left'},
                          /* Дата открытия */
                          {text: this.columns("stocksPositionsColumns", language)[3], align: 'left'},
                          /* Количество */
                          {text: this.columns("stocksPositionsColumns", language)[4], align: 'left'},
                          /* Цена */
                          {text: this.columns("stocksPositionsColumns", language)[5], align: 'left'},
                          /* Комиссия */
                          {text: this.columns("stocksPositionsColumns", language)[6], align: 'left'}
                        ].map((h) => <TableCell align={h.align}>{h.text}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                          ['T', this.validation("stocksOrBondsValidation", language)[0], 'USD', '27.08.2018', '30,00', '32,8717', ''],
                          ['VOD', this.validation("stocksOrBondsValidation", language)[0], 'GBP', '01.03.2019', '9,00', '17,8195', ''],
                        ].map((row) => (
                        <TableRow>
                          {row.map((cell) =>
                            <TableCell align="left">{cell}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.5">
                  <Typography id="2.5" variant="h6" component="h3">
                    {/* Сделки ЦБ */}
                    {this.caption("stocksTransactionsColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием сделок с ценными
                               бумагами в течение периода. В качестве
                               класса актива указывается одно из
                               следующих значений:
                             </span>,
                       "en": <span>
                               A sheet describing transactions in
                               securities during the period. The asset
                               category must be one of the following:
                             </span>,
                       "es": <span>
                               Una hoja que describe las transacciones
                               de valores durante el período. La
                               categoría de activo debe ser una de las
                               siguientes:
                             </span>})}
                <ul>
                  {this.validation("stocksOrBondsValidation", language).map(v => {
                      return <li>{v}</li>;
                  })}
                </ul>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      {[
                          /* Тикер */
                          {text: this.columns("stocksTransactionsColumns", language)[0], align: 'left'},
                          /* Тип позиции */
                          {text: this.columns("stocksTransactionsColumns", language)[1], align: 'left'},
                          /* Валюта */
                          {text: this.columns("stocksTransactionsColumns", language)[2], align: 'left'},
                          /* Дата расчета */
                          {text: this.columns("stocksTransactionsColumns", language)[3], align: 'left'},
                          /* Количество */
                          {text: this.columns("stocksTransactionsColumns", language)[4], align: 'left'},
                          /* Цена */
                          {text: this.columns("stocksTransactionsColumns", language)[5], align: 'left'},
                          /* Комиссия */
                          {text: this.columns("stocksTransactionsColumns", language)[6], align: 'left'}
                        ].map((h) => <TableCell align={h.align}>{h.text}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                          ['T', this.validation("stocksOrBondsValidation", language)[0], 'USD', '20.03.2020', '100,00', '36,00', '-1.00'],
                          ['VOD', this.validation("stocksOrBondsValidation", language)[0], 'GBP', '09.03.2020', '300,00', '1,4054', '-8,11']
                        ].map((row) => (
                        <TableRow>
                          {row.map((cell) =>
                            <TableCell align="left">{cell}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.6">
                  <Typography id="2.6" variant="h6" component="h3">
                    {/* Позиции ПФИ */}
                    {this.caption("derivativesPositionsColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием позиций в производных
                               финансовых инструментах на начало
                               периода. В качестве класса актива
                               указывается одно из следующих значений:
                             </span>,
                       "en": <span>
                               A sheet describing positions in
                               derivative financial instruments at the
                               beginning of the period. The asset
                               category must be one of the following:
                             </span>,
                       "es": <span>
                               Una hoja que describe las posiciones en
                               instrumentos financieros derivados al
                               inicio del período. La categoría de
                               activo debe ser una de las siguientes:
                             </span>})}
                <ul>
                  {this.validation("derivativesValidation", language).map(v => {
                      return <li>{v}</li>;
                  })}
                </ul>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      {[
                          /* Тикер */
                          {text: this.columns("derivativesPositionsColumns", language)[0], align: 'left'},
                          /* Тип позиции */
                          {text: this.columns("derivativesPositionsColumns", language)[1], align: 'left'},
                          /* Валюта */
                          {text: this.columns("derivativesPositionsColumns", language)[2], align: 'left'},
                          /* Дата открытия */
                          {text: this.columns("derivativesPositionsColumns", language)[3], align: 'left'},
                          /* Количество */
                          {text: this.columns("derivativesPositionsColumns", language)[4], align: 'left'},
                          /* Цена */
                          {text: this.columns("derivativesPositionsColumns", language)[5], align: 'left'},
                          /* Комиссия */
                          {text: this.columns("derivativesPositionsColumns", language)[6], align: 'left'}
                        ].map((h) => <TableCell align={h.align}>{h.text}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                          ['JNJ 19FEB21 145.0 P', this.validation("derivativesValidation", language)[0], 'USD', '02.12.2020', '-100,00', '4,6135', ''],
                          ['T 08JAN21 28.0 P', this.validation("derivativesValidation", language)[0], 'USD', '28.12.2020', '-100,00', '0,2721', '']
                        ].map((row) => (
                        <TableRow>
                          {row.map((cell) =>
                            <TableCell align="left">{cell}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.7">
                  <Typography id="2.7" variant="h6" component="h3">
                    {/* Сделки ПФИ */}
                    {this.caption("derivativesTransactionsColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием сделок с ценными
                               бумагами в течение периода. В качестве
                               класса актива указывается одно из
                               следующих значений:
                             </span>,
                       "en": <span>
                               A sheet describing transactions in
                               securities during the period. The asset
                               category must be one of the following:
                             </span>,
                       "es": <span>
                               Una hoja que describe las transacciones
                               de valores durante el período. La
                               categoría de activo debe ser una de las
                               siguientes:
                             </span>})}
                <ul>
                  {this.validation("derivativesValidation", language).map(v => {
                      return <li>{v}</li>;
                  })}
                </ul>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      {[
                          /* Тикер */
                          {text: this.columns("derivativesTransactionsColumns", language)[0], align: 'left'},
                          /* Тип позиции */
                          {text: this.columns("derivativesTransactionsColumns", language)[1], align: 'left'},
                          /* Валюта */
                          {text: this.columns("derivativesTransactionsColumns", language)[2], align: 'left'},
                          /* Дата расчета */
                          {text: this.columns("derivativesTransactionsColumns", language)[3], align: 'left'},
                          /* Количество */
                          {text: this.columns("derivativesTransactionsColumns", language)[4], align: 'left'},
                          /* Цена */
                          {text: this.columns("derivativesTransactionsColumns", language)[5], align: 'left'},
                          /* Комиссия */
                          {text: this.columns("derivativesTransactionsColumns", language)[6], align: 'left'}
                        ].map((h) => <TableCell align={h.align}>{h.text}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                          ['T 21FEB20 36.0 P', this.validation("derivativesValidation", language)[0], 'USD', '30.01.2020', '-100,00', '0,2300', '-1,10'],
                          ['JNJ 17JUL20 136.0 P', this.validation("derivativesValidation", language)[0], 'USD', '08.07.2020', '-100,00', '0,5500', '-1,58']
                        ].map((row) => (
                        <TableRow>
                          {row.map((cell) =>
                            <TableCell align="left">{cell}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.8">
                  <Typography id="2.8" variant="h6" component="h3">
                    {/* Дивиденды */}
                    {this.caption("dividendsColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием дивидендов и уплаченных налогов по ним.
                             </span>,
                       "en": <span>
                               A sheet describing dividends and withholding taxes.
                             </span>,
                       "es": <span>
                               Una hoja que describe dividendos y retenciones fiscales.
                             </span>})}
                <p/>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      {[
                          /* Тикер */
                          {text: this.columns("dividendsColumns", language)[0], align: 'left'},
                          /* Валюта */
                          {text: this.columns("dividendsColumns", language)[1], align: 'left'},
                          /* Дата зачисления */
                          {text: this.columns("dividendsColumns", language)[2], align: 'left'},
                          /* Сумма зачисленных дивидендов */
                          {text: this.columns("dividendsColumns", language)[3], align: 'left'},
                          /* Удержанный налог по СИДН */
                          {text: this.columns("dividendsColumns", language)[4], align: 'left'}
                        ].map((h) => <TableCell align={h.align}>{h.text}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                          ['AAPL', 'USD', '14.05.2020', '5,74', '0,57'],
                          ['T', 'USD', '01.05.2020', '77,48', '7,75']
                        ].map((row) => (
                        <TableRow>
                          {row.map((cell) =>
                            <TableCell align="left">{cell}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.9">
                  <Typography id="2.9" variant="h6" component="h3">
                    {/* Прочее */}
                    {this.caption("othersColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием прочих доходов и
                               расходов. В качестве типа
                               дохода/расхода указывается одно из
                               следующих значений:
                             </span>,
                       "en": <span>
                               A sheet describing other income and
                               expenses. The following values can be
                               usea as a type of income/expense:
                             </span>,
                       "es": <span>
                               Una hoja que describe otros ingresos y
                               gastos. Los siguientes valores se
                               pueden utilizar como tipo de
                               ingreso/gasto:
                             </span>})}
                <ul>
                  {this.validation("feesValidationList", language).map(v => {
                      return <li>{v}</li>;
                  })}
                </ul>
                <p/>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      {[
                          /* Описание */
                          {text: this.columns("othersColumns", language)[0], align: 'left'},
                          /* Тип дохода/расхода */
                          {text: this.columns("othersColumns", language)[1], align: 'left'},
                          /* Валюта */
                          {text: this.columns("othersColumns", language)[2], align: 'left'},
                          /* Дата зачисления / списания */
                          {text: this.columns("othersColumns", language)[3], align: 'left'},
                          /* Сумма */
                          {text: this.columns("othersColumns", language)[4], align: 'left'}
                        ].map((h) => <TableCell align={h.align}>{h.text}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                          [this.validation("feesValidationList", language)[0], this.validation("feesValidationList", language)[0], 'USD', '02.09.2020', '-3,65'],
                          [this.validation("feesValidationList", language)[1], this.validation("feesValidationList", language)[1], 'USD', '06.01.2020', '-5,98']
                        ].map((row) => (
                        <TableRow>
                          {row.map((cell) =>
                            <TableCell align="left">{cell}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.10">
                  <Typography id="2.10" variant="h6" component="h3">
                    {/* Позиции Forex */}
                    {this.caption("forexPositionsColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием позиций в валютных парах.
                             </span>,
                       "en": <span>
                               A sheet describing positions in currency pairs.
                             </span>,
                       "es": <span>
                               Una hoja que describe posiciones en pares de divisas.
                             </span>})}
                <p/>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      {[
                          /* Тикер */
                          {text: this.columns("forexPositionsColumns", language)[0], align: 'left'},
                          /* Тип позиции */
                          {text: this.columns("forexPositionsColumns", language)[1], align: 'left'},
                          /* Валюта */
                          {text: this.columns("forexPositionsColumns", language)[2], align: 'left'},
                          /* Дата открытия */
                          {text: this.columns("forexPositionsColumns", language)[3], align: 'left'},
                          /* Количество */
                          {text: this.columns("forexPositionsColumns", language)[4], align: 'left'},
                          /* Цена */
                          {text: this.columns("forexPositionsColumns", language)[5], align: 'left'},
                          /* Комиссия */
                          {text: this.columns("forexPositionsColumns", language)[6], align: 'left'}
                        ].map((h) => <TableCell align={h.align}>{h.text}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                          ['USD.GBP', 'Forex', 'GBP', '07.12.2018', '205,81', '0,7915', '-133.1582'],
                          ['USD.CAD', 'Forex', 'CAD', '29.04.2019', '-135,10', '1,3460', '-2.692']
                        ].map((row) => (
                        <TableRow>
                          {row.map((cell) =>
                            <TableCell align="left">{cell}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

                <HelpHashLink to="/help/FinParserFormat#2.11">
                  <Typography id="2.11" variant="h6" component="h3">
                    {/* Сделки Forex */}
                    {this.caption("forexTransactionsColumns", language)}
                  </Typography>
                </HelpHashLink>
                <br/>
                {text(language,
                      {"ru": <span>
                               Лист с описанием сделок с валютными парами.
                             </span>,
                       "en": <span>
                               Sheet describing transactions with currency pairs.
                             </span>,
                       "es": <span>
                               Hoja que describe transacciones con pares de divisas.
                             </span>})}
                <p/>
                <TableContainer component={Paper}
                                className={isMobile ? classes.tableContainerMobile : ""}>
                  <Table className={isMobile ? classes.tableMobile : classes.table}
                         size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      {[
                          /* Тикер */
                          {text: this.columns("forexTransactionsColumns", language)[0], align: 'left'},
                          /* Тип позиции */
                          {text: this.columns("forexTransactionsColumns", language)[1], align: 'left'},
                          /* Валюта */
                          {text: this.columns("forexTransactionsColumns", language)[2], align: 'left'},
                          /* Дата открытия */
                          {text: this.columns("forexTransactionsColumns", language)[3], align: 'left'},
                          /* Количество */
                          {text: this.columns("forexTransactionsColumns", language)[4], align: 'left'},
                          /* Цена */
                          {text: this.columns("forexTransactionsColumns", language)[5], align: 'left'},
                          /* Комиссия */
                          {text: this.columns("forexTransactionsColumns", language)[6], align: 'left'}
                        ].map((h) => <TableCell align={h.align}>{h.text}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                          ['USD.GBP', 'Forex', 'GBP', '07.02.2020', '-29,58', '0,7901', '-132,431'],
                          ['USD.CAD', 'Forex', 'CAD', '17.11.2020', '4,90', '1,3074', '-2'],
                        ].map((row) => (
                        <TableRow>
                          {row.map((cell) =>
                            <TableCell align="left">{cell}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p/>

              </CardContent>
            </Card>
             :<div className={classes.loader}>
                   {this.state.documentGeneration
                    ? <Progress
                        parent={this}
                        context={this.context}/>
                    : <CircularProgress size={90}/>
                  }
                 </div>}
            </>
        );
    }
}

function mapStateToProps(state) {
    const language = state.language;
    return {
        language
    };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpFinParserFormat));
