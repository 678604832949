import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Center from 'react-center';
import PromptDialog from "./promptDialog";
import { connect } from "react-redux";
import { setLoginOk } from './state/loginOkSlice';
import { setAccessLevel } from './state/accessLevelSlice';
import { text } from "./languageSelection";
import { isMobile } from './common/deviceDetector';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paperMobile: {
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loader: {
        padding: '200px 0',
    }
});

class Login extends React.Component {
    constructor(props) {
        super(props);

        // This binding is necessary to make `this` work in the callback
        this.submitLogin = this.submitLogin.bind(this);
    }

    state = {
        showRegisterInfoDialog: false,
        inProgress: false,
        loginFailed: false,
        login: '',
        password: '',
        email: '',
        rememberMe: false,
        showPassword: false
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleChangeBoolean = name => event => {
        this.setState({
            [name]: event.target.checked
        });
    };

    handleForgetPasswordDialog = event => {
        if (event != null) {
            event.preventDefault();
        }
        this.setState({ showRegisterInfoDialog: true });
    };

    gotoTax() {
        window.location.hash = "tax";
    }
    
    handleResetPasswordByEmail = value => {
      this.setState({ showRegisterInfoDialog: false });      
      if (value === 'ok') {
        this.setState({ inProgress: true });
        const config = { headers: { 'Content-Type': 'application/json',
                                    'X-Requested-With': 'HttpRequest',
                                    'Csrf-Token': 'nocheck'},
                         timeout: 0};
        const form = new FormData();
        form.append(['login'], this.state.login);
        form.append(['email'], this.state.email);
        axios.post("/resetPassword", form, config)
        .then( (response) => {
          this.setState({ inProgress: false });
        })
        .catch( (error) => {
            this.setState({ inProgress: false });
            console.log(error);
        });
      }
    };

    submitLogin(event) {
        if (event != null) {
            event.preventDefault();
        }
        this.setState({ inProgress: true });
        const config = { headers: { 'Content-Type': 'application/json',
                                    'X-Requested-With': 'HttpRequest',
                                    'Csrf-Token': 'nocheck'},
                         timeout: 0};
        const data = new FormData();
        data.append('login', this.state['login']);
        data.append('password', this.state['password']);
        data.append('rememberMe', this.state['rememberMe']);
        axios.post("/login", data, config)
            .then( (response) => {
                if (response.status === 200) {
                    this.props.setLoginOk(true);
                    this.props.setAccessLevel(response.data.access);
                    this.setState({ loginFailed: false });
                    this.props.parent.forceUpdate();
                    this.gotoTax();
                    this.forceUpdate();
                } else {
                    this.props.setLoginOk(false);
                    this.props.setAccessLevel("none");
                    this.setState({ loginFailed: true });
                }
                this.setState({ inProgress: false });
            })
            .catch( (error) => {
                this.props.setLoginOk(false);
                this.props.setAccessLevel("none");
                this.setState({ inProgress: false });
                this.setState({ loginFailed: true });
            });
    };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <Center>
              {!this.state.inProgress
               ?
               <Card className={classes.card}>
                 <CardContent>
                   <Container component="main" maxWidth="xs">
                     <CssBaseline />
                     <div className={isMobile ? classes.paperMobile : classes.paper}>
                       <Avatar className={classes.avatar}>
                         <LockOutlinedIcon />
                       </Avatar>
                       <Typography component="h1" variant="h5">
                         {text(language,
                               {"ru": "Аутентификация",
                                "en": "Authentication",
                                "es": "Autenticación"})}
                       </Typography>
                       <form className={classes.form} noValidate>
                         {this.state.loginFailed
                          ? <Alert severity="error">
                              {text(language,
                               {"ru": "Ошибка аутентификации. Проверьте логин и пароль.",
                                "en": "Authentication failed. Check your login and password.",
                                "es": "La autenticación falló. Verifique su nombre de usuario y contraseña."})}
                            </Alert>
                          : ""}
                         <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           id="login"
                           label={text(language,
                                       {"ru": "Логин",
                                        "en": "Login",
                                        "es": "Acceso"})}
                           name="login"
                           value={this.state.login}
                           onChange={this.handleChange('login')}
                           autoComplete="email" />
                         <OutlinedInput
                           margin="normal"
                           required
                           fullWidth
                           name="password"
                           label={text(language,
                                       {"ru": "Пароль",
                                        "en": "Password",
                                        "es": "Contraseña"})}
                           type={this.state.showPassword ? 'text' : 'password'}
                           id="password"
                           value={this.state.password}
                           onChange={this.handleChange('password')}
                           autoComplete="current-password"
                           endAdornment={
                             <InputAdornment position="end">
                               <IconButton
                                 aria-label="toggle password visibility"
                                 onClick={this.handleClickShowPassword}
                                 onMouseDown={this.handleMouseDownPassword}>
                                 {this.state.showPassword
                                  ? <Visibility />
                                  : <VisibilityOff />}
                               </IconButton>
                             </InputAdornment>}/>
                         <FormControlLabel
                           control={<Checkbox color="primary"/>}
                           label={text(language,
                                       {"ru": "Запомнить меня",
                                        "en": "Remember me",
                                        "es": "Acuérdate de mí"})}
                           value={this.state.rememberMe}
                           onChange={this.handleChangeBoolean('rememberMe')}/>
                         <Button
                           type="submit"
                           fullWidth
                           variant="contained"
                           color="primary"
                           className={classes.submit}
                           onClick={this.submitLogin}>
                           {text(language,
                                 {"ru": "Войти",
                                  "en": "Log in",
                                  "es": "Acceso"})}
                         </Button>
                         <Grid container>
                           <Grid item xs>
                             <Link component="button" variant="body2"
                                   onClick={this.handleForgetPasswordDialog}>
                               {text(language,
                                     {"ru": "Забыли пароль?",
                                      "en": "Forgot your password?",
                                      "es": "¿Olvidaste tu contraseña?"})}
                             </Link>
                           </Grid>
                           <Grid item>
                             <Link variant="body2"
                                   component={NavLink} to="/register">
                               {text(language,
                                     {"ru": "Регистрация",
                                      "en": "Registration",
                                      "es": "Registro"})}
                             </Link>
                           </Grid>
                         </Grid>
                       </form>
                     </div>
                   </Container>
                 </CardContent>
                 <PromptDialog
                    parent={this}                    
                    open={this.state.showRegisterInfoDialog}
                    onClose={this.handleResetPasswordByEmail}
                    title={text(language,
                                     {"ru": "Почта для нового пароля",
                                      "en": "Email for new password",
                                      "es": "Correo electrónico para nueva contraseña"})}
                    message={text(language,
                                  {"ru": "Укажите Email адрес, на который " +
                                   "необходимо отправить новый пароль.",
                                   "en": "Enter the email address to which " +
                                   "you want to send a new password.",
                                   "es": "Introduzca la dirección de correo electrónico " +
                                   "a la que desea enviar una nueva contraseña."})}
                    stateName="email"
                    label='Email'
                    type='email'
                    stateName2="login"
                    label2={text(language,
                                 {"ru": "Логин",
                                  "en": "Login",
                                  "es": "Acceso"})}
                    type2='text'/>
               </Card>
               : <div className={classes.loader}>
                   <CircularProgress className={classes.progress} size={90} />
                 </div>}
            </Center>
        );
    }
}

function mapStateToProps(state) {
    const loginOk = state.loginOk;
    const accessLevel = state.accessLevel;
    const language = state.language;
    return {
        loginOk,
        accessLevel,
        language
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
      setLoginOk: (value) => dispatch(setLoginOk(value)),
      setAccessLevel: (value) => dispatch(setAccessLevel(value)),
  };
};

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(Login));
