import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import Center from 'react-center';
import { grey } from '@material-ui/core/colors';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { caseCountries } from "./countrySelection";
import { text } from "./languageSelection";
import { connect } from "react-redux";
import { isMobile } from './common/deviceDetector';

const styles = {
    vcenter: {
        position: 'absolute', top: '50%',
        transform: 'translate(0%, -50%)'
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 200,
        width: 200,
    },
    es: {
        height: 112,
    },
    mobileHelpCard: {
      height: 80,
      width: "100%",
      textTransform: "none",
      justifyContent: "left",
      marginBottom: "8px"
    },
    iconButtonLabel: {
        display: "flex",
        flexDirection: 'column',
    }
};

class HelpGrid extends React.Component {

    render() {
        const { classes } = this.props;
        let residenceCountry = this.props.residence.value;
        let language = this.props.language.value;

        return (
          isMobile
          // ------------------------------------------------------------
          //                          Mobile
          // ------------------------------------------------------------
            ? <Card>
                <CardContent>
                  <Center>
                    <IconButton
                      color="primary"
                      classes={{label: classes.iconButtonLabel}}
                      style={{ paddingTop: 0,
                               paddingBottom: 4 }}
                      component={NavLink}
                      to="/welcome" >
                      <HomeIcon
                        style={{ fontSize: 30,
                                 paddingTop: 0,
                                 paddingBottom: 0}}/>
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "На главную страницу",
                               "en": "To Home Page",
                               "es": "A la página de inicio"})}
                      </Typography>
                    </IconButton>
                  </Center>
                  {caseCountries(
                    residenceCountry,
                    ["none", "russia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/FAQ"
                            startIcon={<HelpOutlineIcon
                              style={{ fontSize: 70, color: grey[600] }}/>} >
                      <Typography variant="body2" align='left'>
                        Часто задаваемые вопросы
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "russia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/GenericRF"
                            startIcon={<img src='/images/fnsRF_gray.png'
                              width='60'
                              alt=''/>} >
                      <Typography variant="body2" align='left'>
                        Налогообложение инвестиций в РФ
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "spain"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/HelpSpain"
                            startIcon={<img src='/images/es/Agencia_Tributaria.svg'
                                            height='64'
                                            alt=''/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Налогообложение инвестиций в Испании",
                               "en": "Taxation of investments in Spain",
                               "es": "Fiscalidad de las inversiones en España"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "estonia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/HelpEstonia"
                            startIcon={<img src='/images/ee/Maksu-_ja_Tolliameti_logo_gray.svg'
                                            width='60'
                                            alt=''/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Налогообложение инвестиций в Эстонии",
                               "en": "Taxation of investments in Estonia",
                               "es": "Fiscalidad de las inversiones en Estonia"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "russia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/TaxInstructions"
                            startIcon={<FormatListNumberedIcon
                                         style={{ fontSize: 70, color: grey[700] }}/>} >
                      <Typography variant="body2" align='left'>
                        Инструкция по использованию сервиса FinParser
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "earth", "spain", "russia", "estonia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/IBDeclarationReport"
                            startIcon={<img src='/images/ibLogo_gray.svg'
                                            height='64'
                                            alt=''/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Как скачать отчет Interactive Brokers",
                               "en": "How to download the Interactive Brokers report",
                               "es": "Cómo descargar el informe de Interactive Brokers"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["all"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/CharlesSchwabReport"
                            startIcon={<img src="/images/schwab_gray.svg"
                                            height='60'
                                            alt=''
                                            style={{marginBottom: '3px', marginTop: '5px'}}/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Как скачать отчет Charles Schwab",
                               "en": "How to download the Charles Schwab report",
                               "es": "Cómo descargar el informe Charles Schwab"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "earth", "spain", "russia", "estonia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/FFReport"
                            startIcon={<img src='/images/ffLogo_gray.png'
                                            height='60'
                                            alt=''
                                            style={{marginBottom: '3px', marginTop: '5px'}}/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Как скачать отчет Freedom Finance Белиз",
                               "en": "How to download the Freedom Finance Belize report",
                               "es": "Cómo descargar el informe Freedom Finance Belice"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "earth", "spain", "russia", "estonia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/FFCyReport"
                            startIcon={<img src='/images/ffGlobalLogo_gray.svg'
                                            height='60'
                                            alt=''
                                            style={{marginBottom: '3px', marginTop: '5px'}}/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Как скачать отчет Freedom Finance Кипр",
                               "en": "How to download the Freedom Finance Cyprus report",
                               "es": "Cómo descargar el informe Freedom Finance Chipre"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "earth", "spain", "russia", "estonia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/RevolutReport"
                            startIcon={<img src='/images/RevolutSymbol.png'
                                            height='60'
                                            alt=''
                                            style={{marginBottom: '3px', marginTop: '5px'}}/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Как скачать отчет Revolut",
                               "en": "How to download the Revolut report",
                               "es": "Cómo descargar el informe Revolut"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "earth", "spain", "russia", "estonia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/TradeStationReport"
                            startIcon={<img src='/images/TradeStationLogo.png'
                                            height='60'
                                            alt=''
                                            style={{marginBottom: '3px', marginTop: '5px'}}/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Как скачать отчет TradeStation",
                               "en": "How to download the TradeStation report",
                               "es": "Cómo descargar el informe TradeStation"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["all"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/CitiReport"
                            startIcon={<img src="/images/citi_gray.svg"
                                            height='50'
                                            alt=''
                                            style={{marginBottom: '3px', marginTop: '5px'}}/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Как скачать отчет Citibank",
                               "en": "How to download the Citibank report",
                               "es": "Cómo descargar el informe Citibank"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "earth", "spain", "russia", "estonia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/FinParserFormat"
                            startIcon={<img src='/images/finParserLogo_gray.png'
                                            height='60'
                                            alt=''
                                            style={{marginBottom: '3px', marginTop: '5px'}}/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Как привести отчеты других " +
                               "брокеров к универсальному формату",
                               "en": "How to bring reports of other " +
                               "brokers to a universal format",
                               "es": "Cómo traer informes de otros " +
                               "brókers a un formato universal"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "earth", "spain", "russia", "estonia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/HelpCyberSecurity"
                            startIcon={<img src='/images/cyber_security_gray.png'
                                            height='60'
                                            alt=''/>} >
                      <Typography variant="body2" align='left'>
                        {text(language,
                              {"ru": "Безопасность данных и шифрование",
                               "en": "Data security and encryption",
                               "es": "Seguridad y cifrado de datos"})}
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "russia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/IBForex"
                            startIcon={<img src='/images/forex_gray.png'
                                            height='64'
                                            alt=''/>} >
                      <Typography variant="body2" align='left'>
                        Forex сделки в Interactive Brokers
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "russia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/TaxOptimization"
                            startIcon={<img src='/images/idea_gray.png'
                                            height='64'
                                            alt=''/>} >
                      <Typography variant="body2" align='left'>
                        Налоговая оптимизация
                      </Typography>
                    </Button>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "russia"],
                    <Button className={classes.mobileHelpCard}
                            variant="outlined"
                            component={NavLink}
                            to="/help/CashFlowReport"
                            startIcon={<SwapHorizOutlinedIcon
                                         style={{ fontSize: 70, color: grey[700] }}/>} >
                      <Typography variant="body2" align='left'>
                        Как подать отчет о движении денежных средств
                      </Typography>
                    </Button>
                  )}
                </CardContent>
              </Card>
          // ------------------------------------------------------------
          //                          Desktop
          // ------------------------------------------------------------
          : <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="left" spacing={3}>
                  {caseCountries(
                      residenceCountry,
                      ["none", "russia"],
                      <Grid key={0} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/FAQ">
                            <CardContent>
                              <Center>
                                <HelpOutlineIcon
                                  style={{ fontSize: 100, color: grey[600] }}/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                Часто задаваемые вопросы
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "russia"],
                      <Grid key={1} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/GenericRF">
                            <CardContent>
                              <Center>
                                <img src='/images/fnsRF_gray.png'
                                     width='100'
                                     alt=''/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                Налогообложение инвестиций в РФ
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "spain"],
                      <Grid key={2} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/HelpSpain">
                            <CardContent>
                              <Center className={classes.es}>
                                <img src='/images/es/Agencia_Tributaria.svg'
                                     height='104'
                                     alt=''/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                {text(language,
                                      {"ru": "Налогообложение инвестиций в Испании",
                                       "en": "Taxation of investments in Spain",
                                       "es": "Fiscalidad de las inversiones en España"})}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "estonia"],
                      <Grid key={3} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/HelpEstonia">
                            <CardContent>
                              <Center>
                                <img src='/images/ee/Maksu-_ja_Tolliameti_logo_gray.svg'
                                     width='100'
                                     alt=''/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                {text(language,
                                      {"ru": "Налогообложение инвестиций в Эстонии",
                                       "en": "Taxation of investments in Estonia",
                                       "es": "Fiscalidad de las inversiones en Estonia"})}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "russia"],
                      <Grid key={4} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/TaxInstructions">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <FormatListNumberedIcon
                                  style={{ fontSize: 100, color: grey[700] }}/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                Инструкция по использованию сервиса FinParser
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "earth", "spain", "russia", "estonia"],
                      <Grid key={5} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/IBDeclarationReport">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <img src='/images/ibLogo_gray.svg'
                                     height='90'
                                     alt=''/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                {text(language,
                                      {"ru": "Как скачать отчет Interactive Brokers",
                                       "en": "How to download the Interactive Brokers report",
                                       "es": "Cómo descargar el informe de Interactive Brokers"})}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["all"],
                    <Grid key={5} item>
                      <Card className={classes.paper}>
                        <CardActionArea className={classes.paper}
                                        component={NavLink}
                                        to="/help/CharlesSchwabReport">
                          <CardContent className={classes.vcenter}>
                            <Center>
                              <img src="/images/schwab_gray.svg"
                                   height='90'
                                   alt=''/>
                            </Center>
                            <Typography variant="body2" align='center'>
                              {text(language,
                                    {"ru": "Как скачать отчет Charles Schwab",
                                     "en": "How to download the Charles Schwab report",
                                     "es": "Cómo descargar el informe Charles Schwab"})}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "earth", "spain", "russia", "estonia"],
                      <Grid key={6} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/FFReport">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <img src='/images/ffLogo_gray.png'
                                     height='80'
                                     alt=''
                                     style={{marginBottom: '6px', marginTop: '10px'}}/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                {text(language,
                                      {"ru": "Как скачать отчет Freedom Finance Белиз",
                                       "en": "How to download the Freedom Finance Belize report",
                                       "es": "Cómo descargar el informe Freedom Finance Belice"})}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                    residenceCountry,
                    ["none", "earth", "spain", "russia", "estonia"],
                    <Grid key={6} item>
                      <Card className={classes.paper}>
                        <CardActionArea className={classes.paper}
                                        component={NavLink}
                                        to="/help/FFCyReport">
                          <CardContent className={classes.vcenter}>
                            <Center>
                              <img src='/images/ffGlobalLogo_gray.svg'
                                   height='80'
                                   alt=''
                                   style={{marginBottom: '6px', marginTop: '10px'}}/>
                            </Center>
                            <Typography variant="body2" align='center'>
                              {text(language,
                                    {"ru": "Как скачать отчет Freedom Finance Кипр",
                                     "en": "How to download the Freedom Finance Cyprus report",
                                     "es": "Cómo descargar el informe Freedom Finance Chipre"})}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "earth", "spain", "russia", "estonia"],
                      <Grid key={"revolut"} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/RevolutReport">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <img src='/images/RevolutSymbol.png'
                                     height='80'
                                     alt=''
                                     style={{marginBottom: '6px', marginTop: '10px'}}/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                {text(language,
                                      {"ru": "Как скачать отчет Revolut",
                                       "en": "How to download the Revolut report",
                                       "es": "Cómo descargar el informe Revolut"})}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "earth", "spain", "russia", "estonia"],
                      <Grid key={"TradeStation"} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/TradeStationReport">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <img src='/images/TradeStationLogo.png'
                                     height='80'
                                     alt=''
                                     style={{marginBottom: '6px', marginTop: '10px'}}/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                {text(language,
                                      {"ru": "Как скачать отчет TradeStation",
                                       "en": "How to download the TradeStation report",
                                       "es": "Cómo descargar el informe TradeStation"})}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["all"],
                      <Grid key={"Citi"} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/CitiReport">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <img src="/images/citi_gray.svg"
                                     height='74'
                                     alt=''
                                     style={{marginBottom: '6px', marginTop: '10px'}}/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                {text(language,
                                      {"ru": "Как скачать отчет Citibank",
                                       "en": "How to download the Citibank report",
                                       "es": "Cómo descargar el informe Citibank"})}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "earth", "spain", "russia", "estonia"],
                      <Grid key={7} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/FinParserFormat">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <img src='/images/finParserLogo_gray.png'
                                     height='70'
                                     alt=''
                                     style={{marginBottom: '6px', marginTop: '10px'}}/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                {text(language,
                                      {"ru": "Как привести отчеты других " +
                                       "брокеров к универсальному формату",
                                       "en": "How to bring reports of other " +
                                       "brokers to a universal format",
                                       "es": "Cómo traer informes de otros " +
                                       "brókers a un formato universal"})}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "earth", "spain", "russia", "estonia"],
                      <Grid key={8} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/HelpCyberSecurity">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <img src='/images/cyber_security_gray.png'
                                     height='100'
                                     alt=''/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                {text(language,
                                      {"ru": "Безопасность данных и шифрование",
                                       "en": "Data security and encryption",
                                       "es": "Seguridad y cifrado de datos"})}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "russia"],
                      <Grid key={9} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/IBForex">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <img src='/images/forex_gray.png'
                                     height='100'
                                     alt=''/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                Forex сделки в Interactive Brokers
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "russia"],
                      <Grid key={10} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/TaxOptimization">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <img src='/images/idea_gray.png'
                                     height='100'
                                     alt=''/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                Налоговая оптимизация
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      ["none", "russia"],
                      <Grid key={11} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/CashFlowReport">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <SwapHorizOutlinedIcon
                                  style={{ fontSize: 100, color: grey[700] }}/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                Как подать отчет о движении денежных средств
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                  {caseCountries(
                      residenceCountry,
                      [],
                      <Grid key={12} item>
                        <Card className={classes.paper}>
                          <CardActionArea className={classes.paper}
                                          component={NavLink}
                                          to="/help/HelpAlgorithm">
                            <CardContent className={classes.vcenter}>
                              <Center>
                                <img src='/images/algorithm.png'
                                     height='100'
                                     alt=''
                                     style={{marginBottom: '6px'}}/>
                              </Center>
                              <Typography variant="body2" align='center'>
                                Алгоритм работы сервиса
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const residence = state.residence;
    const language = state.language;
    return {
        residence,
        language,
    };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpGrid));
