import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Center from 'react-center';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Button from '@material-ui/core/Button';

const styles = {
    table: {
        minWidth: 650,
    },
    button: {
        margin: 7,
    },
    dateFiled: {
        width: 200,
        margin: 7,
    },
    loader: {
        padding: '200px 0',
    },
};

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

class AdminConsoleActivity extends React.Component {
    constructor(props) {
        super(props);
        this.handleTableReload = this.handleTableReload.bind(this);

        axios.get('/api/activity/list/today')
            .then((response) => {
                this.setState({
                    rows: response.data
                }, () => {
                    this.forceUpdate();
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    
    state = {
        rows: null,
        dateFrom: {selectedDate: new Date()},
        dateTo: {selectedDate: new Date()}
    };

    dateFormat(date) {
        if (date instanceof Date) {
            var [month, day, year] = date.toLocaleDateString("en-US").split("/");
            if (day.length < 2) {
                day = '0' + day;
            }
            return day + '.' + month + '.' + year;
        } else {
            return date;
        }
    }

    formatActivityType(activityType) {
        return activityType.name
    }

    formatFormats(outputFormat) {
        return outputFormat.map(d => d.code).toString()
    }

    formatMessages(message) {
        var result = "";
        if (message !== "") {
            let parsed = JSON.parse(message);
            if (Array.isArray(parsed)) {
                parsed.map(m => result = result + m.message + "\n")
            }
        }
        return result
            .replaceAll("<br/>", "")
            .replaceAll("<tt>", "")
            .replaceAll("</tt>", "");
    }

    handleDateChange = name => (date) => {
        this.setState({[name]: { selectedDate: date }});
    };

    handleTableReload(event) {
        this.setState({
            rows: null
        }, () => {
            this.forceUpdate();
        });
        axios.get('/api/activity/list/' + 
            this.dateFormat(this.state.dateFrom.selectedDate) 
            + '-' + 
            this.dateFormat(this.state.dateTo.selectedDate))
        .then((response) => {
            this.setState({
                rows: response.data
            }, () => {
                this.forceUpdate();
            });
        })
        .catch((error) => {
            console.log(error);
            this.setState({
                rows: []
            }, () => {
                this.forceUpdate();
            });
        });
    }

    render() {
        const { classes } = this.props;
        
        return (
           <Center>
                <table>
                    <tr><td>
                        <KeyboardDatePicker
                                id="dateFrom"
                                keyboard
                                label="Дата с"
                                format="dd.MM.yyyy"
                                placeholder="10.10.2018"
                                value={this.state.dateFrom.selectedDate}
                                onChange={this.handleDateChange('dateFrom')}
                                animateYearScrolling={false}
                                className={classes.dateFiled}/>
                        <KeyboardDatePicker
                                id="dateTo"
                                keyboard
                                label="Дата по"
                                format="dd.MM.yyyy"
                                placeholder="10.10.2018"
                                value={this.state.dateTo.selectedDate}
                                onChange={this.handleDateChange('dateTo')}
                                animateYearScrolling={false}
                                className={classes.dateFiled}/>
                        <Button className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleTableReload}>
                                Обновить
                        </Button>
                    </td></tr>
                    <tr><td>
                        {(this.state.rows == null)
                        ? <div className={classes.loader}>
                            <CircularProgress size={90} />
                          </div>
                        : <Table className={classes.table} 
                                 size="small" 
                                 aria-label="Активность">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Дата</TableCell>
                                    <TableCell>Логин</TableCell>
                                    <TableCell>Тип действия</TableCell>
                                    <TableCell>Год отчета</TableCell>
                                    <TableCell>Форматы файлов</TableCell>
                                    <TableCell>Сообщение</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {(this.state.rows.length > 0) 
                            ? this.state.rows.map((row) => (
                                <StyledTableRow key={row.id}>
                                <TableCell>{row.date}</TableCell>                        
                                <TableCell>{row.user != null ? row.user.login : ""}</TableCell>
                                <TableCell>{this.formatActivityType(row.activityType)}</TableCell>
                                <TableCell>{row.year === 0 ? "" : row.year}</TableCell>
                                <TableCell>{this.formatFormats(row.outputFormat)}</TableCell>
                                <TableCell>{this.formatMessages(row.message)}</TableCell>
                                </StyledTableRow>
                            ))
                            : ""}
                            </TableBody>
                          </Table>}
                        </td></tr>
                  </table>
            </Center>
        );
    }
}

export default withStyles(styles)(AdminConsoleActivity);
