import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const YellowCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#D4B106',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default YellowCheckbox;

