import React from 'react';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Center from 'react-center';
import Icon from "@material-ui/core/Icon";
import HelpIcon from '@material-ui/icons/Help';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import LoginDialog from './loginDialog';
import CountryGrid from './countryGrid';
import WelcomeRu from './ru/welcome_ru';
import WelcomeEs from './es/welcome_es';
import CountrySelection from './countrySelection';
import LanguageSelection from './languageSelection';
import SupportEmail from './common/supportEmail';
import { isMobile } from './common/deviceDetector';
import { text } from "./languageSelection";
import { connect } from "react-redux";

function Copyright(args) {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Center>
        <Box style={{width: "fit-content"}}>
          &emsp;
          <Link component={NavLink}
                to="/privacyPolicy"
                color="inherit">
            {text(args.language,
                  {"ru": "Политика конфиденциальности",
                   "en": "Privacy Policy",
                   "es": "Política de Privacidad"})}
          </Link>
          &emsp;&emsp;
          <Link component={NavLink}
                to="/cookiePolicy"
                color="inherit">
            {text(args.language,
                  {"ru": "Политика использования файлов Cookie",
                   "en": "Cookie Policy",
                   "es": "Política de Cookies"})}
          </Link>
          &emsp;
          <Divider variant="fullWidth"/>
        </Box>
      </Center>
      <br/>
      {'Copyright © '}
      <Link color="inherit" href="http://finparser.com">
        finparser.com
      </Link>{' '}
      {(new Date()).getFullYear()}
      {'.'}
    </Typography>
  );
}

const FinparserIcon = (
    <Icon>
      <img alt='logo' src='/images/finParserLogo.png'
           height='22px' />
    </Icon>
);

const FinparserIconWhite = (
    <Icon>
      <img alt='logo' src='/images/finParserLogo_white.png'
           height='22px' />
    </Icon>
);

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    right: {
        position: 'absolute',
        top: '260px',
        right: '40px',
    },
    headerMargin: {
        marginTop: '60px'
    },
    buttons: {
        height: '54px',
        width: '240px',
        marginBottom: '15px',
        marginRight: '50px'
    },
    buttonMobile: {
        height: '54px',
        width: '240px',
        marginBottom: '10px',
        marginRight: '10px'
    },
    primaryTail: {
        backgroundColor: theme.palette.primary.main,
    },
    cloud: {
        paddingBottom: "100px"
    },
    justify: {
        textAlign: 'justify'
    },
    welcomeCardContentMobile: {
        "&:last-child": {
            paddingBottom: "80px"
        }
    }
});

class Welcome extends React.Component {
    state = {
        showLoginDialog: false
    }

    render() {
        const { classes } = this.props;
        let residenceCountry = this.props.residence.value;
        let language = this.props.language.value;

        return (
          isMobile
          // ------------------------------------------------------------
          //                          Mobile
          // ------------------------------------------------------------
          ? <div>
              <Card>
                <CardContent className={classes.welcomeCardContentMobile}>
                  <Typography variant="subtitle1" align="justify" gutterBottom>
                    <Box style ={{fontSize: "1.5rem"}} display='inline'>
                      FinParser
                    </Box>
                    {text(language,
                          {"ru": (" — мгновенная генерация налоговых деклараций" +
                                  (residenceCountry === "russia" ? " 3-НДФЛ" : "")),
                           "en": " — instant tax returns generation",
                           "es": " — generación instantánea de declaraciones de impuestos"})}
                    {text(language,
                          {"ru": " на основе отчетов",
                           "en": " for",
                           "es": " para"})}
                    &ensp;
                    <Link href="https://www.interactivebrokers.com"
                          className={classes.link}
                          target="_blank">
                      Interactive Brokers
                    </Link>,&ensp;
                    <Link href="https://ffin.bz"
                          className={classes.link}
                          target="_blank">
                      Freedom Finance
                    </Link>,&ensp;
                    <Link href="https://www.revolut.com"
                          className={classes.link}
                          target="_blank">
                      Revolut
                    </Link>,&ensp;
                    <Link href="https://www.tradestation.com"
                          className={classes.link}
                          target="_blank">
                      TradeStation
                    </Link>&ensp;
                    {text(language,
                          {"ru": "и",
                           "en": "and",
                           "es": "y"})}
                    &ensp;
                    <Link component={NavLink}
                          to="/help/FinParserFormat"
                          className={classes.link}>
                      {text(language,
                            {"ru": "других брокеров",
                             "en": "other brokers",
                             "es": "otros brókers"})}
                    </Link>.
                  </Typography>
                  <CountrySelection/>
                  <LanguageSelection/>
                  <p/>
                  <Box>
                    {!this.props.loginOk.value
                     ? <>
                         <Grid container justify="flex-end">
                           <Button component={NavLink} to="/register"
                                   className={classes.buttonMobile}
                                   variant="contained"
                                   color="primary"
                                   startIcon={<AccountCircleOutlinedIcon />}>
                             {text(language,
                                   {"ru": "Регистрация",
                                    "en": "Registration",
                                    "es": "Registro"})}
                           </Button>
                         </Grid>
                         <Grid container justify="flex-end">
                           <Button component={NavLink} to="/login"
                                   className={classes.buttonMobile}
                                   variant="contained"
                                   color="primary"
                                   startIcon={<LockOutlinedIcon />}>
                             {text(language,
                                   {"ru": "Войти",
                                    "en": "Log In",
                                    "es": "Acceso"})}
                           </Button>
                         </Grid>
                       </>
                     : ""
                    }
                    <Grid container justify="flex-end">
                      <Button component={NavLink} to="/help"
                              className={classes.buttonMobile}
                              variant="contained"
                              color="primary"
                              startIcon={<HelpIcon />}>
                        {text(language,
                              {"ru": "Справка",
                               "en": "Help",
                               "es": "Ayuda"})}
                      </Button>
                    </Grid>
                    {(residenceCountry != "none")
                     ? <Grid container justify="flex-end">
                        <Button component={NavLink} to="/tax"
                                className={classes.buttonMobile}
                                variant="contained"
                                color="primary"
                                startIcon={FinparserIconWhite}>
                          {text(language,
                                {"ru": <div>Сгенерировать<br/>декларацию</div>,
                                 "en": <div>Generate<br/>tax return</div>,
                                 "es": <div>Generar formulario<br/>de impuestos</div>})}
                        </Button>
                      </Grid>
                     : ""
                    }
                  </Box>

                  {/* -------------------------------------------------- */}

                  {(residenceCountry == "none")
                   ? <CountryGrid/>
                   : (residenceCountry == "russia")
                   ? <WelcomeRu/>
                   : (residenceCountry == "spain")
                   ? <WelcomeEs/>
                   : ""}

                  {/* -------------------------------------------------- */}
                  <p/>
                  <Typography variant="h5" align="center"
                              gutterBottom>
                    {text(language,
                          {"ru": "Условия возврата",
                           "en": "Refund Terms",
                           "es": "Condiciones de reembolso"})}
                  </Typography>
                  <Box className={classes.justify}>
                    {text(language,
                          {"ru": <div>Если вас не устраивает полученный
                                   результат - напишите на Email поддержки,
                                   описав проблему и приложив отчет брокера,
                                   на основе которого была сгенерирована
                                   декларация. По соглашению с вами, вам будет
                                   либо предоставлена исправленная декларация,
                                   либо возвращены деньги за пользование сервисом.
                                 </div>,
                           "en": <div>If you are not satisfied with the result,
                                   please write to the Support Email,
                                   describe the problem and attach the
                                   broker's report, which is used for the tax
                                   form generation. By agreement with
                                   you, you will either be provided with an
                                   amended declaration or a refund for using
                                   the service.
                                 </div>,
                           "es": <div>Si no está satisfecho con el resultado,
                                   escriba al correo electrónico de soporte,
                                   describa el problema y adjunte el informe
                                   del bróker, en base al cual se generó la
                                   declaración. Por acuerdo con usted, se le
                                   proporcionará una declaración modificada o
                                   un reembolso por el uso del servicio.
                                 </div>})}
                  </Box>
                  {/* -------------------------------------------------- */}
                  <p/>
                  <Typography variant="h5" align="center"
                              gutterBottom>
                    {text(language,
                          {"ru": "Контактные данные",
                           "en": "Contact",
                           "es": "Contacto"})}
                  </Typography>
                  <Box className={classes.justify}>
                    {text(language,
                          {"ru": ("При обнаружении ошибок, неточностей и в "+
                                  "случае возникновения других вопросов пишите на "),
                           "en": ("If you find errors, inaccuracies, or " +
                                  "if you have any other questions, write to "),
                           "es": ("Si encuentra errores, inexactitudes o " +
                                  "si tiene alguna otra pregunta, escriba a ")})}
                    <SupportEmail/>
                    {text(language,
                          {"ru": ", либо в чат пользовательской поддержки.",
                           "en": ", or in the user support chat.",
                           "es": ", o en el chat de soporte al usuario."})}
                  </Box>
                </CardContent>
              </Card>
            </div>
          // ------------------------------------------------------------
          //                          Desktop
          // ------------------------------------------------------------
          : <div className={classes.root}>
              <Card>
                <CardContent>
                  <Typography variant="h4" align="center" gutterBottom>
                    {text(language,
                          {"ru": ("FinParser — мгновенная генерация налоговых деклараций" +
                                  (residenceCountry === "russia" ? " 3-НДФЛ" : "")),
                           "en": "FinParser — instant tax returns generation",
                           "es": "FinParser — generación instantánea de declaraciones de impuestos"})}
                  </Typography>
                  <Typography variant="h6" align="center" gutterBottom>
                    {text(language,
                          {"ru": "на основе отчетов",
                           "en": "for",
                           "es": "para"})}
                    &ensp;
                    <Link href="https://www.interactivebrokers.com"
                          className={classes.link}
                          target="_blank">
                      Interactive Brokers
                    </Link>,&ensp;
                    <Link href="https://ffin.bz"
                          className={classes.link}
                          target="_blank">
                      Freedom Finance
                    </Link>,&ensp;
                    <Link href="https://www.revolut.com"
                          className={classes.link}
                          target="_blank">
                      Revolut
                    </Link>,&ensp;
                    <Link href="https://www.tradestation.com"
                          className={classes.link}
                          target="_blank">
                      TradeStation
                    </Link>&ensp;
                    {text(language,
                          {"ru": "и",
                           "en": "and",
                           "es": "y"})}
                    &ensp;
                    <Link component={NavLink}
                          to="/help/FinParserFormat"
                          className={classes.link}>
                      {text(language,
                            {"ru": "других брокеров",
                             "en": "other brokers",
                             "es": "otros brókers"})}
                    </Link>
                  </Typography>
                </CardContent>
                <CardContent>

                <Center>
                  <img src='/images/cloud.svg'/>
                </Center>

                  <div className={classes.right}>
                    {!this.props.loginOk.value
                     ? <Box>
                         <Grid container justify="flex-end">
                           <Button component={NavLink} to="/register"
                                   className={classes.buttons}
                                   variant="contained"
                                   color="primary"
                                   startIcon={<AccountCircleOutlinedIcon />}>
                             {text(language,
                                   {"ru": "Регистрация",
                                    "en": "Registration",
                                    "es": "Registro"})}
                           </Button>
                         </Grid>
                         <Grid container justify="flex-end">
                           <Button component={NavLink} to="/login"
                                   className={classes.buttons}
                                   variant="contained"
                                   color="primary"
                                   startIcon={<LockOutlinedIcon />}>
                             {text(language,
                                   {"ru": "Войти",
                                    "en": "Log In",
                                    "es": "Acceso"})}
                           </Button>
                         </Grid>
                       </Box>
                     : ""}
                    <Grid container justify="flex-end">
                      <Button component={NavLink} to="/help"
                              className={classes.buttons}
                              variant="contained"
                              color="primary"
                              startIcon={<HelpIcon />}>
                        {text(language,
                              {"ru": "Справка",
                               "en": "Help",
                               "es": "Ayuda"})}
                      </Button>
                    </Grid>
                    <Grid container justify="flex-end">
                      <Button component={NavLink} to="/tax"
                              className={classes.buttons}
                              variant="contained"
                              color="primary"
                              startIcon={FinparserIconWhite}>
                        {text(language,
                              {"ru": <div>Сгенерировать<br/>декларацию</div>,
                               "en": <div>Generate<br/>tax return</div>,
                               "es": <div>Generar formulario<br/>de impuestos</div>})}
                      </Button>
                    </Grid>
                  </div>

                  {/* -------------------------------------------------- */}

                  {(residenceCountry == "none")
                   ? <CountryGrid/>
                   : (residenceCountry == "russia")
                   ? <WelcomeRu/>
                   : (residenceCountry == "spain")
                   ? <WelcomeEs/>
                   : ""}

                  {/* -------------------------------------------------- */}
                  <Typography variant="h4" align="center"
                              className={classes.headerMargin}
                              gutterBottom>
                    {text(language,
                          {"ru": "Условия возврата",
                           "en": "Refund Terms",
                           "es": "Condiciones de reembolso"})}
                  </Typography>
                  <Box m={4} className={classes.justify}>
                    {text(language,
                          {"ru": <div>Если вас не устраивает полученный
                                   результат - напишите на Email поддержки,
                                   описав проблему и приложив отчет брокера,
                                   на основе которого была сгенерирована
                                   декларация. По соглашению с вами, вам будет
                                   либо предоставлена исправленная декларация,
                                   либо возвращены деньги за пользование сервисом.
                                 </div>,
                           "en": <div>If you are not satisfied with the result,
                                   please write to the Support Email,
                                   describe the problem and attach the
                                   broker's report, which is used for the tax
                                   form generation. By agreement with
                                   you, you will either be provided with an
                                   amended declaration or a refund for using
                                   the service.
                                 </div>,
                           "es": <div>Si no está satisfecho con el resultado,
                                   escriba al correo electrónico de soporte,
                                   describa el problema y adjunte el informe
                                   del bróker, en base al cual se generó la
                                   declaración. Por acuerdo con usted, se le
                                   proporcionará una declaración modificada o
                                   un reembolso por el uso del servicio.
                                 </div>})}
                  </Box>

                  {/* -------------------------------------------------- */}
                  <Typography variant="h4" align="center"
                              className={classes.headerMargin}
                              gutterBottom>
                    {text(language,
                          {"ru": "Контактные данные",
                           "en": "Contact",
                           "es": "Contacto"})}
                  </Typography>
                  <Box m={2}>
                    <List dense={false}>
                      <ListItem>
                        {text(language,
                              {"ru": ("При обнаружении ошибок, неточностей и в "+
                                      "случае возникновения других вопросов пишите на"),
                               "en": ("If you find errors, inaccuracies, or " +
                                      "if you have any other questions, write to"),
                               "es": ("Si encuentra errores, inexactitudes o " +
                                      "si tiene alguna otra pregunta, escriba a")})}
                        &ensp;
                        <SupportEmail/>
                        {text(language,
                              {"ru": ", либо в чат пользовательской поддержки.",
                               "en": ", or in the user support chat.",
                               "es": ", o en el chat de soporte al usuario."})}
                      </ListItem>
                      {/* <ListItem>
                        Телефон: +7 903 050-00-35
                      </ListItem> */}
                    </List>
                  </Box>

                  <Box mt={8}>
                    <Copyright language={language} />
                  </Box>

                </CardContent>
              </Card>
              <LoginDialog
                open={this.state.showLoginDialog}
                propertyName='showLoginDialog'
                text={text(language,
                           {"ru": "оплата недоступна",
                            "en": "payment is not available",
                            "es": "el pago no está disponible"})}
                parent={this}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const residence = state.residence;
    const language = state.language;
    const loginOk = state.loginOk;
    return {
        residence,
        language,
        loginOk
    };
}

export default withStyles(styles)(
    connect(mapStateToProps)(Welcome));
