import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavLink } from 'react-router-dom';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';
import Typography from '@material-ui/core/Typography';
import ImageZoom from 'react-medium-image-zoom';
import {Red, Teal} from '../colors';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    },
  imgStyle: {
    width: '700px'
  },
  imgStyleMobile: {
    width: '100%'
  }
});

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderWidth: '1px',
    marginBottom: -1,
    minHeight: 42,
    '&$expanded': {
      minHeight: 42,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
    justifyContent: "center"
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "flow",
    justifyContent: "center",
    paddingTop: "0px",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

class HelpSpain extends React.Component {

  render() {
    const { classes } = this.props;
    let language = this.props.language.value;

    const exampleRows = [
      {name: 'V0913-08',
       url: 'https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V0913-08'},
      {name: 'V0569-21',
       url: 'https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V0569-21'},
      {name: 'V1119-21',
       url: 'https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V1119-21'},
      {name: 'V0571-21',
       url: 'https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V0571-21'},
      {name: 'V2481-20',
       url: 'https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V2481-20'},
      {name: 'V3282-18',
       url: 'https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V3282-18'}
    ];

    const exampleForRenta = [
      {name: 'Example-1',
       url: 'https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V3282-18'}
    ];

    return (
      <Card className={classes.card}>
        <CardContent>
          <HelpToContentsLink/>
          <div className={classes.text}>
            <br/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_0">
              <h3 id="HelpSpain_0">
                {text(language,
                      {"ru": "Оглавление",
                       "en": "Table of contents",
                       "es": "Tabla de contenido"})}
              </h3>
            </HelpHashLink>
            <ul>
              <li>
                <HelpHashLink to="/help/HelpSpain#HelpSpain_1">
                  {text(language,
                        {"ru": "Форма 100. Декларация НДФЛ",
                         "en": "Form 100. Personal Income Tax Declaration",
                         "es": "Modelo 100. Declaración del Impuesto sobre la"
                         + " Renta de las Personas Físicas"})}
                </HelpHashLink>
                <ul>
                  <li>
                    <HelpHashLink to="/help/HelpSpain#HelpSpain_2">
                      {text(language,
                            {"ru": "Налоговое резидентство",
                             "en": "Personal income tax taxpayers",
                             "es": "Contribuyentes por el IRPF"})}
                    </HelpHashLink>
                  </li>
                  <li>
                    <HelpHashLink to="/help/HelpSpain#HelpSpain_3">
                      {text(language,
                            {"ru": "Срок подачи налоговой декларации и уплаты налогов",
                             "en": "Deadline for filing tax returns and paying taxes",
                             "es": "Fecha límite para presentar declaraciones de impuestos y pagar impuestos"})}
                    </HelpHashLink>
                  </li>
                  <li>
                    <HelpHashLink to="/help/HelpSpain#HelpSpain_4">
                      {text(language,
                            {"ru": "Налог на прибыль в Испании",
                             "en": "Income tax in Spain",
                             "es": "Impuesto sobre la renta en España"})}
                    </HelpHashLink>
                  </li>
                  <li>
                    <HelpHashLink to="/help/HelpSpain#HelpSpain_5">
                      {text(language,
                            {"ru": "Правила зачета при закрытии позиций",
                             "en": "Set-off rules for closing positions",
                             "es": "Reglas de compensación para el cierre de posiciones"})}
                    </HelpHashLink>
                    <ul>
                      <li>
                        <HelpHashLink to="/help/HelpSpain#HelpSpain_6">
                          {text(language,
                                {"ru": "Общие положения",
                                 "en": "General issues",
                                 "es": "Asuntos Generales"})}
                        </HelpHashLink>
                      </li>
                      <li>
                        <HelpHashLink to="/help/HelpSpain#HelpSpain_7">
                          {text(language,
                                {"ru": "Правило FIFO",
                                 "en": "FIFO rule",
                                 "es": "Regla FIFO"})}
                        </HelpHashLink>
                      </li>
                      <li>
                        <HelpHashLink to="/help/HelpSpain#HelpSpain_8">
                          {text(language,
                                {"ru": "Операции Forex",
                                 "en": "Forex operations",
                                 "es": "Transacciones de divisas"})}
                        </HelpHashLink>
                      </li>
                      <li>
                        <HelpHashLink to="/help/HelpSpain#HelpSpain_9">
                          {text(language,
                                {"ru": "Правило двух месяцев",
                                 "en": "Two month rule",
                                 "es": "Regla de los dos meses"})}
                        </HelpHashLink>
                      </li>
                      <li>
                        <HelpHashLink to="/help/HelpSpain#HelpSpain_10">
                          {text(language,
                                {"ru": "Компенсация прибыли по дивидендам",
                                 "en": "Dividend profit compensation",
                                 "es": "Compensación de beneficios de dividendos"})}
                        </HelpHashLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <HelpHashLink to="/help/HelpSpain#HelpSpain_11">
                      {text(language,
                            {"ru": <span>
                                     Список секций для заполнения Формы 100 по
                                     инвестиционным доходам:
                                   </span>,
                             "en": <span>
                                     List of cells to fill out Form 100 for
                                     investment income:
                                   </span>,
                             "es": <span>
                                     Lista de celdas para cumplimentar el Modelo 100
                                     de rentas de inversiones:
                                   </span>})}
                    </HelpHashLink>
                  </li>
                  <li>
                    <HelpHashLink to="/help/HelpSpain#HelpSpain_12">
                      {text(language,
                            {"ru": "Процедура подачи налоговой декларации",
                             "en": "Procedure for filing a tax return",
                             "es": "Procedimiento para presentar una declaración de impuestos"})}
                    </HelpHashLink>
                    <ul>
                      <li>
                        <HelpHashLink to="/help/HelpSpain#HelpSpain_13">
                          {text(language,
                                {"ru": "Акции",
                                 "en": "Stocks",
                                 "es": "Acciones"})}
                        </HelpHashLink>
                        <ul>
                          <li>
                            <HelpHashLink to="/help/HelpSpain#HelpSpain_14">
                              {text(language,
                                    {"ru": "Вмененные прибыли и убытки по акциям",
                                     "en": "Imputed gains and losses on shares",
                                     "es": "Ganancias y pérdidas imputadas sobre acciones"})}
                            </HelpHashLink>
                          </li>
                          <li>
                            <HelpHashLink to="/help/HelpSpain#HelpSpain_15">
                              {text(language,
                                    {"ru": "Расходы которые не могут быть вменены",
                                     "en": "Expenses that cannot be imputed",
                                     "es": "Gastos que no pueden imputarse"})}
                            </HelpHashLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <HelpHashLink to="/help/HelpSpain#HelpSpain_16">
                          {text(language,
                                {"ru": "Деривативы (Опционы, Фьючерсы, CFD, Форекс)",
                                 "en": "Derivatives (Options, Futures, CFD, Forex)",
                                 "es": "Derivados (Opciones, Futuros, CFD, Forex)"})}
                        </HelpHashLink>
                      </li>
                      <li>
                        <HelpHashLink to="/help/HelpSpain#HelpSpain_17">
                          {text(language,
                                {"ru": "Дивиденды",
                                 "en": "Dividends",
                                 "es": "Dividendos"})}
                        </HelpHashLink>
                        <ul>
                          <li>
                            <HelpHashLink to="/help/HelpSpain#HelpSpain_18">
                              {text(language,
                                    {"ru": <span>
                                             Дивиденды, налог по которым
                                             удержан в Испании (секция 0029)
                                           </span>,
                                     "en": <span>
                                             Dividends on which tax is
                                             withheld in Spain (cell 0029)
                                           </span>,
                                     "es": <span>
                                             Dividendos sobre los que se
                                             retiene impuesto en España
                                             (casilla 0029)
                                           </span>})}
                            </HelpHashLink>
                          </li>
                          <li>
                            <HelpHashLink to="/help/HelpSpain#HelpSpain_19">
                              {text(language,
                                    {"ru": <span>
                                             Дивиденды с международным двойным
                                             налогообложением (секция 0588)
                                           </span>,
                                     "en": <span>
                                             Dividends with international
                                             double taxation (cell 0588)
                                           </span>,
                                     "es": <span>
                                             Dividendos con doble imposición
                                             internacional (casilla 0588)
                                           </span>})}
                            </HelpHashLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <HelpHashLink to="/help/HelpSpain#HelpSpain_20">
                  {text(language,
                        {"ru": "Форма 720. Информационная налоговая декларация.",
                         "en": "Form 720. Informative Tax Return.",
                         "es": "Modelo 720. Declaración Informativa."})}
                </HelpHashLink>
              </li>
            </ul>
            {/* ---------------------------------------- */}
            <HelpHashLink to="/help/HelpSpain#HelpSpain_1">
              <Typography variant="h4" component="h2"
                          id="HelpSpain_1">
                {text(language,
                      {"ru": "Форма 100. Декларация НДФЛ",
                       "en": "Form 100. Personal Income Tax Declaration",
                       "es": "Modelo 100. Declaración del Impuesto sobre la Renta de las Personas Físicas"})}
              </Typography>
            </HelpHashLink>
            <br/>
            {text(language,
                  {"ru": <span>
                           Более подробная информация на&ensp;
                           <Link href="https://sede.agenciatributaria.gob.es/Sede/en_gb/Ayuda/23Presentacion/100.html"
                                 className={classes.link}
                                 target="_blank">
                             Agencia Tributaria Form 100.
                           </Link>
                         </span>,
                   "en": <span>
                           More details at&ensp;
                           <Link href="https://sede.agenciatributaria.gob.es/Sede/en_gb/Ayuda/23Presentacion/100.html"
                                 className={classes.link}
                                 target="_blank">
                             Agencia Tributaria Form 100.
                           </Link>.
                         </span>,
                   "es": <span>
                           Más detalles en&ensp;
                           <Link href="https://sede.agenciatributaria.gob.es/Sede/Ayuda/23Presentacion/100.html"
                                 className={classes.link}
                                 target="_blank">
                             Agencia Tributaria Modelo 100.
                           </Link>
                         </span>})}
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_2">
              <Typography variant="h5" component="h2"
                          id="HelpSpain_2">
                {text(language,
                      {"ru": "Налоговое резидентство",
                       "en": "Personal income tax taxpayers",
                       "es": "Contribuyentes por el IRPF"})}
              </Typography>
            </HelpHashLink>
            <br/>
            {text(language,
                  {"ru": <span>
                           Плательщиками подоходного налога являются
                           физические лица, которые находятся на
                           территории Испании более 183 дней в течение
                           календарного года.
                         </span>,
                   "en": <span>
                           Personal income tax taxpayers are individuals
                           who stay more than 183 days, during the
                           calendar year, in Spanish territory.
                         </span>,
                   "es": <span>
                           Son contribuyentes del IRPF las personas
                           físicas que permanecen más de 183 días,
                           durante el año natural, en territorio
                           español.
                         </span>})}
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_3">
              <Typography variant="h5" component="h2"
                          id="HelpSpain_3">
                {text(language,
                      {"ru": "Срок подачи налоговой декларации и уплаты налогов",
                       "en": "Deadline for filing tax returns and paying taxes",
                       "es": "Fecha límite para presentar declaraciones de impuestos y pagar impuestos"})}
              </Typography>
            </HelpHashLink>
            <br/>
            {text(language,
                  {"ru": <span>
                           В соответствии с испанским налоговым
                           законодательством, граждане Испании, а также
                           иностранные лица, проживающие на территории
                           Испании, обязаны подать декларацию о доходах за
                           предыдущий год в период с <Teal>3 апреля</Teal>
                           &ensp;до <Teal>1 июля</Teal>.
                         </span>,
                   "en": <span>
                           As a general rule, Spanish tax returns should
                           be filed and paid between <Teal>April 3</Teal>
                           &ensp;and <Teal>July 1</Teal>.
                         </span>,
                   "es": <span>
                           Como regla general, las declaraciones
                           tributarias españolas deben presentarse y
                           pagarse entre el <Teal>3 de abril</Teal> y el
                           &ensp;<Teal>1 de julio</Teal>.
                         </span>})}
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_4">
              <Typography variant="h5" component="h2"
                          id="HelpSpain_4">
                {text(language,
                      {"ru": "Налог на прибыль в Испании",
                       "en": "Income tax in Spain",
                       "es": "Impuesto sobre la renta en España"})}
              </Typography>
            </HelpHashLink>
            <br/>
            {text(language,
                  {"ru": <span>
                           Подоходный налог в Испании облагается по следующим ставкам:
                           <ul>
                             <li>19% на первые 6000 евро налогооблагаемого дохода.</li>
                             <li>21% для следующих 6 000 евро до 50 000 евро налогооблагаемого дохода.</li>
                             <li>23% для следующих 50 000 евро до 200 000 евро налогооблагаемого дохода.</li>
                             <li>26% для любых сумм свыше 200 000 евро.</li>
                           </ul>
                           Для общего налогооблагаемого дохода применяются прогрессивные налоговые ставки.
                         </span>,
                   "en": <span>
                           Income Tax in Spain is taxed at the following rates:
                           <ul>
                             <li>19% for the first EUR 6,000 of taxable income.</li>
                             <li>21% for the following EUR 6,000 to EUR 50,000 of taxable income.</li>
                             <li>23% for the following EUR 50,000 to EUR 200,000 of taxable income.</li>
                             <li>26% for any amounts over EUR 200,000.</li>
                           </ul>
                           For general taxable income, progressive tax rates will apply.
                         </span>,
                   "es": <span>
                           El Impuesto sobre la Renta en España tributa a las siguientes tasas:
                           <ul>
                             <li>19% por los primeros 6.000 euros de base imponible.</li>
                             <li>21% para los siguientes 6.000 EUR a 50.000 EUR de renta imponible.</li>
                             <li>23% para los siguientes 50.000 EUR a 200.000 EUR de renta imponible.</li>
                             <li>26% para importes superiores a 200.000 euros.</li>
                           </ul>
                           Para la renta gravable general, se aplicarán tasas impositivas progresivas.
                         </span>})}
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_5">
              <Typography variant="h5" component="h2"
                          id="HelpSpain_5">
                {text(language,
                      {"ru": "Правила зачета при закрытии позиций",
                       "en": "Set-off rules for closing positions",
                       "es": "Reglas de compensación para el cierre de posiciones"})}
              </Typography>
            </HelpHashLink>
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_6">
              <Typography variant="h6" component="h3"
                          id="HelpSpain_6">
                {text(language,
                      {"ru": "Общие положения",
                       "en": "General issues",
                       "es": "Asuntos Generales"})}
              </Typography>
            </HelpHashLink>
            {text(language,
                  {"ru": <span>
                           Убытки могут быть зачтены в счет доходов
                           в рамках годовой декларации.
                         </span>,
                   "en": <span>
                           Losses can be off-set against other income in
                           the annual income tax declaration.
                         </span>,
                   "es": <span>
                           Las pérdidas pueden compensarse con otros
                           ingresos en la declaración anual del impuesto
                           sobre la renta.
                         </span>})}
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_7">
              <Typography variant="h6" component="h3"
                          id="HelpSpain_7">
                {text(language,
                      {"ru": "Правило FIFO",
                       "en": "FIFO rule",
                       "es": "Regla FIFO"})}
              </Typography>
            </HelpHashLink>
            {text(language,
                  {"ru": <span>
                           При покупке/продаже расчет происходит согласно
                           правилу <b>FIFO</b> (первая купленная бумага
                           считается первой проданной).
                         </span>,
                   "en": <span>
                           The buying/selling, transitions occurs
                           according to <b>FIFO</b> rule (the first
                           purchased security is considered the first
                           sold).
                         </span>,
                   "es": <span>
                           Las transiciones de compra/venta se producen de
                           acuerdo con la regla <b>FIFO</b> (el primer
                           valor comprado se considera el primero
                           vendido).
                         </span>})}
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_8">
              <Typography variant="h6" component="h3"
                          id="HelpSpain_8">
                {text(language,
                      {"ru": "Операции Forex",
                       "en": "Forex operations",
                       "es": "Transacciones de divisas"})}
              </Typography>
            </HelpHashLink>
            {text(language,
                  {"ru": <span>
                           Согласно разъяснения&ensp;
                           <Link href="https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V2422-20&ref=bovedainversion.com"
                                 className={classes.link}
                                 target="_blank">
                             V2422-20
                           </Link>, нет необходимости декларировать
                           прирост капитала ни от акций, ни от валют до
                           фактического момента их продажи.
                         </span>,
                   "en": <span>
                           According to clarification&ensp;
                           <Link href="https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V2422-20&ref=bovedainversion.com"
                                 className={classes.link}
                                 target="_blank">
                             V2422-20
                           </Link>, it is not necessary to declare capital
                           gains from either shares or currencies until
                           the actual moment of their sale.
                         </span>,
                   "es": <span>
                           De acuerdo con la aclaración&ensp;
                           <Link href="https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V2422-20&ref=bovedainversion.com"
                                 className={classes.link}
                                 target="_blank">
                             V2422-20
                           </Link>, no es necesario declarar las ganancias
                           de capital ya sea de acciones o divisas hasta
                           el momento mismo de su enajenación.
                         </span>})}
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_9">
              <Typography variant="h6" component="h3"
                          id="HelpSpain_9">
                {text(language,
                      {"ru": "Правило двух месяцев",
                       "en": "Two month rule",
                       "es": "Regla de los dos meses"})}
              </Typography>
            </HelpHashLink>
            {text(language,
                  {"ru": <span>
                           Это правило предотвращает компенсацию потерь
                           ценной бумаги за счет прироста капитала,
                           полученного от этой же ценной бумаги, если
                           инвестор покупает ее в течение двух месяцев до
                           или после продажи, вызвавшей имущественные
                           убытки.<br/>
                           Однако, после последующей продажи оставшихся
                           лотов данных ценных бумаг, предыдущий убыток,
                           не учтенный согласно этого правила может быть
                           учтен.
                         </span>,
                   "en": <span>
                           This rule prevents the loss of a security from
                           being offset by capital gains from the same
                           security if an investor purchases it within two
                           months before or after the sale that caused the
                           property loss.<br/>
                           However, after the subsequent sale of the
                           remaining lots of these securities, the
                           previous loss not taken into account under this
                           rule can be used for offset.
                         </span>,
                   "es": <span>
                           Esta regla impide que la pérdida de un valor
                           sea compensada con ganancias de capital del
                           mismo valor si un inversionista lo compra
                           dentro de los dos meses anteriores o
                           posteriores a la venta que causó la pérdida de
                           la propiedad.<br/>
                           No obstante, tras la posterior enajenación de
                           los restantes lotes de estos valores, podrá
                           tenerse en cuenta la pérdida anterior no tenida
                           en cuenta con arreglo a esta regla.
                         </span>})}
            <br/>
            {text(language,
                  {"ru": <span>
                           Правило ожидания будет составлять 12 месяцев
                           вместо 2 месяцев, если вы продаете и выкупаете
                           акции, которые не котируются или котируются на
                           рынках, не регулируемых/ассимилированных ЕС.
                           Следующие рынки являются ассимилированнми ЕС, и
                           поэтому применяется правило 2 месяцев:
                           <ul>
                             <li>Австралия (ASX)</li>
                             <li>Гонконг (SEHK)</li>
                             <li>США (CBOE, NYSE, Nasdaq)</li>
                           </ul>
                         </span>,
                   "en": <span>
                           The waiting rule will be 12 months instead of 2
                           months if you are selling and repurchasing
                           shares that are not listed or listed on non-EU
                           regulated/assimilated markets.
                           The following markets are EU assimilated and
                           therefore the 2 month rule applies:
                           <ul>
                             <li>Australia (ASX)</li>
                             <li>Hong Kong (SEHK)</li>
                             <li>USA (CBOE, NYSE, Nasdaq)</li>
                           </ul>
                         </span>,
                   "es": <span>
                           La regla de anticipación serían 12 meses en
                           lugar de 2 meses si lo que estás vendiendo y
                           recomprando son acciones no cotizadas o
                           cotizadas en mercados no regulados/asimilados
                           por la UE.
                           Los siguientes mercados están asimilados a la
                           UE y, por lo tanto, se aplica la regla de los 2
                           meses:
                           <ul>
                             <li>Australia (ASX)</li>
                             <li>Hong Kong (SEHK)</li>
                             <li>USA (CBOE, NYSE, Nasdaq)</li>
                           </ul>
                         </span>})}
            {isMobile
             ? <Paper>
                 {/* -- Mobile -- */}
                 <Accordion>
                   <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content">
                     <Typography variant="h5"
                                 className={classes.examplesAccordionHeader}
                                 gutterBottom>
                       {text(language,
                             {"ru": "Примеры",
                              "en": "Examples",
                              "es": "Ejemplos"})}
                     </Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                     <div className={classes.justify}>
                       {text
                        (language,
                         {"ru": <span>
                                  Ниже приводится ряд примеров, которые можно
                                  использовать для пробной генерации отчетов
                                  бесплатно. Данные примеры представляют собой
                                  описание сделок в универсальном формате
                                  (<Link component={NavLink}
                                         target="_blank"
                                         to="/help/FinParserFormat"
                                         className={classes.link}>
                                     подробнее
                                   </Link>), отражающие примеры запросов в
                                  Генеральное Налоговое Управление.

                                  В таблице, приведенной ниже, первая строка
                                  со ссылками - адрес страницы запроса,
                                  вторая, - ссылка на файл с исходными данными
                                  сделок, третья и четвертая - результат
                                  обработки этих данных сервисом. Вы можете
                                  загрузить любой из приведенных ниже файлов в
                                  систему для ознакомления с результатом:
                                </span>,
                          "en": <span>
                                  The following are a number of examples that
                                  can be used for a free trial report
                                  generation. These examples are descriptions
                                  of transactions in a universal format
                                  (<Link component={NavLink}
                                         target="_blank"
                                         to="/help/FinParserFormat"
                                         className={classes.link}>
                                     details
                                   </Link>), reflecting examples of requests
                                  to the General Tax Administration.

                                  In the table below, the first line with
                                  links is the address of the request page,
                                  the second is a link to the file with the
                                  original transaction data, the third and
                                  fourth are the result of processing this
                                  data by the service. You can upload any of
                                  the following files to the system to view
                                  the result:
                                </span>,
                          "es": <span>
                                  A continuación se presentan una serie de
                                  ejemplos que pueden utilizarse para generar
                                  informes de forma gratuita a modo de
                                  prueba. Estos ejemplos son descripciones de
                                  transacciones en formato universal
                                  (<Link component={NavLink}
                                         target="_blank"
                                         to="/help/FinParserFormat"
                                         className={classes.link}>
                                     detalles
                                   </Link>), que reflejan ejemplos de
                                  solicitudes a la Administración General
                                  Tributaria.

                                  En la siguiente tabla, la primera línea con
                                  enlaces es la dirección de la página de
                                  solicitud, la segunda es un enlace al
                                  archivo con los datos originales de la
                                  transacción, la tercera y cuarta son el
                                  resultado del procesamiento de estos datos
                                  por parte del servicio. Puede cargar
                                  cualquiera de los siguientes archivos al
                                  sistema para ver el resultado:
                                </span>})}
                     </div>
                     <p/>
                     <TableContainer component={Box}>
                       <Table className={classes.table}>
                         {exampleRows.map((row) => (
                           <>
                             <TableRow key={row.name}>
                               <TableCell align="left"
                                          className={classes.cellLeftDescriptionMobile}>
                                 <b>
                                   {text(language,
                                         {"ru": "Описание",
                                          "en": "Description",
                                          "es": "Descripción"})}
                                 </b>
                               </TableCell>
                               <TableCell align="center"
                                          className={classes.cellRightMobile}>
                                 <Link href={row.url}
                                       target="_blank"
                                       className={classes.link}>
                                   {row.name}
                                 </Link>
                               </TableCell>
                             </TableRow>
                             <TableRow key={row.name}>
                               <TableCell align="left"
                                          className={classes.cellLeftMobile}>
                                 {text(language,
                                       {"ru": "Исходный файл отчета",
                                        "en": "Source report file",
                                        "es": "Archivo de informe fuente"})}
                               </TableCell>
                               <TableCell align="center"
                                          className={classes.cellRightMobile}>
                                 <Link href={'/es/example/' + row.name + '/finparser_xlsx/' + language}
                                       className={classes.link}>
                                   <img src="/images/finparser-file.png"
                                        height='32px'
                                        width='32px'
                                        alt=''/>
                                 </Link>
                               </TableCell>
                             </TableRow>
                             <TableRow key={row.name}>
                               <TableCell align="left"
                                          className={classes.cellLeftMobile}>
                                 {text(language,
                                       {"ru": "Форма 100. Отчет по доходам для налоговой декларации",
                                        "en": "Form 100. Income report for tax return",
                                        "es": "Modelo 100. Informe de ingresos para declaración de impuestos"})}
                               </TableCell>
                               <TableCell align="center"
                                          className={classes.cellRightMobile}>
                                 <Link href={'/es/example/' + row.name + '/expl_note_xlsx/' + language}
                                       className={classes.link}
                                       target="_blank">
                                   <img src='/images/xls-file.png'
                                        width='32'
                                        alt=''/>
                                 </Link>
                                 <Link href={'/es/example/' + row.name + '/expl_note_pdf/' + language}
                                       className={classes.link}
                                       target="_blank">
                                   <img src='/images/pdf-file.png'
                                        width='32'
                                        alt=''/>
                                 </Link>
                               </TableCell>
                             </TableRow>
                             <TableRow key={row.name}>
                               <TableCell align="left"
                                          className={classes.cellLeftMobile}>
                                 {text(language,
                                       {"ru": "Форма 100. Детализированный отчет по доходам для налоговой декларации",
                                        "en": "Form 100. Detailed income report for tax return",
                                        "es": "Modelo 100. Informe de ingresos detallado para declaración de impuestos"})}
                               </TableCell>
                               <TableCell align="center"
                                          className={classes.cellRightMobile}>
                                 <Link href={'/es/example/' + row.name + '/expl_note_detail_xlsx/' + language}
                                       className={classes.link}
                                       target="_blank">
                                   <img src='/images/xls-file.png'
                                        width='32'
                                        alt=''/>
                                 </Link>
                                 <Link href={'/es/example/' + row.name + '/expl_note_detail_pdf/' + language}
                                       className={classes.link}
                                       target="_blank">
                                   <img src='/images/pdf-file.png'
                                        width='32'
                                        alt=''/>
                                 </Link>
                               </TableCell>
                             </TableRow>
                           </>
                         ))}
                       </Table>
                     </TableContainer>
                   </AccordionDetails>
                 </Accordion>
               </Paper>
             : <>
                 {/* -- Desktop -- */}
                 <Typography variant={isMobile ? "h5" : "h7"}
                             component="h4"
                             className={classes.headerMargin}
                             gutterBottom>
                   {text(language,
                         {"ru": "Примеры",
                          "en": "Examples",
                          "es": "Ejemplos"})}
                 </Typography>
                 <p/>
                 <div className={classes.justify}>
                   {text
                    (language,
                     {"ru": <span>
                              Ниже приводится ряд примеров, которые можно
                              использовать для пробной генерации отчетов
                              бесплатно. Данные примеры представляют собой
                              описание сделок в универсальном формате
                              (<Link component={NavLink}
                                     target="_blank"
                                     to="/help/FinParserFormat"
                                     className={classes.link}>
                                 подробнее
                               </Link>), отражающие примеры запросов в
                              Генеральное Налоговое Управление.

                              В таблице, приведенной ниже, сначала идет
                              ссылками страницы запроса, далее, - ссылка на
                              файл с исходными данными сделок, затем -
                              результат обработки этих данных сервисом. Вы
                              можете загрузить любой из приведенных ниже
                              файлов в систему для ознакомления с результатом:
                            </span>,
                      "en": <span>
                              The following are a number of examples that
                              can be used for a free trial report
                              generation. These examples are descriptions
                              of transactions in a universal format
                              (<Link component={NavLink}
                                     target="_blank"
                                     to="/help/FinParserFormat"
                                     className={classes.link}>
                                 details
                               </Link>), reflecting examples of requests
                              to the General Tax Administration.

                              In the table below, first there are links to the
                              request page, then there is a link to the file
                              with the initial transaction data, then there is
                              the result of processing this data by the
                              service. You can upload any of the following
                              files to the system to view the result:
                            </span>,
                      "es": <span>
                              A continuación se presentan una serie de
                              ejemplos que pueden utilizarse para generar
                              informes de forma gratuita a modo de
                              prueba. Estos ejemplos son descripciones de
                              transacciones en formato universal
                              (<Link component={NavLink}
                                     target="_blank"
                                     to="/help/FinParserFormat"
                                     className={classes.link}>
                                 detalles
                               </Link>), que reflejan ejemplos de
                              solicitudes a la Administración General
                              Tributaria.

                              En la siguiente tabla, primero hay enlaces a la
                              página de solicitud, luego hay un enlace al
                              archivo con los datos de la transacción inicial
                              y luego está el resultado del procesamiento de
                              estos datos por parte del servicio. Puede cargar
                              cualquiera de los siguientes archivos al sistema
                              para ver el resultado:
                            </span>})}
                 </div>
                 <p/>
                 <TableContainer component={Paper}>
                   <Table className={classes.table} size="small">
                     <TableHead>
                       <TableRow>
                         <TableCell align="center">
                           {text(language,
                                 {"ru": "Описание",
                                  "en": "Description",
                                  "es": "Descripción"})}
                         </TableCell>
                         <TableCell align="center">
                           {text(language,
                                 {"ru": "Исходный файл отчета",
                                  "en": "Source report file",
                                  "es": "Archivo de informe fuente"})}
                         </TableCell>
                         <TableCell align="center">
                           {text(language,
                                 {"ru": "Форма 100. Отчет по доходам для налоговой декларации",
                                  "en": "Form 100. Income report for tax return",
                                  "es": "Modelo 100. Informe de ingresos para declaración de impuestos"})}
                         </TableCell>
                         <TableCell align="center">
                           {text(language,
                                 {"ru": "Форма 100. Детализированный отчет по доходам для налоговой декларации",
                                  "en": "Form 100. Detailed income report for tax return",
                                  "es": "Modelo 100. Informe de ingresos detallado para declaración de impuestos"})}
                         </TableCell>
                       </TableRow>
                     </TableHead>
                     <TableBody>
                       {exampleRows.map((row) => (
                         <TableRow key={row.name}>
                           <TableCell align="center">
                             <Link href={row.url}
                                   target="_blank"
                                   className={classes.link}>
                               {row.name}
                             </Link>
                           </TableCell>
                           <TableCell align="center"
                                      className={classes.imgCell}>
                             <Link href={'/es/example/' + row.name + '/finparser_xlsx/' + language}
                                   className={classes.link}>
                               <img src="/images/finparser-file.png"
                                    height='32px'
                                    width='32px'
                                    alt=''/>
                             </Link>
                           </TableCell>
                           <TableCell align="center"
                                      className={classes.imgCell}>
                             <Link href={'/es/example/' + row.name + '/expl_note_xlsx/' + language}
                                   className={classes.link}
                                   target="_blank">
                               <img src='/images/xls-file.png'
                                    width='32'
                                    alt=''/>
                             </Link>
                             <Link href={'/es/example/' + row.name + '/expl_note_pdf/' + language}
                                   className={classes.link}
                                   target="_blank">
                               <img src='/images/pdf-file.png'
                                    width='32'
                                    alt=''/>
                             </Link>
                           </TableCell>
                           <TableCell align="center">
                             <Link href={'/es/example/' + row.name + '/expl_note_detail_xlsx/' + language}
                                   className={classes.link}
                                   target="_blank">
                               <img src='/images/xls-file.png'
                                    width='32'
                                    alt=''/>
                             </Link>
                             <Link href={'/es/example/' + row.name + '/expl_note_detail_pdf/' + language}
                                   className={classes.link}
                                   target="_blank">
                               <img src='/images/pdf-file.png'
                                    width='32'
                                    alt=''/>
                             </Link>
                           </TableCell>
                         </TableRow>
                       ))}
                     </TableBody>
                   </Table>
                 </TableContainer>
               </>
            }
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_10">
              <Typography variant="h6" component="h3"
                          id="HelpSpain_10">
                {text(language,
                      {"ru": "Компенсация прибыли по дивидендам",
                       "en": "Dividend profit compensation",
                       "es": "Compensación de beneficios de dividendos"})}
              </Typography>
            </HelpHashLink>
            {text(language,
                  {"ru": <span>
                           Убыток от продажи ценных бумаг фондовом рынке,
                           может быть зачтен с процентами со счетов,
                           дивидендами и купонами по облигациям до 25% от
                           суммы дохода.
                         </span>,
                   "en": <span>
                           Losses from the sale of securities in the stock
                           market can be offset against interest from
                           accounts, dividends and bond coupons up to 25%
                           of income.
                         </span>,
                   "es": <span>
                           Las pérdidas por la venta de valores en el
                           mercado de valores pueden compensarse con
                           intereses de cuentas, dividendos y cupones de
                           bonos hasta el 25% de los ingresos.
                         </span>})}
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_11">
              <Typography variant="h5" component="h2"
                          id="HelpSpain_11">
                {text(language,
                      {"ru": <span>
                               Список секций для заполнения Формы 100 по
                               инвестиционным доходам:
                             </span>,
                       "en": <span>
                               List of cells to fill out Form 100 for
                               investment income:
                             </span>,
                       "es": <span>
                               Lista de celdas para cumplimentar el Modelo 100
                               de rentas de inversiones:
                             </span>})}
              </Typography>
            </HelpHashLink>
            <ol>
              {text(language,
                    {"ru": <li>Секция <Teal>0328</Teal>. Количество глобальных
                             транзакций осуществленных в 2023 году.
                           </li>,
                     "en": <li>Cell <Teal>0328</Teal>. Amount of global
                             transactions carried out in 2023.
                           </li>,
                     "es": <li>Casilla <Teal>0328</Teal>. Importe global de las
                             transmisiones efectuadas en 2023.
                           </li>})}
              {text(language,
                    {"ru": <li>Секция <b>0027</b>. Доходы по депозитам и
                             финансовым активам в целом.
                           </li>,
                     "en": <li>Cell <b>0027</b>. Yield on deposits and
                             financial assets in general.
                           </li>,
                     "es": <li>Casilla <b>0027</b>. Rendimiento por
                             depósitos y activos financieros en general.
                           </li>})}
              {text(language,
                    {"ru": <li>Секция <b>0029</b>. Дивиденды, налог по
                             которым удержан в Испании.</li>,
                     "en": <li>Cell <b>0029</b>. Dividends on which tax
                             is withheld in Spain.</li>,
                     "es": <li>Casilla <b>0029</b>. Dividendos sobre los
                             que se retiene impuesto en España.</li>})}
              {text(language,
                    {"ru": <li>Секция <b>0588</b>. Дивиденды, связанные
                             с двойным налогообложением.</li>,
                     "en": <li>Cell <b>0588</b>. Dividends subject to
                             double taxation.</li>,
                     "es": <li>Casilla <b>0588</b>. Dividendos sujetos a
                             doble imposición.</li>})}
              {text(language,
                    {"ru": <li>Секция <b>030</b>. Доход от продажи или
                             погашения казначейских векселей.
                           </li>,
                     "en": <li>Cell <b>030</b>. Income from transmission
                             or amortization of Treasury Bills.
                           </li>,
                     "es": <li>Casilla <b>030</b>. Rendimiento por
                             transmisión o amortización de Letras del
                             Tesoro.
                           </li>})}
              {text(language,
                    {"ru": <li>Секция <b>0031</b>. Доходы от продажи,
                             или погашения других финансовых активов.
                           </li>,
                     "en": <li>Cell <b>0031</b>. Income from the
                             transmission, amortization or reimbursement
                             of other financial assets.
                           </li>,
                     "es": <li>Casilla <b>0031</b>. Rendimientos
                             procedentes de transmisión, amortización o
                             reembolso de otros activos financieros.
                           </li>})}
              {text(language,
                    {"ru": <li>Секция <b>0037</b>. Расходы, вычитаемые
                             из налогооблагаемой базы. Сюда включаются
                             расходы на управление и хранение ценных
                             бумаг.
                           </li>,
                     "en": <li>Cell <b>0037</b>. Tax deductible
                             expenses. This includes the costs of
                             managing and storing securities.
                           </li>,
                     "es": <li>Casilla <b>0037</b>. Gastos fiscalmente
                             deducibles. Incluye aquí los gastos de
                             administración y depósito de valores
                             negociables, porque son deducibles.
                           </li>})}
              {text(language,
                    {"ru": <li>Секция <b>0327</b>. Прирост капитала и
                             убытки, полученные в результате сделок с
                             ациями и другими инструментами, таких
                             как ETF, опционы, фьючерсы.
                           </li>,
                     "en": <li>Cell <b>0327</b>. Capital gains and
                             losses resulting from transactions in
                             stocks and other instruments such as ETFs,
                             options, futures.
                           </li>,
                     "es": <li>Casilla <b>0327</b>. Ganancias y pérdidas
                             de capital resultantes de transacciones con
                             acciones y otros instrumentos como ETF,
                             opciones y futuros.
                           </li>})}
              {text(language,
                    {"ru": <li>Секции&ensp;
                             <Teal>1624</Teal>,&ensp;
                             <Teal>1631</Teal> - <Teal>1654</Teal>.
                             Прирост капитала и убытки, полученные в
                             результате передачи других активов (Опционы,
                             Фьючерсы, CFD, Форекс).
                           </li>,
                     "en": <li>Cells&ensp;
                             <Teal>1624</Teal>,&ensp;
                             <Teal>1631</Teal> - <Teal>1654</Teal>.
                             Capital gains and losses resulting from the
                             transfer of other assets (Options, Futures, CFDs,
                             Forex).
                           </li>,
                     "es": <li>Casillas
                             <Teal>1624</Teal>,&ensp;
                             <Teal>1631</Teal> - <Teal>1654</Teal>.
                             Ganancias y pérdidas patrimoniales derivadas de
                             transmisiones de otros elementos patrimoniales
                             (Opciones, Futuros, CFD, Forex).
                           </li>})}
              {text(language,
                    {"ru": <li>Секции <b>1802</b> и <b>1803</b>.
                             Проданная и купленная криптовалюта.
                           </li>,
                     "en": <li>Cells <b>1802</b> and <b>1803</b>.
                             Sold and purchased cryptocurrency.
                           </li>,
                     "es": <li>Casillas <b>1802</b> y <b>1803</b>.
                             Criptomoneda vendida y comprada.
                           </li>})}
              {text(language,
                    {"ru": <li>Секции <b>1804</b> - <b>1806</b>.
                             Стоимость покупки и продажи криптовалюты.
                           </li>,
                     "en": <li>Cells <b>1804</b> - <b>1806</b>.
                             Value of buying and selling cryptocurrency.
                           </li>,
                     "es": <li>Casillas <b>1804</b> - <b>1806</b>.
                             Valor de compra y venta de criptomonedas.
                           </li>})}
            </ol>
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_12">
              <Typography variant="h5" component="h2"
                          id="HelpSpain_12">
                {text(language,
                      {"ru": "Процедура подачи налоговой декларации",
                       "en": "Procedure for filing a tax return",
                       "es": "Procedimiento para presentar una declaración de impuestos"})}
              </Typography>
            </HelpHashLink>
            <br/>
            {text(language,
                  {"ru": "Зайдите на сайт",
                   "en": "Go to the site",
                   "es": "Ir al sitio"})}&ensp;
            <Link href="https://sede.agenciatributaria.gob.es/Sede/Renta.html"
                  className={classes.link}
                  target="_blank">
              Agencia Tributaria Renta
            </Link>.
            <br/>
            {text(language,
                  {"ru": "Выберите",
                   "en": "Select",
                   "es": "Seleccionar"})}&ensp;
            <Red>
              {text(language,
                    {"ru": "Draft / tax return processing service (Renta WEB).",
                     "en": "Draft / tax return processing service (Renta WEB).",
                     "es": "Servicio tramitación de borrador / declaración (Renta WEB)."})}
            </Red>&ensp;
            {text(language,
                  {"ru": "Пройдите процедуру идентификации.",
                   "en": "Complete the identification procedure.",
                   "es": "Realice el procedimiento de identificación."})}
            <br/>
            <ImageZoom
              image={{
                src: text(language,
                          {"ru": '../images/es/agenciaTributariaEn1.png',
                           "en": '../images/es/agenciaTributariaEn1.png',
                           "es": '../images/es/agenciaTributariaEs1.png'}),
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: text(language,
                          {"ru": '../images/es/agenciaTributariaEn1.png',
                           "en": '../images/es/agenciaTributariaEn1.png',
                           "es": '../images/es/agenciaTributariaEs1.png'}),
                alt: ''
              }}
              zoomMargin={0}
            />
            <br/>
            {text(language,
                  {"ru": "В качестве примера возьмем следующие данные",
                   "en": "Let's take the following data as an example",
                   "es": "Tomemos como ejemplo los siguientes datos"})}
            <br/>
            {isMobile
             ? <Paper>
                 {/* -- Mobile -- */}
                 <Accordion>
                   <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content">
                     <Typography variant="h5"
                                 className={classes.examplesAccordionHeader}
                                 gutterBottom>
                       {text(language,
                             {"ru": "Пример",
                              "en": "Example",
                              "es": "Ejemplo"})}
                     </Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                     <TableContainer component={Box}>
                       <Table className={classes.table}>
                         {exampleForRenta.map((row) => (
                           <>
                             <TableRow key={row.name}>
                               <TableCell align="left"
                                          className={classes.cellLeftMobile}>
                                 {text(language,
                                       {"ru": "Исходный файл отчета",
                                        "en": "Source report file",
                                        "es": "Archivo de informe fuente"})}
                               </TableCell>
                               <TableCell align="center"
                                          className={classes.cellRightMobile}>
                                 <Link href={'/es/example/' + row.name + '/finparser_xlsx/' + language}
                                       className={classes.link}>
                                   <img src="/images/finparser-file.png"
                                        height='32px'
                                        width='32px'
                                        alt=''/>
                                 </Link>
                               </TableCell>
                             </TableRow>
                             <TableRow key={row.name}>
                               <TableCell align="left"
                                          className={classes.cellLeftMobile}>
                                 {text(language,
                                       {"ru": "Форма 100. Отчет по доходам для налоговой декларации",
                                        "en": "Form 100. Income report for tax return",
                                        "es": "Modelo 100. Informe de ingresos para declaración de impuestos"})}
                               </TableCell>
                               <TableCell align="center"
                                          className={classes.cellRightMobile}>
                                 <Link href={'/es/example/' + row.name + '/expl_note_xlsx/' + language}
                                       className={classes.link}
                                       target="_blank">
                                   <img src='/images/xls-file.png'
                                        width='32'
                                        alt=''/>
                                 </Link>
                                 <Link href={'/es/example/' + row.name + '/expl_note_pdf/' + language}
                                       className={classes.link}
                                       target="_blank">
                                   <img src='/images/pdf-file.png'
                                        width='32'
                                        alt=''/>
                                 </Link>
                               </TableCell>
                             </TableRow>
                             <TableRow key={row.name}>
                               <TableCell align="left"
                                          className={classes.cellLeftMobile}>
                                 {text(language,
                                       {"ru": "Форма 100. Детализированный отчет по доходам для налоговой декларации",
                                        "en": "Form 100. Detailed income report for tax return",
                                        "es": "Modelo 100. Informe de ingresos detallado para declaración de impuestos"})}
                               </TableCell>
                               <TableCell align="center"
                                          className={classes.cellRightMobile}>
                                 <Link href={'/es/example/' + row.name + '/expl_note_detail_xlsx/' + language}
                                       className={classes.link}
                                       target="_blank">
                                   <img src='/images/xls-file.png'
                                        width='32'
                                        alt=''/>
                                 </Link>
                                 <Link href={'/es/example/' + row.name + '/expl_note_detail_pdf/' + language}
                                       className={classes.link}
                                       target="_blank">
                                   <img src='/images/pdf-file.png'
                                        width='32'
                                        alt=''/>
                                 </Link>
                               </TableCell>
                             </TableRow>
                           </>
                         ))}
                       </Table>
                     </TableContainer>
                   </AccordionDetails>
                 </Accordion>
               </Paper>
             : <>
                 {/* -- Desktop -- */}
                 <p/>
                 <TableContainer component={Paper}>
                   <Table className={classes.table} size="small">
                     <TableHead>
                       <TableRow>
                         <TableCell align="center">
                           {text(language,
                                 {"ru": "Исходный файл отчета",
                                  "en": "Source report file",
                                  "es": "Archivo de informe fuente"})}
                         </TableCell>
                         <TableCell align="center">
                           {text(language,
                                 {"ru": "Форма 100. Отчет по доходам для налоговой декларации",
                                  "en": "Form 100. Income report for tax return",
                                  "es": "Modelo 100. Informe de ingresos para declaración de impuestos"})}
                         </TableCell>
                         <TableCell align="center">
                           {text(language,
                                 {"ru": "Форма 100. Детализированный отчет по доходам для налоговой декларации",
                                  "en": "Form 100. Detailed income report for tax return",
                                  "es": "Modelo 100. Informe de ingresos detallado para declaración de impuestos"})}
                         </TableCell>
                       </TableRow>
                     </TableHead>
                     <TableBody>
                       {exampleForRenta.map((row) => (
                         <TableRow key={row.name}>
                           <TableCell align="center"
                                      className={classes.imgCell}>
                             <Link href={'/es/example/' + row.name + '/finparser_xlsx/' + language}
                                   className={classes.link}>
                               <img src="/images/finparser-file.png"
                                    height='32px'
                                    width='32px'
                                    alt=''/>
                             </Link>
                           </TableCell>
                           <TableCell align="center"
                                      className={classes.imgCell}>
                             <Link href={'/es/example/' + row.name + '/expl_note_xlsx/' + language}
                                   className={classes.link}
                                   target="_blank">
                               <img src='/images/xls-file.png'
                                    width='32'
                                    alt=''/>
                             </Link>
                             <Link href={'/es/example/' + row.name + '/expl_note_pdf/' + language}
                                   className={classes.link}
                                   target="_blank">
                               <img src='/images/pdf-file.png'
                                    width='32'
                                    alt=''/>
                             </Link>
                           </TableCell>
                           <TableCell align="center">
                             <Link href={'/es/example/' + row.name + '/expl_note_detail_xlsx/' + language}
                                   className={classes.link}
                                   target="_blank">
                               <img src='/images/xls-file.png'
                                    width='32'
                                    alt=''/>
                             </Link>
                             <Link href={'/es/example/' + row.name + '/expl_note_detail_pdf/' + language}
                                   className={classes.link}
                                   target="_blank">
                               <img src='/images/pdf-file.png'
                                    width='32'
                                    alt=''/>
                             </Link>
                           </TableCell>
                         </TableRow>
                       ))}
                     </TableBody>
                   </Table>
                 </TableContainer>
               </>
            }
            <br/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_13">
              <Typography variant="h6" component="h3"
                          id="HelpSpain_13">
                {text(language,
                      {"ru": "Акции",
                       "en": "Stocks",
                       "es": "Acciones"})}
              </Typography>
            </HelpHashLink>
            {text(language,
                  {"ru": "Нажмите на кнопку ",
                   "en": "Click on the button ",
                   "es": "Haga clic en el botón "})}
            <Red>Mostrar opciones</Red>
            <br/>
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEn2.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEn2.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            {text(language,
                  {"ru": "Нажмите на цифру 1 между стрелочками.",
                   "en": "Click on the number 1 between the arrows.",
                   "es": "Haga clic en el número 1 entre las flechas."})}
            <br/>
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEn3.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEn3.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            {text(language,
                  {"ru": "Перейдите на страницу ",
                   "en": "Go to page ",
                   "es": "Ir a panel "})}
            <Red>16 / 53</Red>
            <br/>
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEn4.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEn4.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            <Red>F2. Ganancias y pérdidas patrimoniales derivadas de la
              transmisión de elementos patrimoniales (a integrar en la
              base imponible del ahorro) (continuación)</Red>
            <br/>
            {text(language,
                  {"ru": "Нажмите на значок карандаша рядом с секцией ",
                   "en": "Click on the pencil icon next to the cell ",
                   "es": "Haga clic en el ícono de lápiz al lado del casilla "})}
            <Teal>0328</Teal>&ensp;
            <Red>Importe global de las transmisiones efectuadas en 2023</Red>
            <br/>
            <ImageZoom
              image={{
                src: text(language,
                          {"ru": '../images/es/agenciaTributariaEs_stocks5.png',
                           "en": '../images/es/agenciaTributariaEs_stocks5.png',
                           "es": '../images/es/agenciaTributariaEs_stocks5.png'}),
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: text(language,
                          {"ru": '../images/es/agenciaTributariaEs_stocks5.png',
                           "en": '../images/es/agenciaTributariaEs_stocks5.png',
                           "es": '../images/es/agenciaTributariaEs_stocks5.png'}),
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_14">
              <Typography variant="h7" component="h4"
                          id="HelpSpain_14">
                {text(language,
                      {"ru": "Вмененные прибыли и убытки по акциям",
                       "en": "Imputed gains and losses on shares",
                       "es": "Ganancias y pérdidas imputadas sobre acciones"})}
              </Typography>
            </HelpHashLink>
            {text(language,
                  {"ru": 'Следующие поля необходимо заполнить:',
                   "en": 'The following fields must be filled in:',
                   "es": 'Se deben completar los siguientes campos:'})}
            <ul>
              <li>
                {text(language,
                      {"ru": <span>
                               Эмитент - <Red>Entidad Emisora</Red>
                             </span>,
                       "en": <span>
                               Issuing Entity - <Red>Entidad Emisora</Red>
                             </span>,
                       "es": <span>
                               <Red>Entidad Emisora</Red>
                             </span>})}.&ensp;
                {text(language,
                      {"ru": <span>
                               В качестве эмитента при указании общих данных
                               по всем сделкам вы можете ввести название
                               брокера (брокеров).
                             </span>,
                       "en": <span>
                               As an issuer, when entering general data for
                               all transactions, you can enter the name of the
                               broker(s).
                             </span>,
                       "es": <span>
                               Como emisor, al ingresar datos generales para
                               todas las transacciones, puede ingresar el
                               nombre del bróker(s).
                             </span>})}
              </li>
              <li>
                {text(language,
                      {"ru": <span>
                               Стоимость продажи - <Red>Valor de transmisión</Red>
                             </span>,
                       "en": <span>
                               Transmission value - <Red>Valor de transmisión</Red>
                             </span>,
                       "es": <span>
                               <Red>Valor de transmisión</Red>
                             </span>})}
              </li>
              <li>
                {text(language,
                      {"ru": <span>
                               Стоимость приобретения - <Red>Valor de adquisición</Red>
                             </span>,
                       "en": <span>
                               Acquisition value - <Red>Valor de adquisición</Red>
                             </span>,
                       "es": <span>
                               <Red>Valor de adquisición</Red>
                             </span>})}
              </li>
              <li>
                {text(language,
                      {"ru": <span>
                               Неотнесение убытков от выкупа однородных ценных
                               бумаг -&ensp;
                               <Red>No imputación de pérdidas por recompra de
                                 valores homogéneos</Red>
                             </span>,
                       "en": <span>
                               Non-attribution of losses due to repurchase of
                               homogeneous securities -&ensp;
                               <Red>No imputación de pérdidas por recompra de
                                 valores homogéneos</Red>
                             </span>,
                       "es": <span>
                               <Red>No imputación de pérdidas por recompra de
                                 valores homogéneos</Red>
                             </span>})}.&ensp;
                {text(language,
                      {"ru": "Установите значение ",
                       "en": "Set value ",
                       "es": "Valor ajustado "})}
                {text(language,
                      {"ru": <span>
                               <Red>Marque la casilla si ha obtenido una
                                 pérdida patrimonial y ha recomprado acciones
                                 homogéneas en los dos meses anteriores o
                                 posteriores a la transmisión</Red>
                             </span>,
                       "en": <span>
                               <Red>Marque la casilla si ha obtenido una
                                 pérdida patrimonial y ha recomprado acciones
                                 homogéneas en los dos meses anteriores o
                                 posteriores a la transmisión</Red>
                             </span>,
                       "es": <span>
                               <Red>Marque la casilla si ha obtenido una
                                 pérdida patrimonial y ha recomprado acciones
                                 homogéneas en los dos meses anteriores o
                                 posteriores a la transmisión</Red>
                             </span>})}
                {text(language,
                      {"ru": " выключенным при внесении вмененной прибыли или убытка.",
                       "en": " unchecked when declaring the imputable gain or loss.",
                       "es": " no marcar para cuando declaramos la ganancia o perdida que puede imputarse."})}
              </li>
            </ul>
            <ImageZoom
              image={{
                src: text(language,
                          {"ru": '../images/es/agenciaTributariaEs_stocks6.png',
                           "en": '../images/es/agenciaTributariaEs_stocks6.png',
                           "es": '../images/es/agenciaTributariaEs_stocks6.png'}),
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: text(language,
                          {"ru": '../images/es/agenciaTributariaEs_stocks6.png',
                           "en": '../images/es/agenciaTributariaEs_stocks6.png',
                           "es": '../images/es/agenciaTributariaEs_stocks6.png'}),
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_15">
              <Typography variant="h7" component="h4"
                          id="HelpSpain_15">
                {text(language,
                      {"ru": "Расходы которые не могут быть вменены",
                       "en": "Expenses that cannot be imputed",
                       "es": "Gastos que no pueden imputarse"})}
              </Typography>
            </HelpHashLink>
            <Red>Alta Entidad Emisora</Red>
            <br/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_16">
              <Typography variant="h6" component="h3"
                          id="HelpSpain_16">
                {text(language,
                      {"ru": "Деривативы (Опционы, Фьючерсы, CFD, Форекс)",
                       "en": "Derivatives (Options, Futures, CFD, Forex)",
                       "es": "Derivados (Opciones, Futuros, CFD, Forex)"})}
              </Typography>
            </HelpHashLink>
            {text(language,
                  {"ru": "Перейдите на страницу ",
                   "en": "Go to page ",
                   "es": "Ir a panel "})}
            <Red>20 / 53</Red>
            <br/>
            <Red>F2. Ganancias y pérdidas patrimoniales derivadas de la
              transmisión de elementos patrimoniales (a integrar en la base
              imponible del ahorro) (continuación)</Red>
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEs_derivs7.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEs_derivs7.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            {text(language,
                  {"ru": "Нажмите на значок карандаша рядом с секцией ",
                   "en": "Click on the pencil icon next to the cell ",
                   "es": "Haga clic en el ícono de lápiz al lado del casilla "})}
            <Teal>1626</Teal>.
            {text(language,
                  {"ru": " Тип актива ",
                   "en": " Type of asset ",
                   "es": " "})}
            <Red>Tipo de elemento patrimonial. Clave</Red>
            <br/>
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEs_derivs8.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEs_derivs8.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            {text(language,
                  {"ru": <span>Во всплывающем окне&ensp;
                           <Red>Tipo de elemento patrimonial</Red>
                           &ensp;выберите: </span>,
                   "en": <span>In a pop-up window&ensp;
                           <Red>Tipo de elemento patrimonial</Red>
                           &ensp;select the option: </span>,
                   "es": <span>En una ventana emergente&ensp;
                           <Red>Tipo de elemento patrimonial</Red>
                           &ensp;seleccione la opción: </span>})}
            {text(language,
                  {"ru": <span>Прочие активы, не затронутые
                           экономической деятельностью - </span>,
                   "en": <span>Other assets not assigned to economic
                           activities - </span>,
                   "es": <span></span>})}
            <Red>Otros elementos patrimoniales (bienes o derechos) no afectos
              a actividades económicas</Red>.
            <br/>
            {text(language,
                  {"ru": "Далее нажмите на значок карандаша рядом с секцией ",
                   "en": "Next, click on the pencil icon next to the cell ",
                   "es": "A continuación, haga clic en el ícono de lápiz al"
                   + " lado del casilla "})}
            <Teal>1631</Teal>.
            <br/>
            {text(language,
                  {"ru": "Отметьте ",
                   "en": "Check ",
                   "es": "Haga clic "})}
            <Red>Transmisión intervivos onerosa (venta, permuta, etc)</Red>.
            <br/>
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEs_derivs9.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEs_derivs9.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_17">
              <Typography variant="h6" component="h3"
                          id="HelpSpain_17">
                {text(language,
                      {"ru": "Дивиденды",
                       "en": "Dividends",
                       "es": "Dividendos"})}
              </Typography>
            </HelpHashLink>

            <HelpHashLink to="/help/HelpSpain#HelpSpain_18">
              <Typography variant="h7" component="h4"
                          id="HelpSpain_18">
                {text(language,
                      {"ru": <span>
                               Дивиденды, налог по которым
                               удержан в Испании (секция 0029)
                             </span>,
                       "en": <span>
                               Dividends on which tax is
                               withheld in Spain (cell 0029)
                             </span>,
                       "es": <span>
                               Dividendos sobre los que se
                               retiene impuesto en España
                               (casilla 0029)
                             </span>})}
              </Typography>
            </HelpHashLink>
            {text(language,
                  {"ru": "Перейдите на страницу ",
                   "en": "Go to page ",
                   "es": "Ir a panel "})}
            <Red>6 / 53</Red>
            <br/>
            <Red>B. Rendimientos del capital mobiliario</Red>
            <br/>
            <Red>Rendimientos del capital mobiliario a integrar en la base
              imponible del ahorro</Red>
            <br/>
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEs_dividends10.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEs_dividends10.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            {text(language,
                  {"ru": "Нажмите на значок карандаша рядом с секцией ",
                   "en": "Click on the pencil icon next to the cell ",
                   "es": "Haga clic en el ícono de lápiz al lado del casilla"
                   + " "})}
            <Teal>0029</Teal>.
            <br/>
            {text(language,
                  {"ru": <span>Дивиденды и другие доходы от владения
                           активами -&ensp;
                           <Red>Dividendos y demás rendimientos por la
                             participación en fondos propios de
                             entidades</Red></span>,
                   "en": <span>Dividends and other income from holdings in own
                           funds of entities -&ensp;
                           <Red>Dividendos y demás rendimientos por la
                             participación en fondos propios de
                             entidades</Red></span>,
                   "es": <span>
                           <Red>Dividendos y demás rendimientos por la
                             participación en fondos propios de
                             entidades</Red></span>})}
            <p/>
            {text(language,
                  {"ru": <span>
                           Заполните следующие поля:
                           <ul>
                             <li>
                               Полный доход - <Red>Ingresos íntegros</Red>.
                             </li>
                             <li>
                               Удержания - <Red>Retenciones</Red>.
                             </li>
                             <li>
                               Административные и депозитные сборы -&ensp;
                               <Red>Gastos de administración y depósito</Red>.
                               Сюда можно включить прочие расходы по
                               брокерскому счету, такие как депозитарные сборы.
                             </li>
                           </ul>
                         </span>,
                   "en": <span>
                           Fill in the following fields:
                           <ul>
                             <li>
                               Full income - <Red>Ingresos íntegros</Red>.
                             </li>
                             <li>
                               Withholdings - <Red>Retenciones</Red>.
                             </li>
                             <li>
                               Administration and deposit fees -&ensp;
                               <Red>Gastos de administración y depósito</Red>.
                               Here you can include other expenses related to
                               the brokerage account, such as custodial fees.
                             </li>
                           </ul>
                         </span>,
                   "es": <span>
                           Complete los siguientes campos:
                           <ul>
                             <li>
                               <Red>Ingresos íntegros</Red>.
                             </li>
                             <li>
                               <Red>Retenciones</Red>.
                             </li>
                             <li>
                               <Red>Gastos de administración y depósito</Red>.
                               Aquí puede incluir otros gastos relacionados
                               con la cuenta de corretaje, como las comisiones
                               de custodia.
                             </li>
                           </ul>
                         </span>})}
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEs_dividends11.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEs_dividends11.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <p/>
            <HelpHashLink to="/help/HelpSpain#HelpSpain_19">
              <Typography variant="h7" component="h4"
                          id="HelpSpain_19">
                {text(language,
                      {"ru": <span>
                               Дивиденды с международным двойным
                               налогообложением (секция 0588)
                             </span>,
                       "en": <span>
                               Dividends with international
                               double taxation (cell 0588)
                             </span>,
                       "es": <span>
                               Dividendos con doble imposición
                               internacional (casilla 0588)
                             </span>})}
              </Typography>
            </HelpHashLink>
            {text(language,
                  {"ru": "Перейдите на страницу ",
                   "en": "Go to page ",
                   "es": "Ir a panel "})}
            <Red>33 / 53</Red>
            <br/>
            <Red>M. Cálculo del impuesto y resultado de la declaración</Red>
            <br/>
            <Red>Cuota resultante de la autoliquidación</Red>
            <br/>
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEs_dividends12.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEs_dividends12.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            {text(language,
                  {"ru": "Нажмите на значок карандаша рядом с секцией ",
                   "en": "Click on the pencil icon next to the cell ",
                   "es": "Haga clic en el ícono de lápiz al lado del casilla"
                   + " "})}
            <Teal>0588</Teal>
            <br/>
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEs_dividends13.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEs_dividends13.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            {text(language,
                  {"ru": "Далее нажмите на значок карандаша рядом с полем ",
                   "en": "Next, click on the pencil icon next to the field ",
                   "es": "A continuación, haga clic en el icono del lápiz " +
                   "situado junto al camp "})}
            {text(language,
                  {"ru": "Доходы, включаемые в налоговую базу по сбережениям - ",
                   "en": "Income included in the savings tax base - ",
                   "es": ""})}
            <Red>Rentas incluidas en la base del ahorro</Red>.
            <br/>
            <ImageZoom
              image={{
                src: '../images/es/agenciaTributariaEs_dividends14.png',
                alt: '',
                className: isMobile
                  ? classes.imgStyleMobile
                  : classes.imgStyle
              }}
              zoomImage={{
                src: '../images/es/agenciaTributariaEs_dividends14.png',
                alt: ''
              }}
              zoomMargin={0}></ImageZoom>
            <br/>
            {text(language,
                  {"ru": <span>
                           Заполните следующие поля:
                           <ul>
                             <li>
                               Уменьшенный чистый доход от движимого капитала,
                               полученного за рубежом, включенный в налоговую
                               базу по налогу на сбережения -&ensp;
                               <Red>Rendimientos netos reducidos del capital
                                 mobiliario obtenidos en el extranjero
                                 incluidos en la base del ahorro</Red>.
                             </li>
                             <li>
                               Налог, уплаченный за рубежом (*) -&ensp;
                               <Red>Impuesto satisfecho en el extranjero
                                 (*)</Red>.
                             </li>
                           </ul>
                         </span>,
                   "en": <span>
                           Fill in the following fields:
                           <ul>
                             <li>
                               Reduced net income from movable capital
                               obtained abroad included in the savings tax
                               base -&ensp;
                               <Red>Rendimientos netos reducidos del capital
                                 mobiliario obtenidos en el extranjero
                                 incluidos en la base del ahorro</Red>.
                             </li>
                             <li>
                               Tax paid abroad (*) -&ensp;
                               <Red>Impuesto satisfecho en el extranjero
                                 (*)</Red>.
                             </li>
                           </ul>
                         </span>,
                   "es": <span>
                           Complete los siguientes campos:
                           <ul>
                             <li>
                               <Red>Rendimientos netos reducidos del capital
                                 mobiliario obtenidos en el extranjero
                                 incluidos en la base del ahorro</Red>.
                             </li>
                             <li>
                               <Red>Impuesto satisfecho en el extranjero
                                 (*)</Red>.
                             </li>
                           </ul>
                         </span>})}
            {text(language,
                  {"ru": <span>
                           Заполните прочие доходы и расходы и нажимте
                           Подача декларации -&ensp;
                           <Red>Presentar declaración</Red>
                         </span>,
                   "en": <span>
                           Fill in other income and expenses and click on
                           Filing a tax return -&ensp;
                           <Red>Presentar declaración</Red>
                         </span>,
                   "es": <span>
                           Rellene otros ingresos y gastos y haga clic en
                           -&ensp;
                           <Red>Presentar declaración</Red>
                         </span>})}.
            <p/>
            {/* ---------------------------------------- */}
            <HelpHashLink to="/help/HelpSpain#HelpSpain_20">
              <Typography variant="h4" component="h2"
                          id="HelpSpain_20">
                {text(language,
                      {"ru": "Форма 720. Информационная налоговая декларация.",
                       "en": "Form 720. Informative Tax Return.",
                       "es": "Modelo 720. Declaración Informativa."})}
              </Typography>
            </HelpHashLink>
            <br/>
            {text(language,
                  {"ru": <span>
                           Форма 720 - это информативная декларация, в
                           которой налоговый резидент сообщает
                           о своем имуществе вне Испании, а именно
                           <ul>
                             <li>банковских счетах</li>
                             <li>инвестициях</li>
                             <li>недвижимости.</li>
                           </ul>
                         </span>,
                   "en": <span>
                           Form 720 is an informative return in which a tax
                           resident reports his or her assets outside Spain,
                           as follows
                           <ul>
                             <li>bank accounts</li>
                             <li>investments</li>
                             <li>real estates.</li>
                           </ul>
                         </span>,
                   "es": <span>
                           El modelo 720 es una declaración informativa en la
                           que un residente fiscal informa de sus bienes fuera
                           de España, en concreto
                           <ul>
                             <li>cuentas bancarias</li>
                             <li>inversiones</li>
                             <li>propiedades.</li>
                           </ul>
                         </span>})}
            {text(language,
                  {"ru": <span>
                           Ее необходимо подавать тем кто является налоговым
                           резидентом Испании и на момент фискального года на
                           31 декабря и имел в одной из перечисленных выше
                           категорий имущества больше чем на 50.000€.
                         </span>,
                   "en": <span>
                           It must be filed by those who are tax residents of
                           Spain and at the time of the fiscal year on
                           December 31 and had in one of the above categories
                           of property more than 50.000 €.
                         </span>,
                   "es": <span>
                           Deberán presentarla quienes tengan su residencia
                           fiscal en España y en el momento del ejercicio
                           fiscal, a 31 de diciembre, tuvieran más de 50.000
                           euros en alguna de las categorías de bienes
                           anteriores.
                         </span>})}
            <br/>
            {text(language,
                  {"ru": <span>
                           Период подачи с <Teal>1 января</Teal> по&ensp;
                           <Teal>31 марта</Teal>.
                           <br/>
                           Стоимость активов расчитывается на 31 декабря
                           отчетного года.
                         </span>,
                   "en": <span>
                           The submission period is <Teal>January 1</Teal>&ensp;
                           through <Teal>March 31</Teal>.
                           <br/>
                           The value of assets is calculated as of December 31
                           of the reporting year.
                         </span>,
                   "es": <span>
                           El periodo de presentación es del&ensp;
                           <Teal>1 de enero</Teal> al <Teal>31 de marzo</Teal>.
                           <br/>
                           El valor de los activos se calcula a 31 de
                           diciembre del año de referencia.
                         </span>})}
            {/*
               - However, taxpayers who obtain income exclusively
               from the following sources, under individual or
               joint taxation, are not required to declare:
               - Full income from movable capital (dividends
               from shares, interest from accounts, deposits or
               fixed-income securities, etc.) and capital gains
               (profits derived from redemptions of shares in
               Investment Funds, prizes for participation in
               contests or games, etc.), subject to withholding
               or payment on account, with the joint limit of
               1,600 euros per year.
               - 0027 - проценты, проценты на остаток, векселя?
               - Купоны по облигациям?
               - Чем отличаются 0327 vs 0328 vs 1624
               - T+ ?
               - Как считать обменный курс (курс на дату открытия)
               - Нужен ли формат sas. Аналогичен ли он для тестовой
               среды и реальной.
               - Насколько оправдано подавать каждую сделку отдельно или
               агрегированного варианта более, чем достаточно.
               - Можно ли указывать прочие расходы в
                 Administration and deposit fees
                 (Gastos de administración y depósito)
                 секции 0029. Какие именно расходы можно туды включать ?
             */}
          </div>
        </CardContent>
      </Card>
    );
  }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpSpain));
