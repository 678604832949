import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ImageZoom from 'react-medium-image-zoom';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    }
});

class HelpCashFlowReport extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>

                <div className={classes.text}>
                  <br/>
                  <HelpHashLink to="/help/CashFlowReport#1">
                    <Typography id="1" variant="h5" component="h2">
                        Как подать отчет о движении денежных средств
                    </Typography>
                  </HelpHashLink>

                  <p/>
                  Варианты подачи:
                  <ol>
                    <li>Полученный в результае генерации PDF файл распечатать, 
                        отнести в инспекцию или отправить заказным письмом с
                        описью.
                    </li>
                    <li>Внести данные из файла в личном кабинете 
                        налогоплательщика на <Link href="https://www.nalog.ru/">nalog.ru</Link>
                    </li>
                  </ol>

                  <Alert severity="warning">
                    Необходимо отметить, что при автоматической обработке 
                    отчетов Interactive Brokers, купля-продажа валюты 
                    (сделки Forex) учитывается как изменение стоимости иных
                    финансовых активов.
                  </Alert>

                  <p/>
                  Отчет о движении денежных средств, сгенерированный в формате
                  Excel, может быть использован только для копирования 
                  приведенных в нем значений при заполненении в личном кабинете 
                  налогоплательщика на <Link href="https://www.nalog.gov.ru">nalog.ru</Link>
                  <p/>
                  Для внесения данных через личный кабинет налогоплательщика
                  необходимо:
                  <ol>
                    <li>Открываете&ensp;
                        <Link href="https://www.nalog.ru"
                          className={classes.link}
                          target="_blank">
                            nalog.ru
                        </Link> Физические лица/Личный кабинет<br/>
                        <ImageZoom
                            image={{
                                src: '../images/nalog_ru/nalog_ru0.png',
                                alt: '',
                                className: 'img',
                                style: { width: '700px' }
                            }}
                            zoomImage={{
                                src: '../images/nalog_ru/nalog_ru0.png',
                                alt: ''
                            }}
                            zoomMargin={0}
                        />
                    </li>
                    <li>Каталог обращений<br/>
                        <ImageZoom
                            image={{
                                src: '../images/nalog_ru_cfr/nalog_ru_cfr1.png',
                                alt: '',
                                className: 'img',
                                style: { width: '700px' }
                            }}
                            zoomImage={{
                                src: '../images/nalog_ru_cfr/nalog_ru_cfr1.png',
                                alt: ''
                            }}
                            zoomMargin={0}
                        />
                    </li>
                    <li>Выбираете Информировать о счете в иностранном банке<br/>
                        <ImageZoom
                            image={{
                                src: '../images/nalog_ru_cfr/nalog_ru_cfr2.png',
                                alt: '',
                                className: 'img',
                                style: { width: '700px' }
                            }}
                            zoomImage={{
                                src: '../images/nalog_ru_cfr/nalog_ru_cfr2.png',
                                alt: ''
                            }}
                            zoomMargin={0}
                        />
                    </li>
                    <li>Далее - Отчет о движении средств по счету (вкладу)<br/>
                        <ImageZoom
                            image={{
                                src: '../images/nalog_ru_cfr/nalog_ru_cfr3.png',
                                alt: '',
                                className: 'img',
                                style: { width: '700px' }
                            }}
                            zoomImage={{
                                src: '../images/nalog_ru_cfr/nalog_ru_cfr3.png',
                                alt: ''
                            }}
                            zoomMargin={0}
                        />
                    </li>
                    <li>Нажимаете Сообщить о движении денежных средств и иных
                      финансовых активов по счетам (вкладам) (Раздел 1)
                      столько раз, сколько брокерских счетов имеется<br/>
                        <ImageZoom
                            image={{
                                src: '../images/nalog_ru_cfr/nalog_ru_cfr4.png',
                                alt: '',
                                className: 'img',
                                style: { width: '700px' }
                            }}
                            zoomImage={{
                                src: '../images/nalog_ru_cfr/nalog_ru_cfr4.png',
                                alt: ''
                            }}
                            zoomMargin={0}
                        />
                    </li>                    
                    <li>Заполняете поля в соответствии с данными полученных
                        документов.
                        <Alert severity="success">
                            При заполнении в личном кабинете налогоплательщика
                            будет отмечено, что стоимость иных финансовых 
                            активов на начало и конец отчетного периода с
                            учетом зачислений и списаний, не совпадают. И это
                            правильно: зачастую, они и не должны совпадать из-за
                            изменения стоимости активов в течение года.
                        </Alert>                        
                    </li>
                    <li>Подтверждаете и отправляте отчет.
                    </li>                    
                  </ol>

                </div>

              </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(HelpCashFlowReport);
