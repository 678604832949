import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { HashLink } from 'react-router-hash-link';

const styles = theme => ({
    headerlink: {
        color: 'black',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: "underline"
        }
    }
});

class HelpHashLink extends React.Component {
    scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    render() {
        const { classes } = this.props;

        return (
            <HashLink to={this.props.to}
                      smooth scroll={el => this.scrollWithOffset(el)}
                      className={classes.headerlink}>
              {this.props.children}
            </HashLink>
        );
    }
}

export default withStyles(styles)(HelpHashLink);
