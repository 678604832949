import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import ImageZoom from 'react-medium-image-zoom';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Code} from '../colors';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';
import DeclarationFlowData from './declarationFlowData';
import FlowGraph from '../flow/flowGraph';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { NavLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { isMobile } from '../common/deviceDetector';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    arrow: {
        marginBottom: '-6px'
    },
    text: {
      maxWidth: '800pt'
    },
    diagram: {
        height: 720,
        width: 800
    },
    diagramMobile: {
        zoom: "0.4",
        height: 720,
        width: 800
    },
    list1_Mobile: {
        paddingInlineStart: "14px",
        maxWidth: '800pt'
    },
    list2_Mobile: {
        paddingInlineStart: "28px",
        maxWidth: '800pt'
    },
    imgStyle: {
        width: '700px'
    },
    imgStyleMobile: {
        width: '100%'
    }
});

class HelpTaxInstructions extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>

                <HelpHashLink to="/help/FinParser#1" >
                  <Typography id="1" variant="h5" component="h2">
                    Общая схема генерации и подачи декларации
                  </Typography>
                </HelpHashLink>
                <p/>
                {isMobile
                 ? <TransformWrapper>
                     <TransformComponent>
                       <div className={classes.diagramMobile}>
                         <FlowGraph data={DeclarationFlowData}/>
                       </div>
                     </TransformComponent>
                   </TransformWrapper>
                 : <div className={classes.diagram}>
                     <FlowGraph data={DeclarationFlowData}/>
                   </div>
                }
                <p/>
                <HelpHashLink to="/help/FinParser#2" >
                  <Typography id="2" variant="h5" component="h2">
                    Генерация декларации
                  </Typography>
                </HelpHashLink>
                <br/>
                <div className={classes.text}>
                  Ниже приводится алгоритм подачи налоговой декларации 3-НДФЛ в
                  электронном виде на основании отчета брокера.
                </div>
                <ol className={isMobile ? classes.list1_Mobile : classes.text}>
                  <li>
                    Прежде всего необходимо получить отчет брокера, на
                    основании которого будет сгенерирована декларация.<br/>
                    В настоящий момент поддерживаются следующие форматы 
                    исходных данных:
                    <ul className={isMobile ? classes.list2_Mobile : classes.text}>
                      <li>Пользовательский отчет Interactive Brokers в формате 
                        CSV
                        (<Link component={NavLink}
                               to="/help/IBDeclarationReport">
                           инструкция по его получению
                         </Link>
                         <Link component={NavLink} 
                               to="/help/IBDeclarationReport">
                           <ArrowForwardIcon className={classes.arrow}
                                             fontSize="small"/>
                         </Link>).
                      </li>
                      <li>Брокерский отчет Freedom Finance Белиз в формате 
                        Excel
                        (<Link component={NavLink}
                               to="/help/FFReport">
                           инструкция по его получению
                         </Link>
                         <Link component={NavLink} 
                               to="/help/FFReport">
                           <ArrowForwardIcon className={classes.arrow}
                                             fontSize="small"/>
                         </Link>).
                      </li>
                      <li>Универсальный формат FinParser в Excel. К данному 
                        формату можно привести любой другой<br/> 
                        отчет, заполнив его самостоятельно
                        (<Link component={NavLink}
                               to="/help/FinParserFormat">
                           шаблон и инструкция по его заполнению
                         </Link>
                         <Link component={NavLink}
                               to="/help/FinParserFormat">
                           <ArrowForwardIcon className={classes.arrow}
                                             fontSize="small"/>
                         </Link>).
                      </li>
                    </ul>
                  </li>
                  <li>Открываете вкладку&ensp;
                    <Link component={NavLink}
                         to="/tax">
                        Расчет налогов
                    </Link> данного сервиса.<br/>
                    Выбираете формат исходных данных и прикрепляете
                    соответствующий ему файла отчета, полученный в предыдущем
                    шаге.
                    Далее, вы можете выбрать формат результата&ensp;
                    <Code>Налоговая декларация</Code> как в виде&ensp;
                    <Code>XML</Code>, что сделает возможным его подачу 
                    непосредственно на <Link href="https://www.nalog.ru"
                          className={classes.link}
                          target="_blank">
                      nalog.ru
                    </Link>, так и в виде&ensp;<Code>DCX</Code>. Во втором
                    случае вы сможете внести необходимые корректировки в 
                    программе&ensp;
                    <Link href="https://www.nalog.ru/rn77/program/5961249/"
                          className={classes.link}
                          target="_blank">
                        Декларация
                    </Link> и затем уже экспортировать его в <Code>XML</Code>.
                    <Alert severity="success" className={classes.text}>
                      При выборе форматов нет необходимости выбирать все 
                      доступные сразу. Можно выбрать только тот формат или
                      форматы, которые действительно необходимы. Оплата
                      всех форматов, помеченных синей галочкой происходит 
                      только один раз независимо от того какие отчеты были 
                      выбраны. В дальнейшем их можно генерировать сколько 
                      угодно раз в различных комбинациях и с различными 
                      настройками для того же самого исходного отчета
                      (отчетов) бесплатно (
                      <Link component={NavLink}
                               to="/help/HelpCyberSecurity">
                           подробнее
                      </Link>
                      <Link component={NavLink} 
                            to="/help/HelpCyberSecurity">
                        <ArrowForwardIcon className={classes.arrow}
                                          fontSize="small"/>
                      </Link>).
                    </Alert>                    
                    <br/>
                    <ImageZoom
                      image={{
                          src: '../images/finParser_taxForm1a.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                      }}
                      zoomImage={{
                          src: '../images/finParser_taxForm1a.png',
                          alt: ''
                      }}
                      zoomMargin={0}
                    />
                  </li>
                  <li>Заполняете прочие необходимые поля. 
                    <Alert severity="info" className={classes.text}>
                      Для удобства заполнения данных налогоплательщика можно
                      использовать кнопку Сохранить в соответствующей секции 
                      формы. При этом данные будут сохранены в файл на вашем 
                      компьютере. На сервере данные не сохраняются. Для загрузки
                      данных о налогоплательщике из файла нажмите кнопку 
                      Загрузить и выберите сохраненный ранее файл.
                    </Alert>
                    <br/>
                    <ImageZoom
                      image={{
                          src: '../images/finParser_taxForm1b.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                      }}
                      zoomImage={{
                          src: '../images/finParser_taxForm1b.png',
                          alt: ''
                      }}
                      zoomMargin={0}
                    />
                  </li>
                  <li>Нажимаете <Code>СГЕНЕРИРОВАТЬ ОТЧЕТЫ</Code>,
                    скачиваете полученный файл (в случае выбора нескольких 
                    форматов, это будет архив, включающий в себя все файлы 
                    выбранных форматов).
                  </li>
                  <li>Открываете&ensp;
                    <Link href="https://www.nalog.ru"
                          className={classes.link}
                          target="_blank">
                      nalog.ru
                    </Link> Физические лица/Личный кабинет<br/>
                    <ImageZoom
                      image={{
                          src: '../images/nalog_ru/nalog_ru0.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                      }}
                      zoomImage={{
                          src: '../images/nalog_ru/nalog_ru0.png',
                          alt: ''
                      }}
                      zoomMargin={0}
                    />
                  </li>
                  <li>Доходы и вычеты/Декларации<br/>
                    <ImageZoom
                      image={{
                          src: '../images/nalog_ru/nalog_ru1.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                      }}
                      zoomImage={{
                          src: '../images/nalog_ru/nalog_ru1.png',
                          alt: ''
                      }}
                      zoomMargin={0}
                    />
                  </li>
                  <li>Подать декларацию/Подать декларацию 3-НДФЛ/Загрузить готовую декларацию<br/>
                    <ImageZoom
                      image={{
                          src: '../images/nalog_ru/nalog_ru2.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                      }}
                      zoomImage={{
                          src: '../images/nalog_ru/nalog_ru2.png',
                          alt: ''
                      }}
                      zoomMargin={0}
                    />
                  </li>
                  <li>Указываете год за который она подается и выбираете 
                      сгенерированный XML файл декларации<br/>
                    <ImageZoom
                      image={{
                          src: '../images/nalog_ru/nalog_ru3.png',
                          alt: '',
                          className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                      }}
                      zoomImage={{
                          src: '../images/nalog_ru/nalog_ru3.png',
                          alt: ''
                      }}
                      zoomMargin={0}
                    />
                  </li>
                </ol>

                <div className={classes.text}>
                Кроме того, при подаче декларации должны быть также добавлены
                следующие документы:

                <ul className={isMobile ? classes.list1_Mobile : classes.text}>
                  <li>Отчет в долларах, выгруженный из личного кабинета
                    (на русском языке или с переводом)
                  </li>
                  <li>Справка о наличии брокерского счета (IB предоставляет
                    на русском в PDF; редко, но некоторые ФНС требуют с "живой"
                    печатью).
                  </li>
                  <li>Договор об открытии брокерского счета на английском и
                    перевод на русский язык (не нотариальный) – мы рекомендуем
                    не прикладывать, пока его не попросят.
                  </li>
                </ul>
                </div>
              </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(HelpTaxInstructions);
