import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import AssessmentIcon from '@material-ui/icons/Assessment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { Switch, Route, HashRouter, NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import axios from 'axios';
import UserMenu from './userMenu';
import Welcome from './welcome';
import AlertDialog from "./alertDialog";
import HelpGrid from './helpGrid';
import HelpGenericRF from './help/helpGenericRF';
import HelpSpain from './help/helpSpain';
import HelpEstonia from './help/helpEstonia';
import HelpTaxInstructions from './help/helpTaxInstructions';
import HelpFinParserFormat from './help/helpFinParserFormat';
import HelpIBDeclarationReport from './help/helpIBDeclarationReport';
import CharlesSchwabReport from './help/helpCharlesSchwabReport';
import HelpFFReport from './help/helpFFReport';
import HelpFFCyReport from './help/helpFFCyReport';
import HelpRevolutReport from './help/helpRevolutReport';
import HelpTradeStationReport from './help/helpTradeStationReport';
import HelpCitiReport from './help/helpCitiReport';
import HelpCyberSecurity from './help/helpCyberSecurity';
import HelpIBForex from './help/helpIBForex';
import HelpTaxOptimization from './help/helpTaxOptimization';
import HelpCashFlowReport from './help/helpCashFlowReport';
import HelpAlgorithm from './help/helpAlgorithm';
import HelpFAQ from './help/helpFAQ';
import TaxForm from './taxForm';
import UserDataForm from './userDataForm';
import AdminConsoleTabs from './adminConsoleTabs';
import Login from './login';
import CookieAcceptDialog from './cookieAcceptDialog';
import {getCookie, setCookie, getCookieBoolean} from './cookieLib';
import Register from './register';
import PaymentForm from './paymentForm';
import RenewPassword from './renewPassword';
import LeaveFeedbackForm from './leaveFeedbackForm';
import CountrySelection from './countrySelection';
import LanguageSelection from './languageSelection';
import CookieSettingsForm from './cookieSettingsForm';
import CookiePolicy from './policy/cookiePolicy';
import PrivacyPolicy from './policy/privacyPolicy';
import ScrollToTop from './common/scrollToTop';
import { text } from "./languageSelection";
import { caseCountries } from "./countrySelection";
import { connect } from "react-redux";
import { setLoginOk } from './state/loginOkSlice';
import { setAccessLevel } from './state/accessLevelSlice';
import { isMobile } from './common/deviceDetector';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
        position: "relative",
        zIndex: "1"
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    mobileMain: {
      width: "100%"
    }
});

class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        this.checkLogin(() => {
            // For page reload.
            this.forceUpdate();
        });
    }

    scrollElement() {
        // wait for a paint before running scrollHeight dependent code.
        window.requestAnimationFrame(function() {
            const yOffset = -80;
            let hashes = window.location.hash.split("#");
            if (hashes.length === 3) {
                let target = document.getElementById(hashes.pop());
                if (target != null) {
                    window.scrollTo({ top: target.offsetTop + yOffset,
                                      behavior: 'smooth' });
                }
            }
        });
    }

    componentDidMount() {
        this.scrollElement();
    }

    componentDidUpdate() {
        this.scrollElement();
    }

    checkLogin(callback) {
        const config = { headers: { 'Content-Type': 'application/json',
                                    'X-Requested-With': 'HttpRequest',
                                    'Csrf-Token': 'nocheck'},
                         timeout: 0};
        axios.post("/checkLogin", null, config)
            .then( (response) => {
                if (response.status === 200) {
                    this.props.setLoginOk(true);
                    this.props.setAccessLevel(response.data.access);
                } else {
                    this.props.setLoginOk(false);
                    this.props.setAccessLevel("none");
                }
                callback();
            })
            .catch( (error) => {
                this.props.setLoginOk(false);
                this.props.setAccessLevel("none");
            });
    }

    checkMainMenuOpen = () => {
        let defaultValue = "true";
        return getCookieBoolean("mainMenuOpen", defaultValue);
    }

    state = {
        showVersionInfoDialog: false,
        version: "",
        open: isMobile ? false: this.checkMainMenuOpen(),
    };

    handleVersionInfo = event => {
        axios.get("/api/version")
            .then( (response) => {
                this.setState({ version: response.data });
                this.setState({ showVersionInfoDialog: true });
            })
            .catch( (error) => {
                this.setState({ version: "" });
                console.log(error);
            });
    };

    setOpen = value => {
        this.setState({ open: value });
    };

    handleDrawerOpen = () => {
        setCookie("mainMenuOpen", "true");
        this.setOpen(true);
    };

    handleDrawerClose = () => {
        setCookie("mainMenuOpen", "false");
        this.setOpen(false);
    };

    toggleDrawer = (openOrClose) => (event) => {
      if ((event && event.type === 'keydown' &&
           (event.key === 'Tab' || event.key === 'Shift'))
          || !isMobile) {
        return;
      }

      this.setState({ open: openOrClose });
    };

    render() {
        const { classes } = this.props;
        let language = this.props.language.value;
        let residenceCountry = this.props.residence.value;

        return (
            <HashRouter hashType="noslash">
            <div className={classes.root}>
              <CssBaseline />
              <AppBar
                color="default"
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: this.state.open,
                })}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton,
                                    this.state.open && classes.hide)} >
                    <MenuIcon />
                  </IconButton>
                  <Link component={NavLink}
                        to="/welcome">
                    <img src="/images/finParserLogo.png" height='32px'
                        alt='logo'/>
                  </Link>
                  <Typography variant="h5" className={classes.title} noWrap>
                    &nbsp;FinParser
                  </Typography>
                  {isMobile ? "" : <CountrySelection/>}
                  {isMobile ? "" : <LanguageSelection/>}
                  {this.props.loginOk.value &&
                   this.props.accessLevel.value === "admin"
                   ? <Tooltip title="Версия" enterDelay={500}>
                       <IconButton color="inherit"
                                   onClick={this.handleVersionInfo}>
                         <InfoOutlinedIcon />
                       </IconButton>
                     </Tooltip>
                   : ""}
                  <UserMenu parent={this}/>
                </Toolbar>
              </AppBar>

              <SwipeableDrawer
                className={classes.drawer}
                variant={isMobile ? "temporary" : "persistent"}
                anchor="left"
                open={this.state.open}
                onClick={this.toggleDrawer(false)}
                onClose={this.toggleDrawer(false)}
                onOpen={this.toggleDrawer(true)}
                classes={{
                    paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={this.handleDrawerClose}>
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
                <Divider />
                <List>
                  <ListItem button
                            key={"welcome"}
                            component={NavLink}
                            to="/welcome">
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={text(language,
                                    {"ru": "Главная страница",
                                     "en": "Home page",
                                     "es": "Página de inicio"})} />
                  </ListItem>
                  {caseCountries(
                      residenceCountry,
                      ["spain", "russia"],
                      <ListItem button
                                key={"tax"}
                                component={NavLink}
                                to="/tax">
                        <ListItemIcon>
                          <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={text(language,
                                        {"ru": "Расчет налогов",
                                         "en": "Tax calculation",
                                         "es": "Cálculo de impuestos"})}/>
                      </ListItem>)}

                  {/* <ListItem button */}
                  {/*           key={"customReport"} */}
                  {/*           component={NavLink} */}
                  {/*           to="/customReport"> */}
                  {/*   <ListItemIcon> */}
                  {/*     <AssessmentIcon /> */}
                  {/*   </ListItemIcon> */}
                  {/*   <ListItemText primary={"Парсинг отчета"} /> */}
                  {/* </ListItem> */}
                </List>
                {this.props.loginOk.value
                 ? <div>
                     <Divider />
                     <List>
                       <ListItem button
                                 key={"userData"}
                                 component={NavLink}
                                 to="/userData">
                         <ListItemIcon>
                           <PersonIcon />
                         </ListItemIcon>
                         <ListItemText
                           primary={text(language,
                                         {"ru": "Личный кабинет",
                                          "en": "Account info",
                                          "es": "Informacion de cuenta"})}/>
                       </ListItem>
                     </List>
                   </div>
                 : ""}
                {this.props.loginOk.value &&
                 this.props.accessLevel.value === "admin"
                  ? <ListItem button
                              key={"adminConsole"} 
                              component={NavLink} 
                              to="/adminConsole">
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={text(language,
                                      {"ru": "Консоль администратора",
                                       "en": "Admin Console",
                                       "es": "Consola de administración"})} />
                    </ListItem>
                  : ""}
                <Divider />
                <List>
                  <ListItem button
                            key={"help"}
                            component={NavLink}
                            to="/cookieSettings">
                    <ListItemIcon>
                      <img src="/images/cookies.png" height='20px'
                           alt='cookies'/>
                    </ListItemIcon>
                    <ListItemText
                      primary={text(language,
                                    {"ru": "Cookies",
                                     "en": "Cookies",
                                     "es": "Cookies"})}/>
                  </ListItem>
                  <ListItem button
                            key={"help"}
                            component={NavLink}
                            to="/help">
                    <ListItemIcon>
                      <HelpIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={text(language,
                                    {"ru": "Справка",
                                     "en": "Help",
                                     "es": "Ayuda"})}/>
                  </ListItem>
                </List>
              </SwipeableDrawer>

              <main
                className={isMobile ? classes.mobileMain
                           : clsx(classes.content, {
                             [classes.contentShift]: this.state.open,
                })}>
                <div className={classes.drawerHeader} />

                <ScrollToTop>
                <Switch>
                  <Route path="/welcome" component={Welcome}/>
                  <Route exact path="/tax" render={(props) =>
                      <TaxForm {...props}
                               context={this.context}
                               parent={this}/>} />
                  <Route path="/userData" component={UserDataForm}/>
                  <Route path="/adminConsole" component={AdminConsoleTabs}/>
                  <Route path="/cookieSettings" component={CookieSettingsForm}/>
                  <Route path="/privacyPolicy" component={PrivacyPolicy}/>
                  <Route path="/cookiePolicy" component={CookiePolicy}/>

                  <Route path="/help/GenericRF" component={HelpGenericRF}/>
                  <Route path="/help/HelpSpain" component={HelpSpain}/>
                  <Route path="/help/HelpEstonia" component={HelpEstonia}/>
                  <Route path="/help/TaxInstructions" component={HelpTaxInstructions}/>
                  <Route path="/help/FinParserFormat" component={HelpFinParserFormat}/>
                  <Route path="/help/IBDeclarationReport"
                         component={HelpIBDeclarationReport}/>
                  <Route path="/help/CharlesSchwabReport"
                         component={CharlesSchwabReport}/>
                  <Route path="/help/RevolutReport"
                         component={HelpRevolutReport}/>
                  <Route path="/help/TradeStationReport"
                         component={HelpTradeStationReport}/>
                  <Route path="/help/FFReport"
                         component={HelpFFReport}/>
                  <Route path="/help/FFCyReport"
                         component={HelpFFCyReport}/>
                  <Route path="/help/CitiReport"
                         component={HelpCitiReport}/>

                  <Route path="/help/HelpCyberSecurity"
                         component={HelpCyberSecurity}/>
                  <Route path="/help/IBForex"
                         component={HelpIBForex}/>
                  <Route path="/help/TaxOptimization"
                         component={HelpTaxOptimization}/>
                  <Route path="/help/CashFlowReport"
                         component={HelpCashFlowReport}/>
                  <Route path="/help/HelpAlgorithm"
                         component={HelpAlgorithm}/>
                  <Route path="/help/FAQ"
                         component={HelpFAQ}/>

                  <Route path="/help" component={HelpGrid}/>

                  <Route path="/login" render={(props) =>
                      <Login {...props}
                             parent={this}/>}/>
                  <Route path="/register" component={Register}/>
                  <Route path="/renewPassword" component={RenewPassword}/>
                  <Route path="/leaveFeedback" component={LeaveFeedbackForm}/>
                  <Route path="/payment" render={(props) =>
                      <PaymentForm {...props}
                               context={this.context}
                               parent={this}/>}/>

                  <Route path="/" component={Welcome}/>
                </Switch>
                </ScrollToTop>
              </main>

              <AlertDialog
                open={this.state.showVersionInfoDialog}
                propertyName='showVersionInfoDialog'
                parent={this}
                title={text(language,
                            {"ru": "Версия",
                             "en": "Version",
                             "es": "Versión"})}
                message={this.state.version}/>

              <CookieAcceptDialog/>
            </div>
            </HashRouter>
        );
    }
}

function mapStateToProps(state) {
    const residence = state.residence;
    const language = state.language;
    const loginOk = state.loginOk;
    const accessLevel = state.accessLevel;
    return {
        residence,
        language,
        loginOk,
        accessLevel
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
      setLoginOk: (value) => dispatch(setLoginOk(value)),
      setAccessLevel: (value) => dispatch(setAccessLevel(value)),
  };
};

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(MainMenu));
