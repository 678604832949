import React from 'react';
import './styles.css';

export class LightRed extends React.Component {
    render() {
        return (
            <span className="light-red">
              {this.props.children}
            </span>
        );
    }
}

export class Red extends React.Component {
    render() {
        return (
            <span className="red">
              {this.props.children}
            </span>
        );
    }
}

export class Pink extends React.Component {
    render() {
        return (
            <span className="pink">
              {this.props.children}
            </span>
        );
    }
}

export class Purple extends React.Component {
    render() {
        return (
            <span className="purple">
              {this.props.children}
            </span>
        );
    }
}

export class LightIndigo extends React.Component {
    render() {
        return (
            <span className="light-indigo">
              {this.props.children}
            </span>
        );
    }
}

export class Indigo extends React.Component {
    render() {
        return (
            <span className="indigo">
              {this.props.children}
            </span>
        );
    }
}

export class Blue extends React.Component {
    render() {
        return (
            <span className="blue">
              {this.props.children}
            </span>
        );
    }
}

export class Teal extends React.Component {
    render() {
        return (
            <span className="teal">
              {this.props.children}
            </span>
        );
    }
}

export class Green extends React.Component {
    render() {
        return (
            <span className="green">
              {this.props.children}
            </span>
        );
    }
}

export class Lime extends React.Component {
    render() {
        return (
            <span className="lime">
              {this.props.children}
            </span>
        );
    }
}

export class Yellow extends React.Component {
    render() {
        return (
            <span className="yellow">
              {this.props.children}
            </span>
        );
    }
}

export class Amber extends React.Component {
    render() {
        return (
            <span className="amber">
              {this.props.children}
            </span>
        );
    }
}

export class DeepOrange extends React.Component {
    render() {
        return (
            <span className="deep-orange">
              {this.props.children}
            </span>
        );
    }
}

export class Brown extends React.Component {
    render() {
        return (
            <span className="brown">
              {this.props.children}
            </span>
        );
    }
}

export class Gray extends React.Component {
    render() {
        return (
            <span className="gray">
              {this.props.children}
            </span>
        );
    }
}

export class BlueGray extends React.Component {
    render() {
        return (
            <span className="blue-gray">
              {this.props.children}
            </span>
        );
    }
}

export class Code extends React.Component {
    render() {
        return (
            <span className="code">
              {this.props.children}
            </span>
        );
    }
}
