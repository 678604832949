import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';

export default memo(({ data, isConnectable }) => {
    return (
      <>
        <div>
            {data.label}
        </div>
        <Handle
          type="target"
          position="top"
          id="top"
          style={{ width: '0px',
                   height: '0px',
                   opacity: 0 }}
          isConnectable={isConnectable}
        />        
        <Handle
          type="source"
          position="bottom"
          id="bottom"
          style={{ width: '0px',
                   height: '0px',
                   opacity: 0 }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position="bottom"
          id="bottom-left"
          style={{ width: '0px',
                   height: '0px',
                   left: '15%',
                   opacity: 0 }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position="bottom"
          id="bottom-right"
          style={{ width: '0px',
                   height: '0px',
                   left: '85%',
                   opacity: 0 }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position="right"
          id="r"
          style={{ width: '0px',
                   height: '0px',
                   opacity: 0 }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position="right"
          id="r"
          style={{ width: '0px',
                   height: '0px',
                   opacity: 0 }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position="left"
          id="l"
          style={{ width: '0px',
                   height: '0px',
                   opacity: 0 }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position="left"
          id="l"
          style={{ width: '0px',
                   height: '0px',
                   opacity: 0 }}
          isConnectable={isConnectable}
        />
      </>
    );
  });