// -*- mode: rjsx-mode; js2-basic-offset: 4; -*-
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';
import {getCookieBoolean, setCookie} from './cookieLib';
import { text } from "./languageSelection";
import { connect } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CookieAcceptDialog extends React.Component {
    state = {
        show: function() {
            var isShow = false;
            switch (getCookieBoolean("acceptCookies")) {
            case true:
                isShow = false;
                break;
            case false:
                isShow = false;
                break;
            default:
                isShow = true;
            }
            return isShow;
        }()
    }

    handleClose = (accept) => () => {
        if (accept) {
          setCookie("acceptCookies", "true", true);
        } else {
          setCookie("acceptCookies", "false", true);
        }
        this.setState({show: false});
    };

    render() {
        let language = this.props.language.value;

        return (
            <div>
              <Dialog
                open={this.state.show}
                TransitionComponent={Transition}
                keepMounted
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                onClose={this.handleClose(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description"
                                     style={{textAlign: 'justify'}}>
                    {text(language,
                          {"ru": <span>
                                   Для персонализации контента, а также для
                                   обеспечения общей безопасности сайт
                                   использует файлы cookie. Нажимая кнопку
                                   "Подтвердить", вы соглашаетесь с политикой
                                   использования cookie данного сервиса.
                                   В ином случае будут использоваться только
                                   неободимые cookies.
                                   Опциональные cookie включают в себя выбор
                                   языка, а также пользовательские настройки
                                   форм генерации отчетов для налоговой
                                   декларации.
                                   Ознакомиться с политикой использования
                                   cookie вы можете по&ensp;
                                   <Link component={NavLink}
                                         to="/cookiePolicy">ссылке</Link>.
                                   Изменить настройки в дальнейшем можно
                                   выбрав Cookies в главном меню.
                                 </span>,
                           "en": <span>
                                   To personalize content, as well as to ensure
                                   general security, the site uses cookies. By
                                   clicking the "Accept" button, you agree to
                                   the cookie policy of this service.
                                   Only essential cookies will be used otherwise.
                                   Optional cookies include language
                                   selection, as well as customer settings
                                   for generation reporting forms for
                                   tax return.
                                   You can read the cookie policy&ensp;
                                   <Link component={NavLink}
                                         to="/cookiePolicy">here</Link>.
                                   You can change your settings later by
                                   selecting Cookies in the main menu.
                                 </span>,
                           "es": <span>
                                   Para personalizar el contenido, así como para
                                   garantizar la seguridad general, el sitio
                                   utiliza cookies. Al hacer clic en el botón
                                   "Aceptar", acepta la política de cookies
                                   de este servicio.
                                   De lo contrario, solo se utilizarán cookies
                                   esenciales.
                                   Las cookies opcionales incluyen la
                                   selección de idioma, así como la
                                   configuración del cliente para la
                                   generación de formularios de
                                   declaración de impuestos.
                                   Puedes leer la política de cookies&ensp;
                                   <Link component={NavLink}
                                         to="/cookiePolicy">aquí</Link>.
                                   Puede cambiar su configuración más tarde
                                   seleccionando Cookies en el menú principal.
                                 </span>})}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose(false)}>
                    {text(language,
                          {"ru": "Отмена",
                           "en": "Reject",
                           "es": "Rechazar"})}
                  </Button>
                  <Button onClick={this.handleClose(true)} color="primary">
                    {text(language,
                          {"ru": "Подтвердить",
                           "en": "Accept",
                           "es": "Aceptar"})}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default connect(mapStateToProps)(CookieAcceptDialog);
