// -*- mode: rjsx-mode; js2-basic-offset: 4; -*-
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { NavLink } from 'react-router-dom';
import Center from 'react-center';
import axios from 'axios';
import {getCookie, setCookie} from './cookieLib';
import { setResidence } from './state/residenceSlice';
import { setLanguage } from './state/languageSlice';
import { buildNewUrl, getLanguage,
         getResidenceCountry, langsPerResidenceCountry } from './common/urlLib';
import { connect } from "react-redux";

const styles = {
    residence: {
        textAlign: "left"
    },
    residenceSelect: {
        marginLeft: 5,
        width: 140,
    },
    flag: {
        marginRight: 10,
    }
};

const langsData = {
    "en": {
        img: '/images/flags/Flag_of_the_United_Kingdom.svg',
        name: "English"
    },
    "es": {
        img: '/images/flags/Bandera_de_España.svg',
        name: "Español"
    },
    "ru": {
        img: '/images/flags/Flag_of_Russia.svg',
        name: "Русский"
    }
};

class LanguageSelection extends React.Component {

    constructor(props) {
        super(props);
        this.removeSupportChat = this.removeSupportChat.bind(this);
        this.loadSupportChat = this.loadSupportChat.bind(this);

        this.props.setResidence(getResidenceCountry());
        this.props.setLanguage(getLanguage());
        setCookie("language", getLanguage());
    }

    removeSupportChat = () => {
        const scripts = Array.from(document.querySelectorAll('script'))
              .filter(x => x.src.includes("tawk.to"));
        scripts.map(s => s.remove());
    }

    loadSupportChat = () => {
        var path;
        const language = getLanguage();
        switch (language) {
        case "en":
            path = "https://embed.tawk.to/617aeb1d86aee40a5738db87/1fj42crgh";
            break;
        case "es":
            path = "https://embed.tawk.to/617aeb1d86aee40a5738db87/1hbhrckt0";
            break;
        case "ru":
            path = "https://embed.tawk.to/617aeb1d86aee40a5738db87/1hbd04g92";
            break;
        default:
            path = "https://embed.tawk.to/617aeb1d86aee40a5738db87/1fj42crgh";
            break;
        }
        // color: #3f51b5
        // EN: Customer support
        // Hello!
        // Do you need some help? Find answers in the Help section or
        // start a conversation:
        // New conversation
        // Type question here and press "Enter"...
        // --
        // ES: Atención al cliente
        // ¡Hola!
        // ¿Necesitas ayuda? Encuentre respuestas en la sección Ayuda
        // o inicie una conversación:
        // Nueva conversación
        // Escriba la pregunta aquí y presione "Entrar"...
        // --
        // RU: Поддержка
        // Привет!
        // Нужна помощь? Найдите ответы в нашем справочном центре
        // или напишите в чат. Если вы не получили ответ в чате, напишите
        // на support@finparser.com.
        // Новая беседа
        // Наберите текст здесь и нажмите "Enter"...
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        var s1 = document.createElement("script");
        var s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = path;
        s1.charset = "UTF-8";
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        // document.head.appendChild(script);
    }

    componentDidMount() {
        this.loadSupportChat();
    }

    changeLanguage = (language) => {
        this.props.setLanguage(language);
        setCookie("language", language);
        if (this.props.language.value != language) {
            window.location.href = buildNewUrl({language: language});
        }
    }

    handleChangeLanguage = event => {
        let language = event.target.value;
        this.changeLanguage(language);
    };

    render() {
        const { classes } = this.props;
        let residenceCountry = this.props.residence.value;
        let language =
            langsPerResidenceCountry[residenceCountry].includes(
                this.props.language.value)
            ? this.props.language.value
            : langsPerResidenceCountry[residenceCountry][0];

        return (
            <FormControl>
              <table>
                <tr>
                  <td>
                    <Typography variant="body2"
                                align='center'
                                className={classes.residence}>
                      {(language === "ru")
                       ? "Язык"
                       : (language == "en")
                       ? "Language"
                       : (language == "es")
                       ? "Idioma"
                       : ""}
                    </Typography>
                  </td>
                  <td>
                    <Select className={classes.residenceSelect}
                            value={language}
                            onChange={this.handleChangeLanguage}>
                      {langsPerResidenceCountry[residenceCountry].map(l => (
                          <MenuItem value={l}>
                            <img src={langsData[l].img}
                                 className={classes.flag}
                                 width='20'
                                 alt=''/>
                            {langsData[l].name}</MenuItem>
                      ))}
                    </Select>
                  </td>
                </tr>
              </table>
            </FormControl>
        );
    }
}

function mapStateToProps(state) {
    const residence = state.residence;
    const language = state.language;
    return {
        residence,
        language
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
      setResidence: (value) => dispatch(setResidence(value)),
      setLanguage: (value) => dispatch(setLanguage(value))
  };
};

export function text(language, translationsMap) {
    let result = translationsMap[language];
    if (result == undefined) {
        return translationsMap['en'];
    } else {
        return translationsMap[language];
    }
}

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(LanguageSelection));
