import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Center from 'react-center';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import {range} from './miscLib';

const styles = {
  table: {
    minWidth: 650,
  },
  loader: {
    padding: '200px 0',
  },
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class AdminConsoleFeedback extends React.Component {
  constructor(props) {
    super(props);

    axios.get('/feedback/list')
      .then((response) => {
        this.setState({
          rows: response.data
        }, () => {
          this.forceUpdate();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  state = {
    rows: null
  };

  getStars(stars) {
    return range(1, stars).map(s =>
      "★"
    ).join('');
  }

  render() {
    const { classes } = this.props;

    return (
      <Center>
        <table>
          <tr><td>
              </td></tr>
          <tr><td>
                {(this.state.rows == null)
                 ? <div className={classes.loader}>
                     <CircularProgress size={90} />
                   </div>
                 : <Table className={classes.table}
                          size="small"
                          aria-label="Отзывы">
                     <TableHead>
                       <TableRow>
                         <TableCell>Дата</TableCell>
                         <TableCell>Пользователь представился как</TableCell>
                         <TableCell>Пользователь зарегистрирован</TableCell>
                         <TableCell>Оценка</TableCell>
                         <TableCell>Текст отзыва</TableCell>
                       </TableRow>
                     </TableHead>
                     <TableBody>
                       {(this.state.rows.length > 0)
                        ? this.state.rows.map((row) => (
                          <StyledTableRow key={row.id}>
                            <TableCell>{row.date}</TableCell>
                            <TableCell>{row.userName + " " + row.email}</TableCell>
                            <TableCell>{row.user == null
                                        ? ""
                                        : (row.user.login + " " +
                                           row.user.firstName + " " +
                                           row.user.lastName + " " +
                                           row.user.email)}</TableCell>
                            <TableCell>{this.getStars(row.stars)}</TableCell>
                            <TableCell>{row.text}</TableCell>
                          </StyledTableRow>
                        ))
                        : ""}
                     </TableBody>
                   </Table>}
              </td></tr>
        </table>
      </Center>
    );
  }
}

export default withStyles(styles)(AdminConsoleFeedback);
