import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { NavLink } from 'react-router-dom';
import Center from 'react-center';
import axios from 'axios';
import {getCookie, setCookie} from './cookieLib';
import { setResidence } from './state/residenceSlice';
import { setLanguage } from './state/languageSlice';
import { text } from "./languageSelection";
import { buildNewUrl, getResidenceCountry } from './common/urlLib';
import { connect } from "react-redux";

const styles = {
    residence: {
        textAlign: "left"
    },
    residenceSelect: {
        marginLeft: 5,
        width: 140,
    },
    flag: {
        marginRight: 10,
    }
};

const availableResidenceCountries = ['ru', 'ee', 'es'];
const availableLanguages = ['ru', 'en', 'es'];

class CountrySelection extends React.Component {

    constructor(props) {
        super(props);
        setCookie("residenceCountry", getResidenceCountry());
        this.props.setResidence(getResidenceCountry());
    }

    changeResidenceCountry = (residenceCountry) => {
      this.props.setResidence(residenceCountry);
      setCookie("residenceCountry", residenceCountry);
      if (this.props.residence.value != residenceCountry) {
            window.location.href = buildNewUrl({residenceCountry: residenceCountry});
      }
    }

    changeLanguage = (language) => {
        this.props.setLanguage(language);
        setCookie("language", language);
    }

    handleChangeResidenceCountry = event => {
        let residenceCountry = event.target.value;
        this.changeResidenceCountry(residenceCountry);
    };

    render() {
        const { classes } = this.props;
        let residenceCountry = this.props.residence.value;
        let language = this.props.language.value;

        return (
            <FormControl>
              <table>
                <tr>
                  <td>
                    <Typography variant="body2"
                                align='center'
                                className={classes.residence}>
                      {text(language,
                            {"ru": <div>Налоговое<br/>резидентство</div>,
                             "en": <div>Tax<br/>residency</div>,
                             "es": <div>Residencia<br/>fiscal</div>})}
                    </Typography>
                  </td>
                  <td>
                    <Select className={classes.residenceSelect}
                            value={residenceCountry}
                            onChange={this.handleChangeResidenceCountry}>
                      <MenuItem value={'none'}>
                        {text(language,
                              {"ru": "Не определено",
                               "en": "Undefined",
                               "es": "Indefinido"})}
                        </MenuItem>
                      <MenuItem value={'earth'}>
                        <img src='/images/flags/International_Flag_of_Planet_Earth.svg'
                             className={classes.flag}
                             width='20'
                             alt=''/>
                        {text(language,
                              {"ru": "Любое",
                               "en": "Any",
                               "es": "Cualquier"})}
                      </MenuItem>
                      <MenuItem value={'spain'}>
                        <img src='/images/flags/Bandera_de_España.svg'
                             className={classes.flag}
                             width='20'
                             alt=''/>
                        {text(language,
                              {"ru": "Испания",
                               "en": "Spain",
                               "es": "España"})}
                      </MenuItem>
                      <MenuItem value={'russia'}>
                        <img src='/images/flags/Flag_of_Russia.svg'
                             className={classes.flag}
                             width='20'
                             alt=''/>
                        {text(language,
                              {"ru": "Россия",
                               "en": "Russia",
                               "es": "Rusia"})}
                      </MenuItem>
                      <MenuItem value={'estonia'}>
                        <img src='/images/flags/Flag_of_Estonia.svg'
                             className={classes.flag}
                             width='20'
                             alt=''/>
                        {text(language,
                              {"ru": "Эстония",
                               "en": "Estonia",
                               "es": "Estonia"})}
                      </MenuItem>
                    </Select>
                  </td>
                </tr>
              </table>
            </FormControl>
        );
    }
}

function mapStateToProps(state) {
    const residence = state.residence;
    const language = state.language;
    return {
        residence,
        language
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
      setResidence: (value) => dispatch(setResidence(value)),
      setLanguage: (value) => dispatch(setLanguage(value)),
  };
};

export function switchCountry(country, switchMap) {
    return switchMap[country];
}

export function caseCountries(country, countriesList, data) {
    if (countriesList.includes("all") || countriesList.includes(country)) {
        return data;
    } else {
        return <></>;
    }
}

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(CountrySelection));
