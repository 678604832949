import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { NavLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Center from 'react-center';
import axios from 'axios';
import { connect } from "react-redux";
import { text } from "./languageSelection";
import { isMobile } from './common/deviceDetector';

const styles = theme => ({
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    card: {
        width: '500px',
        maxWidth: '500px',
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
    },
    cardMobile: {
        width: '500px',
        maxWidth: '500px',
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        marginBottom: '60px'
    },
    buttonBottom: {
      marginTop: '10px',
      float: 'right',
      width: 200
    }
});

class UserDataForm extends React.Component {
    constructor(props) {
        super(props);

        axios.get('/userData')
            .then((response) => {
                this.setState({
                    login: response.data.login,
                    email: response.data.email,
                    registerDate: response.data.registerDate,
                    lastName: response.data.lastName,
                    firstName: response.data.firstName,
                    middleName: response.data.middleName,
                    balanceList: response.data.balanceList,
                    bonus: response.data.bonus,
                    subscribed: response.data.subscribed
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    state = {
        login: ' ',
        email: ' ',
        registerDate: ' ',
        lastName: ' ',
        firstName: ' ',
        middleName: ' ',
        balanceList: [],
        bonus: 0,
        subscribed: true
    };

    handleSubscribedChange = event => {
      let subscribed = event.target.checked;
      let name = event.target.name;
      let actionUrl = subscribed ? "/subscribe" : "/unsubscribe";
      return axios.get(actionUrl)
      .then((response) => {
        this.setState({[name]: subscribed });
      })
      .catch((error) => {
          console.log(error);
      });
    };

    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
          <Center>
            <Card className={isMobile ? classes.cardMobile : classes.card}>
              <CardContent>
                <Container component="main" maxWidth="xs">
                  <CssBaseline />
                  <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                      {text(
                          language,
                          {"ru": "Личный кабинет",
                           "en": "Account info",
                           "es": "Informacion de cuenta"})}
                    </Typography>
                    <form className={classes.form} noValidate>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="login"
                        label={text(
                            language,
                            {"ru": "Логин",
                             "en": "Login",
                             "es": "Login"})}
                        name="login"
                        value={this.state.login}
                        InputProps={{
                            readOnly: true,
                        }}/>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="registerDate"
                        label={text(
                            language,
                            {"ru": "Дата регистрации",
                             "en": "Date of registration",
                             "es": "Fecha de registro"})}
                        value={this.state.registerDate}
                        InputProps={{
                            readOnly: true,
                        }}/>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="lastName"
                        label={text(language,
                                    {"ru": "Фамилия",
                                     "en": "Surname",
                                     "es": "Apellido"})}
                        value={this.state.lastName}
                        InputProps={{
                            readOnly: true,
                        }}/>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="firstName"
                        label={text(language,
                                    {"ru": "Имя",
                                     "en": "Name",
                                     "es": "Nombre"})}
                        value={this.state.firstName}
                        InputProps={{
                            readOnly: true,
                        }}/>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="middleName"
                        label={text(language,
                                    {"ru": "Отчество",
                                     "en": "Middle name",
                                     "es": "Patronímico"})}
                        value={this.state.middleName}
                        InputProps={{
                            readOnly: true,
                        }}/>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email"
                        value={this.state.email}
                        InputProps={{
                            readOnly: true,
                        }}/>
                      <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.subscribed}
                              onChange={this.handleSubscribedChange}
                              name="subscribed"
                              color="primary"/>
                          }
                          label={text(language,
                                      {"ru": "Подписаться на рассылку",
                                       "en": "Subscribe to newsletter",
                                       "es": "Suscríbete al boletín"})}/>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="bonus"
                        label="Бонусов"
                        value={this.state.bonus}
                        InputProps={{
                            readOnly: true,
                        }}/>
                      {(this.state.balanceList.length > 0)
                       ? this.state.balanceList.map(
                         b => <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={text(language,
                                            {"ru": "На счету",
                                             "en": "Balance",
                                             "es": "Balance"})}
                                value={b.amount + " " + b.currencySymbol}
                                InputProps={{
                                  readOnly: true,
                                }}/>)
                       : <TextField
                           variant="outlined"
                           margin="normal"
                           fullWidth
                           label={text(language,
                                       {"ru": "На счету",
                                        "en": "Balance",
                                        "es": "Balance"})}
                           value={0}
                           InputProps={{
                             readOnly: true,
                           }}/>
                      }
                      <Button
                        className={classes.buttonBottom}
                        component={NavLink} to="/payment?count=1"
                        variant='contained'
                        color="primary">
                        {text(language,
                              {"ru": "Пополнить",
                               "en": "Make a deposit",
                               "es": "Recarga tu cuenta"})}
                      </Button>
                    </form>
                  </div>
                </Container>
              </CardContent>
            </Card>
          </Center>
        );
    }
}

function mapStateToProps(state) {
    const language = state.language;
    return {
        language,
    };
}

export default withStyles(styles)(connect(mapStateToProps)(UserDataForm));
