import axios from 'axios';

export function formatNumber(num) {
    return Math.round(num).toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

export function robokassa(accessLevel) {
    this.setState({ disablePaymentButton: true });
    const description = this.getDescription();
    const sumForm = new FormData();
    sumForm.append('sum', this.getSum());
    sumForm.append('description', description);
    const sumConfig = { headers: { 'Content-Type': 'application/json',
                                'X-Requested-With': 'HttpRequest',
                                'Csrf-Token': 'nocheck'},
                        timeout: 0};
    axios.post('/payment/signature', sumForm, sumConfig)
        .then( (response) => {
            const signature = response.data.signature;
            const orderId = response.data.orderId;
            const email = response.data.email;
            const url = 'https://auth.robokassa.ru/Merchant/Index.aspx';
            document.write(
                "<a style='display: none' id='payLink' " +
                    "href='" + url +
                    // Идентификатор магазина в ROBOKASSA
                "?MerchantLogin=finparser" +
                    // Номер заказа
                "&InvoiceID=" + orderId +
                    // Тест
                "&IsTest="+ (accessLevel === "admin" ? "1" : "0") +
                    // Язык общения с клиентом
                "&Culture=ru"+
                    // Кодировка, в которой отображается страница ROBOKASSA
                "&Encoding=utf-8"+
                    // Требуемая к получению сумма
                "&OutSum=" + this.getSum() +
                    // Email покупателя
                "&Email=" + email +
                    // Контрольная сумма - хэш
                "&SignatureValue=" + signature +
                    // Описание покупки
                "&Description=" + description + "'/>");
            document.getElementById('payLink').click();
        })
        .catch( (error) => {
            console.log(error);
            });
}
