import React from 'react';

export default [
  {
    id: 'forex__ib',
    data: {
      label: (
        <>
          Interactive Brokers
        </>
      ),
    },
    position: { x: 250, y: 0 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #e91e63',
    },
  },
  {
    id: 'forex__finparser',
    data: {
      label: (
        <>
          FinParser
        </>
      ),
    },
    position: { x: 250, y: 150 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #3f51b5',
    },
  },
  {
    id: 'forex__nalog.ru',    
    data: {
      label: 'nalog.ru',
    },    
    position: { x: 600, y: 225 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #009688',
    },
  },
  {
    id: 'forex__finparser2',
    data: {
      label: (
        <>
          FinParser
        </>
      ),
    },
    position: { x: 250, y: 300 },
    type: 'allSidesNode',
    className: 'react-flow__node-default',
    style: {
      border: '1px solid #3f51b5',
    },
  },
  {
    id: 'forex__...',
    data: { label: '' },
    position: { x: 324, y: 450 },
    type: 'emptyNode',
  },
  // edges
  {
    id: 'forex__ib-b1->finparser',
    source: 'forex__ib',
    sourceHandle: 'bottom-left',
    target: 'forex__finparser',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'forex__ib-b2->finparser',
    source: 'forex__ib',
    sourceHandle: 'bottom-right',
    target: 'forex__finparser',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'forex__ib-b3->finparser',
    source: 'forex__ib',
    target: 'forex__finparser',
    label: 'Отчеты за 2018, 2019 и 2020 годы (CSV)',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'forex__finparser->nalog.ru',
    source: 'forex__finparser',
    sourceHandle: 'r',
    target: 'forex__nalog.ru',
    targetHandle: 'l',
    data: { label: (<div>
                      Декларация и<br />
                      пояснительная записка<br />
                      за 2020 год
                    </div>),
            height: 42,
            width: 120},
    type: 'labelEdge',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'forex__finparser->finparser2',
    source: 'forex__finparser',
    sourceHandle: 'bottom',
    target: 'forex__finparser2',
    targetHandle: 'top',
    data: { label: (<div>
                      Сделки и позиции в формате<br />
                      FinParser по состоянию<br />
                      на конец 2020 года (Excel)
                    </div>),
            height: 56,
            width: 140},
    type: 'labelEdge',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'forex__finparser2->nalog.ru',
    source: 'forex__finparser2',
    sourceHandle: 'r',
    target: 'forex__nalog.ru',
    targetHandle: 'l',
    // label: 'Декларация (XML)',
    data: { label: (<div>
                      Декларация и<br />
                      пояснительная записка<br />
                      за 2021 год
                    </div>),
            height: 42,
            width: 120},
    type: 'labelEdge',
    style: { stroke: '#000000' },
    animated: true,
  },  
  {
    id: 'forex__finparser2->...',
    source: 'forex__finparser2',
    sourceHandle: 'bottom',
    target: 'forex__...',
    data: { label: (<div>
                      Сделки и позиции в формате<br />
                      FinParser по состоянию<br />
                      на конец 2021 года (Excel)
                    </div>),
            height: 56,
            width: 140},
    type: 'labelEdge',
    style: { stroke: '#000000' },
    animated: true,
  },
  // ib - ib-a - Отчет за 2021 год (CSV) - ib-b -> FinParser
  {
    id: 'forex__ib-a',
    data: { label: '' },
    type: 'emptyNode',
    position: { x:160, y: 110 },
  },
  {
    id: 'forex__ib-b',
    data: { label: '' },
    position: { x: 160, y: 230 },
    type: 'emptyNode',
  },
  {
    id: 'forex__ib->ib-a',
    source: 'forex__ib',
    target: 'forex__ib-a',
    sourceHandle: 'l',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'forex__ib-a->ib-b',
    source: 'forex__ib-a',
    target: 'forex__ib-b',
    label: 'Отчет за 2021 год (CSV)',
    style: { stroke: '#000000' },
    animated: true,
  },
  {
    id: 'forex__ib-b-finparser2',
    source: 'forex__ib-b',
    target: 'forex__finparser2',
    targetHandle: 'l',
    style: { stroke: '#000000' },
    animated: true,
  },
];
