import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Timeline from '@material-ui/lab/Timeline';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import GetAppIcon from '@material-ui/icons/GetApp';
import DoneIcon from '@material-ui/icons/Done';
import HelpIcon from '@material-ui/icons/Help';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { inputFormatsList } from '../taxForm';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';

const styles = () => ({
    imgCell: {
        padding: '6px 24px 6px 16px'
    },
    paper: {
        padding: '6px 16px',
    },
    justify: {
        textAlign: 'justify'
    },
    buttonMobile: {
        height: '54px',
        width: '240px',
        marginBottom: '10px',
        marginRight: '10px'
    },
    buttons: {
        height: '54px',
        width: '200px',
        marginBottom: '15px',
        marginRight: '50px'
    },
    imgSpan: {
        width:'16px',
        display: 'inline-block'
    },
    minLi: {
        marginBottom: '4px',
        marginTop: '4px'
    },
    timelineMobile: {
        marginTop: "0px",
        paddingTop: "0px",
        paddingLeft: "2px",
        paddingRight: "4px"
    },
    timelineItemMobile: {
        marginTop: '0px',
        marginBottom: '0px'
    },
    timelineContentMobile: {
        paddingRight: "0px"
    },
    examplesAccordionHeader: {
        paddingLeft: "42px"
    },
    cellRightMobile: {
        paddingLeft: "0px",
        paddingRight: "2px",
        paddingTop: "4px",
        paddingBottom: "2px",
        minWidth: "100px"
    },
    cellLeftMobile: {
        paddingLeft: "2px",
        paddingRight: "0px",
        paddingTop: "4px",
        paddingBottom: "2px",
    },
    cellLeftDescriptionMobile: {
        paddingLeft: "2px",
        paddingRight: "0px",
        paddingTop: "12px",
        paddingBottom: "10px",
    },
});

class WelcomeEs extends React.Component {

    createData(name, url) {
        return { name, url };
    }

    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <div className={classes.root}>
              <p/>
              <Typography variant={isMobile ? "h5" : "h4"} align="center"
                          className={classes.headerMargin}
                          gutterBottom>
                {text(language,
                      {"ru": "Как сделать налоговую декларацию",
                       "en": "How to make a tax return",
                       "es": "Cómo hacer una declaración de impuestos"})}
              </Typography>
              <Timeline className={isMobile ? classes.timelineMobile : ""}
                        align={isMobile ? "left" : "alternate"}>
                <TimelineItem className={isMobile ? classes.timelineItemMobile : ""}>
                  {isMobile
                   ? <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
                   : ""}
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      <GetAppIcon />
                    </TimelineDot>
                    <TimelineConnector className={classes.primaryTail}/>
                  </TimelineSeparator>
                  <TimelineContent className={isMobile ? classes.timelineContentMobile : ""}>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant={isMobile ? 'subtitle1' : "h6"} component="h1">
                        {text(language,
                              {"ru": "Скачайте отчет брокера/брокеров",
                               "en": "Download the broker/brokers report",
                               "es": "Descargue el informe del bróker/brókers"})}
                      </Typography>
                      <Typography>
                        {text(language,
                              {"ru": "Описание процедуры приведено в",
                               "en": "The procedure is described in",
                               "es": "El procedimiento se describe en la"})}
                        &ensp;
                        <Link component={NavLink} to="/help">
                          {text(language,
                              {"ru": "справочном руководстве",
                               "en": "reference guide",
                               "es": "guía de referencia"})}
                        </Link>:&ensp;
                        <br/>
                        <ul>
                          {Object.values(inputFormatsList(language))
                           .filter(i => i.outputInBrokersList)
                           .map(i => (
                              <li className={classes.minLi}>
                                <Link to={i.helpURL}
                                      component={NavLink}
                                      className={classes.link}>
                                  <span className={classes.imgSpan}>
                                    <img src={i.img}
                                         height='16px'
                                         alt='logo'>
                                    </img>
                                  </span>
                                  &ensp;
                                  {i.brokerName}
                                </Link>
                              </li>
                          ))}
                        </ul>
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem className={isMobile ? classes.timelineItemMobile : ""}>
                  {isMobile
                   ? <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
                   : ""}
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      <img src="/images/finParserLogo.svg"
                           height='27px'
                           width='27px'
                           alt='logo'/>
                    </TimelineDot>
                    <TimelineConnector className={classes.primaryTail}/>
                  </TimelineSeparator>
                  <TimelineContent className={isMobile ? classes.timelineContentMobile : ""}>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant={isMobile ? 'subtitle1' : "h6"} component="h1">
                        {text(language,
                              {"ru": "Сгенерируйте пояснение по доходам",
                               "en": "Generate income statement",
                               "es": "Generar estado de resultados"})}
                      </Typography>
                      <Typography variant={isMobile ? 'body2' : 'body1'}>
                        {text(language,
                              {"ru": "Воспользовавшись разделом",
                               "en": "Using the section",
                               "es": "Usando la sección"})}
                        &ensp;
                        <Link component={NavLink} to="/tax">
                          {text(language,
                              {"ru": "расчет налогов",
                               "en": "tax calculation",
                               "es": "calculo de impuestos"})}
                        </Link>
                        &ensp;{text(language,
                                    {"ru": "данного сервиса",
                                     "en": "of this service",
                                     "es": "de este servicio"})}
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem className={isMobile ? classes.timelineItemMobile : ""}>
                  {isMobile
                   ? <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
                   : ""}
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      <DoneIcon />
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent className={isMobile ? classes.timelineContentMobile : ""}>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant={isMobile ? 'subtitle1' : "h6"} component="h1">
                        {text(language,
                              {"ru": "Заполните налоговую декларацию",
                               "en": "Complete your tax return",
                               "es": "Complete su declaración de impuestos"})}
                      </Typography>
                      <Typography variant={isMobile ? 'body2' : 'body1'}>
                        {text(language,
                              {"ru": "Для заполнения используйте",
                               "en": "To fill out, use the",
                               "es": "Para completar, siga las"})}
                        &ensp;
                        <Link component={NavLink} to="/help/HelpSpain">
                          {text(language,
                                {"ru": "приведенную инструкцию",
                                 "en": "instructions provided",
                                 "es": "instrucciones proporcionadas"})}
                        </Link>
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
              <Typography variant={isMobile ? "h5" : "h4"} align="center"
                          className={classes.headerMargin}
                          gutterBottom>
                {text(language,
                      {"ru": "Учитываются следующие виды сделок",
                       "en": "The following types of transactions are taken into account",
                       "es": "Se tienen en cuenta los siguientes tipos de transacciones"})}
              </Typography>
              <p/>
              <ul>
                <li>{text
                     (language,
                      {"ru": <span>
                               Дивиденды (выплаты по ETF)  в том числе с
                               учетом уже удержанных налогов
                             </span>,
                       "en": <span>
                               Dividends (payments from ETFs),
                               including taxes already withheld
                             </span>,
                       "es": <span>
                               Dividendos (pagos de ETF), incluidos
                               los impuestos ya retenidos
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Закрытие длинных позиций
                             </span>,
                       "en": <span>
                               Closing long positions
                             </span>,
                       "es": <span>
                               Cerrar posiciones largas
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Закрытие коротких позиций
                             </span>,
                       "en": <span>
                               Closing short positions
                             </span>,
                       "es": <span>
                               Cerrar posiciones cortas
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Сделки с опционами (в том числе с учетом экспирации)
                             </span>,
                       "en": <span>
                               Transactions with options (including expiration)
                             </span>,
                       "es": <span>
                               Transacciones con opciones (incluido vencimiento)
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Сделки с фьючерсами
                             </span>,
                       "en": <span>
                               Futures transactions
                             </span>,
                       "es": <span>
                               Transacciones de futuros
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Контракты на разницу (CFD)
                             </span>,
                       "en": <span>
                               Contracts for Difference (CFD)
                             </span>,
                       "es": <span>
                               Contratos por Diferencia (CFD)
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Проценты на остаток
                             </span>,
                       "en": <span>
                               Balance interest
                             </span>,
                       "es": <span>
                               Interés del saldo
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Купоны по облигациям
                             </span>,
                       "en": <span>
                               Bond coupons
                             </span>,
                       "es": <span>
                               Cupones de bonos
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Комиссии брокера (подписка на рыночные данные и т.д.)
                             </span>,
                       "en": <span>
                               Broker commissions (subscription to market data, etc.)
                             </span>,
                       "es": <span>
                               Comisiones de broker (suscripción a datos de mercado, etc.)
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Варранты
                             </span>,
                       "en": <span>
                               Warrants
                             </span>,
                       "es": <span>
                               Órdenes
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Операции Forex
                             </span>,
                       "en": <span>
                               Forex operations
                             </span>,
                       "es": <span>
                               Operaciones Forex
                             </span>})}
                </li>
                <li>{text
                     (language,
                      {"ru": <span>
                               Корпоративные действия (выкупы, акции в
                               качестве дивидендов, погашение облигаций)
                             </span>,
                       "en": <span>
                               Corporate actions (buybacks,
                               stock dividends, bond redemptions)
                             </span>,
                       "es": <span>
                               Acciones corporativas (recompras,
                               dividendos de acciones, reembolsos de bonos)
                             </span>})}
                </li>
              </ul>
              <p/>

              <Typography variant={isMobile ? "h5" : "h4"} align="center"
                          className={classes.headerMargin}
                          gutterBottom>
                {text(language,
                          {"ru": "Цены",
                           "en": "Prices",
                           "es": "Precios"})}
              </Typography>
              <Box m={isMobile ? 0 : 4} className={classes.justify}>
                {text
                 (language,
                  {"ru": <span>
                           Цена составляет от <b>25</b> до <b>50</b> EUR
                           за декларацию в зависимости детализации
                           отчета<b>*</b>.
                           <br/>
                           <p/>
                           <b>*</b> Простая декларация (за 25 EUR)
                           подразумевает вывод агрегированных операций
                           по бумагам на брокерском
                           счете. Детализированная декларация включает
                           также описание каждой сделки, а в случае
                           невозможности зачета убытка по сделке,
                           указывается блокирующая от вменения убытка
                           сделка.
                         </span>,
                   "en": <span>
                           The price ranges from <b>25</b> to <b>50</b> EUR
                           per declaration, depending on
                           the report detail<b>*</b>.
                           <br/>
                           <p/>
                           <b>*</b> A simple declaration (for 25 EUR)
                           shows aggregated transactions on securities
                           in a brokerage account. The detailed
                           declaration also includes a description of
                           each transaction, and if it is impossible
                           to offset the loss on the transaction, the
                           transaction blocking the imputation of the
                           loss is indicated.
                         </span>,
                   "es": <span>
                           El precio oscila entre <b>25</b> y <b>50</b> EUR
                           por declaración, dependiendo
                           del detalle del informe<b>*</b>.
                           <br/>
                           <p/>
                           <b>*</b> Una declaración simple (por 25 EUR)
                           muestra transacciones agregadas sobre
                           valores en una cuenta de corretaje. La
                           declaración detallada también incluye una
                           descripción de cada transacción, y si es
                           imposible compensar la pérdida de la
                           transacción, se indica la transacción que
                           bloquea la imputación de la pérdida.
                         </span>})}
                <br/>
                <Grid container justify="flex-end">
                  <Button component={NavLink} to="/payment"
                          style={{ marginTop: '10px' }}
                          className={isMobile
                                     ? classes.buttonMobile
                                     : classes.buttons}
                          variant="contained"
                          color="primary">
                    {text(language,
                          {"ru": "Оплатить",
                           "en": "Pay",
                           "es": "Pagar"})}
                  </Button>
                </Grid>
                <Grid container justify="flex-end">
                  <Button component={NavLink} to="/leaveFeedback"
                          className={isMobile
                                     ? classes.buttonMobile
                                     : classes.buttons}
                          variant="contained"
                          color="primary">
                    {text(language,
                          {"ru": "Оставить отзыв",
                           "en": "Leave feedback",
                           "es": "Deja un comentario"})}
                  </Button>
                </Grid>
              </Box>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const language = state.language;
    return {
        language
    };
}

export default withStyles(styles)(
    connect(mapStateToProps)(WelcomeEs));
