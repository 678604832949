import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertDialog from "./alertDialog";
import axios from 'axios';
import Center from 'react-center';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loader: {
        padding: '200px 0',
    }
});

class RenewPassword extends React.Component {
    constructor(props) {
        super(props);

        // This binding is necessary to make `this` work in the callback
        this.submitRenewPassword = this.submitRenewPassword.bind(this);
    }

    state = {
        inProgress: false,
        changePasswordFailed: false,
        errorText: '',
        login: (new URL(window.location.href.replace(/#/g,""))).searchParams.get("login"),
        hash: (new URL(window.location.href.replace(/#/g,""))).searchParams.get("hash"),
        password: '',
        showPasswordUpdatedDialog: false
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    gotoLogin() {
        window.location.hash = "login";
    }
    
    submitRenewPassword(event) {
        if (event != null) {
            event.preventDefault();
        }
        if (this.state['password'].trim() === "") {
          this.setState({ changePasswordFailed: true });
          this.setState({ errorText: 'Пароль не может быть пустым.' });
          return
        } else if (this.state['password'] !== this.state['repeatPassword']) {
          this.setState({ changePasswordFailed: true });
          this.setState({ errorText: 'Пароли не совпадают.' });
          return
        }
        this.setState({ inProgress: true });
        this.setState({ changePasswordFailed: false });
        this.setState({ errorText: '' });
        const config = { headers: { 'Content-Type': 'application/json',
                                    'X-Requested-With': 'HttpRequest',
                                    'Csrf-Token': 'nocheck'},
                         timeout: 0};
        const data = new FormData();
        data.append('login', this.state['login']);
        data.append('password', this.state['password']);
        data.append('repeatPassword', this.state['repeatPassword']);
        data.append('hash', this.state['hash']);
        axios.post("/renewPassword", data, config)
            .then( (response) => {
                this.setState({ inProgress: false });
                if (response.status === 200) {
                    this.setState({ changePasswordFailed: false,
                                    showPasswordUpdatedDialog: true });
                    
                } else {                    
                    this.setState({ changePasswordFailed: true, 
                                    errorText: response.data.error });
                }
            })
            .catch( (error) => {                
                this.setState({ changePasswordFailed: true, 
                                errorText: error.response.data.error,
                                inProgress: false});
            });
    };

    render() {
        const { classes } = this.props;
               
        return (
            <Center>
              {!this.state.inProgress
               ?
               <Card className={classes.card}>
                 <CardContent>
                   <Container component="main" maxWidth="xs">
                     <CssBaseline />
                     <div className={classes.paper}>
                       <Avatar className={classes.avatar}>
                         <LockOutlinedIcon />
                       </Avatar>
                       <Typography component="h1" variant="h5">
                         Изменение пароля
                       </Typography>
                       <form className={classes.form} noValidate>
                         {this.state.changePasswordFailed
                          ? <Alert severity="error">
                              {this.state.errorText}
                            </Alert>
                          : ""}
                         <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           id="login"
                           label="Логин"
                           name="login"
                           InputProps={{
                            readOnly: true,
                          }}
                           value={this.state.login}                           
                           autoComplete="email" />
                         <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           name="password"
                           label="Пароль"
                           type="password"
                           id="password"
                           value={this.state.password}
                           onChange={this.handleChange('password')}
                           autoComplete="current-password"/>
                         <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           name="repeatPassword"
                           label="Повторите пароль"
                           type="password"
                           id="repeatPassword"
                           value={this.state.repeatPassword}
                           onChange={this.handleChange('repeatPassword')}/>                         
                         <Button
                           type="submit"
                           fullWidth
                           variant="contained"
                           color="primary"
                           className={classes.submit}
                           onClick={this.submitRenewPassword}>
                           Сохранить
                         </Button>
                       </form>
                     </div>
                   </Container>
                 </CardContent>
               </Card>
               : <div className={classes.loader}>
                   <CircularProgress className={classes.progress} size={90} />
                 </div>}
               <AlertDialog
                open={this.state.showPasswordUpdatedDialog}
                propertyName='passwordUpdated'
                parent={this}
                title='Пароль обновлен'
                message='Для входа введите его заново.'
                handler={this.gotoLogin}/>

                 
            </Center>
        );
    }
}

export default withStyles(styles)(RenewPassword);
