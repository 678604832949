// -*- mode: rjsx-mode; js2-basic-offset: 4; -*-
import React from 'react';
import { connect } from "react-redux";
import { switchCountry } from "./countrySelection";
import TaxFormRu from './ru/taxForm_ru';
import TaxFormEe from './ee/taxForm_ee';
import TaxFormEs from './es/taxForm_es';
import { text } from "./languageSelection";

class TaxForm extends React.Component {
    render() {
        let residenceCountry = this.props.residence.value;
        return (<>
                  {switchCountry(
                      residenceCountry,
                      {"spain": <TaxFormEs/>,
                       "russia": <TaxFormRu/>,
                       "estonia": <TaxFormEe/>})}
                </>
               );
    }
}

function mapStateToProps(state) {
    const residence = state.residence;
    return {
        residence,
    };
}

export default connect(mapStateToProps)(TaxForm);

export function inputFormatsList(language) {
    return {
        'ib': {
            brokerName: text(language,
                             {"ru": 'Interactive Brokers',
                              "en": 'Interactive Brokers',
                              "es": 'Interactive Brokers'}),
            name: text(language,
                       {"ru": 'Interactive Brokers (Пользовательский отчет в CSV)',
                        "en": 'Interactive Brokers (Custom report in CSV)',
                        "es": 'Interactive Brokers (Informe personalizado en CSV)'}),
            sortName: text(language,
                           {"ru": 'Interactive Brokers (CSV)',
                            "en": 'Interactive Brokers (CSV)',
                            "es": 'Interactive Brokers (CSV)'}),
            img: "/images/ibLogo_gray.svg",
            extensionAccept: '.csv',
            helpURL: "/help/IBDeclarationReport",
            outputInBrokersList: true
        },
        'ff_bz': {
            brokerName: text(language,
                             {"ru": 'Freedom Finance Белиз',
                              "en": 'Freedom Finance Belize',
                              "es": 'Freedom Finance Belice'}),
            name: text(language,
                       {"ru": 'Freedom Finance Белиз (Отчет в Excel)',
                        "en": 'Freedom Finance Belize (Excel report)',
                        "es": 'Freedom Finance Belice (Informe de Excel)'}),
            sortName: text(language,
                           {"ru": 'Freedom Finance Belize (Excel)',
                            "en": 'Freedom Finance Belize (Excel)',
                            "es": 'Freedom Finance Belize (Excel)'}),
            img: "/images/ffLogo_gray.png",
            extensionAccept: '.xlsx',
            helpURL: "/help/FFReport",
            outputInBrokersList: true
        },
        'ff_cy': {
            brokerName: text(language,
                             {"ru": 'Freedom Finance Кипр',
                              "en": 'Freedom Finance Cyprus',
                              "es": 'Freedom Finance Chipre'}),
            name: text(language,
                       {"ru": 'Freedom Finance Кипр (Отчет в Excel)',
                        "en": 'Freedom Finance Cyprus (Excel report)',
                        "es": 'Freedom Finance Chipre (Informe de Excel)'}),
            sortName: text(language,
                           {"ru": 'Freedom Finance Cyprus (Excel)',
                            "en": 'Freedom Finance Cyprus (Excel)',
                            "es": 'Freedom Finance Cyprus (Excel)'}),
            img: "/images/ffGlobalLogo_gray.svg",
            extensionAccept: '.xlsx',
            helpURL: "/help/FFCyReport",
            outputInBrokersList: true
        },
        'ff_kz': {
            brokerName: text(language,
                             {"ru": 'Freedom Finance Казахстан',
                              "en": 'Freedom Finance Kazakhstan',
                              "es": 'Freedom Finance Kazajstán'}),
            name: text(language,
                       {"ru": 'Freedom Finance Казахстан (Отчет в Excel)',
                        "en": 'Freedom Finance Kazakhstan (Excel report)',
                        "es": 'Freedom Finance Kazajstán (Informe de Excel)'}),
            sortName: text(language,
                           {"ru": 'Freedom Finance Kazakhstan (Excel)',
                            "en": 'Freedom Finance Kazakhstan (Excel)',
                            "es": 'Freedom Finance Kazakhstan (Excel)'}),
            img: "/images/ffGlobalLogo_gray.svg",
            extensionAccept: '.xlsx',
            helpURL: "/help/FFCyReport",
            outputInBrokersList: false
        },
        'revolut': {
            brokerName: text(language,
                             {"ru": 'Revolut',
                              "en": 'Revolut',
                              "es": 'Revolut'}),
            name: text(language,
                       {"ru": 'Revolut (Отчет в CSV)',
                        "en": 'Revolut (Report in CSV)',
                        "es": 'Revolut (Informe en CSV)'}),
            sortName: text(language,
                           {"ru": 'Revolut (CSV)',
                            "en": 'Revolut (CSV)',
                            "es": 'Revolut (CSV)'}),
            img: "/images/RevolutSymbol.png",
            extensionAccept: '.csv',
            helpURL: "/help/RevolutReport",
            outputInBrokersList: true
        },
        'trade_station': {
            brokerName: text(language,
                             {"ru": 'TradeStation',
                              "en": 'TradeStation',
                              "es": 'TradeStation'}),
            name: text(language,
                       {"ru": 'TradeStation (Отчет в Excel)',
                        "en": 'TradeStation (Report in Excel)',
                        "es": 'TradeStation (Informe en Excel)'}),
            sortName: text(language,
                           {"ru": 'TradeStation (Excel)',
                            "en": 'TradeStation (Excel)',
                            "es": 'TradeStation (Excel)'}),
            img: "/images/TradeStationLogo.png",
            extensionAccept: '.xlsx',
            helpURL: "/help/TradeStationReport",
            outputInBrokersList: true
        },
        'trade_station_transactions': {
            brokerName: text(language,
                             {"ru": 'TradeStation',
                              "en": 'TradeStation',
                              "es": 'TradeStation'}),
            name: text(language,
                       {"ru": 'TradeStation (Отчет transactions в CSV)',
                        "en": 'TradeStation (Report transactions in CSV)',
                        "es": 'TradeStation (Informe transactions en CSV)'}),
            sortName: text(language,
                           {"ru": 'TradeStation transactions (CSV)',
                            "en": 'TradeStation transactions (CSV)',
                            "es": 'TradeStation transactions (CSV)'}),
            img: "/images/TradeStationLogo.png",
            extensionAccept: '.csv',
            helpURL: "/help/TradeStationReport",
            outputInBrokersList: false
        },
        'trade_station_blotter': {
            brokerName: text(language,
                             {"ru": 'TradeStation',
                              "en": 'TradeStation',
                              "es": 'TradeStation'}),
            name: text(language,
                       {"ru": 'TradeStation (Отчет blotter в CSV)',
                        "en": 'TradeStation (Report blotter in CSV)',
                        "es": 'TradeStation (Informe blotter en CSV)'}),
            sortName: text(language,
                           {"ru": 'TradeStation (CSV)',
                            "en": 'TradeStation (CSV)',
                            "es": 'TradeStation (CSV)'}),
            img: "/images/TradeStationLogo.png",
            extensionAccept: '.csv',
            helpURL: "/help/TradeStationReport",
            outputInBrokersList: false
        },
        'degiro': {
            brokerName: text(language,
                             {"ru": 'Degiro',
                              "en": 'Degiro',
                              "es": 'Degiro'}),
            name: text(language,
                       {"ru": 'Degiro (Отчет в Excel)',
                        "en": 'Degiro (Report in Excel)',
                        "es": 'Degiro (Informe en Excel)'}),
            sortName: text(language,
                           {"ru": 'Degiro (Excel)',
                            "en": 'Degiro (Excel)',
                            "es": 'Degiro (Excel)'}),
            img: "/images/degiro_gray.svg",
            extensionAccept: '.xlsx',
            helpURL: "/help/TradeStationReport",
            outputInBrokersList: true
        },
        'charles_schwab_pdf': {
            brokerName: text(language,
                             {"ru": 'Charles Schwab',
                              "en": 'Charles Schwab',
                              "es": 'Charles Schwab'}),
            name: text(language,
                       {"ru": 'Charles Schwab (Отчет в PDF)',
                        "en": 'Charles Schwab (Report in PDF)',
                        "es": 'Charles Schwab (Informe en PDF)'}),
            sortName: text(language,
                           {"ru": 'Charles Schwab (Report in PDF)',
                            "en": 'Charles Schwab (Report in PDF)',
                            "es": 'Charles Schwab (Report in PDF)'}),
            img: "/images/schwab_gray.svg",
            extensionAccept: '.pdf',
            helpURL: "/help/CharlesSchwabReport",
            outputInBrokersList: true
        },
        'charles_schwab_excel': {
            brokerName: text(language,
                             {"ru": 'Charles Schwab',
                              "en": 'Charles Schwab',
                              "es": 'Charles Schwab'}),
            name: text(language,
                       {"ru": 'Charles Schwab (Отчет в Excel)',
                        "en": 'Charles Schwab (Report in Excel)',
                        "es": 'Charles Schwab (Informe en Excel)'}),
            sortName: text(language,
                           {"ru": 'Charles Schwab (Report in Excel)',
                            "en": 'Charles Schwab (Report in Excel)',
                            "es": 'Charles Schwab (Report in Excel)'}),
            img: "/images/schwab_gray.svg",
            extensionAccept: '.xlsx',
            helpURL: "/help/CharlesSchwabReport",
            outputInBrokersList: false
        },
        'citi': {
            brokerName: text(language,
                             {"ru": 'Citibank, N.A.',
                              "en": 'Citibank, N.A.',
                              "es": 'Citibank, N.A.'}),
            name: text(language,
                       {"ru": 'Citibank, N.A. (Отчет в PDF)',
                        "en": 'Citibank, N.A. (Report in PDF)',
                        "es": 'Citibank, N.A. (Informe en PDF)'}),
            sortName: text(language,
                           {"ru": 'Citibank, N.A. (Report in PDF)',
                            "en": 'Citibank, N.A. (Report in PDF)',
                            "es": 'Citibank, N.A. (Report in PDF)'}),
            img: "/images/citi_gray.svg",
            extensionAccept: '.pdf',
            helpURL: "/help/CitiReport",
            outputInBrokersList: true
        },
        'finparser': {
            brokerName: text(language,
                             {"ru": 'FinParser (Универсальный формат)',
                              "en": 'FinParser (Universal Format)',
                              "es": 'FinParser (Formato Universal)'}),
            name: text(language,
                       {"ru": 'FinParser (Универсальный формат в Excel)',
                        "en": 'FinParser (Universal Format in Excel)',
                        "es": 'FinParser (Formato Universal en Excel)'}),
            sortName: text(language,
                           {"ru": 'FinParser (Excel)',
                            "en": 'FinParser (Excel)',
                            "es": 'FinParser (Excel)'}),
            img: "/images/finParserLogo_gray.png",
            extensionAccept: '.xlsx',
            helpURL: "/help/FinParserFormat",
            outputInBrokersList: true
        }
    };
}
