import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Center from 'react-center';
import axios from 'axios';
import LoginDialog from '../loginDialog';
import GreenCheckbox from '../common/greenCheckbox';
import YellowCheckbox from '../common/yellowCheckbox';
import PurpleCheckbox from '../common/purpleCheckbox';
import {formatNumber} from '../paymentsLib';
import {isBlank} from '../miscLib';
import { setLoginOk } from '../state/loginOkSlice';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#F5F5F5',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #BDBDBD',
  },
}))(Tooltip);

const styles = theme => ({
  textField: {
    width: 150,
    margin: 7,
  },
  justify: {
    width: '968px',
    textAlign: 'justify'
  },
  justifyMobile: {
    textAlign: 'justify'
  },
  paper: {
    maxWidth: 1000,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  width42: {
    width: "42px"
  },
  width250: {
    width: "250px"
  },
  width100: {
    width: "110px",
  },
  notchedOutline: {
    borderWidth: "2px",
    borderColor: "#757575 !important"
  },
  marginTop: {
    marginTop: '10px'
  },
  tableMinWidth: {
    width: "1%",
    whiteSpace: "nowrap"
  },
  totalTable: {
    marginTop: '10px',
  },
  totalText: {
    fontSize: "large",
  },
  totalAmount: {
    fontSize: "large",
    textAlign: 'right',
    width: "120px"
  },
  payButton: {
    margin: 5,
    marginLeft: '35px'
  },
  buttonAddDiv: {
    maxWidth: 1000,
    margin: `${theme.spacing(1)}px auto`,
    padding: 0,
  },
  buttonAdd: {
    margin: 0,
    marginTop: '5px',
    float: "right"
  },
  buttonDel: {
    margin: 5,
    marginTop: 5,
    marginBottom: 0,
    paddingLeft: 6,
    paddingRight: 6,
    minWidth: 32,
    float: "right"
  },
  loader: {
    padding: '200px 0',
  },
  formControlMobile: {
    height: "20px",
    marginBottom: "10px"
  }
});

class PaymentEs extends React.Component {
  constructor(props) {
    super(props);
    let language = this.props.language.value;

    this.updateTotalPrice = this.updateTotalPrice.bind(this);
    this.getDescription = this.getDescription.bind(this);
    this.handleStartPayment = this.handleStartPayment.bind(this);
    this.setDefaultCustomPaymentText = this.setDefaultCustomPaymentText.bind(this);
    this.setDefaultCustomPaymentText(language);
  }

  isEndOfYear() {
    return new Date().getMonth() >= 10;
  }

  state = {
    toPay: 0,
    declarations: [{
      index: 0,
      form100_BasicSelected: true,
      form100_DetailSelected: true,
      form720_Selected: true,
      form721_Selected: false,
      customPayment: false,
      customPaymentAmount: 0,
      customPaymentText: '',
    }],
    form100_BasicNumber: 0,
    form100_DetailNumber: 0,
    inProgress: false,
    disablePaymentButton: false,
    showLoginDialog: !this.props.loginOk.value
  };

  updateTotalPrice = (updateUI = true) => {
    var totalPrice = 0;
    var f100b = 0;
    var f100d = 0;
    var f720 = 0;
    var f721 = 0;

    this.state.declarations.forEach(d => {
      if (d.customPayment) {
        totalPrice += d.customPaymentAmount;
      } else {
        if (d.form100_BasicSelected && !d.form100_DetailSelected) {
          totalPrice += 25;
          f100b += 1;
        }
        if (d.form100_DetailSelected) {
          totalPrice += 50;
          f100d += 1;
        }
        if (d.form720_Selected) {
          totalPrice += 20;
          f720 += 1;
        }
        if (d.form721_Selected) {
          totalPrice += 20;
          f721 += 1;
        }
      }
    });
    this.setState({
      toPay: totalPrice,      
      form100_BasicNumber: f100b,
      form100_DetailNumber: f100d,
      form720_Number: f720,
      form721_Number: f721});
    if (updateUI) {
      this.forceUpdate();
    }
  }

  updateCustomPriceAmount = declaration => event => {
    let value = parseInt(event.target.value);
    declaration.customPaymentAmount = value;
    this.updateTotalPrice();
  }

  updateCustomPriceText = declaration => event => {
    let value = event.target.value;
    declaration.customPaymentText = value;
    this.forceUpdate();
  }

  getSum = () => {
    return Math.round(this.state.toPay).toString() + '.00';
  }

  getDescription = (language) => {
    let f100b = this.state.form100_BasicNumber;
    let f100d = this.state.form100_DetailNumber;
    let f720 = this.state.form720_Number;
    let f721 = this.state.form721_Number;
    if (this.state.declarations.find(d => (
      d.customPayment === true && d.customPaymentAmount > 0))) {
      let text = this.state.declarations.find(d => (
        d.customPayment === true && d.customPaymentAmount > 0))
          .customPaymentText;
      if (!isBlank(text)) {
        return text;
      } else {
        return text(language,
                    {"ru": "Пользовательский платеж",
                     "en": "Custom payment",
                     "es": "Pago personalizado"});
      }
    } else if (f100b === 0 && f100d === 0 && f720 == 0 && f721 == 0) {
      return text(language,
                    {"ru": "Ничего не выбрано",
                     "en": "Nothing selected",
                     "es": "Nada seleccionado"});
    } else {
      return text(language,
                  {"ru": "Оплата генерации отчета для налоговой декларации",
                   "en": "Payment for generating a report for a tax return",
                   "es": "Pago por generar un informe para una declaración de impuestos"});
    }
  }

  handleForm100_Basic = declaration => event => {
    let selected = event.target.checked;
    if (!selected) {
      declaration.form100_DetailSelected = selected;
    }
    if (selected) {
      declaration.customPayment = false;
      declaration.customPaymentAmount = 0;
    }
    declaration.form100_BasicSelected = selected;
    this.updateTotalPrice();
  }

  handleForm100_Detail = declaration => event => {
    let selected = event.target.checked;
    if (selected) {
      declaration.form100_BasicSelected = selected;
    }
    if (selected) {
      declaration.customPayment = false;
      declaration.customPaymentAmount = 0;
    }
    declaration.form100_DetailSelected = selected;
    this.updateTotalPrice();
  }

  handleForm720_Selected = declaration => event => {
    let selected = event.target.checked;
    if (selected) {
      declaration.customPayment = false;
      declaration.customPaymentAmount = 0;
    }
    declaration.form720_Selected = selected;
    this.updateTotalPrice();
  }

  handleForm721_Selected = declaration => event => {
    let selected = event.target.checked;
    if (selected) {
      declaration.customPayment = false;
      declaration.customPaymentAmount = 0;
    }
    declaration.form721_Selected = selected;
    this.updateTotalPrice();
  }

  handleCustom = declaration => event => {
    let selected = event.target.checked;
    declaration.customPayment = selected;
    if (selected) {
      declaration.form100_BasicSelected = false;
      declaration.form100_DetailSelected = false;
      declaration.form720_Selected = false;
      declaration.form721_Selected = false;
    }
    this.updateTotalPrice();
  }

  handleAddDeclaration = event => {
    let idx = Math.max.apply(null, this.state.declarations.map(i => {
      return i.index;
    })) + 1;
    this.state.declarations.push({
      index: idx,
      form100_BasicSelected: true,
      form100_DetailSelected: true,
      form720_Selected: true,
      form721_Selected: false});
    this.updateTotalPrice();
  }

  scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  delDeclaration = index => event => {
    this.setState({ declarations: this.state.declarations.filter(
      i => {
        return index !== i.index;
      })},
                  () => {this.updateTotalPrice();});
  }

  handleStartPayment = language => event => {
    const form = new FormData();
    form.append('currency', "EUR");
    form.append('sum', this.getSum());
    form.append('description', this.getDescription(language));
    this.setState({ disablePaymentButton: true,
                    inProgress: true });

    const config = {headers: {'Content-Type': 'application/json',
                              'X-Requested-With': 'HttpRequest',
                              'Csrf-Token': 'nocheck'},
                    timeout: 0};
    axios.post('/jet-pay/payment', form, config)
      .then( (response) => {
        const url = response.data;
        document.write(
          "<a style='display: none' id='payLink' " +
            "href='" + url + "'/>");
        document.getElementById('payLink').click();
      })
      .catch( (error) => {
        if (error.response.status === 401) {
          this.props.setLoginOk(false);
        } else {
          console.log(error);
        }
        this.setState({ inProgress: false,
                        disablePaymentButton: false,
                        showLoginDialog: !this.props.loginOk.value });
        this.forceUpdate();
      });
  }

  componentDidMount = () => {
    this.updateTotalPrice(false);
  }

  setDefaultCustomPaymentText = (language) => {
    this.state.declarations.map(
      d => {
        d.customPaymentText =
          text(language,
               {"ru": "Пользовательский платеж",
                "en": "Custom payment",
                "es": "Pago personalizado"});
      }
    );
  }

  render() {
    const { classes } = this.props;
    let language = this.props.language.value;

    return (
      <div>
        {!this.state.inProgress
         ? <div>
             <Paper className={classes.paper}>
               <table className={classes.totalTable}>
                 <tr>
                   <td className={classes.totalText}>
                     {this.getDescription(language)}
                   </td>
                 </tr>
               </table>
               <table>
                 <tr>
                   <td valign="center" className={classes.totalText}>
                     {text(language,
                           {"ru": "Сумма:",
                            "en": "Sum:",
                            "es": "Suma:"})}
                   </td>
                   <td valign="center" className={classes.totalAmount}>
                     {this.state.toPay} €
                   </td>
                   <td valign="center">
                     <Button className={classes.payButton}
                             disabled={this.state.disablePaymentButton ||
                                       !this.props.loginOk.value}
                             onClick={this.handleStartPayment(language)}
                             variant="contained"
                             color="primary">
                       {text(language,
                             {"ru": "Оплатить",
                              "en": "Pay",
                              "es": "Pagar"})}
                     </Button>
                   </td>
                 </tr>
               </table>
             </Paper>

             {this.state.declarations.map(d => (
               <Paper className={classes.paper}>
                 {d.index > 0
                  ? <Button className={classes.buttonDel}
                            variant="contained"
                            color="secondary"
                            onClick={this.delDeclaration(d.index)}>
                      <RemoveIcon/>
                    </Button>
                  : ""}
                 {text(language,
                       {"ru": "Получить результат в виде:",
                        "en": "Get the result in the form:",
                        "es": "Obtenga el resultado en la forma:"})}
                 {isMobile
                  // ------------------------------------------------------------
                  //                          Mobile
                  // ------------------------------------------------------------
                  ? <FormGroup className={classes.marginTop}>
                      <Typography style={{marginTop: "12px"}}>
                        {text(language,
                              {"ru": "Получить результат в виде:",
                               "en": "Get the result in the form:",
                               "es": "Obtenga el resultado en la forma:"})}
                      </Typography>
                      <table>
                        {/* ---- */}
                        <tr>
                          <td colspan="2">
                            <Typography align='left'>
                              {text(language,
                                    {"ru": "Форма 100. НДФЛ. Годовой доход.",
                                     "en": "Form 100. Personal Income Tax. Annual return.",
                                     "es": "Modelo 100. Impuesto sobre la Renta de las Personas Físicas. Declaración anual."})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <img src='/images/document.png'
                                 width='32'
                                 alt=''/>
                          </td>
                          <td>
                            <Typography>
                              {text(language,
                                    {"ru": "Отчет по доходам для налоговой декларации",
                                     "en": "Income report for tax return",
                                     "es": "Informe de ingresos para declaración de impuestos"})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <GreenCheckbox
                                  checked={d.form100_BasicSelected}
                                  onChange={this.handleForm100_Basic(d)}
                                  name="outputXLSX"
                                  color="primary"/>}
                              label="Excel"/>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <GreenCheckbox
                                  checked={d.form100_BasicSelected}
                                  onChange={this.handleForm100_Basic(d)}
                                  name="outputExpalnationNotePDF"
                                  color="primary"/>}
                              label="PDF"/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td className={classes.width42}>
                            <img src='/images/documents.png'
                                 width='32'
                                 alt=''/>
                          </td>
                          <td>
                            <Typography>
                              {text(language,
                                    {"ru": "Детализированный отчет по доходам для налоговой декларации",
                                     "en": "Detailed income report for tax return",
                                     "es": "Informe de ingresos detallado para declaración de impuestos"})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Checkbox
                                  checked={d.form100_DetailSelected}
                                  onChange={this.handleForm100_Detail(d)}
                                  name="outputDetailXLSX"
                                  color="primary"/>}
                              label="Excel"/>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Checkbox
                                  checked={d.form100_DetailSelected}
                                  onChange={this.handleForm100_Detail(d)}
                                  name="outputExpalnationNoteDetailPDF"
                                  color="primary"/>}
                              label="PDF"/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td className={classes.width42}>
                            <img src='/images/ses_file.png'
                                 width='32'
                                 alt=''/>
                          </td>
                          <td>
                            <Typography>
                              {text(language,
                                    {"ru": "Файл Renta WEB",
                                     "en": "Renta WEB file",
                                     "es": "Fichero de Renta WEB"})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Checkbox
                                  checked={d.form100_DetailSelected}
                                  onChange={this.handleForm100_Detail(d)}
                                  name="outputDetailXLSX"
                                  color="primary"/>}
                              label="SES"/>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <Checkbox
                                  checked={d.form100_DetailSelected}
                                  disabled={true}
                                  onChange={this.handleForm100_Detail(d)}
                                  name="outputExpalnationNoteDetailPDF"
                                  color="primary"/>}
                              label="XML"/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td colspan="2">
                            <Typography align='left'>
                              {text(language,
                                    {"ru": "Форма 720. Информационная налоговая декларация.",
                                     "en": "Form 720. Informative Tax Return.",
                                     "es": "Modelo 720. Declaración Informativa."})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <AssignmentTurnedInOutlinedIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td>
                            <Typography>
                              {text(language,
                                    {"ru": "Форма 720",
                                     "en": "Form 720",
                                     "es": "Modelo 720"})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <YellowCheckbox
                                  checked={d.form720_Selected}
                                  onChange={this.handleForm720_Selected(d)}
                                  name="form720_Selected"
                                  disabled={false}/>}
                              label="Form"/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td colspan="2">
                            <Typography align='left'>
                              {text(language,
                                    {"ru": "Форма 721. Информационное уведомление о виртуальных валютах, находящихся за рубежом.",
                                     "en": "Form 721. Information statement on virtual currencies located abroad.",
                                     "es": "Modelo 721. Declaración informativa sobre monedas virtuales situadas en el extranjero."})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <img src="/images/crypto.svg"
                                 height='30px'
                                 width='30px'
                                 alt='logo'/>
                          </td>
                          <td>
                            <Typography>
                              {text(language,
                                    {"ru": "Форма 721",
                                     "en": "Form 721",
                                     "es": "Modelo 721"})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <PurpleCheckbox
                                  checked={d.form721_Selected}
                                  onChange={this.handleForm721_Selected(d)}
                                  name="form721_Selected"
                                  disabled={true}/>}
                              label="Form"/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td colspan="2">
                            <Typography align='left'>
                              {text(language,
                                    {"ru": "Формат FinParser",
                                     "en": "FinParser format",
                                     "es": "Formato FinParser"})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <img src="/images/finParserLogo.svg"
                                 height='30px'
                                 width='30px'
                                 alt='logo'/>
                          </td>
                          <td>
                            <Typography>
                              {text(language,
                                    {"ru": "Сделки и позиции в формате FinParser",
                                     "en": "Deals and positions in FinParser format",
                                     "es": "Ofertas y posiciones en formato FinParser"})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              className={classes.formControlMobile}
                              control={
                                <GreenCheckbox
                                  checked={d.form100_BasicSelected}
                                  onChange={this.handleForm100_Basic(d)}
                                  name="outputFinParser"
                                  color="primary"/>}
                              label="Excel"/>
                          </td>
                        </tr>
                        {/* ---- */}
                        <tr>
                          <td className={classes.width42}>
                            <KeyboardIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td>
                            <Typography>
                              {text(language,
                                    {"ru": "Произвольная сумма",
                                     "en": "Amount typed by the user",
                                     "es": "Cantidad mecanografiada por el usuario"})}
                              <b>*</b>
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.customPayment}
                                  onChange={this.handleCustom(d)}
                                  color="secondary"
                                  name="custom"/>}
                              label=""/>
                            <TextField
                              size="small"
                              className={classes.width100}
                              disabled={!d.customPayment}
                              onChange={this.updateCustomPriceAmount(d)}
                              id="customPaymentAmount"
                              label={text(language,
                                          {"ru": "Сумма",
                                           "en": "Amount",
                                           "es": "Cantidad"})}
                              name="customPaymentAmount"
                              value={d.customPaymentAmount}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              size="small"
                              disabled={!d.customPayment}
                              fullWidth
                              id="customPaymentText"
                              label={text(language,
                                          {"ru": "Пояснение платежа",
                                           "en": "Explanation of payment",
                                           "es": "Explicacion de pago"})}
                              name="customPaymentText"
                              onChange={this.updateCustomPriceText(d)}
                              value={d.customPaymentText}/>
                          </td>
                        </tr>
                      </table>
                    </FormGroup>
                  // ------------------------------------------------------------
                  //                          Desktop
                  // ------------------------------------------------------------
                  : <FormGroup className={classes.marginTop}>
                      <table>
                        <tr>
                          <td colspan="4">
                            <Typography align='left'>
                              {text(language,
                                    {"ru": "Форма 100. НДФЛ. Годовой доход.",
                                     "en": "Form 100. Personal Income Tax. Annual return.",
                                     "es": "Modelo 100. Impuesto sobre la Renta de las Personas Físicas. Declaración anual."})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <img src='/images/document.png'
                                 width='32'
                                 alt=''/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              {text(language,
                                    {"ru": "Отчет по доходам для налоговой декларации",
                                     "en": "Income report for tax return",
                                     "es": "Informe de ingresos para declaración de impuestos"})}
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={d.form100_BasicSelected}
                                  onChange={this.handleForm100_Basic(d)}
                                  name="outputXLSX"
                                  color="primary"/>
                              }
                              label="Excel"/>
                          </td>
                          <td className={classes.tableMinWidth} colspan="2">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={d.form100_BasicSelected}
                                  onChange={this.handleForm100_Basic(d)}
                                  name="outputExpalnationNotePDF"
                                  color="primary"/>
                              }
                              label="PDF"/>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <img src='/images/documents.png'
                                 width='32'
                                 alt=''/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              {text(language,
                                    {"ru": "Детализированный отчет по доходам для налоговой декларации",
                                     "en": "Detailed income report for tax return",
                                     "es": "Informe de ingresos detallado para declaración de impuestos"})}
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.form100_DetailSelected}
                                  onChange={this.handleForm100_Detail(d)}
                                  name="outputDetailXLSX"
                                  color="primary"/>
                              }
                              label="Excel"/>
                          </td>
                          <td className={classes.tableMinWidth} colspan="2">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.form100_DetailSelected}
                                  onChange={this.handleForm100_Detail(d)}
                                  name="outputExpalnationNoteDetailPDF"
                                  color="primary"/>
                              }
                              label="PDF"/>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <img src='/images/ses_file.png'
                                 width='32'
                                 alt=''/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              {text(language,
                                    {"ru": "Файл Renta WEB",
                                     "en": "Renta WEB file",
                                     "es": "Fichero de Renta WEB"})}
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.form100_DetailSelected}
                                  onChange={this.handleForm100_Detail(d)}
                                  name="outputDetailXLSX"
                                  color="primary"/>
                              }
                              label="SES"/>
                          </td>
                          <td className={classes.tableMinWidth} colspan="2">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.form100_DetailSelected}
                                  disabled={true}
                                  onChange={this.handleForm100_Detail(d)}
                                  name="outputExpalnationNoteDetailPDF"
                                  color="primary"/>
                              }
                              label="XML"/>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <Typography align='left'>
                              {text(language,
                                    {"ru": "Форма 720. Информационная налоговая декларация.",
                                     "en": "Form 720. Informative Tax Return.",
                                     "es": "Modelo 720. Declaración Informativa."})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <AssignmentTurnedInOutlinedIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              {text(language,
                                    {"ru": "Форма 720",
                                     "en": "Form 720",
                                     "es": "Modelo 720"})}
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth} colspan="3">
                            <FormControlLabel
                              control={
                                <YellowCheckbox
                                  checked={d.form720_Selected}
                                  onChange={this.handleForm720_Selected(d)}
                                  name="form720_Selected"
                                  disabled={false}/>}
                              label="Form"/>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <Typography align='left'>
                              {text(language,
                                    {"ru": "Форма 721. Информационное уведомление о виртуальных валютах, находящихся за рубежом.",
                                     "en": "Form 721. Information statement on virtual currencies located abroad.",
                                     "es": "Modelo 721. Declaración informativa sobre monedas virtuales situadas en el extranjero."})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <img src="/images/crypto.svg"
                                 height='30px'
                                 width='30px'
                                 alt='logo'/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              {text(language,
                                    {"ru": "Форма 721",
                                     "en": "Form 721",
                                     "es": "Modelo 721"})}
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth} colspan="3">
                            <FormControlLabel
                              control={
                                <PurpleCheckbox
                                  checked={d.form721_Selected}
                                  onChange={this.handleForm721_Selected(d)}
                                  name="form721_Selected"
                                  disabled={true}/>}
                              label="Form"/>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <Typography align='left'>
                              {text(language,
                                    {"ru": "Формат FinParser",
                                     "en": "FinParser format",
                                     "es": "Formato FinParser"})}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <img src="/images/finParserLogo.svg"
                                 height='30px'
                                 width='30px'
                                 alt='logo'/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              {text(language,
                                    {"ru": "Сделки и позиции в формате FinParser",
                                     "en": "Deals and positions in FinParser format",
                                     "es": "Ofertas y posiciones en formato FinParser"})}
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth} colspan="3">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={d.form100_BasicSelected}
                                  onChange={this.handleForm100_Basic(d)}
                                  name="outputFinParser"
                                  color="primary"/>}
                              label="Excel"/>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className={classes.width42}>
                            <KeyboardIcon style={{ fontSize: 32 }}/>
                          </td>
                          <td className={classes.width250}>
                            <Typography>
                              {text(language,
                                    {"ru": "Произвольная сумма",
                                     "en": "Amount typed by the user",
                                     "es": "Cantidad mecanografiada por el usuario"})}
                              <b>*</b>
                            </Typography>
                          </td>
                          <td className={classes.tableMinWidth}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={d.customPayment}
                                  onChange={this.handleCustom(d)}
                                  color="secondary"
                                  name="custom"/>}
                              label=""/>
                          </td>
                          <td className={classes.tableMinWidth} colspan="2">
                            <TextField
                              size="small"
                              className={classes.width100}
                              disabled={!d.customPayment}
                              onChange={this.updateCustomPriceAmount(d)}
                              id="customPaymentAmount"
                              label={text(language,
                                          {"ru": "Сумма",
                                           "en": "Amount",
                                           "es": "Cantidad"})}
                              name="customPaymentAmount"
                              value={d.customPaymentAmount}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              style={{ width: "280px" }}
                              size="small"
                              disabled={!d.customPayment}
                              fullWidth
                              id="customPaymentText"
                              label={text(language,
                                          {"ru": "Пояснение платежа",
                                           "en": "Explanation of payment",
                                           "es": "Explicacion de pago"})}
                              name="customPaymentText"
                              onChange={this.updateCustomPriceText(d)}
                              value={d.customPaymentText}/>
                          </td>
                        </tr>
                      </table>
                    </FormGroup>
                 }
                 <div className={isMobile ? classes.justifyMobile : classes.justify}>
                   <b>* </b>
                   {text(language,
                         {"ru": <span>
                                  В случае, если, например, у пользователя
                                  имеется неиспользованный остаток денежных
                                  средств из прошлых платежей, можно
                                  воспользоваться ими, доплатив разницу.
                                </span>,
                          "en": <span>
                                  If, for example, you have an unused
                                  balance of funds from past payments,
                                  you can use them by paying the difference.
                                </span>,
                          "es": <span>
                                  Si, por ejemplo, tiene un saldo no
                                  utilizado de fondos de pagos anteriores,
                                  puede utilizarlos pagando la diferencia.
                                </span>})}
                 </div>
               </Paper>
             ))}
             {isMobile
              ? <Center>
                  <Button style={{ borderRadius: "64px",
                                   margin: 5,
                                   marginTop: 0,
                                   marginBottom: 20,
                                   paddingLeft: 6,
                                   paddingRight: 6,
                                   minWidth: 60,
                                   minHeight: 60 }}
                          variant="contained"
                          color="primary"
                          onClick={this.scrollTop}>
                    <ArrowUpwardIcon/>
                  </Button>
                </Center>
              : <div className={classes.buttonAddDiv}>
                  <Button className={classes.buttonAdd}
                          variant="contained"
                          color="primary"
                          onClick={this.handleAddDeclaration}>
                    <AddIcon/>
                    {text(language,
                          {"ru": "Добавить декларацию",
                           "en": "Add a declaration",
                           "es": "Agregar una declaración"})}
                  </Button>
                </div>
             }
             <LoginDialog
               open={this.state.showLoginDialog &&
                     !this.props.loginOk.value}
               propertyName='showLoginDialog'
               text={text(language,
                          {"ru": "оплата будет недоступна",
                           "en": "payment will not be available",
                           "es": "el pago no estará disponible"})}
               parent={this}/>
           </div>
         : <Center>
             <div className={classes.loader}>
               <CircularProgress size={90} />
             </div>
           </Center>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const loginOk = state.loginOk;
  const language = state.language;
  return {
    loginOk,
    language
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginOk: (value) => dispatch(setLoginOk(value)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PaymentEs));
