import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ConfirmationDialog from "../confirmationDialog";
import Center from 'react-center';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Upload from "../upload";
import {setCookie, getCookieBoolean} from '../cookieLib';
import AlertDialog from '../alertDialog';
import LoginDialog from '../loginDialog';
import Progress from '../progress';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GreenCheckbox from '../common/greenCheckbox';
import YellowCheckbox from '../common/yellowCheckbox';
import PurpleCheckbox from '../common/purpleCheckbox';
import { inputFormatsList } from '../taxForm';
import { setLoginOk } from '../state/loginOkSlice';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#F5F5F5',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #BDBDBD',
  },
}))(Tooltip);

const styles = {
  buttonBottom: {
    margin: 5,
    marginTop: '15px',
    float: 'right'
  },
  buttonPlus: {
    margin: 5,
    marginTop: 18,
    marginBottom: 0,
    paddingLeft: 6,
    paddingRight: 6,
    minWidth: 32
  },
  input: {
    display: 'none',
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'column',
    marginBottom: '6px'
  },
  cardContentWithoutPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 800,
    flex: 1,
    flexDirection: 'column',
  },
  textField: {
    width: 200,
    margin: 7,
  },
  fileField: {
    marginTop: '0px',
    marginLeft: '7px',
    marginRight: '7px',
  },
  textMax: {
    width: 418,
    margin: 7,
  },
  cleanTable: {
    borderCollapse: 'collapse',
    borderSpacing: '0'
  },
  menu: {
  },
  loader: {
    padding: '200px 0',
  },
  link: {
    display: 'inline-block',
  },
  linkLeftMargin: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 7,
  },
  notchedOutline: {
    borderWidth: "2px",
    borderColor: "#3f51b5 !important"
  },
  width42: {
    width: "42px"
  },
  width250: {
    width: "250px"
  },
  marginTop: {
    marginTop: '10px'
  },
  tableMinWidth: {
    width: "1%",
    whiteSpace: "nowrap"
  },
  imgSpan: {
    width:'16px',
    display:"inline-block"
  },
  formControlMobile: {
    height: "20px",
    marginBottom: "10px"
  },
  vcenter: {
    position: 'absolute', top: '50%',
    transform: 'translate(0%, -50%)',
  }
};

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderWidth: '1px',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const range = (start, stop, step = 1) =>
      Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step);

const year = new Date().getFullYear();

const years = range(2000, year+1)
      .reverse()
      .map(function (i) {
        return {
          value: i,
          label: i,
        };
      });

class TaxFormEs extends React.Component {
  constructor(props) {
    super(props);

    this.setState({ showLoginDialog: !this.props.loginOk.value });

    // This binding is necessary to make `this` work in the callback
    this.sendDataToCreateDeclaration = this.sendDataToCreateDeclaration.bind(this);
    this.handleFillTestData = this.handleFillTestData.bind(this);
    this.addReportFile = this.addReportFile.bind(this);
    this.requestProgress = this.requestProgress.bind(this);
  }

  maxInputFilesCount = 10;
  submitURL = "/es/submitIBReportToDecl";

  state = {
    taxpayerDataFile: null,
    lastName: '',
    firstName: '',
    middleName: '',
    nif: '', // Tax Identification Number
    taxYear: '',

    futuresBasedOnVariationMargin: getCookieBoolean("futuresBasedOnVariationMargin", false),
    enable2MonthsRule: getCookieBoolean("enable2MonthsRule", true),
    enable2MonthsRuleShorts: getCookieBoolean("enable2MonthsRuleShorts", false),
    consider2or12MonthsDiff: getCookieBoolean("consider2or12MonthsDiff", true),
    useLastTradingDayExRates: getCookieBoolean("useLastTradingDayExRates", false),
    useOnlyECBExRates: getCookieBoolean("useOnlyECBExRates", true),

    outputXLSX: getCookieBoolean("outputXLSX", false),
    outputExpalnationNotePDF: getCookieBoolean("outputExpalnationNotePDF", false),
    outputDetailXLSX: getCookieBoolean("outputDetailXLSX", false),
    outputExpalnationNoteDetailPDF: getCookieBoolean("outputExpalnationNoteDetailPDF", false),
    outputFinParser: getCookieBoolean("outputFinParser", false),
    outputForm_100_SES: getCookieBoolean("outputForm_100_SES", false),
    outputForm_100_XML: getCookieBoolean("outputForm_100_XML", false),
    outputForm_720: getCookieBoolean("outputForm_720", true),
    outputForm_721: getCookieBoolean("outputForm_721", false),

    inProgress: false,
    documentGeneration: false,

    startStage: text(this.props.language.value,
                     {"ru": "Чтение файлов отчетов",
                      "en": "Reading report files",
                      "es": "Lectura de archivos de informe"}),
    progress: {
      id: '',
      percent: 0,
      stage: this.startStage,
      timeout: 1000},

    inputFiles: [{
      fileIndex: 0,
      fileContents: null,
      fileName: '',
      inputFormat: 'ib'}],

    showConfDialog: false,
    showAlertDialog: false,
    showLoginDialog: !this.props.loginOk.value,
    errorShow: false,
    errorMessage: '',
    warningShow: false,
    warningMessages: [],
    warningConfirm: false,
  };

  handleAlert() {
    this.setState({ showAlertDialog: true });
  };

  checkFormFilled() {
    var result = true;
    this.setState({ nifError: false,
                    nifErrorText: ''});
    this.setState({ taxYearError: false,
                    taxYearErrorText: ''});
    if (this.state.taxYear === '') {
      this.setState({ taxYearError: true,
                      taxYearErrorText:
                      text(this.props.language.value,
                           {"ru": "Налоговый год не может быть пустым.",
                            "en": "The tax year cannot be empty.",
                            "es": "El año fiscal no puede estar vacío."})});
      result = false;
    }

    this.state.inputFiles.forEach(f => {
      if (f.fileContents != null) {
        f.fileError = false;
        f.fileErrorText = '';
      } else {
        f.fileError = true;
        f.fileErrorText = text(this.props.language.value,
                               {"ru": "Добавьте файл отчета.",
                                "en": "Add a report file.",
                                "es": "Agregar un archivo de informe."});
        result = false;
      }});

    return result;
  }

  dateFormat(date) {
    if (date instanceof Date) {
      var [month, day, year] = date.toLocaleDateString("en-US").split("/");
      if (day.length < 2) {
        day = '0' + day;
      }
      return day + '.' + month + '.' + year;
    } else {
      return date;
    }
  }

  sendDataToCreateDeclaration(event) {
    if (event != null) {
      event.preventDefault();
    }
    if (!this.checkFormFilled()) {
      this.handleAlert();
      return;
    }

    this.setState({ inProgress: true, documentGeneration: true });
    const config = { headers: { 'Content-Type': 'multipart/form-data',
                                'X-Requested-With': 'HttpRequest',
                                'Csrf-Token': 'nocheck'},
                     responseType: 'json',
                     timeout: 0};
    const form = new FormData();
    const data = {};
    ['lastName',
     'firstName',
     'middleName',
     'nif'].forEach(f => {
       switch (f) {
       default:
         data[f] = this.state[f];
       }
     });
    form.append(['iserInfoData'], JSON.stringify(data));

    var inputFormats = [];
    var i = 0;
    this.state.inputFiles.forEach(item => {
      form.append('report'+i, item.fileContents.file);

      const inputFormatData = {};
      ['inputFormat'].forEach(f => {
        switch (f) {
        default:
          inputFormatData[f] = item[f];
        }
      });
      inputFormats[i] = inputFormatData;
      i += 1;
    });
    form.append('inputFormats', JSON.stringify(inputFormats));

    form.append('outputFormats', JSON.stringify({
      'expl_note_xlsx': this.state.outputXLSX,
      'expl_note_pdf': this.state.outputExpalnationNotePDF,
      'expl_note_detail_xlsx': this.state.outputDetailXLSX,
      'expl_note_detail_pdf': this.state.outputExpalnationNoteDetailPDF,
      'form_100_SES': this.state.outputForm_100_SES,
      'form_100_XML': this.state.outputForm_100_XML,
      'form_720': this.state.outputForm_720,
      'form_721': this.state.outputForm_721,
      'finparser_xlsx' : this.state.outputFinParser}));

    const settingsData = {};
    ['taxYear',
     'futuresBasedOnVariationMargin',
     'enable2MonthsRule',
     'enable2MonthsRuleShorts',
     'consider2or12MonthsDiff',
     'useLastTradingDayExRates',
     'useOnlyECBExRates',
     'language'].forEach(f => {
       switch (f) {
       case 'language':
         settingsData[f] = this.props.language.value;
         break;
       case 'useOnlyECBExRates':
         // 1 - CBR
         // 2 - ECB
         settingsData['acceptedDataSources'] = this.state[f]
           ? '[2]'
           : '[2,1]';
         break;
       default:
         settingsData[f] = this.state[f];
       }
     });
    form.append(['settingsData'], JSON.stringify(settingsData));

    ['warningConfirm'].forEach(f => {
      form.append([f], this.state[f]);
    });

    this.setState({ errorShow: false, errorMessage: '' });
    this.setState({ warningConfirm: false });

    this.setState({progress: {
      id: '',
      percent: 0,
      stage: this.startStage,
      timeout: 1000}}, () => {
        axios.post(this.submitURL, form, config)
          .then( (response) => {
            this.state.progress.id = response.data.progressId;
            setTimeout(this.requestProgress, 100);
          })
          .catch( (error) => {
            this.setState({ inProgress: false, documentGeneration: false});
            let self = this;
            if (error.response.status === 413) {
              self.setState({
                errorShow: true,
                errorMessage: 'Размер файла превышает допустимый лимит'
              });
            } else if (error.response.status === 401) {
              this.props.setLoginOk(false);
              this.setState({ showLoginDialog: !this.props.loginOk.value });
              this.forceUpdate();
            } else {
              console.log(error);
            }
          });
      });
  };

  requestProgress = () => {
    axios.get('/progress/get/' + this.state.progress.id)
      .then((response) => {
        let isStoped = response.data.isStoped;
        let isComplete = response.data.isComplete;

        this.state.progress.percent = response.data.percent;
        this.state.progress.stage = response.data.stage;
        this.forceUpdate();

        if (isStoped) {
          let self = this;
          let warnResp = JSON.parse(response.data.message);
          self.setState({
            inProgress: false, documentGeneration: false,
            warningShow: true,
            warningMessages: warnResp
          }, () => {this.forceUpdate();});

        } else if (isComplete) {
          const config = { headers: { 'Content-Type': 'multipart/form-data',
                                      'X-Requested-With': 'HttpRequest',
                                      'Csrf-Token': 'nocheck'},
                           responseType: 'blob',
                           timeout: 0};
          axios.post('/progress/result/' + this.state.progress.id, null, config)
            .then( (response) => {
              const fileName = response.headers['content-disposition']
                    .split("attachment; filename=")[1];
              var download = require('downloadjs');
              this.setState({ inProgress: false, documentGeneration: false});
              download(response.data, fileName);
            })
            .catch( (error) => {
              this.setState({ inProgress: false, documentGeneration: false });
              console.log(error);
            });
        } else {
          setTimeout(this.requestProgress, this.state.progress.timeout);
          this.state.progress.timeout = Math.min(
            this.state.progress.timeout + 1000, 5000);
        }
      })
      .catch((error) => {
        this.setState({ inProgress: false, documentGeneration: false});
        console.log(error);
      });
  }

  addReportFile = inputFormat => event => {
    if (event != null) {
      event.preventDefault();
    }

    let idx = Math.max.apply(null, this.state.inputFiles.map(i => {
      return i.fileIndex;
    })) + 1;
    this.state.inputFiles.push(
      {fileIndex: idx,
       fileContents: null,
       fileName: '',
       inputFormat: inputFormat});
    this.forceUpdate();
    return idx;
  }

  delReportFile = idx => event => {
    if (event != null) {
      event.preventDefault();
    }
    this.setState({ inputFiles: this.state.inputFiles.filter(
      i => {
        return idx !== i.fileIndex;
      })});
    this.forceUpdate();
  }

  handleChange = name => event => {
    var value = null;
    if (((typeof this.state[name]) === "number") &&
        ((typeof event.target.value) === "string")) {
      value = parseInt(event.target.value);
    } else {
      value = event.target.value;
    }
    this.setState({
      [name]: value,
    });
    if (name === 'taxYear') {
      this.forceUpdate();
    }
  };

  handleCheckboxChange = event => {
    this.setState({[event.target.name]: event.target.checked });
  };

  handleCheckboxChangeWithCookie = cname => event => {
    this.setState({[event.target.name]: event.target.checked });
    if (cname != null) {
      setCookie(cname, event.target.checked);
    }
  };

  handleMultipleUpload = inputFormat => event => {
    Array.from(event.target.files).slice(1) // tail
      .map(file => {
        let idx = this.addReportFile(inputFormat)();
        this.state.inputFiles[idx].fileContents = {
          name: event.target.files[idx].name,
          file: event.target.files[idx]
        };
        this.state.inputFiles[idx].fileName = file.name;
      });
  }

  handleAttachFile = filesListItem => event => {
    filesListItem.fileContents = {
      name: event.target.files[0].name,
      file: event.target.files[0]
    };
    filesListItem.fileName = event.target.value;
    this.forceUpdate();
  }

  handleInputFormatChange = filesListItem => event => {
    filesListItem.inputFormat = event.target.value;
    this.forceUpdate();
  };

  handleAccountOpenCloseDateChange = (filesListItem, name) => (date) => {
    filesListItem[name] = { selectedDate: date };
    this.forceUpdate();
  };

  handleFillTestData() {
    this.handleOpen();
  };

  handleConfirmWarning = value => {
    let confirm = (value === 'ok' ? true : false);
    this.setState({ warningShow: false }, () => {
      this.setState({warningMessages: [],
                     warningConfirm: confirm },
                    () => {
                      if (confirm) {
                        this.sendDataToCreateDeclaration(null);
                      }
                    });
    });
  }

  handleOpen = () => {
    this.setState({ showConfDialog: true });
  };

  handleClose = value => {
    this.setState({ showConfDialog: false });
    if (value === 'ok') {
      this.setState({ inProgress: true });
      axios.get('/es/fooUserInfo')
        .then( (response) => {
          this.setState({ inProgress: false });
          Object.keys(response.data).map(key => {
            this.setState({
              [key]: response.data[key]
            });
            return null;
          });
        })
        .catch( (error) => {
          this.setState({ inProgress: false });
          console.log(error);
        });
    }
  };

  render() {
    const { classes } = this.props;
    let language = this.props.language.value;
    var inputFormatsListEs = inputFormatsList(language);
    inputFormatsListEs['form720es'] = {
      brokerName: text(language,
                       {"ru": '',
                        "en": '',
                        "es": ''}),
      name: text(language,
                 {"ru": 'Форма 720 (Информационная налоговая декларация)',
                  "en": 'Form 720 (Informative Tax Return)',
                  "es": 'Modelo 720 (Declaración Informativa)'}),
      sortName: text(language,
                     {"ru": 'Form 720',
                      "en": 'Form 720',
                      "es": 'Form 720'}),
      icon: <AssignmentTurnedInOutlinedIcon style={{ fontSize: 18,
                                                     marginBottom: "-2px"}}/>,
      extensionAccept: '.720',
      helpURL: "/help/IBDeclarationReport"
    };

    return (
      <>
        {isMobile
         ? this.renderMobile(inputFormatsListEs)
         : this.renderDesktop(inputFormatsListEs)}
        <Center>
          <ConfirmationDialog
            open={this.state.showConfDialog}
            onClose={this.handleClose}
            title={text(language,
                        {"ru": "Заполнение формы",
                         "en": "Filling out the form",
                         "es": "Llenando el formulario"})}
            message={text(language,
                          {"ru": "Форма будет заполнена тестовой информацией. " +
                           "Все уже введенные данные будут перезаписаны. Продолжить?",
                           "en": "The form will be filled with test information. " +
                           "All data already entered will be overwritten. Continue?",
                           "es": "El formulario se llenará con la información de la prueba. " +
                           "Todos los datos ya introducidos se sobrescribirán. ¿Continuar?"})}/>
          <ConfirmationDialog
            open={this.state.warningShow}
            onClose={this.handleConfirmWarning}
            messages={this.state.warningMessages}
            title={text(language,
                        {"ru": "Подтвердите генерацию отчетов",
                         "en": "Confirm report generation",
                         "es": "Confirmar generación de informes"})}
            ok={text(language,
                     {"ru": "Подтвердить",
                      "en": "Confirm",
                      "es": "Confirmar"})}
            cancel={text(language,
                         {"ru": "Отмена",
                          "en": "Cancel",
                          "es": "Cancelar"})}/>
          <AlertDialog
            open={this.state.errorShow}
            propertyName='errorShow'
            parent={this}
            title={text(language,
                        {"ru": "Ошибка обработки отчета",
                         "en": "Report processing error",
                         "es": "Informe de error de procesamiento"})}
            severity='error'
            message={this.state.errorMessage}/>
          <AlertDialog
            open={this.state.showAlertDialog}
            propertyName='showAlertDialog'
            parent={this}
            title={text(language,
                        {"ru": "Ошибка заполнения данных",
                         "en": "Data filling error",
                         "es": "Error de llenado de datos"})}
            message={text(language,
                          {"ru": "Заполните необходимые поля формы.",
                           "en": "Fill in the required form fields",
                           "es": "Rellene los campos del formulario requeridos"})}/>
          <LoginDialog
            open={this.state.showLoginDialog &&
                  !this.props.loginOk.value}
            propertyName='showLoginDialog'
            text={text(language,
                       {"ru": "генерация отчетов будет недоступна",
                        "en": "report generation will be unavailable",
                        "es": "la generación de informes no estará disponible"})}
            parent={this}/>
        </Center>
      </>);
  }

  renderMobile(inputFormatsListEs) {
    const { classes } = this.props;
    let language = this.props.language.value;

    return (
      <>
        {!this.state.inProgress
         ? (
           <form className={classes.container}
                 noValidate autoComplete="off"
                 id="taxForm">
             {this.state.inputFiles.map(f => (
               <Card style={{marginBottom: '4px'}}>
                 <CardContent style={{display: "flow"}}>
                   <TextField
                     id="inputFormat"
                     select
                     label={text(language,
                                 {"ru": "Формат исходных данных",
                                  "en": "Source data format",
                                  "es": "Formato de datos de origen"})}
                     value={f.inputFormat}
                     onChange={this.handleInputFormatChange(f)}
                     style={{width: "100%"}}
                     SelectProps={{
                       MenuProps: {
                         className: classes.menu,
                       },
                     }}>
                     {Object.keys(inputFormatsListEs).map(k => (
                       <MenuItem key={k} value={k}>
                         <span className={classes.imgSpan}>
                           {(inputFormatsListEs[k].icon === undefined)
                            ? <img src={inputFormatsListEs[k].img}
                                   height='16px'
                                   style={{ maxWidth: '18px'}}
                                   alt='logo'>
                              </img>
                            : inputFormatsListEs[k].icon}
                         </span>
                         &nbsp;
                         {inputFormatsListEs[k].sortName}
                       </MenuItem>
                     ))}
                   </TextField>
                   <div className={classes.linkLeftMargin}>
                     <Link to={inputFormatsListEs[f.inputFormat].helpURL}
                           component={NavLink}
                           className={classes.link}>
                       {text(language,
                             {"ru": "Как получить данный отчет",
                              "en": "How to get this report",
                              "es": "Cómo obtener este informe"})}
                     </Link>
                   </div>
                   <Upload label={text(language,
                                       {"ru": "Файл отчета",
                                        "en": "Report file",
                                        "es": "Archivo de informe"})}
                           accept={inputFormatsListEs[f.inputFormat].extensionAccept}
                           error={f.fileError}
                           helperText={f.fileErrorText}
                           parent={this}
                           dataFromParent = {f.fileName}
                           handleChange={this.handleAttachFile(f)}
                           handleMultipleUpload={this.handleMultipleUpload(f.inputFormat)}/>
                   {f.fileIndex === 0
                    ? <Button className={classes.buttonPlus}
                              style={{float: "right"}}
                              variant="contained"
                              color="primary"
                              disabled={this.state.inputFiles.length >=
                                        this.maxInputFilesCount}
                              onClick={this.addReportFile(f.inputFormat)}>
                        <AddIcon/>
                      </Button>
                    : <Button className={classes.buttonPlus}
                              style={{float: "right"}}
                              variant="contained"
                              color="secondary"
                              onClick={this.delReportFile(f.fileIndex)}>
                        <RemoveIcon/>
                      </Button>}
                 </CardContent>
               </Card>
             ))}

             <Card style={{marginBottom: '4px'}}>
               <CardContent>
                 <Center>
                   <TextField
                     id="taxYear"
                     select
                     error={this.state.taxYearError}
                     helperText={this.state.taxYearErrorText}
                     label={text(language,
                                 {"ru": "Налоговый год*",
                                  "en": "Tax year",
                                  "es": "Año fiscal"})}
                     value={this.state.taxYear}
                     onChange={this.handleChange('taxYear')}
                     style={{minWidth: "200px",
                             marginTop: "8px"}}
                     variant="outlined"
                     margin="normal"
                     InputProps={{
                       classes: {
                         notchedOutline: classes.notchedOutline
                       }
                     }}
                     SelectProps={{
                       MenuProps: {
                         className: classes.menu,
                       },
                     }}>
                     {years.map(option => (
                       <MenuItem key={option.value} value={option.value}>
                         {option.label}
                       </MenuItem>
                     ))}
                   </TextField>
                 </Center>
                 <Typography style={{marginTop: "12px"}}>
                   {text(language,
                         {"ru": "Получить результат в виде:",
                          "en": "Get the result in the form:",
                          "es": "Obtenga el resultado en la forma:"})}
                 </Typography>
                 <table>
                   {/* ---- */}
                   <tr>
                     <td colspan="2">
                       <Typography align='left'>
                         {text(language,
                               {"ru": "Форма 100. НДФЛ. Годовой доход.",
                                "en": "Form 100. Personal Income Tax. Annual return.",
                                "es": "Modelo 100. Impuesto sobre la Renta de las Personas Físicas. Declaración anual."})}
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td className={classes.width42}>
                       <img src='/images/document.png'
                            width='32'
                            alt=''/>
                     </td>
                     <td>
                       <Typography>
                         {text(language,
                               {"ru": "Отчет по доходам для налоговой декларации",
                                "en": "Income report for tax return",
                                "es": "Informe de ingresos para declaración de impuestos"})}
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <GreenCheckbox
                             checked={this.state.outputXLSX}
                             onChange={this.handleCheckboxChangeWithCookie("outputXLSX")}
                             name="outputXLSX"
                             color="primary"/>}
                         label="Excel"/>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <GreenCheckbox
                             checked={this.state.outputExpalnationNotePDF}
                             onChange={this.handleCheckboxChangeWithCookie("outputExpalnationNotePDF")}
                             name="outputExpalnationNotePDF"
                             color="primary"/>}
                         label="PDF"/>
                     </td>
                   </tr>
                   {/* ---- */}
                   <tr>
                     <td className={classes.width42}>
                       <img src='/images/documents.png'
                            width='32'
                            alt=''/>
                     </td>
                     <td>
                       <Typography>
                         {text(language,
                               {"ru": "Детализированный отчет по доходам для налоговой декларации",
                                "en": "Detailed income report for tax return",
                                "es": "Informe de ingresos detallado para declaración de impuestos"})}
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <Checkbox
                             checked={this.state.outputDetailXLSX}
                             onChange={this.handleCheckboxChangeWithCookie("outputDetailXLSX")}
                             name="outputDetailXLSX"
                             color="primary"/>}
                         label="Excel"/>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <Checkbox
                             checked={this.state.outputExpalnationNoteDetailPDF}
                             onChange={this.handleCheckboxChangeWithCookie("outputExpalnationNoteDetailPDF")}
                             name="outputExpalnationNoteDetailPDF"
                             color="primary"/>}
                         label="PDF"/>
                     </td>
                   </tr>
                   {/* ---- */}
                   <tr>
                     <td className={classes.width42}>
                       <img src='/images/ses_file.png'
                              width='32'
                              alt=''/>
                     </td>
                     <td>
                       <Typography>
                         {text(language,
                               {"ru": "Файл Renta WEB",
                                "en": "Renta WEB file",
                                "es": "Fichero de Renta WEB"})}
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <Checkbox
                             checked={this.state.outputForm_100_SES}
                             onChange={this.handleCheckboxChangeWithCookie("outputForm_100_SES")}
                             name="outputForm_100_SES"
                             color="primary"/>}
                         label="SES">
                       </FormControlLabel>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <Checkbox
                             checked={this.state.outputForm_100_XML}
                             disabled={true}
                             onChange={this.handleCheckboxChangeWithCookie("outputForm_100_XML")}
                             name="outputForm_100_XML"
                             color="primary"/>}
                         label="XML">
                       </FormControlLabel>
                     </td>
                   </tr>
                   {/* ---- */}
                   <tr>
                     <td colspan="2">
                       <Typography align='left'>
                         {text(language,
                               {"ru": "Форма 720. Информационная налоговая декларация.",
                                "en": "Form 720. Informative Tax Return.",
                                "es": "Modelo 720. Declaración Informativa."})}
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td className={classes.width42}>
                       <AssignmentTurnedInOutlinedIcon style={{ fontSize: 32 }}/>
                     </td>
                     <td>
                       <Typography>
                         {text(language,
                               {"ru": "Форма 720",
                                "en": "Form 720",
                                "es": "Modelo 720"})}
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <YellowCheckbox
                             checked={this.state.outputForm_720}
                             onChange={this.handleCheckboxChangeWithCookie("outputForm_720")}
                             name="outputForm_720"
                             disabled={false}/>}
                         label="Form"/>
                     </td>
                   </tr>
                   {/* ---- */}
                   <tr>
                     <td colspan="2">
                       <Typography align='left'>
                         {text(language,
                               {"ru": "Форма 721. Информационное уведомление о виртуальных валютах, находящихся за рубежом.",
                                "en": "Form 721. Information statement on virtual currencies located abroad.",
                                "es": "Modelo 721. Declaración informativa sobre monedas virtuales situadas en el extranjero."})}
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td className={classes.width42}>
                       <img src="/images/crypto.svg"
                            height='30px'
                            width='30px'
                            alt='logo'/>
                     </td>
                     <td>
                       <Typography>
                         {text(language,
                               {"ru": "Форма 721",
                                "en": "Form 721",
                                "es": "Modelo 721"})}
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <PurpleCheckbox
                             checked={this.state.outputForm_721}
                             onChange={this.handleCheckboxChangeWithCookie("outputForm_721")}
                             name="outputForm_721"
                             disabled={true}/>}
                         label="Form"/>
                     </td>
                   </tr>
                   {/* ---- */}
                   <tr>
                     <td colspan="2">
                       <Typography align='left'>
                         {text(language,
                               {"ru": "Формат FinParser",
                                "en": "FinParser format",
                                "es": "Formato FinParser"})}
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td className={classes.width42}>
                       <img src="/images/finParserLogo.svg"
                            height='30px'
                            width='30px'
                            alt='logo'/>
                     </td>
                     <td>
                       <Typography>
                         {text(language,
                               {"ru": "Сделки и позиции в формате FinParser",
                                "en": "Deals and positions in FinParser format",
                                "es": "Ofertas y posiciones en formato FinParser"})}
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <GreenCheckbox
                             checked={this.state.outputFinParser}
                             onChange={this.handleCheckboxChangeWithCookie("outputFinParser")}
                             name="outputFinParser"
                             color="primary"/>}
                         label="Excel"/>
                     </td>
                   </tr>
                 </table>
               </CardContent>
             </Card>

             <Card style={{marginBottom: '4px'}}>
               <Accordion onChange={(event, expanded) => {
                 setCookie("expandTaxpayerDataSection", expanded);
                 this.forceUpdate();}}
                          expanded={getCookieBoolean("expandTaxpayerDataSection")}>
                 <AccordionSummary
                   expandIcon={<ExpandMoreIcon />}
                   aria-controls="panel1a-content"
                   id="panel1a-header" >
                   <Typography>
                     {text(language,
                           {"ru": "Данные налогоплательщика",
                            "en": "Taxpayer data",
                            "es": "Datos del contribuyente"})}
                   </Typography>
                 </AccordionSummary>
                 <AccordionDetails
                   style={{paddingTop: "0px",
                           paddingBottom: "16px",
                           paddingLeft: "16px",
                           paddingRight: "16px",
                           display: "flow"}}>
                     <TextField
                       id="lastName"
                       label={text(language,
                                   {"ru": "Фамилия",
                                    "en": "Surname",
                                    "es": "Apellido"})}
                       style={{width: "100%"}}
                       value={this.state.lastName}
                       onChange={this.handleChange('lastName')}
                       margin="dense"/>
                   <TextField
                     id="firstName"
                     label={text(language,
                                 {"ru": "Имя",
                                  "en": "Name",
                                  "es": "Nombre"})}
                     style={{width: "100%"}}
                     value={this.state.firstName}
                     onChange={this.handleChange('firstName')}
                     margin="dense"/>
                   <TextField
                     id="middleName"
                     label={text(language,
                                 {"ru": "Отчество",
                                  "en": "Middle name",
                                  "es": "Patronímico"})}
                     style={{width: "100%"}}
                     value={this.state.middleName}
                     onChange={this.handleChange('middleName')}
                     margin="dense"/>
                   <TextField
                     id="nif"
                     error={this.state.nifError}
                     helperText={this.state.nifErrorText}
                     label="NIF"
                     value={this.state.nif}
                     onChange={this.handleChange('nif')}
                     style={{width: "100%"}}
                     margin="dense"/>
                   <Button
                     style={{
                       marginBottom: '24px',
                       marginTop: '16px',
                       float: 'right'
                     }}
                     variant="contained"
                     color="secondary"
                     onClick={this.handleFillTestData}>
                     {text(language,
                           {"ru": "Заполнить тестовыми данными",
                            "en": "Fill with test data",
                            "es": "Rellenar con datos de prueba"})}
                   </Button>
                 </AccordionDetails>
               </Accordion>
             </Card>

             <Card style={{marginBottom: '4px'}}>
               <Accordion onChange={(event, expanded) => {
                 setCookie("expandIgnoresSection", expanded);
                 this.forceUpdate();}}
                          expanded={getCookieBoolean("expandIgnoresSection")}>
                 <AccordionSummary
                   expandIcon={<ExpandMoreIcon />}
                   aria-controls="panel1a-content"
                   id="panel1a-header" >
                   <Typography>
                     {text(language,
                           {"ru": "Дополнительные параметры",
                            "en": "Additional options",
                            "es": "Opciones adicionales"})}
                   </Typography>
                 </AccordionSummary>
                 <AccordionDetails
                   style={{paddingTop: "0px",
                           paddingBottom: "16px",
                           paddingLeft: "16px",
                           paddingRight: "16px",
                           display: "flow"}}>
                   <FormControlLabel
                     style={{width: "100%"}}
                     margin="dense"
                     control={
                       <Switch
                         checked={this.state.useLastTradingDayExRates}
                         onChange={this.handleCheckboxChangeWithCookie("useLastTradingDayExRates")}
                         name="useLastTradingDayExRates"
                         color="primary"/>
                     }
                     label={
                       <div>
                         <Typography>
                           {text(language,
                                 {"ru": "Использовать обменный курс последнего торгового дня для всех сделок и дивидендов",
                                  "en": "Use the last trading day's exchange rate for all trades and dividends",
                                  "es": "Utilice el tipo de cambio del último día de negociación para todas las operaciones y dividendos."})}
                         </Typography>
                       </div>}>
                   </FormControlLabel>
                   <FormControlLabel
                     style={{width: "100%"}}
                     margin="dense"
                     control={
                       <Checkbox
                         checked={this.state.useOnlyECBExRates}
                         onChange={this.handleCheckboxChangeWithCookie("useOnlyECBExRates")}
                         name="useOnlyECBExRates"
                         color="primary"/>
                     }
                     label={<div>
                              <Typography>
                                {text(language,
                                      {"ru": <span>
                                               Использовать только обменные
                                               курсы, публикуемые ЕЦБ
                                             </span>,
                                       "en": <span>
                                               Use only exchange rates
                                               published by the ECB
                                             </span>,
                                       "es": <span>
                                               Utilice únicamente los tipos
                                               de cambio publicados por el BCE
                                             </span>})}
                              </Typography>
                            </div>}>
                   </FormControlLabel>
                   <FormControlLabel
                     style={{width: "100%"}}
                     margin="dense"
                     control={
                       <Checkbox
                         checked={this.state.enable2MonthsRule}
                         onChange={this.handleCheckboxChangeWithCookie("enable2MonthsRule")}
                         name="enable2MonthsRule"
                         color="primary"/>
                     }
                     label={
                       <div>
                         <Typography>
                           {text(language,
                                 {"ru": "Учитывать правило 2 месяцев",
                                  "en": "Consider the 2 month rule",
                                  "es": "Considere la regla de 2 meses"})}
                         </Typography>
                       </div>}/>
                   <FormControlLabel
                     style={{width: "100%"}}
                     margin="dense"
                     control={
                       <Switch
                         checked={this.state.enable2MonthsRuleShorts}
                         onChange={this.handleCheckboxChangeWithCookie("enable2MonthsRuleShorts")}
                         name="enable2MonthsRuleShorts"
                         color="primary"
                         disabled={!this.state.enable2MonthsRule}/>
                     }
                     label={
                       <div>
                         <Typography>
                           {text(language,
                                 {"ru": "Учитывать короткие продажи для правила 2 месяцев",
                                  "en": "Account for short sales for the 2 month rule",
                                  "es": "Cuenta para ventas cortas para la regla de 2 meses"})}
                         </Typography>
                       </div>}>
                   </FormControlLabel>
                   <FormControlLabel
                     style={{width: "100%"}}
                     margin="dense"
                     control={
                       <Switch
                         checked={this.state.consider2or12MonthsDiff}
                         onChange={this.handleCheckboxChangeWithCookie("consider2or12MonthsDiff")}
                         name="consider2or12MonthsDiff"
                         color="primary"
                         disabled={!this.state.enable2MonthsRule}/>
                     }
                     label={
                       <div>
                         <Typography>
                           {text(language,
                                 {"ru": <span>
                                          Учитывать разницу для 2 и 12 месяцев
                                          в зависимости от биржи, на которой
                                          произошла сделка (только для отчетов IB)
                                        </span>,
                                  "en": <span>
                                          Take into account the difference for 2 and 12
                                          months depending on the exchange on which
                                          the transaction occurred (only for IB reports)
                                        </span>,
                                  "es": <span>
                                          Tenga en cuenta la diferencia entre 2 y 12 meses
                                          dependiendo del intercambio en el que se realizó
                                          la transacción <br/> (solo para informes IB)
                                        </span>})}
                         </Typography>
                       </div>}/>
                   <FormControlLabel
                     style={{width: "100%"}}
                     margin="dense"
                     control={
                       <Switch
                         checked={this.state.futuresBasedOnVariationMargin}
                         onChange={this.handleCheckboxChangeWithCookie("futuresBasedOnVariationMargin")}
                         name="futuresBasedOnVariationMargin"
                         color="primary"/>
                     }
                     label={<div>
                              <Typography>
                                {text(language,
                                      {"ru": <span>
                                               Считать фьючерсы на основе
                                               вариационной маржи
                                             </span>,
                                       "en": <span>
                                               Calculate futures based
                                               on variation margin
                                             </span>,
                                       "es": <span>
                                               Calcular futuros basados ​​
                                               en el margen de variación
                                             </span>})}
                              </Typography>
                            </div>}>
                   </FormControlLabel>
                 </AccordionDetails>
               </Accordion>
             </Card>

             <div>
               <Button style={{
                 margin: 5,
                 marginTop: '10px',
                 marginBottom: '100px',
                 float: 'right'}}
                       variant="contained"
                       color="primary"
                       disabled={!this.props.loginOk.value}
                       onClick={this.sendDataToCreateDeclaration}>
                 {text(language,
                       {"ru": "Сгенерировать отчеты",
                        "en": "Generate Reports",
                        "es": "Generar informes"})}
               </Button>
             </div>
           </form>
         )
         : <Center>
             <div className={classes.loader}>
               {this.state.documentGeneration
                ? <Progress
                    parent={this}
                    context={this.context}/>
                : <CircularProgress size={90}/>
               }
             </div>
           </Center>
        }
      </>);
  }

  renderDesktop(inputFormatsListEs) {
    const { classes } = this.props;
    let language = this.props.language.value;

    return (
      <Center>
        <input
          hidden={true}
          accept=".json"
          ref={input => this.inputTaxpayerDataFile = input}
          type="file" onChange={this.handleAttachTaxpayerDataFile} />
        {!this.state.inProgress
         ? <form className={classes.container}
                 noValidate autoComplete="off"
                 id="taxForm">

             {this.state.inputFiles.map(f => (
               <Card className={classes.card}>
                 <CardContent>
                   <table className="cleanTable" cellspacing="0">
                     <tr>
                       <td>
                         <span className={classes.fileField}>
                           <Upload
                             label={text(language,
                                         {"ru": "Файл отчета",
                                          "en": "Report file",
                                          "es": "Archivo de informe"})}
                             accept={inputFormatsListEs[f.inputFormat].extensionAccept}
                             error={f.fileError}
                             helperText={f.fileErrorText}
                             parent={this}
                             dataFromParent = {f.fileName}
                             handleChange={this.handleAttachFile(f)}
                             handleMultipleUpload={this.handleMultipleUpload(f.inputFormat)}/>
                         </span>
                       </td>
                       <td>
                         <TextField
                           id="inputFormat"
                           select
                           label={text(language,
                                       {"ru": "Формат исходных данных",
                                        "en": "Source data format",
                                        "es": "Formato de datos de origen"})}
                           value={f.inputFormat}
                           onChange={this.handleInputFormatChange(f)}
                           className={classes.textMax}
                           margin="normal"
                           SelectProps={{
                             MenuProps: {
                               className: classes.menu,
                             },
                           }}>
                           {Object.keys(inputFormatsListEs).map(k => (
                             <MenuItem key={k} value={k}>
                               {(inputFormatsListEs[k].icon === undefined)
                                ? <span className={classes.imgSpan}>
                                    <img src={inputFormatsListEs[k].img}
                                         height='16px'
                                         style={{ maxWidth: '18px'}}
                                         alt='logo'>
                                    </img>
                                  </span>
                                : inputFormatsListEs[k].icon}
                               &nbsp;
                               {inputFormatsListEs[k].name}
                             </MenuItem>
                           ))}
                         </TextField>
                       </td>
                       <td>
                         {f.fileIndex === 0
                          ? (<HtmlTooltip
                               title={
                                 <React.Fragment>
                                   {text(language,
                                         {"ru": <span>
                                                  Добавить отчет.<p/>
                                                  В случае, если требуется составить декларацию
                                                  на основе нескольких отчетов (возможно различных)
                                                  брокеров.
                                                </span>,
                                          "en": <span>
                                                  Add report.<p/>
                                                  If you need to make a declaration based on several
                                                  reports (possibly different) brokers.
                                                </span>,
                                          "es": <span>
                                                  Agregar informe.<p/>
                                                  Si necesita hacer una declaración basada en varios
                                                  informes (posiblemente diferentes) brókers.
                                                </span>})}
                                 </React.Fragment>
                               }
                               arrow={true}
                               placement="left" >
                               <Button className={classes.buttonPlus}
                                       variant="contained"
                                       color="primary"
                                       disabled={this.state.inputFiles.length >=
                                                 this.maxInputFilesCount}
                                       onClick={this.addReportFile(f.inputFormat)}>
                                 <AddIcon/>
                               </Button>
                             </HtmlTooltip>)
                          : (<Button className={classes.buttonPlus}
                                     variant="contained"
                                     color="secondary"
                                     onClick={this.delReportFile(f.fileIndex)}>
                               <RemoveIcon/>
                             </Button>)}
                       </td>
                     </tr>
                     <tr>
                       <td></td>
                       <td>
                         <div className={classes.linkLeftMargin}>
                           <Link to={inputFormatsListEs[f.inputFormat].helpURL}
                                 component={NavLink}
                                 className={classes.link}>
                             {text(language,
                                   {"ru": "Как получить данный отчет",
                                    "en": "How to get this report",
                                    "es": "Cómo obtener este informe"})}
                           </Link>
                         </div>
                       </td>
                       <td></td>
                     </tr>
                   </table>
                 </CardContent>
               </Card>
             ))}

             <Card className={classes.card}>
               <CardContent>
                 <Center>
                   <HtmlTooltip
                     title={
                       <React.Fragment>
                         {text(language,
                               {"ru": <span>
                                        Должен соответствовать периоду в загружаемом отчете.<br/>
                                        <b><u>Иначе сгенерированный отчет будет будет пустой.</u></b>.
                                      </span>,
                                "en": <span>
                                        Must match the period in the loaded report.<br/>
                                        <b><u>Otherwise, the generated report will be empty.</u></b>.
                                      </span>,
                                "es": <span>
                                        Debe coincidir con el período del informe cargado.<br/>
                                        <b><u>De lo contrario, el informe generado estará vacío.</u></b>.
                                      </span>})}
                       </React.Fragment>
                     }
                     arrow={true}
                     placement="right">
                     <TextField
                       id="taxYear"
                       select
                       error={this.state.taxYearError}
                       helperText={this.state.taxYearErrorText}
                       label={text(language,
                                   {"ru": "Налоговый год*",
                                    "en": "Tax year",
                                    "es": "Año fiscal"})}
                       value={this.state.taxYear}
                       onChange={this.handleChange('taxYear')}
                       className={classes.textField}
                       variant="outlined"
                       margin="normal"
                       InputProps={{
                         classes: {
                           notchedOutline: classes.notchedOutline
                         }
                       }}
                       SelectProps={{
                         MenuProps: {
                           className: classes.menu,
                         },
                       }}>
                       {years.map(option => (
                         <MenuItem key={option.value} value={option.value}>
                           {option.label}
                         </MenuItem>
                       ))}
                     </TextField>
                   </HtmlTooltip>
                 </Center>
                 {text(language,
                       {"ru": "Получить результат в виде:",
                        "en": "Get the result in the form:",
                        "es": "Obtenga el resultado en la forma:"})}
                 <FormGroup className={classes.marginTop}>
                   <table>
                     <tr>
                       <td colspan="4">
                         <Typography align='left'>
                           {text(language,
                                 {"ru": "Форма 100. НДФЛ. Годовой доход.",
                                  "en": "Form 100. Personal Income Tax. Annual return.",
                                  "es": "Modelo 100. Impuesto sobre la Renta de las Personas Físicas. Declaración anual."})}
                         </Typography>
                       </td>
                     </tr>
                     <tr>
                       <td className={classes.width42}>
                         <img src='/images/document.png'
                              width='32'
                              alt=''/>
                       </td>
                       <td className={classes.width250}>
                         <Typography>
                           {text(language,
                                 {"ru": "Отчет по доходам для налоговой декларации",
                                  "en": "Income report for tax return",
                                  "es": "Informe de ingresos para declaración de impuestos"})}
                         </Typography>
                       </td>
                       <td className={classes.tableMinWidth}>
                         <FormControlLabel
                           control={
                             <GreenCheckbox
                               checked={this.state.outputXLSX}
                               onChange={this.handleCheckboxChangeWithCookie("outputXLSX")}
                               name="outputXLSX"
                               color="primary"/>
                           }
                           label="Excel"/>
                       </td>
                       <td className={classes.tableMinWidth} colspan="2">
                         <FormControlLabel
                           control={
                             <GreenCheckbox
                               checked={this.state.outputExpalnationNotePDF}
                               onChange={this.handleCheckboxChangeWithCookie("outputExpalnationNotePDF")}
                               name="outputExpalnationNotePDF"
                               color="primary"/>
                           }
                           label="PDF"/>
                       </td>
                       <td></td>
                     </tr>
                     <tr>
                       <td className={classes.width42}>
                         <img src='/images/documents.png'
                              width='32'
                              alt=''/>
                       </td>
                       <td className={classes.width250}>
                         <Typography>
                           {text(language,
                                 {"ru": "Детализированный отчет по доходам для налоговой декларации",
                                  "en": "Detailed income report for tax return",
                                  "es": "Informe de ingresos detallado para declaración de impuestos"})}
                         </Typography>
                       </td>
                       <td className={classes.tableMinWidth}>
                         <FormControlLabel
                           control={
                             <Checkbox
                               checked={this.state.outputDetailXLSX}
                               onChange={this.handleCheckboxChangeWithCookie("outputDetailXLSX")}
                               name="outputDetailXLSX"
                               color="primary"/>
                           }
                           label="Excel"/>
                       </td>
                       <td className={classes.tableMinWidth} colspan="2">
                         <FormControlLabel
                           control={
                             <Checkbox
                               checked={this.state.outputExpalnationNoteDetailPDF}
                               onChange={this.handleCheckboxChangeWithCookie("outputExpalnationNoteDetailPDF")}
                               name="outputExpalnationNoteDetailPDF"
                               color="primary"/>
                           }
                           label="PDF"/>
                       </td>
                       <td></td>
                     </tr>
                     <tr>
                       <td className={classes.width42}>
                         <img src='/images/ses_file.png'
                              width='32'
                              alt=''/>
                       </td>
                       <td className={classes.width250}>
                         <Typography>
                           {text(language,
                                 {"ru": "Файл Renta WEB",
                                  "en": "Renta WEB file",
                                  "es": "Fichero de Renta WEB"})}
                         </Typography>
                       </td>
                       <td className={classes.tableMinWidth}>
                         <FormControlLabel
                           control={
                             <Checkbox
                               checked={this.state.outputForm_100_SES}
                               onChange={this.handleCheckboxChangeWithCookie("outputForm_100_SES")}
                               name="outputForm_100_SES"
                               color="primary"/>
                           }
                           label="SES"/>
                       </td>
                       <td className={classes.tableMinWidth} colspan="2">
                         <FormControlLabel
                           control={
                             <Checkbox
                               checked={this.state.outputForm_100_XML}
                               disabled={true}
                               onChange={this.handleCheckboxChangeWithCookie("outputForm_100_XML")}
                               name="outputForm_100_XML"
                               color="primary"/>
                           }
                           label="XML"/>
                       </td>
                       <td></td>
                     </tr>
                     <tr>
                       <td colspan="4">
                         <Typography align='left'>
                           {text(language,
                                 {"ru": "Форма 720. Информационная налоговая декларация.",
                                  "en": "Form 720. Informative Tax Return.",
                                  "es": "Modelo 720. Declaración Informativa."})}
                         </Typography>
                       </td>
                     </tr>
                     <tr>
                       <td className={classes.width42}>
                         <AssignmentTurnedInOutlinedIcon style={{ fontSize: 32 }}/>
                       </td>
                       <td className={classes.width250}>
                         <Typography>
                           {text(language,
                                 {"ru": "Форма 720",
                                  "en": "Form 720",
                                  "es": "Modelo 720"})}
                         </Typography>
                       </td>
                       <td className={classes.tableMinWidth} colspan="3">
                         <FormControlLabel
                           control={
                             <YellowCheckbox
                               checked={this.state.outputForm_720}
                               onChange={this.handleCheckboxChangeWithCookie("outputForm_720")}
                               name="outputForm_720"
                               disabled={false}/>}
                           label="Form"/>
                       </td>
                       <td></td>
                       <td></td>
                     </tr>
                     <tr>
                       <td colspan="4">
                         <Typography align='left'>
                           {text(language,
                                 {"ru": "Форма 721. Информационное уведомление о виртуальных валютах, находящихся за рубежом.",
                                  "en": "Form 721. Information statement on virtual currencies located abroad.",
                                  "es": "Modelo 721. Declaración informativa sobre monedas virtuales situadas en el extranjero."})}
                         </Typography>
                       </td>
                     </tr>
                     <tr>
                       <td className={classes.width42}>
                         <img src="/images/crypto.svg"
                              height='30px'
                              width='30px'
                              alt='logo'/>
                       </td>
                       <td className={classes.width250}>
                         <Typography>
                           {text(language,
                                 {"ru": "Форма 721",
                                  "en": "Form 721",
                                  "es": "Modelo 721"})}
                         </Typography>
                       </td>
                       <td className={classes.tableMinWidth} colspan="3">
                         <FormControlLabel
                           control={
                             <PurpleCheckbox
                               checked={this.state.outputForm_721}
                               onChange={this.handleCheckboxChangeWithCookie("outputForm_721")}
                               name="outputForm_721"
                               disabled={true}/>}
                           label="Form"/>
                       </td>
                       <td></td>
                       <td></td>
                     </tr>
                     <tr>
                       <td colspan="4">
                         <Typography align='left'>
                           {text(language,
                                 {"ru": "Формат FinParser",
                                  "en": "FinParser format",
                                  "es": "Formato FinParser"})}
                         </Typography>
                       </td>
                     </tr>
                     <tr>
                       <td className={classes.width42}>
                         <img src="/images/finParserLogo.svg"
                              height='30px'
                              width='30px'
                              alt='logo'/>
                       </td>
                       <td className={classes.width250}>
                         <Typography>
                           {text(language,
                                 {"ru": "Сделки и позиции в формате FinParser",
                                  "en": "Deals and positions in FinParser format",
                                  "es": "Ofertas y posiciones en formato FinParser"})}
                         </Typography>
                       </td>
                       <td className={classes.tableMinWidth} colspan="3">
                         <FormControlLabel
                           control={
                             <GreenCheckbox
                               checked={this.state.outputFinParser}
                               onChange={this.handleCheckboxChangeWithCookie("outputFinParser")}
                               name="outputFinParser"
                               color="primary"/>}
                           label="Excel"/>
                       </td>
                       <td></td>
                       <td></td>
                     </tr>
                   </table>
                 </FormGroup>
               </CardContent>
             </Card>
             <Card className={classes.card}>
               <div className={classes.cardContentWithoutPadding}>
                 <HtmlTooltip
                   title={
                     <React.Fragment>
                       {text(language,
                             {"ru": <span>
                                      Не обязательно для заполнения
                                    </span>,
                              "en": <span>
                                      Not required to be filled out
                                    </span>,
                              "es": <span>
                                      No es necesario completarlo
                                    </span>})}
                     </React.Fragment>
                   }
                   arrow={true}
                   placement="top">
                   <Accordion onChange={(event, expanded) => {
                     setCookie("expandTaxpayerDataSection", expanded);
                     this.forceUpdate();}}
                              expanded={getCookieBoolean("expandTaxpayerDataSection")}>
                     <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header" >
                       <Typography>
                         {text(language,
                               {"ru": "Данные налогоплательщика",
                                "en": "Taxpayer data",
                                "es": "Datos del contribuyente"})}
                       </Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                       <CardContent className={classes.cardContentWithoutPadding}>
                         <TextField
                           id="lastName"
                           label={text(language,
                                       {"ru": "Фамилия",
                                        "en": "Surname",
                                        "es": "Apellido"})}
                           className={classes.textField}
                           value={this.state.lastName}
                           onChange={this.handleChange('lastName')}
                           margin="normal"/>
                         <TextField
                           id="firstName"
                           label={text(language,
                                       {"ru": "Имя",
                                        "en": "Name",
                                        "es": "Nombre"})}
                           className={classes.textField}
                           value={this.state.firstName}
                           onChange={this.handleChange('firstName')}
                           margin="normal"/>
                         <TextField
                           id="middleName"
                           label={text(language,
                                       {"ru": "Отчество",
                                        "en": "Middle name",
                                        "es": "Patronímico"})}
                           className={classes.textField}
                           value={this.state.middleName}
                           onChange={this.handleChange('middleName')}
                           margin="normal"/>
                         <TextField
                           id="nif"
                           error={this.state.nifError}
                           helperText={this.state.nifErrorText}
                           label="NIF"
                           value={this.state.nif}
                           onChange={this.handleChange('nif')}
                           className={classes.textField}
                           margin="normal"/>
                         <br/>
                         <Button className={classes.buttonBottom}
                                 variant="contained"
                                 color="secondary"
                                 onClick={this.handleFillTestData}>
                           {text(language,
                                 {"ru": "Заполнить тестовыми данными",
                                  "en": "Fill with test data",
                                  "es": "Rellenar con datos de prueba"})}
                         </Button>
                       </CardContent>
                     </AccordionDetails>
                   </Accordion>
                 </HtmlTooltip>
               </div>
             </Card>
             <Card className={classes.card}>
               <div className={classes.cardContentWithoutPadding}>
                 <HtmlTooltip
                   title={
                     <React.Fragment>
                       {text(language,
                             {"ru": <span>
                                      Рекомендуемые настройки
                                      установлены по умолчанию, но
                                      вы можете изменить их.
                                    </span>,
                              "en": <span>
                                      The recommended settings
                                      are set by default, but
                                      you can change them.
                                    </span>,
                              "es": <span>
                                      Las configuraciones
                                      recomendadas están
                                      configuradas de forma
                                      predeterminada, pero
                                      puedes cambiarlas si lo
                                      deseas.
                                    </span>})}
                     </React.Fragment>
                   }
                   arrow={true}
                   placement="top">
                   <Accordion onChange={(event, expanded) => {
                     setCookie("expandIgnoresSection", expanded);
                     this.forceUpdate();}}
                              expanded={getCookieBoolean("expandIgnoresSection")}>
                     <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header" >
                       <Typography>
                         {text(language,
                               {"ru": "Дополнительные параметры",
                                "en": "Additional options",
                                "es": "Opciones adicionales"})}
                       </Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                       <CardContent className={classes.cardContentWithoutPadding}>
                         <FormControlLabel
                           style={{width: "100%"}}
                           margin="dense"
                           control={
                             <Switch
                               checked={this.state.useLastTradingDayExRates}
                               onChange={this.handleCheckboxChangeWithCookie("useLastTradingDayExRates")}
                               name="useLastTradingDayExRates"
                               color="primary"/>
                           }
                           label={
                             <div>
                               <Typography>
                                 {text(language,
                                       {"ru": "Использовать обменный курс последнего торгового дня для всех сделок и дивидендов",
                                        "en": "Use the last trading day's exchange rate for all trades and dividends",
                                        "es": "Utilice el tipo de cambio del último día de negociación para todas las operaciones y dividendos."})}
                               </Typography>
                             </div>}>
                         </FormControlLabel>
                         <FormControlLabel
                           className={this.textField}
                           control={
                             <Checkbox
                               checked={this.state.useOnlyECBExRates}
                               onChange={this.handleCheckboxChangeWithCookie("useOnlyECBExRates")}
                               name="useOnlyECBExRates"
                               color="primary"/>
                           }
                           label={<div>
                                    <Typography>
                                      {text(language,
                                            {"ru": <span>
                                                     Использовать только обменные
                                                     курсы, публикуемые ЕЦБ
                                                   </span>,
                                             "en": <span>
                                                     Use only exchange rates
                                                     published by the ECB
                                                   </span>,
                                             "es": <span>
                                                     Utilice únicamente los tipos
                                                     de cambio publicados por el BCE
                                                   </span>})}
                                    </Typography>
                                  </div>}>
                         </FormControlLabel>
                         <table>
                           <tr>
                             <td>
                               <FormControlLabel
                                 className={this.textField}
                                 control={
                                   <Checkbox
                                     checked={this.state.enable2MonthsRule}
                                     onChange={this.handleCheckboxChangeWithCookie("enable2MonthsRule")}
                                     name="enable2MonthsRule"
                                     color="primary"/>
                                 }
                                 label={
                                   <div>
                                     <Typography>
                                       {text(language,
                                             {"ru": "Учитывать правило 2 месяцев",
                                              "en": "Consider the 2 month rule",
                                              "es": "Considere la regla de 2 meses"})}
                                     </Typography>
                                   </div>}/>
                             </td>
                             <td>
                               <FormControlLabel
                                 className={this.textField}
                                 control={
                                   <Switch
                                     checked={this.state.enable2MonthsRuleShorts}
                                     onChange={this.handleCheckboxChangeWithCookie("enable2MonthsRuleShorts")}
                                     name="enable2MonthsRuleShorts"
                                     color="primary"
                                     disabled={!this.state.enable2MonthsRule}/>
                                 }
                                 label={
                                   <div>
                                     <Typography>
                                       {text(language,
                                             {"ru": "Учитывать короткие продажи для правила 2 месяцев",
                                              "en": "Account for short sales for the 2 month rule",
                                              "es": "Cuenta para ventas cortas para la regla de 2 meses"})}
                                     </Typography>
                                   </div>}/>
                             </td>
                           </tr>
                           <tr>
                             <td></td>
                             <td><FormControlLabel
                                   className={this.textField}
                                   control={
                                     <Switch
                                       checked={this.state.consider2or12MonthsDiff}
                                       onChange={this.handleCheckboxChangeWithCookie("consider2or12MonthsDiff")}
                                       name="consider2or12MonthsDiff"
                                       color="primary"
                                       disabled={!this.state.enable2MonthsRule}/>
                                   }
                                   label={
                                     <div>
                                       <Typography>
                                         {text(language,
                                               {"ru": <span>
                                                        Учитывать разницу для 2 и 12 месяцев
                                                        в зависимости от биржи, на которой
                                                        произошла сделка (только для отчетов IB)
                                                      </span>,
                                                "en": <span>
                                                        Take into account the difference for 2 and 12
                                                        months depending on the exchange on which
                                                        the transaction occurred (only for IB reports)
                                                      </span>,
                                                "es": <span>
                                                        Tenga en cuenta la diferencia entre 2 y 12 meses
                                                        dependiendo del intercambio en el que se realizó
                                                        la transacción <br/> (solo para informes IB)
                                                      </span>})}
                                       </Typography>
                                     </div>}/>
                             </td>
                           </tr>
                         </table>
                         <HtmlTooltip
                           title={
                             <React.Fragment>
                               {text(language,
                                     {"ru": <span>
                                              Считать фьючерсы для отчетов
                                              Interactive Brokers на основе
                                              вариационной маржи.
                                            </span>,
                                      "en": <span>
                                              Calculate futures for
                                              Interactive Brokers reports
                                              based on variation margin.
                                            </span>,
                                      "es": <span>
                                              Calcule los futuros para los
                                              informes de Interactive
                                              Brokers en función del
                                              margen de variación.
                                            </span>})}
                             </React.Fragment>
                           }
                           arrow={true}
                           placement="top">
                           <FormControlLabel
                             className={this.textField}
                             control={
                               <Switch
                                 checked={this.state.futuresBasedOnVariationMargin}
                                 onChange={this.handleCheckboxChangeWithCookie("futuresBasedOnVariationMargin")}
                                 name="futuresBasedOnVariationMargin"
                                 color="primary"/>
                             }
                             label={<div>
                                      <Typography>
                                        {text(language,
                                              {"ru": <span>
                                                       Считать фьючерсы на основе
                                                       вариационной маржи
                                                     </span>,
                                               "en": <span>
                                                       Calculate futures based
                                                       on variation margin
                                                     </span>,
                                               "es": <span>
                                                       Calcular futuros basados ​​
                                                       en el margen de variación
                                                     </span>})}
                                      </Typography>
                                    </div>}/>
                         </HtmlTooltip>
                       </CardContent>
                     </AccordionDetails>
                   </Accordion>
                 </HtmlTooltip>
               </div>
             </Card>

             <div>
               <Button className={classes.buttonBottom}
                       variant="contained"
                       color="primary"
                       disabled={!this.props.loginOk.value}
                       onClick={this.sendDataToCreateDeclaration}>
                 {text(language,
                       {"ru": "Сгенерировать отчеты",
                        "en": "Generate Reports",
                        "es": "Generar informes"})}
               </Button>
             </div>

           </form>
         : <div className={classes.loader}>
             {this.state.documentGeneration
              ? <Progress
              parent={this}
              context={this.context}/>
              : <CircularProgress size={90}/>
             }
           </div>
        }
      </Center>
    );
  }
}

function mapStateToProps(state) {
  const loginOk = state.loginOk;
  const language = state.language;
  return {
    loginOk,
    language
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginOk: (value) => dispatch(setLoginOk(value)),
  };
};

TaxFormEs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TaxFormEs));
