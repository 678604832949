import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ConfirmationDialog from "../confirmationDialog";
import Center from 'react-center';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Upload from "../upload";
import {setCookie, getCookieBoolean} from '../cookieLib';
import AlertDialog from '../alertDialog';
import LoginDialog from '../loginDialog';
import Progress from '../progress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { inputFormatsList } from '../taxForm';
import { setLoginOk } from '../state/loginOkSlice';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';
import GreenCheckbox from '../common/greenCheckbox';
import PurpleCheckbox from '../common/purpleCheckbox';
import YellowCheckbox from '../common/yellowCheckbox';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#F5F5F5',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #BDBDBD',
  },
}))(Tooltip);

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#009688',
    },
    '&$checked + $track': {
      backgroundColor: '#009688',
    },
  },
  checked: {},
  track: {},
})(Switch);

const styles = {
  button: {
    margin: 5,
  },
  buttonBottom: {
    margin: 5,
    marginTop: '15px',
    float: 'right'
  },
  buttonFixedWidth: {
    margin: 5,
    width: 200,
  },
  buttonPlus: {
    margin: 5,
    marginTop: 18,
    marginBottom: 0,
    paddingLeft: 6,
    paddingRight: 6,
    minWidth: 32
  },
  buttonPlusNoMargin: {
    paddingLeft: 6,
    paddingRight: 6,
    minWidth: 32
  },
  input: {
    display: 'none',
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'column',
    marginBottom: '6px'
  },
  cardWithPaddingBottom: {
    marginBottom: '6px'
  },
  cardWithPaddingBottom2: {
    marginBottom: '2px'
  },
  cardContentWithoutPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 800,
    flex: 1,
    flexDirection: 'column',
  },
  dateFiled: {
    width: 200,
    margin: 7,
  },
  dateFiled2: {
    width: 270,
    margin: 7,
  },
  textField: {
    width: 200,
    margin: 7,
  },
  textField216: {
    width: 216,
    margin: 7,
  },
  textFieldSmall: {
    width: 100,
    margin: 7,
  },
  textField2: {
    width: 270,
    margin: 7,
  },
  textField25: {
    width: 250,
    margin: 7,
  },
  rigthPosition: {
    marginLeft: '214px'
  },
  fileField: {
    marginTop: '0px',
    marginLeft: '7px',
    marginRight: '7px',
  },
  textMax: {
    width: 418,
    margin: 7,
  },
  textMax2: {
    width: 630,
    margin: 7,
  },
  textMax2T: {
    width: 630,
    marginTop: '0px',
    marginBottom: '7px',
    marginLeft: '7px',
    marginRight: '7px',
  },
  cleanTable: {
    borderCollapse: 'collapse',
    borderSpacing: '0'
  },
  tableColumn: {
    width: 280,
    margin: 7,
  },
  menu: {
  },
  menuExtended: {
    width: 1400,
  },
  loader: {
    padding: '200px 0',
  },
  link: {
    display: 'inline-block',
  },
  linkLeftMargin: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 7,
  },
  notchedOutline: {
    borderWidth: "2px",
    borderColor: "#3f51b5 !important"
  },
  topAlign: {
    verticalAlign: "top"
  },
  width42: {
    width: "42px"
  },
  width250: {
    width: "250px"
  },
  marginTop: {
    marginTop: '10px'
  },
  tableMinWidth: {
    width: "1%",
    whiteSpace: "nowrap"
  },
  maxWidth480: {
    width: "480pt",
    paddingRight: "10px"
  },
  imgSpan: {
    width:'16px'
  },
  formControlMobile: {
    height: "20px",
    marginBottom: "10px"
  }
};

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderWidth: '1px',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const year = new Date().getFullYear();

const years = Array.apply(null, Array(13)).map(
  function (_, i) {
    return {
      value: year - i,
      label: year - i,
    };
  });

const contractTypes = {
  0: 'Договор негосударственного пенсионного страхования',
  1: 'Договор добровольного пенсионного страхования',
  2: 'Договор добровольного страхования жизни',
  3: 'Дополнительные страховые взносы на накопительную пенсию'
};

const taxCurrencies = {
  'RUB': 'RUB',
  'EUR': 'EUR'
};

const forexIncomeCodes = {
  1535: '1535 - Доходы по операциям с производными финансовыми инструментами',
  1520: '1520 - Доходы от реализации имущества (доли), кроме ценных бумаг',
  2900: '2900 - Доходы, полученные от операций с иностранной валютой',
};

const foreignExchangeSwapCodes = {
  1535: '1535 - Доходы по операциям с производными финансовыми инструментами',
  1530: '1530 - Доходы, полученные по операциям с ценными бумагами',
};

class TaxFormRu extends React.Component {
  constructor(props) {
    super(props);

    this.setState({ showLoginDialog: !this.props.loginOk.value });

    // This binding is necessary to make `this` work in the callback
    this.sendDataToCreateDeclaration = this.sendDataToCreateDeclaration.bind(this);
    this.handleFillTestData = this.handleFillTestData.bind(this);
    this.loadInspections = this.loadInspections.bind(this);
    this.loadInspections();
    this.addReportFile = this.addReportFile.bind(this);
    this.addDeductionByPrevious = this.addDeductionByPrevious.bind(this);
    this.addDeductionContracts = this.addDeductionContracts.bind(this);
    this.saveTaxpayerData = this.saveTaxpayerData.bind(this);
    this.loadTaxpayerData = this.loadTaxpayerData.bind(this);
    this.handleAttachTaxpayerDataFile = this.handleAttachTaxpayerDataFile.bind(this);
    this.addDeductionChildrenEducation = this.addDeductionChildrenEducation.bind(this);
    this.delDeductionChildrenEducation = this.delDeductionChildrenEducation.bind(this);
    this.requestProgress = this.requestProgress.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
  }

  loadInspections() {
    this.setState({ inProgress: true });
    return axios.get('/listInspections')
      .then((response) => {
        this.setState({ inProgress: false });
        this.setState({ inspections: response.data });
      })
      .catch((error) => {
        this.setState({ inProgress: false });
        console.log(error);
      });
  }

  maxInputFilesCount = 10;
  submitURL = "/submitIBReportToDecl";

  state = {
    taxpayerDataFile: null,
    lastName: '',
    firstName: '',
    middleName: '',
    index: '',
    regionCode: '',
    inspectionNumber: '',
    district: '',
    city: '',
    street: '',
    house: '',
    housing: '',
    flat: '',
    inn: '',
    oktmo: '',
    birthDate: {selectedDate: function() {
      var date = new Date();
      date.setFullYear( date.getFullYear() - 40);
      return date;
    }()},
    birthLocation: '',
    passpNumber: '',
    passpDate: {selectedDate: function() {
      var date = new Date();
      date.setFullYear( date.getFullYear() - 20);
      return date;
    }()},
    passpIssued: '',
    passpDepartmentCode: '',
    taxYear: '',
    transferFees: '0',
    managementFees: '0',
    tPlus: true,
    groupByDays: false,
    email: '',
    correctionNumber: 0,
    phone: '',
    ignoreMonthlyMinimumFee: false,
    ignoreForex: getCookieBoolean("ignoreForex", true),
    editablePDFs: getCookieBoolean("editablePDFs", false),
    ignoreWithdrawFee: false,
    ignoreConsultantFee: false,
    ignoreVariousFee: false,
    futuresBasedOnVariationMargin: true,
    roundNumbersForCFR: getCookieBoolean("roundNumbersForCFR", false),
    takeIntoAccountSuspensionSIODN: getCookieBoolean("takeIntoAccountSuspensionSIODN", false),

    outputAccountNotice: getCookieBoolean("outputAccountNotice", false),
    outputOpenNotice: getCookieBoolean("outputOpenNotice", true),
    outputXLSX: getCookieBoolean("outputXLSX", false),
    outputExpalnationNotePDF: getCookieBoolean("outputExpalnationNotePDF", false),
    outputXML: getCookieBoolean("outputXML", true),
    outputDC9: getCookieBoolean("outputDCX", false),
    outputFinParser: getCookieBoolean("outputFinParser", false),
    cfrXML: getCookieBoolean("cfrXML", false),
    cfrExcel: getCookieBoolean("cfrExcel", false),
    cfrPDF: getCookieBoolean("cfrPDF", false),
    taxOptimizeXLSX: getCookieBoolean("taxOptimizeXLSX", false),

    inProgress: false,
    documentGeneration: false,

    startStage: "Чтение файлов отчетов",
    progress: {
      id: '',
      percent: 0,
      stage: this.startStage,
      timeout: 1000},

    inputFiles: [{
      fileIndex: 0,
      fileContents: null,
      fileName: '',
      inputFormat: 'ib',
      accountOpeningDate: {selectedDate: null},
      accountClosingDate:  {selectedDate: null}}],

    inspections: [],
    showConfDialog: false,
    showAlertDialog: false,
    showLoginDialog: !this.props.loginOk.value,
    errorShow: false,
    errorMessage: '',
    warningShow: false,
    paymentRequired: true,
    warningMessages: [],
    warningConfirm: false,
    foreignExchangeSwapCode: 1535,
    forexIncomeCode: 1535,
    taxCurrency: 'RUB',

    // Вычеты
    deductionPhilanthropy: 0,
    deductionTreatment: 0,
    deductionSelfEducation: 0,
    deductionExpensiveTreatment: 0,
    deductionInsurance: 0,
    deductionQualificationAssessment: 0,
    deductionPhilanthropy30: 0,
    deductionMedications: 0,
    deductionChildrenEducation: [],
    deductionContracts: [],
    deductionByPrevious: []
  };

  handleAlert() {
    this.setState({ showAlertDialog: true });
  };

  checkFormFilled() {
    var result = true;
    this.setState({ innError: false,
                    innErrorText: ''});
    if (this.state.inn === '') {
      this.setState({ innError: true,
                      innErrorText: 'ИНН не может быть пустым.'});
      result = false;
    } else if (this.state.inn.length !== 12) {
      this.setState({ innError: true,
                      innErrorText: 'ИНН должен состоять из 12 цифр.'});
      result = false;
    }

    this.setState({ taxYearError: false,
                    taxYearErrorText: ''});
    if (this.state.taxYear === '') {
      this.setState({ taxYearError: true,
                      taxYearErrorText:
                      'Налоговый год не может быть пустым.'});
      result = false;
    }

    this.setState({ accountOpeningDateError: false,
                    accountOpeningDateErrorText: ''});
    if (this.state.cfrXML && this.isCfrApplicable()
        && this.state.inputFiles.find(f => {
          return f.accountOpeningDate.selectedDate == null;
        })) {
      this.setState({ accountOpeningDateError: true,
                      accountOpeningDateErrorText:
                      'Необходимо указать дату открытия счета.'});
      this.state.inputFiles.map(f => {
        if (document.getElementById("accId"+ f.fileIndex)
            .children[0].getAttribute("aria-expanded") != 'true') {
          document.getElementById("accId"+ f.fileIndex)
            .children[0].click();
        }
      });
    }

    this.state.inputFiles.forEach(f => {
      if (f.fileContents != null) {
        f.fileError = false;
        f.fileErrorText = '';
      } else {
        f.fileError = true;
        f.fileErrorText = 'Добавьте файл отчета.';
        result = false;
      }});

    return result;
  }

  dateFormat(date) {
    if (date instanceof Date) {
      var [month, day, year] = date.toLocaleDateString("en-US").split("/");
      if (day.length < 2) {
        day = '0' + day;
      }
      return day + '.' + month + '.' + year;
    } else {
      return date;
    }
  }

  sendDataToCreateDeclaration(event) {
    if (event != null) {
      event.preventDefault();
    }
    if (!this.checkFormFilled()) {
      this.handleAlert();
      return;
    }

    this.setState({ inProgress: true, documentGeneration: true });
    const config = { headers: { 'Content-Type': 'multipart/form-data',
                                'X-Requested-With': 'HttpRequest',
                                'Csrf-Token': 'nocheck'},
                     responseType: 'json',
                     timeout: 0};
    const form = new FormData();
    const data = {};
    ['lastName',
     'firstName',
     'middleName',
     'index',
     'regionCode',
     'district',
     'city',
     'street',
     'house',
     'housing',
     'flat',
     'inspectionNumber',
     'inn',
     'birthDate',
     'birthLocation',
     'passpNumber',
     'passpDate',
     'passpIssued',
     'passpDepartmentCode',
     'oktmo',
     'email',
     'correctionNumber',
     'phone'].forEach(f => {
       switch (f) {
       case 'birthDate':
         data['birthDate'] = this.dateFormat(
           this.state.birthDate.selectedDate);
         break;
       case 'passpDate':
         data['passpDate'] = this.dateFormat(
           this.state.passpDate.selectedDate);
         break;
       case 'inspectionNumber':
         let inspNumberText = this.state.inspectionNumber["name"];
         if (inspNumberText != null) {
           let splitted = inspNumberText.split('-');
           if (splitted.length > 0) {
             data['inspectionNumber'] = splitted[0].trim();
             break;
           }
         }
         data['inspectionNumber'] = '';
         break;
       case 'passpNumber':
         let pn = this.state.passpNumber.replace(/\s/g,'');
         data['passpNumber'] =
           pn.slice(0,2) + ' ' + pn.slice(2,4) + ' ' + pn.slice(4);
         break;
       default:
         data[f] = this.state[f];
       }
     });
    form.append(['iserInfoData'], JSON.stringify(data));

    var inputFormats = [];
    var i = 0;
    this.state.inputFiles.forEach(item => {
      form.append('report'+i, item.fileContents.file);

      const inputFormatData = {};
      ['inputFormat',
       'accountOpeningDate',
       'accountClosingDate'].forEach(f => {
         switch (f) {
         case 'accountOpeningDate':
           inputFormatData['accountOpeningDate'] = this.dateFormat(
             item.accountOpeningDate.selectedDate);
           break;
         case 'accountClosingDate':
           inputFormatData['accountClosingDate'] = this.dateFormat(
             item.accountClosingDate.selectedDate);
           break;
         default:
           inputFormatData[f] = item[f];
         }
       });
      inputFormats[i] = inputFormatData;
      i += 1;
    });
    form.append('inputFormats', JSON.stringify(inputFormats));

    form.append('outputFormats', JSON.stringify({
      'acc_notice_pdf': this.state.outputAccountNotice,
      'openNotice': this.state.outputOpenNotice,
      'decl_xml' : this.state.outputXML,
      'decl_dcx' : this.state.outputDC9,
      'expl_note_xlsx': this.state.outputXLSX,
      'expl_note_pdf': this.state.outputExpalnationNotePDF,
      'finparser_xlsx' : this.state.outputFinParser,
      'cfr_xml': this.state.cfrXML && this.isCfrApplicable(),
      'cfr_excel': this.state.cfrExcel && this.isCfrApplicable(),
      'cfr_pdf': this.state.cfrPDF && this.isCfrApplicable(),
      'tax_optimize_xlsx': this.state.taxOptimizeXLSX}));

    const settingsData = {};
    ['tPlus',
     'groupByDays',
     'ignoreMonthlyMinimumFee',
     'ignoreWithdrawFee',
     'ignoreConsultantFee',
     'ignoreVariousFee',
     'futuresBasedOnVariationMargin',
     'roundNumbersForCFR',
     'takeIntoAccountSuspensionSIODN',
     'ignoreForex',
     'editablePDFs',
     'foreignExchangeSwapCode',
     'forexIncomeCode',
     'taxCurrency',
     'taxYear',
     'language'].forEach(f => {
       switch (f) {
       case 'language':
         settingsData[f] = this.props.language.value;
         break;
       default:
         settingsData[f] = this.state[f];
       }
     });
    form.append(['settingsData'], JSON.stringify(settingsData));

    ['transferFees',
     'managementFees',
     'warningConfirm'].forEach(f => {
       form.append([f], this.state[f]);
     });

    const deductions = {
      deductionByPrevious: this.state.deductionByPrevious,
      deductionPhilanthropy: this.state.deductionPhilanthropy,
      deductionTreatment: this.state.deductionTreatment,
      deductionSelfEducation: this.state.deductionSelfEducation,
      deductionExpensiveTreatment: this.state.deductionExpensiveTreatment,
      deductionInsurance: this.state.deductionInsurance,
      deductionQualificationAssessment: this.state.deductionQualificationAssessment,
      deductionPhilanthropy30: this.state.deductionPhilanthropy30,
      deductionMedications: this.state.deductionMedications
    };
    form.append(['deductions'], JSON.stringify(deductions));

    this.setState({ errorShow: false, errorMessage: '' });
    this.setState({ warningConfirm: false });

    this.setState({progress: {
      id: '',
      percent: 0,
      stage: this.startStage,
      timeout: 1000}}, () => {
        axios.post(this.submitURL, form, config)
          .then( (response) => {
            this.state.progress.id = response.data.progressId;
            setTimeout(this.requestProgress, 100);
          })
          .catch( (error) => {
            this.setState({ inProgress: false, documentGeneration: false});
            let self = this;
            if (error.response.status === 413) {
              self.setState({
                errorShow: true,
                errorMessage: 'Размер файла превышает допустимый лимит'
              });
            } else if (error.response.status === 401) {
              this.props.setLoginOk(false);
              this.setState({ showLoginDialog: !this.props.loginOk.value });
              this.forceUpdate();
            } else {
              console.log(error);
            }
          });
      });
  };

  requestProgress = () => {
    axios.get('/progress/get/' + this.state.progress.id)
      .then((response) => {
        let isStoped = response.data.isStoped;
        let isComplete = response.data.isComplete;

        this.state.progress.percent = response.data.percent;
        this.state.progress.stage = response.data.stage;
        this.forceUpdate();

        if (isStoped) {
          let self = this;
          let warnResp = JSON.parse(response.data.message);
          self.setState({
            inProgress: false, documentGeneration: false,
            warningShow: true,
            warningMessages: warnResp
          }, () => {this.forceUpdate();});

        } else if (isComplete) {
          const config = { headers: { 'Content-Type': 'multipart/form-data',
                                      'X-Requested-With': 'HttpRequest',
                                      'Csrf-Token': 'nocheck'},
                           responseType: 'blob',
                           timeout: 0};
          axios.post('/progress/result/' + this.state.progress.id, null, config)
            .then( (response) => {
              const fileName = response.headers['content-disposition']
                    .split("attachment; filename=")[1];
              var download = require('downloadjs');
              this.setState({ inProgress: false, documentGeneration: false});
              download(response.data, fileName);
            })
            .catch( (error) => {
              this.setState({ inProgress: false, documentGeneration: false });
              console.log(error);
            });
        } else {
          setTimeout(this.requestProgress, this.state.progress.timeout);
          this.state.progress.timeout = Math.min(
            this.state.progress.timeout + 1000, 5000);
        }
      })
      .catch((error) => {
        this.setState({ inProgress: false, documentGeneration: false});
        console.log(error);
      });
  }

  handleItemChange = (item, name) => event => {
    if (name.includes('Date')) {
      item[name] = { selectedDate: event };
    } else {
      var value;
      if (Number.isInteger(event.target.value)) {
        value = event.target.value.toString();
      } else {
        value = event.target.value;
      }
      item[name] = value;
    }
    this.forceUpdate();
  };

  addDeductionByPrevious(event) {
    if (event != null) {
      event.preventDefault();
    }

    var idx = 0;
    if (this.state.deductionByPrevious.length > 0) {
      idx = Math.max.apply(null, this.state.deductionByPrevious.map(i => {
        return i.deductionIndex;
      })) + 1;
    }
    this.state.deductionByPrevious.push(
      {deductionIndex: idx,
       brokerName: '',
       year: '',
       deductionForSecurities: 0,
       deductionForDerivatives: 0});
    this.forceUpdate();
  }

  delDeductionByPrevious = idx => event => {
    if (event != null) {
      event.preventDefault();
    }
    this.setState({ deductionByPrevious: this.state.deductionByPrevious.filter(
      i => {
        return idx !== i.deductionIndex;
      })});
    this.forceUpdate();
  }

  addDeductionContracts(event) {
    var idx = 0;
    if (this.state.deductionContracts.length > 0) {
      idx = Math.max.apply(null, this.state.deductionContracts.map(i => {
        return i.deductionIndex;
      })) + 1;
    }
    this.state.deductionContracts.push(
      {deductionIndex: idx,
       contractType: '',
       orgOrFundName: '',
       inn: '',
       kpp: '',
       contractNumber: '',
       contractDate: new Date(),
       amount: 0});
    this.forceUpdate();
  }

  delDeductionContracts = idx => event => {
    if (event != null) {
      event.preventDefault();
    }
    this.setState({ deductionContracts: this.state.deductionContracts.filter(
      i => {
        return idx !== i.deductionIndex;
      })});
    this.forceUpdate();
  }

  addDeductionChildrenEducation(event) {
    this.state.deductionChildrenEducation.push(0);
    this.forceUpdate();
  }

  delDeductionChildrenEducation(event) {
    this.state.deductionChildrenEducation.pop();
    this.forceUpdate();
  }

  addReportFile = inputFormat => event => {
    if (event != null) {
      event.preventDefault();
    }

    let idx = Math.max.apply(null, this.state.inputFiles.map(i => {
      return i.fileIndex;
    })) + 1;
    this.state.inputFiles.push(
      {fileIndex: idx,
       fileContents: null,
       fileName: '',
       inputFormat: inputFormat,
       accountOpeningDate: {selectedDate: null},
       accountClosingDate:  {selectedDate: null}});
    this.forceUpdate();
    return idx;
  }

  delReportFile = idx => event => {
    if (event != null) {
      event.preventDefault();
    }
    this.setState({ inputFiles: this.state.inputFiles.filter(
      i => {
        return idx !== i.fileIndex;
      })});
    this.forceUpdate();
  }

  handleChange = name => event => {
    var value = null;
    if (((typeof this.state[name]) === "number") &&
        ((typeof event.target.value) === "string")) {
      value = parseInt(event.target.value);
    } else {
      value = event.target.value;
    }
    this.setState({
      [name]: value,
    });
    if (name === 'taxYear') {
      this.forceUpdate();
    }
  };

  handleChangeAutocomplete = name => (event, newValue) => {
    this.setState({
      [name]: newValue,
    });
  };

  handleCheckboxChange = event => {
    this.setState({[event.target.name]: event.target.checked });
  };

  handleCheckboxChangeWithCookie = cname => event => {
    this.setState({[event.target.name]: event.target.checked });
    if (cname != null) {
      setCookie(cname, event.target.checked);
    }
  };

  handleDateChange = name => (date) => {
    this.setState({[name]: { selectedDate: date }});
  };

  handleMultipleUpload = inputFormat => event => {
    Array.from(event.target.files).slice(1) // tail
      .map(file => {
        let idx = this.addReportFile(inputFormat)();
        this.state.inputFiles[idx].fileContents = {
          name: event.target.files[idx].name,
          file: event.target.files[idx]
        };
        this.state.inputFiles[idx].fileName = file.name;
      });
  }

  handleAttachFile = filesListItem => event => {
    filesListItem.fileContents = {
      name: event.target.files[0].name,
      file: event.target.files[0]
    };
    filesListItem.fileName = event.target.value;
    this.forceUpdate();
  }

  handleInputFormatChange = filesListItem => event => {
    filesListItem.inputFormat = event.target.value;
    this.forceUpdate();
  };

  handleAccountOpenCloseDateChange = (filesListItem, name) => (date) => {
    filesListItem[name] = { selectedDate: date };
    this.forceUpdate();
  };

  handleFillTestData() {
    this.handleOpen();
  };

  saveTaxpayerData() {
    const config = { headers: { 'Content-Type': 'multipart/form-data',
                                'X-Requested-With': 'HttpRequest',
                                'Csrf-Token': 'nocheck'},
                     responseType: 'blob',
                     timeout: 0};
    const data = {};
    ['lastName',
     'firstName',
     'middleName',
     'inspectionNumber',
     'oktmo',
     'inn',
     'birthDate',
     'birthLocation',
     'passpNumber',
     'passpDate',
     'passpIssued',
     'passpDepartmentCode'].forEach(f => {
       switch (f) {
       case 'birthDate':
         data['birthDate'] = this.dateFormat(
           this.state.birthDate.selectedDate);
         break;
       case 'passpDate':
         data['passpDate'] = this.dateFormat(
           this.state.passpDate.selectedDate);
         break;
       case 'inspectionNumber':
         let inspNumberText = this.state.inspectionNumber["name"];
         if (inspNumberText != null) {
           let splitted = inspNumberText.split('-');
           if (splitted.length > 0) {
             data['inspectionNumber'] = splitted[0].trim();
             break;
           }
         }
         data['inspectionNumber'] = '';
         break;
       case 'passpNumber':
         let pn = this.state.passpNumber.replace(/\s/g,'');
         data['passpNumber'] =
           pn.slice(0,2) + ' ' + pn.slice(2,4) + ' ' + pn.slice(4);
         break;
       default:
         data[f] = this.state[f];
       }
     });

    const form = new FormData();
    form.append(['taxpayerData'], JSON.stringify(data));
    this.setState({ inProgress: true });
    axios.post('/taxpayer/save', form, config)
      .then( (response) => {
        this.setState({ inProgress: false });
        const fileName = response.headers['content-disposition']
              .split("attachment; filename=")[1];
        var download = require('downloadjs');
        download(response.data, fileName);
      })
      .catch( (error) => {
        this.setState({ inProgress: false });
        console.log(error);
      });
  }

  parseDate = text => {
    var parts = text.split(".");
    return new Date(parseInt(parts[2], 10),
                    parseInt(parts[1], 10) - 1,
                    parseInt(parts[0], 10));
  }

  handleAttachTaxpayerDataFile = event => {
    this.setState(
      { taxpayerDataFile: event.target.files[0],
        inProgress: true },
      () =>
      {
        const config = { headers: { 'Content-Type': 'multipart/form-data',
                                    'X-Requested-With': 'HttpRequest',
                                    'Csrf-Token': 'nocheck'},
                         responseType: 'json',
                         timeout: 0};
        const form = new FormData();
        form.append(['taxpayerDataFile'], this.state.taxpayerDataFile);
        this.inputTaxpayerDataFile.value = null;
        axios.post('/taxpayer/load', form, config)
          .then( (response) => {
            this.setState({ inProgress: false });
            Object.keys(response.data).map(key => {
              this.setState({
                [key]: response.data[key]
              });
              return null;
            });
            this.handleDateChange('birthDate')(this.parseDate(response.data['birthDate']));
            this.handleDateChange('passpDate')(this.parseDate(response.data['passpDate']));
            let self = this;
            this.handleChangeAutocomplete('inspectionNumber')(
              null, function() {
                let item = self.state.inspections.find(function(i) {
                  return i["kod"] === response.data['inspectionNumber'];
                });
                return self.createInspectionSelectionItem(item);
              }());
          })
          .catch( (error) => {
            this.setState({ inProgress: false });
            console.log(error);
          });
      });
  }

  loadTaxpayerData() {
    this.inputTaxpayerDataFile.click();
  };

  handleConfirmWarning = value => {
    let confirm = (value === 'ok' ? true : false);
    this.setState({ warningShow: false }, () => {
      this.setState({warningMessages: [],
                     warningConfirm: confirm },
                    () => {
                      if (confirm) {
                        this.sendDataToCreateDeclaration(null);
                      }
                    });
    });
  }

  handleGotoPay = value => {
    window.location.hash = '#payment';
  }

  handleOpen = () => {
    this.setState({ showConfDialog: true });
  };

  createInspectionSelectionItem = i => {
    return {name: i["kod"] + ' - ' + i["name"]};
  }

  handleClose = value => {
    this.setState({ showConfDialog: false });
    if (value === 'ok') {
      this.setState({ inProgress: true });
      axios.get('/fooUserInfo')
        .then( (response) => {
          this.setState({ inProgress: false });
          Object.keys(response.data).map(key => {
            this.setState({
              [key]: response.data[key]
            });
            return null;
          });
          this.handleDateChange('birthDate')(response.data['birthDate']);
          this.handleDateChange('passpDate')(response.data['passpDate']);
          let self = this;
          this.handleChangeAutocomplete('inspectionNumber')(
            null, function() {
              let item = self.state.inspections.find(function(i) {
                return i["kod"] === response.data['inspectionNumber'];
              });
              return self.createInspectionSelectionItem(item);
            }());
        })
        .catch( (error) => {
          this.setState({ inProgress: false });
          console.log(error);
        });
    }
  };

  getDeclarationExtension() {
    let yearStr = this.state.taxYear.toString();
    return "DC" + (yearStr.length < 1
                   ? "X"
                   : yearStr.substr(yearStr.length - 1));
  }

  isCfrApplicable() {
    return (this.state.taxYear === '' || parseInt(this.state.taxYear) >= 2020);
  }

  zipBy3(arr) {
    let result = [];
    arr.map(a => {
      if ((result.length === 0) || (result[result.length - 1].length === 3)) {
        return result.push([a]);
      } else {
        return result[result.length - 1].push(a);
      }
    });
    return result;
  }

  zipWithIndex = arr => {
    let i = 0;
    return arr.map(a => {
      let v = [i, a];
      i++;
      return v;
    });
  }

  render() {
    return (
      <>
        {isMobile
         ? this.renderMobile()
         : this.renderDesktop()}
        <Center>
          <ConfirmationDialog
            open={this.state.showConfDialog}
            onClose={this.handleClose}
            title='Заполнение формы'
            message={'Форма будет заполнена тестовой информацией. ' +
                     'Все уже введенные данные будут перезаписаны. Продолжить?'}/>
          <ConfirmationDialog
            open={this.state.warningShow}
            onClose={this.handleConfirmWarning}
            messages={this.state.warningMessages}
            title='Подтвердите генерацию отчетов'
            ok='Подтвердить'
            cancel='Отмена'
            isAction={(this.state.warningMessages != null
                       ? this.state.warningMessages.find(m =>
                         (m.message ===
                          "У вас недостаточно средств/отсутствуют бонусы."))
                       : false)}
            onAction={this.handleGotoPay}
            actionText='Оплатить'/>
          <AlertDialog
            open={this.state.errorShow}
            propertyName='errorShow'
            parent={this}
            title='Ошибка обработки отчета'
            severity='error'
            message={this.state.errorMessage}/>
          <AlertDialog
            open={this.state.showAlertDialog}
            propertyName='showAlertDialog'
            parent={this}
            title='Ошибка заполнения данных'
            message='Заполните необходимые поля формы.'/>
          <LoginDialog
            open={this.state.showLoginDialog &&
                  !this.props.loginOk.value}
            propertyName='showLoginDialog'
            text="генерация отчетов будет недоступна"
            parent={this}/>
        </Center>
      </>);
  }

  renderMobile() {
    const { classes } = this.props;
    const renderInspections = this.state.inspections.map(
      this.createInspectionSelectionItem);
    let language = this.props.language.value;

    return (
      <>
        <input
          hidden={true}
          accept=".json"
          ref={input => this.inputTaxpayerDataFile = input}
          type="file" onChange={this.handleAttachTaxpayerDataFile} />
        {!this.state.inProgress
         ? (
           <form className={classes.container}
                 noValidate autoComplete="off"
                 id="taxForm">
             {this.state.inputFiles.map(f => (
               <Card style={{marginBottom: '4px'}}>
                 <CardContent style={{display: "flow"}}>
                   <TextField
                     id="inputFormat"
                     select
                     label={text(language,
                                 {"ru": "Формат исходных данных",
                                  "en": "Source data format",
                                  "es": "Formato de datos de origen"})}
                     value={f.inputFormat}
                     onChange={this.handleInputFormatChange(f)}
                     style={{width: "100%"}}
                     SelectProps={{
                       MenuProps: {
                         className: classes.menu,
                       },
                     }}>
                     {Object.keys(inputFormatsList(language)).map(k => (
                       <MenuItem key={k} value={k}>
                         <span className={classes.imgSpan}>
                           <img src={inputFormatsList(language)[k].img}
                                height='16px'
                                style={{ maxWidth: '18px'}}
                                alt='logo'>
                           </img>
                         </span>
                         &nbsp;
                         {inputFormatsList(language)[k].sortName}
                       </MenuItem>
                     ))}
                   </TextField>
                   <div className={classes.linkLeftMargin}>
                     <Link to={inputFormatsList(language)[f.inputFormat].helpURL}
                           component={NavLink}
                           className={classes.link}>
                       {text(language,
                             {"ru": "Как получить данный отчет",
                              "en": "How to get this report",
                              "es": "Cómo obtener este informe"})}
                     </Link>
                   </div>
                   <Upload label={text(language,
                                       {"ru": "Файл отчета",
                                        "en": "Report file",
                                        "es": "Archivo de informe"})}
                           accept={inputFormatsList(language)[f.inputFormat].extensionAccept}
                           error={f.fileError}
                           helperText={f.fileErrorText}
                           parent={this}
                           dataFromParent = {f.fileName}
                           handleChange={this.handleAttachFile(f)}
                           handleMultipleUpload={this.handleMultipleUpload(f.inputFormat)}/>
                   {f.fileIndex === 0
                    ? <Button className={classes.buttonPlus}
                              style={{float: "right"}}
                              variant="contained"
                              color="primary"
                              disabled={this.state.inputFiles.length >=
                                        this.maxInputFilesCount}
                              onClick={this.addReportFile(f.inputFormat)}>
                        <AddIcon/>
                      </Button>
                    : <Button className={classes.buttonPlus}
                              style={{float: "right"}}
                              variant="contained"
                              color="secondary"
                              onClick={this.delReportFile(f.fileIndex)}>
                        <RemoveIcon/>
                      </Button>}
                 </CardContent>
               </Card>
             ))}

             <Card style={{marginBottom: '4px'}}>
               <CardContent>
                 <table>
                   {/* ---- */}
                   <tr>
                     <td className={classes.width42}>
                       <AssignmentTurnedInOutlinedIcon style={{ fontSize: 32 }}/>
                     </td>
                     <td>
                       <Typography>
                         Уведомление об открытии и закрытии брокерского счета
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <GreenCheckbox
                             checked={this.state.outputAccountNotice}
                             onChange={this.handleCheckboxChangeWithCookie("outputAccountNotice")}
                             name="outputAccountNotice"
                             className={classes.green}/>
                         }
                         label="PDF"/>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           this.state.outputAccountNotice
                             ? <GreenSwitch
                                 disabled={!this.state.outputAccountNotice}
                                 checked={this.state.outputOpenNotice}
                                 onChange={this.handleCheckboxChangeWithCookie("outputOpenNotice")}
                                 name="outputOpenNotice"
                                 color="primary"/>
                           : <Switch
                               disabled={true}
                               checked={this.state.outputOpenNotice}
                               color="primary"/>
                         }
                         label={<div>
                                  {(this.state.outputOpenNotice)
                                   ? <Typography>открытие</Typography>
                                   : <Typography>закрытие</Typography>}
                                </div>}/>
                     </td>
                   </tr>
                   {/* ---- */}
                   <tr>
                     <td className={classes.width42}>
                       <CreateOutlinedIcon style={{ fontSize: 32 }}/>
                     </td>
                     <td>
                       <Typography>
                         Пояснительная записка к налоговой декларации
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <Checkbox
                             checked={this.state.outputXLSX}
                             onChange={this.handleCheckboxChangeWithCookie("outputXLSX")}
                             name="outputXLSX"
                             color="primary"/>
                         }
                         label="Excel"/>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <Checkbox
                             checked={this.state.outputExpalnationNotePDF}
                             onChange={this.handleCheckboxChangeWithCookie("outputExpalnationNotePDF")}
                             name="outputExpalnationNotePDF"
                             color="primary"/>
                         }
                         label="PDF"/>
                     </td>
                   </tr>
                   {/* ---- */}
                   <tr>
                     <td className={classes.width42}>
                       <DescriptionOutlinedIcon style={{ fontSize: 32 }}/>
                     </td>
                     <td>
                       <Typography>
                         Налоговая декларация
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <Checkbox
                             checked={this.state.outputXML}
                             onChange={this.handleCheckboxChangeWithCookie("outputXML")}
                             name="outputXML"
                             color="primary"/>
                         }
                         label="XML"/>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <Checkbox
                             checked={this.state.outputDC9}
                             onChange={this.handleCheckboxChangeWithCookie("outputDCX")}
                             name="outputDC9"
                             color="primary"/>}
                         label={this.getDeclarationExtension()}/>
                     </td>
                   </tr>
                   {/* ---- */}
                   <tr>
                     <td className={classes.width42}>
                       <SwapHorizOutlinedIcon style={{ fontSize: 32 }}/>
                     </td>
                     <td>
                       <Typography>
                         Отчет о движении денежных средств
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         disabled={!this.isCfrApplicable()}
                         control={
                           <PurpleCheckbox
                             checked={this.state.cfrXML && this.isCfrApplicable()}
                             onChange={this.handleCheckboxChangeWithCookie("cfrXML")}
                             name="cfrXML"/>}
                         label="XML"/>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <PurpleCheckbox
                             checked={this.state.cfrExcel && this.isCfrApplicable()}
                             onChange={this.handleCheckboxChangeWithCookie("cfrExcel")}
                             name="cfrExcel"/>}
                         label="Excel"/>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <PurpleCheckbox
                             checked={this.state.cfrPDF && this.isCfrApplicable()}
                             onChange={this.handleCheckboxChangeWithCookie("cfrPDF")}
                             name="cfrPDF"/>}
                         label="PDF"/>
                     </td>
                   </tr>
                   {/* ---- */}
                   <tr>
                     <td className={classes.width42}>
                       <img src='/images/idea.png'
                            width='32'
                            alt=''/>
                     </td>
                     <td>
                       <Typography>
                         Предложения по налоговой оптимизации
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <YellowCheckbox
                             checked={this.state.taxOptimizeXLSX}
                             onChange={this.handleCheckboxChangeWithCookie("taxOptimizeXLSX")}
                             name="taxOptimizeXLSX"/>}
                         label="Excel"/>
                     </td>
                   </tr>
                   {/* ---- */}
                   <tr>
                     <td className={classes.width42}>
                       <img src="/images/finParserLogo.svg"
                            height='30px'
                            width='30px'
                            alt='logo'/>
                     </td>
                     <td>
                       <Typography>
                         Сделки и позиции в формате FinParser
                       </Typography>
                     </td>
                   </tr>
                   <tr>
                     <td></td>
                     <td>
                       <FormControlLabel
                         className={classes.formControlMobile}
                         control={
                           <Checkbox
                             checked={this.state.outputFinParser}
                             onChange={this.handleCheckboxChangeWithCookie("outputFinParser")}
                             name="outputFinParser"
                             color="primary"/>}
                         label="Excel"/>
                     </td>
                   </tr>
                   {/* ---- */}
                 </table>
               </CardContent>
             </Card>

             <Card style={{marginBottom: '4px'}}>
               <CardContent>
                 <TextField
                   id="lastName"
                   label="Фамилия"
                   style={{width:"100%"}}
                   value={this.state.lastName}
                   onChange={this.handleChange('lastName')}/>
                  <TextField
                    id="firstName"
                    label="Имя"
                    style={{width:"100%"}}
                    value={this.state.firstName}
                    onChange={this.handleChange('firstName')}/>
                  <TextField
                    id="middleName"
                    label="Отчество"
                    style={{width:"100%"}}
                    value={this.state.middleName}
                    onChange={this.handleChange('middleName')}/>
                  <TextField
                    id="inn"
                    error={this.state.innError}
                    helperText={this.state.innErrorText}
                    label="ИНН*"
                    style={{width:"100%"}}
                    value={this.state.inn}
                    onChange={this.handleChange('inn')}
                    type="number"/>
                 <TextField
                   id="taxYear"
                   select
                   error={this.state.taxYearError}
                   helperText={this.state.taxYearErrorText}
                   label="Налоговый год*"
                   value={this.state.taxYear}
                   onChange={this.handleChange('taxYear')}
                   margin="normal"
                   style={{width:"50%"}}
                   variant="outlined"
                   InputProps={{
                     classes: {
                       notchedOutline: classes.notchedOutline
                     }
                   }}
                   SelectProps={{
                     MenuProps: {
                       className: classes.menu,
                     },
                   }}>
                   {years.map(option => (
                     <MenuItem key={option.value} value={option.value}>
                       {option.label}
                     </MenuItem>
                   ))}
                 </TextField>
                 <span style={{display: "inline-flex",
                               width:"2%"}}/>
                 <TextField
                   id="correctionNumber"
                   label="Номер корректировки"
                   value={this.state.correctionNumber}
                   onChange={this.handleChange('correctionNumber')}
                   type="number"
                   margin="normal"
                   style={{width:"48%"}}/>
                 <TextField
                   id="phone"
                   label="Телефон"
                   style={{width:"100%"}}
                   value={this.state.phone}
                   onChange={this.handleChange('phone')}/>
                 <TextField
                   id="oktmo"
                   label="ОКТМО"
                   value={this.state.oktmo}
                   onChange={this.handleChange('oktmo')}
                   style={{width:"70%"}}/>
                 <span style={{display: "inline-flex",
                               width:"2%"}}/>
                 <Link href="https://fias.nalog.ru/"
                       className={classes.link}
                       style={{width:"28%",
                               marginTop: "12px"}}
                       target="_blank">
                   Уточнить<br/>ОКТМО
                 </Link>
                 <table className="cleanTable"
                        style={{
                          marginTop: "10px",
                          width:"100%"}}>
                   <tr>
                     <td style={{width:"70%"}}>
                       <Autocomplete
                         id="inspectionNumber"
                         value={this.state.inspectionNumber}
                         options={renderInspections}
                         getOptionLabel={(option) => option.name}
                         autoHighlight={true}
                         onChange={this.handleChangeAutocomplete(
                           'inspectionNumber')}
                         renderInput={(params) =>
                           <TextField {...params}
                                      label={<Typography
                                               style={{marginTop: "-24px"}}>
                                               Номер налоговой<br/>
                                               инспекции
                                             </Typography>}/>}/>
                     </td>
                     <td style={{width:"2%"}}>
                     </td>
                     <td style={{width:"28%"}}>
                       <Link href="https://service.nalog.ru/addrno.do"
                             className={classes.link}
                             style={{marginTop: "12px"}}
                             target="_blank">
                         Уточнить №<br/>налоговой
                       </Link>
                     </td>
                   </tr>
                 </table>
                 <KeyboardDatePicker
                   id="birthDate"
                   keyboard
                   label="Дата рождения"
                   format="dd.MM.yyyy"
                   placeholder="10.10.2018"
           // handle clearing outside => pass plain array
           // if you are not controlling value outside
           // mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                   value={this.state.birthDate.selectedDate}
                   onChange={this.handleDateChange('birthDate')}
                   animateYearScrolling={false}
                   style={{width:"100%"}}/>
                 <TextField
                   id="birthLocation"
                   label="Место рождения"
                   value={this.state.birthLocation}
                   onChange={this.handleChange('birthLocation')}
                   style={{width:"100%"}}/>
                 <TextField
                   id="passpNumber"
                   label="Номер паспорта"
                   value={this.state.passpNumber}
                   onChange={this.handleChange('passpNumber')}
                   style={{width:"100%"}}/>
                 <KeyboardDatePicker
                   id="passpDate"
                   keyboard
                   label="Дата выдачи паспорта"
                   format="dd.MM.yyyy"
                   placeholder="10.10.2018"
           // handle clearing outside => pass plain array
           // if you are not controlling value outside
           // mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                   value={this.state.passpDate.selectedDate}
                   onChange={this.handleDateChange('passpDate')}
                   animateYearScrolling={false}
                   style={{width:"100%"}}/>
                 <TextField
                    id="passpDepartmentCode"
                    label="Код подразделения"
                    value={this.state.passpDepartmentCode}
                    onChange={this.handleChange('passpDepartmentCode')}
                    style={{width:"100%"}}/>
                 <TextField
                   id="passpIssued"
                   label="Кем выдан паспорт"
                   value={this.state.passpIssued}
                   onChange={this.handleChange('passpIssued')}
                   style={{width:"100%"}}/>
                 <Button className={classes.buttonBottom}
                         variant="contained"
                         color="secondary"
                         onClick={this.handleFillTestData}>
                   Заполнить тестовыми данными
                 </Button>
                 <table className="cleanTable"
                        style={{width:"100%"}}>
                   <tr>
                     <td>
                       <Button className={classes.buttonBottom}
                               variant="contained"
                               onClick={this.loadTaxpayerData}>
                         Загрузить
                       </Button>
                       <Button className={classes.buttonBottom}
                               variant="contained"
                               onClick={this.saveTaxpayerData}>
                         Сохранить
                       </Button>
                     </td>
                   </tr>
                 </table>
               </CardContent>
             </Card>

             <Card style={{marginBottom: '4px'}}>
               <div className={classes.cardContentWithoutPadding}>
                 <Accordion onChange={(event, expanded) => {
                   setCookie("expandIgnoresSection", expanded);
                   this.forceUpdate();}}
                            expanded={getCookieBoolean("expandIgnoresSection")}>
                   <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content"
                     id="panel1a-header" >
                     <Typography>
                       {text(language,
                             {"ru": "Дополнительные параметры",
                              "en": "Additional options",
                              "es": "Opciones adicionales"})}
                     </Typography>
                   </AccordionSummary>
                   <AccordionDetails style={{paddingLeft: "16px"}}>
                     <CardContent className={classes.cardContentWithoutPadding}
                                  style={{paddingTop: "0px",
                                          paddingLeft: "0px",
                                          paddingRight: "0px"}}>
                       <TextField
                         id="foreignExchangeSwapCode"
                         select
                         label="Код доходов по валютным свопам"
                         value={this.state.foreignExchangeSwapCode}
                         onChange={this.handleChange('foreignExchangeSwapCode')}
                         style={{width:"calc(100vw - 48px)"}}
                         margin="dense">
                         {Object.keys(foreignExchangeSwapCodes).sort().map(k => (
                           <MenuItem key={k} value={k}>
                             {foreignExchangeSwapCodes[k]}
                           </MenuItem>
                         ))}
                       </TextField>
                       <FormControlLabel
                         style={{width:"100%"}}
                         control={
                           <Checkbox
                             checked={this.state.ignoreForex}
                             onChange={this.handleCheckboxChangeWithCookie("ignoreForex")}
                             name="ignoreForex"
                             color="primary"/>}
                         label={<Typography>
                                  Игнорировать операции Forex
                                </Typography>}/>
                       <TextField
                         id="forexIncomeCode"
                         select
                         label="Код доходов по операциям Forex"
                         value={this.state.forexIncomeCode}
                         onChange={this.handleChange('forexIncomeCode')}
                         disabled={this.state.ignoreForex}
                         style={{width:"calc(100vw - 48px)"}}
                         margin="dense">
                         {Object.keys(forexIncomeCodes).sort().map(k => (
                           <MenuItem key={k} value={k}>
                             {forexIncomeCodes[k]}
                           </MenuItem>
                         ))}
                       </TextField>
                       <Typography style={{width: "100%",
                                           marginTop: "12px"}}>
                         Игнорировать следующие комиссии:
                       </Typography>
                       <FormControlLabel
                         style={{width: "100%"}}
                         control={
                           <Checkbox
                             checked={this.state.ignoreMonthlyMinimumFee}
                             onChange={this.handleCheckboxChange}
                             name="ignoreMonthlyMinimumFee"
                             color="primary"/>}
                         label={<Typography>
                                  Ежемесячные минимальные платежи
                                </Typography>}/>
                       <FormControlLabel
                         style={{width: "100%"}}
                         control={
                           <Checkbox
                             checked={this.state.ignoreWithdrawFee}
                             onChange={this.handleCheckboxChange}
                             name="ignoreWithdrawFee"
                             color="primary"/>}
                         label={<Typography>
                                  Плата за вывод средств
                                </Typography>}/>
                       <FormControlLabel
                         style={{width: "100%"}}
                         control={
                           <Checkbox
                             checked={this.state.ignoreConsultantFee}
                             onChange={this.handleCheckboxChange}
                             name="ignoreConsultantFee"
                             color="primary"/>}
                         label={<Typography>
                                  Плата за услуги консультанта
                                </Typography>}/>
                       <FormControlLabel
                         style={{width: "100%"}}
                         control={
                           <Checkbox
                             checked={this.state.ignoreVariousFee}
                             onChange={this.handleCheckboxChange}
                             name="ignoreVariousFee"
                             color="primary"/>}
                         label={<Typography>
                                    Прочие комиссии (например,<br/>
                                    подписка на рыночные данные)
                                </Typography>}/>
                       <TextField
                         style={{width: "49%"}}
                         id="transferFees"
                         label="Комиссии за перевод"
                         value={this.state.transferFees}
                         onChange={this.handleChange('transferFees')}
                         type="number"
                         margin="dense"/>
                       <span style={{display: "inline-flex",
                               width:"2%"}}/>
                       <TextField
                         style={{width: "49%"}}
                         id="managementFees"
                         label="Комиссия за управление"
                         value={this.state.managementFees}
                         onChange={this.handleChange('managementFees')}
                         type="number"
                         margin="dense"/>
                       <FormControlLabel
                         style={{width: "100%",
                                 marginTop: "12px"}}
                         control={
                           <Checkbox
                             checked={this.state.editablePDFs}
                             onChange={this.handleCheckboxChangeWithCookie("editablePDFs")}
                             name="editablePDFs"
                             color="secondary"/>}
                         label={<Typography>
                                  Pедактируемые PDF
                                </Typography>}/>
                       <FormControlLabel
                         style={{width: "100%"}}
                         control={
                           <Switch
                             checked={this.state.tPlus}
                             onChange={this.handleCheckboxChange}
                             name="tPlus"
                             color="primary"/>}
                         label={<Typography>
                                  Время сделки (Т+)
                                </Typography>}/>
                       <FormControlLabel
                         style={{width: "100%"}}
                         control={
                           <Switch
                             checked={this.state.groupByDays}
                             onChange={this.handleCheckboxChange}
                             name="groupByDays"
                             color="primary"/>}
                         label={<Typography>
                                  Сгруппировать по дням
                                </Typography>}/>
                       <FormControlLabel
                         style={{width: "100%"}}
                         control={
                           <Switch
                             checked={this.state.futuresBasedOnVariationMargin}
                             onChange={this.handleCheckboxChange}
                             name="futuresBasedOnVariationMargin"
                             color="primary"/>
                         }
                         label={<Typography>
                                  Считать фьючерсы на основе
                                  вариационной маржи
                                </Typography>}/>
                       <FormControlLabel
                         className={this.textField}
                         control={
                           <Switch
                             checked={this.state.roundNumbersForCFR}
                             onChange={this.handleCheckboxChange}
                             name="roundNumbersForCFR"
                             color="primary"/>}
                         label={<Typography>
                                  Округлять цифры в отчете
                                  о движении денежных средств
                                </Typography>}/>
                       <FormControlLabel
                         hidden={true}
                         className={this.textField}
                         control={
                           <Switch
                             checked={this.state.takeIntoAccountSuspensionSIODN}
                             onChange={this.handleCheckboxChange}
                             name="takeIntoAccountSuspensionSIODN"
                             color="primary"/>}
                         label={<Typography>
                                  Учитывать приостановку соглашений об
                                  избежании двойного налогообложения
                                </Typography>}/>
                     </CardContent>
                   </AccordionDetails>
                 </Accordion>
               </div>
             </Card>

             <Card style={{marginBottom: '4px'}}>
               <div className={classes.cardContentWithoutPadding}>
                 <Accordion
                   onChange={(event, expanded) => {
                     setCookie("expandReduce", expanded);
                     this.forceUpdate();}}
                   expanded={getCookieBoolean("expandReduce")}>
                   <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content"
                     id="panel1a-header" >
                     <Typography>
                       Вычеты
                     </Typography>
                   </AccordionSummary>
                   <AccordionDetails
                     style={{display: "flow",
                             paddingBottom: "24px"}}>
                     <div style={{width: "100%",
                                  display: "flow",
                                  paddingBottom: "30px"}}>
                       <Typography
                         style={{float: "left",
                                 marginTop: "-8px"}}>
                         Инвестиционные убытки<br/>
                         прошлых лет (для зачета)
                       </Typography>
                       <Button className={classes.buttonPlusNoMargin}
                               style={{float: "right"}}
                               variant="contained"
                               color="primary"
                               disabled={this.state.deductionByPrevious.length >= 10}
                               onClick={this.addDeductionByPrevious}>
                         <AddIcon/>
                       </Button>
                     </div>
                     {this.state.deductionByPrevious.map(d => (
                       <>
                         <TextField
                           label="Источник (брокерский/банковский счет)"
                           value={d.brokerName}
                           onChange={this.handleItemChange(d, 'brokerName')}
                           style={{width: "100%"}}
                           margin="dense"/>
                         <div style={{width: "100%",
                                      display: "flow"}}>
                           <TextField
                             select
                             error={this.state.taxYearError &&
                                    (d.deductionForSecurities !== 0 ||
                                     d.deductionForDerivatives !== 0 )}
                             helperText={(d.deductionForSecurities !== 0 ||
                                          d.deductionForDerivatives !== 0 )
                                         ? this.state.taxYearErrorText
                                         : ""}
                             label="Год"
                             value={d.year}
                             onChange={this.handleItemChange(d, 'year')}
                             style={{width: "70%",
                                     paddingTop: "11px"}}>
                             {years.map(option => (
                               <MenuItem key={option.value} value={option.value}>
                                 {option.label}
                               </MenuItem>
                             ))}
                           </TextField>
                           <Button className={classes.buttonPlusNoMargin}
                                   style={{float: "right",
                                           marginTop: "20px"}}
                                   variant="contained"
                                   color="secondary"
                                   onClick={this.delDeductionByPrevious(d.deductionIndex)}>
                             <RemoveIcon/>
                           </Button>
                         </div>
                         <TextField
                           label="Убыток по операциям с ЦБ"
                           value={d.deductionForSecurities}
                           onChange={this.handleItemChange(d, 'deductionForSecurities')}
                           type="number"
                           style={{width: "49%"}}
                           margin="dense"/>
                         <span style={{display: "inline-flex",
                                       width:"2%"}}/>
                         <TextField
                           label="Убыток по операциям с ПФИ"
                           value={d.deductionForDerivatives}
                           onChange={this.handleItemChange(d, 'deductionForDerivatives')}
                           type="number"
                           style={{width: "49%"}}
                           margin="dense"/>
                       </>
                     ))}
                   </AccordionDetails>
                 </Accordion>
               </div>
             </Card>

             <div>
               <Button style={{
                 margin: 5,
                 marginTop: '10px',
                 marginBottom: '100px',
                 float: 'right'}}
                       variant="contained"
                       color="primary"
                       disabled={!this.props.loginOk.value}
                       onClick={this.sendDataToCreateDeclaration}>
                 Сгенерировать отчеты
               </Button>
             </div>
           </form>
         )
         : <Center>
             <div className={classes.loader}>
               {this.state.documentGeneration
                ? <Progress
                   parent={this}
                   context={this.context}/>
                : <CircularProgress size={90}/>
               }
             </div>
           </Center>
        }
      </>);
  }

  renderDesktop() {
    const { classes } = this.props;
    const renderInspections = this.state.inspections.map(
      this.createInspectionSelectionItem);

    return (
      <Center>
        <input
          hidden={true}
          accept=".json"
          ref={input => this.inputTaxpayerDataFile = input}
          type="file" onChange={this.handleAttachTaxpayerDataFile} />
        {!this.state.inProgress
         ? (<form className={classes.container}
                  noValidate autoComplete="off"
                  id="taxForm">

              {this.state.inputFiles.map(f => (
                <Card className={classes.card}>
                  <div className={classes.cardContentWithoutPadding}>
                    <Accordion
                      id={"accId" + f.fileIndex}>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            Дата открытия (и закрытия) счета
                            необходима для <b>Отчета о движении денежных средств</b>
                            &nbsp;или <b>Уведомления об открытии и закрытии счета</b>.
                          </React.Fragment>
                        }
                        arrow={true}
                        placement="top">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          className={classes.cardContentWithoutPadding}>
                          <Typography>
                            Указать дату открытия и закрытия счета
                          </Typography>
                        </AccordionSummary>
                      </HtmlTooltip>
                      <AccordionDetails
                        className={classes.cardContentWithoutPadding}>
                        <KeyboardDatePicker
                          error={this.state.accountOpeningDateError &&
                                 f.accountOpeningDate.selectedDate == null}
                          helperText={(this.state.accountOpeningDateError &&
                                       f.accountOpeningDate.selectedDate == null)
                                      ? this.state.accountOpeningDateErrorText
                                      : ''}
                          keyboard
                          label="Дата открытия счета"
                          format="dd.MM.yyyy"
                          placeholder="10.10.2018"
                          value={f.accountOpeningDate.selectedDate}
                          onChange={this.handleAccountOpenCloseDateChange(f, 'accountOpeningDate')}
                          animateYearScrolling={false}
                          className={classes.dateFiled2}/>
                        <KeyboardDatePicker
                          keyboard
                          label="Дата закрытия счета"
                          format="dd.MM.yyyy"
                          placeholder="10.10.2018"
                          value={f.accountClosingDate.selectedDate}
                          onChange={this.handleAccountOpenCloseDateChange(f, 'accountClosingDate')}
                          animateYearScrolling={false}
                          className={classes.dateFiled2}/>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <CardContent>
                    <table className="cleanTable" cellspacing="0">
                      <tr>
                        <td>
                          <span className={classes.fileField}>
                            <Upload label='Файл отчета'
                                    accept={inputFormatsList("ru")[f.inputFormat].extensionAccept}
                                    error={f.fileError}
                                    helperText={f.fileErrorText}
                                    parent={this}
                                    dataFromParent = {f.fileName}
                                    handleChange={this.handleAttachFile(f)}
                                    handleMultipleUpload={this.handleMultipleUpload(f.inputFormat)}/>
                          </span>
                        </td>
                        <td>
                          <TextField
                            id="inputFormat"
                            select
                            label="Формат исходных данных"
                            value={f.inputFormat}
                            onChange={this.handleInputFormatChange(f)}
                            className={classes.textMax}
                            margin="normal"
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu,
                              },
                            }}>
                            {Object.keys(inputFormatsList("ru")).map(k => (
                              <MenuItem key={k} value={k}>
                                <span className={classes.imgSpan}>
                                  <img src={inputFormatsList("ru")[k].img}
                                       height='16px'
                                       style={{ maxWidth: '18px'}}
                                       alt='logo'>
                                  </img>
                                </span>
                                &nbsp;
                                {inputFormatsList("ru")[k].name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </td>
                        <td>
                          {f.fileIndex === 0
                           ? (<HtmlTooltip
                                title={
                                  <React.Fragment>
                                    Добавить отчет.<p/>
                                    В случае, если требуется составить декларацию
                                    на основе нескольких отчетов (возможно различных)
                                    брокеров.
                                  </React.Fragment>
                                }
                                arrow={true}
                                placement="left" >
                                <Button className={classes.buttonPlus}
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.inputFiles.length >=
                                                  this.maxInputFilesCount}
                                        onClick={this.addReportFile(f.inputFormat)}>
                                  <AddIcon/>
                                </Button>
                              </HtmlTooltip>)
                           : (<Button className={classes.buttonPlus}
                                      variant="contained"
                                      color="secondary"
                                      onClick={this.delReportFile(f.fileIndex)}>
                                <RemoveIcon/>
                              </Button>)}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <div className={classes.linkLeftMargin}>
                            <Link to={inputFormatsList("ru")[f.inputFormat].helpURL}
                                  component={NavLink}
                                  className={classes.link}>
                              Как получить данный отчет
                            </Link>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    </table>
                  </CardContent>
                </Card>
              ))}

              <Card className={classes.card}>
                <CardContent>
                  Получить результат в виде:
                  <FormGroup className={classes.marginTop}>
                    <table>
                      <tr>
                        <td className={classes.width42}>
                          <AssignmentTurnedInOutlinedIcon style={{ fontSize: 32 }}/>
                        </td>
                        <td className={classes.width250}>
                          <Typography>
                            Уведомление об открытии и закрытии брокерского счета
                          </Typography>
                        </td>
                        <td className={classes.tableMinWidth}>
                          <FormControlLabel
                            control={
                              <GreenCheckbox
                                checked={this.state.outputAccountNotice}
                                onChange={this.handleCheckboxChangeWithCookie("outputAccountNotice")}
                                name="outputAccountNotice"
                                className={classes.green}/>
                            }
                            label="PDF"/>
                        </td>
                        <td className={classes.tableMinWidth} colspan="2">
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                Открытие или закрытие счета
                              </React.Fragment>
                            }
                            arrow={true}
                            placement="top">
                            <FormControlLabel
                              className={this.textField}
                              control={
                                this.state.outputAccountNotice
                                  ? <GreenSwitch
                                            disabled={!this.state.outputAccountNotice}
                                            checked={this.state.outputOpenNotice}
                                            onChange={this.handleCheckboxChangeWithCookie("outputOpenNotice")}
                                            name="outputOpenNotice"
                                            color="primary"/>
                                : <Switch
                                            disabled={true}
                                            checked={this.state.outputOpenNotice}
                                            color="primary"/>
                              }
                              label={<div>
                                       {(this.state.outputOpenNotice)
                                        ? <Typography>открытие</Typography>
                                        : <Typography>закрытие</Typography>}
                                     </div>}/>
                          </HtmlTooltip>
                        </td>
                        <td className={classes.tableMinWidth}></td>
                      </tr>
                      <tr>
                        <td className={classes.width42}>
                          <CreateOutlinedIcon style={{ fontSize: 32 }}/>
                        </td>
                        <td className={classes.width250}>
                          <Typography>
                            Пояснительная записка к налоговой декларации
                          </Typography>
                        </td>
                        <td>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.outputXLSX}
                                onChange={this.handleCheckboxChangeWithCookie("outputXLSX")}
                                name="outputXLSX"
                                color="primary"/>
                            }
                            label="Excel"/>
                        </td>
                        <td className={classes.tableMinWidth} colspan="2">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.outputExpalnationNotePDF}
                                onChange={this.handleCheckboxChangeWithCookie("outputExpalnationNotePDF")}
                                name="outputExpalnationNotePDF"
                                color="primary"/>
                            }
                            label="PDF"/>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className={classes.width42}>
                          <DescriptionOutlinedIcon style={{ fontSize: 32 }}/>
                        </td>
                        <td className={classes.width250}>
                          <Typography>
                            Налоговая декларация
                          </Typography>
                        </td>
                        <td className={classes.tableMinWidth}>
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                Формат подачи электронной декларации
                              </React.Fragment>
                            }
                            arrow={true}
                            placement="top">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.outputXML}
                                  onChange={this.handleCheckboxChangeWithCookie("outputXML")}
                                  name="outputXML"
                                  color="primary"/>
                              }
                              label="XML"/>
                          </HtmlTooltip>
                        </td>
                        <td className={classes.tableMinWidth} colspan="2">
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                Формат программы Декларация
                              </React.Fragment>
                            }
                            arrow={true}
                            placement="top">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.outputDC9}
                                  onChange={this.handleCheckboxChangeWithCookie("outputDCX")}
                                  name="outputDC9"
                                  color="primary"/>}
                              label={this.getDeclarationExtension()}/>
                          </HtmlTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.width42}>
                          <SwapHorizOutlinedIcon style={{ fontSize: 32 }}/>
                        </td>
                        <td className={classes.width250}>
                          <Typography>
                            Отчет о движении денежных средств
                          </Typography>
                        </td>
                        <td className={classes.tableMinWidth}>
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                Отчет о движении денежных средств<br/>
                                (XML загружается непостредственно на сайт налоговой).
                              </React.Fragment>
                            }
                            arrow={true}
                            placement="top">
                            <FormControlLabel
                              disabled={!this.isCfrApplicable()}
                              control={
                                <PurpleCheckbox
                                  checked={this.state.cfrXML && this.isCfrApplicable()}
                                  onChange={this.handleCheckboxChangeWithCookie("cfrXML")}
                                  name="cfrXML"/>}
                              label="XML"/>
                          </HtmlTooltip>
                        </td>
                        <td className={classes.tableMinWidth}>
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                Отчет о движении денежных средств<br/>
                                (для самостоятельного заполнения на сайте налоговой).
                              </React.Fragment>
                            }
                            arrow={true}
                            placement="top">
                            <FormControlLabel
                              disabled={!this.isCfrApplicable()}
                              control={
                                <PurpleCheckbox
                                  checked={this.state.cfrExcel && this.isCfrApplicable()}
                                  onChange={this.handleCheckboxChangeWithCookie("cfrExcel")}
                                  name="cfrExcel"/>}
                              label="Excel"/>
                          </HtmlTooltip>
                        </td>
                        <td className={classes.tableMinWidth}>
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                Отчет о движении денежных средств<br/>
                                (для подачи в бумажном виде заказным письмом с описью или лично в инспекцию).
                              </React.Fragment>
                            }
                            arrow={true}
                            placement="top">
                            <FormControlLabel
                              disabled={!this.isCfrApplicable()}
                              control={
                                <PurpleCheckbox
                                  checked={this.state.cfrPDF && this.isCfrApplicable()}
                                  onChange={this.handleCheckboxChangeWithCookie("cfrPDF")}
                                  name="cfrPDF"/>}
                              label="PDF"/>
                          </HtmlTooltip>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className={classes.width42}>
                          <img src='/images/idea.png'
                               width='32'
                               alt=''/>
                        </td>
                        <td className={classes.width250}>
                          <Typography>
                            Предложения по налоговой оптимизации
                          </Typography>
                        </td>
                        <td className={classes.tableMinWidth} colspan="3">
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                Налоговую оптимизацию целесообразнее
                                всего проводить ближе к концу года.
                              </React.Fragment>
                            }
                            arrow={true}
                            placement="top">
                            <FormControlLabel
                              control={
                                <YellowCheckbox
                                  checked={this.state.taxOptimizeXLSX}
                                  onChange={this.handleCheckboxChangeWithCookie("taxOptimizeXLSX")}
                                  name="taxOptimizeXLSX"/>}
                              label="Excel"/>
                          </HtmlTooltip>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className={classes.width42}>
                          <img src="/images/finParserLogo.svg"
                               height='30px'
                               width='30px'
                               alt='logo'/>
                        </td>
                        <td className={classes.width250}>
                          <Typography>
                            Сделки и позиции в формате FinParser
                          </Typography>
                        </td>
                        <td className={classes.tableMinWidth} colspan="3">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.outputFinParser}
                                onChange={this.handleCheckboxChangeWithCookie("outputFinParser")}
                                name="outputFinParser"
                                color="primary"/>}
                            label="Excel"/>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </table>
                  </FormGroup>
                </CardContent>
              </Card>

              <Card className={classes.card}>
                <CardContent>
                  <TextField
                    id="lastName"
                    label="Фамилия"
                    className={classes.textField}
                    value={this.state.lastName}
                    onChange={this.handleChange('lastName')}
                    margin="normal"/>
                  <TextField
                    id="firstName"
                    label="Имя"
                    className={classes.textField}
                    value={this.state.firstName}
                    onChange={this.handleChange('firstName')}
                    margin="normal"/>
                  <TextField
                    id="middleName"
                    label="Отчество"
                    className={classes.textField}
                    value={this.state.middleName}
                    onChange={this.handleChange('middleName')}
                    margin="normal"/>
                  <TextField
                    id="inn"
                    error={this.state.innError}
                    helperText={this.state.innErrorText}
                    label="ИНН*"
                    value={this.state.inn}
                    onChange={this.handleChange('inn')}
                    type="number"
                    className={classes.textField}
                    margin="normal"/>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        Должен соответствовать периоду в отчете.<br/>
                        <b><u>Иначе Декларация будет пустая</u></b>.
                      </React.Fragment>
                    }
                    arrow={true}
                    placement="right">
                    <TextField
                      id="taxYear"
                      select
                      error={this.state.taxYearError}
                      helperText={this.state.taxYearErrorText}
                      label="Налоговый год*"
                      value={this.state.taxYear}
                      onChange={this.handleChange('taxYear')}
                      className={classes.textField}
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}>
                      {years.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </HtmlTooltip>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <b>0</b> - <u>первичный документ</u>.<p/>
                        В случае, если эта декларация уточняет другую,
                        уже поданную за этот год декларацию, в номере
                        корректировки должно быть
                        указано <b>1, 2, 3</b> и так далее -
                        <u>уточненный документ</u>.<p/>
                        Для уточненного документа значение должно
                        быть на 1 больше ранее принятого налоговым
                        органом документа.
                      </React.Fragment>
                    }
                    arrow={true}
                    placement="right" >
                    <TextField
                      id="correctionNumber"
                      label="Номер корректировки"
                      value={this.state.correctionNumber}
                      onChange={this.handleChange('correctionNumber')}
                      type="number"
                      className={classes.textField}
                      margin="normal"/>
                  </HtmlTooltip>
                  <TextField
                    id="phone"
                    label="Телефон"
                    className={classes.textField}
                    value={this.state.phone}
                    onChange={this.handleChange('phone')}
                    margin="normal"/>
                  <TextField
                    id="oktmo"
                    label="ОКТМО"
                    value={this.state.oktmo}
                    onChange={this.handleChange('oktmo')}
                    className={classes.textMax}
                    margin="normal"/>
                  <Link href="https://fias.nalog.ru/"
                        className={classes.link}
                        target="_blank">
                    Уточнить<br/>ОКТМО
                  </Link>
                  <table className="cleanTable">
                    <tr>
                      <td>
                        <Autocomplete
                          id="inspectionNumber"
                          value={this.state.inspectionNumber}
                          options={renderInspections}
                          getOptionLabel={(option) => option.name}
                          className={classes.textMax2}
                          autoHighlight={true}
                          onChange={this.handleChangeAutocomplete(
                            'inspectionNumber')}
                          renderInput={(params) =>
                            <TextField {...params}
                                       label="Номер налоговой инспекции"
                                       margin="normal"/>}
                        />
                      </td>
                      <td>
                        <Link href="https://service.nalog.ru/addrno.do"
                              className={classes.link}
                              target="_blank">
                          Уточнить №<br/>налоговой
                        </Link>
                      </td>
                    </tr>
                  </table>
                  <KeyboardDatePicker
                    id="birthDate"
                    keyboard
                    label="Дата рождения"
                    format="dd.MM.yyyy"
                    placeholder="10.10.2018"
            // handle clearing outside => pass plain array
            // if you are not controlling value outside
            // mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                    value={this.state.birthDate.selectedDate}
                    onChange={this.handleDateChange('birthDate')}
                    animateYearScrolling={false}
                    className={classes.dateFiled}/>
                  <TextField
                    id="birthLocation"
                    label="Место рождения"
                    value={this.state.birthLocation}
                    onChange={this.handleChange('birthLocation')}
                    className={classes.textMax}
                    margin="normal"/>
                  <KeyboardDatePicker
                    id="passpDate"
                    keyboard
                    label="Дата выдачи паспорта"
                    format="dd.MM.yyyy"
                    placeholder="10.10.2018"
            // handle clearing outside => pass plain array
            // if you are not controlling value outside
            // mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                    value={this.state.passpDate.selectedDate}
                    onChange={this.handleDateChange('passpDate')}
                    animateYearScrolling={false}
                    className={classes.dateFiled}/>
                  <TextField
                    id="passpNumber"
                    label="Номер паспорта"
                    value={this.state.passpNumber}
                    onChange={this.handleChange('passpNumber')}
                    className={classes.textField}
                    margin="normal"/>
                  <TextField
                    id="passpDepartmentCode"
                    label="Код подразделения"
                    value={this.state.passpDepartmentCode}
                    onChange={this.handleChange('passpDepartmentCode')}
                    className={classes.textField}
                    margin="normal"/>
                  <TextField
                    id="passpIssued"
                    label="Кем выдан паспорт"
                    value={this.state.passpIssued}
                    onChange={this.handleChange('passpIssued')}
                    className={classes.textMax2}
                    margin="normal"/>
                  <Button className={classes.buttonBottom}
                          variant="contained"
                          color="secondary"
                          onClick={this.handleFillTestData}>
                    Заполнить тестовыми данными
                  </Button>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        Загрузить данные о налогоплательщике из файла
                      </React.Fragment>
                    }
                    arrow={true}
                    placement="bottom">
                    <Button className={classes.buttonBottom}
                            variant="contained"
                            onClick={this.loadTaxpayerData}>
                      Загрузить
                    </Button>
                  </HtmlTooltip>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        Сохранить данные о налогоплательщике в файл
                      </React.Fragment>
                    }
                    arrow={true}
                    placement="bottom">
                    <Button className={classes.buttonBottom}
                            variant="contained"
                            onClick={this.saveTaxpayerData}>
                      Сохранить
                    </Button>
                  </HtmlTooltip>
                </CardContent>
              </Card>

              <Card className={getCookieBoolean("expandIgnoresSection") ||
                               getCookieBoolean("expandReduce")
                               ? classes.cardWithPaddingBottom
                               : classes.cardWithPaddingBottom2}>
                <Accordion onChange={(event, expanded) => {setCookie("expandIgnoresSection", expanded); this.forceUpdate();}}
                           expanded={getCookieBoolean("expandIgnoresSection")}>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        Например, в случае, если налоговая инспекция<br/>
                        не признает расходами ряд возникающих<br/>
                        комиссиий, их можно не учитывать
                      </React.Fragment>
                    }
                    arrow={true}
                    placement="top">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header" >
                      <Typography>
                        Дополнительные параметры расчета и комиссии
                      </Typography>
                    </AccordionSummary>
                  </HtmlTooltip>
                  <AccordionDetails>
                    <CardContent className={classes.cardContentWithoutPadding}>
                      <table>
                        <tr>
                          <td colspan="3">
                            <TextField
                              id="foreignExchangeSwapCode"
                              select
                              label="Код доходов по валютным свопам"
                              value={this.state.foreignExchangeSwapCode}
                              onChange={this.handleChange('foreignExchangeSwapCode')}
                              className={classes.textMax2T}
                              margin="normal"
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu,
                                },
                              }}>
                              {Object.keys(foreignExchangeSwapCodes).sort().map(k => (
                                <MenuItem key={k} value={k}>
                                  {foreignExchangeSwapCodes[k]}
                                </MenuItem>
                              ))}
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <TextField
                              id="forexIncomeCode"
                              select
                              label="Код доходов по операциям Forex"
                              value={this.state.forexIncomeCode}
                              onChange={this.handleChange('forexIncomeCode')}
                              disabled={this.state.ignoreForex}
                              className={classes.textMax2T}
                              margin="normal"
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu,
                                },
                              }}>
                              {Object.keys(forexIncomeCodes).sort().map(k => (
                                <MenuItem key={k} value={k}>
                                  {forexIncomeCodes[k]}
                                </MenuItem>
                              ))}
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <FormGroup>
                              Игнорировать следующие комиссии:
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.ignoreMonthlyMinimumFee}
                                    onChange={this.handleCheckboxChange}
                                    name="ignoreMonthlyMinimumFee"
                                    color="primary"
                                  />
                                }
                                label={<div>
                                         <Typography>
                                           Ежемесячные минимальные платежи
                                         </Typography>
                                       </div>}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.ignoreWithdrawFee}
                                    onChange={this.handleCheckboxChange}
                                    name="ignoreWithdrawFee"
                                    color="primary"
                                  />
                                }
                                label={<div>
                                         <Typography>
                                           Плата за вывод средств
                                         </Typography>
                                       </div>}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.ignoreConsultantFee}
                                    onChange={this.handleCheckboxChange}
                                    name="ignoreConsultantFee"
                                    color="primary"
                                  />
                                }
                                label={<div>
                                            <Typography>
                                              Плата за услуги консультанта
                                            </Typography>
                              </div>}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.ignoreVariousFee}
                                    onChange={this.handleCheckboxChange}
                                    name="ignoreVariousFee"
                                    color="primary"
                                  />
                                }
                                label={<div>
                                             <Typography>
                                               Прочие комиссии (например,<br/>
                                               подписка на рыночные данные)
                                             </Typography>
                                       </div>}
                              />
                            </FormGroup>
                          </td>
                          <td className={classes.topAlign}>
                            <FormGroup>
                              Игнорировать следующие расчеты:
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.ignoreForex}
                                    onChange={this.handleCheckboxChangeWithCookie("ignoreForex")}
                                    name="ignoreForex"
                                    color="primary"
                                  />
                                }
                                label={<div>
                                         <Typography>
                                           Операции Forex
                                         </Typography>
                                       </div>}
                              />
                              <TextField
                                id="taxCurrency"
                                select
                                label="Валюта налога"
                                value={this.state.taxCurrency}
                                onChange={this.handleChange('taxCurrency')}
                                className={classes.textField}
                                margin="normal"
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}>
                                {Object.keys(taxCurrencies).sort().map(k => (
                                  <MenuItem key={k} value={k}>
                                    {taxCurrencies[k]}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    При генерации Отчета о движении денежных средств
                                    и Уведомления об открытии и закрытии брокерского счета
                                    в формате PDF, полученный PDF-файл будет редактируемым.
                                    Однако, это <b>значительно увеличит
                                    размер файла</b>.
                                  </React.Fragment>
                                }
                                arrow={true}
                                placement="left">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.editablePDFs}
                                      onChange={this.handleCheckboxChangeWithCookie("editablePDFs")}
                                      name="editablePDFs"
                                      color="secondary"
                                    />
                                  }
                                  label={<div>
                                           <Typography>
                                             Pедактируемые PDF
                                           </Typography>
                                         </div>}
                                />
                              </HtmlTooltip>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="2">
                            <HtmlTooltip
                              title={"Сумма расходов на перевод денежных "+
                                     "средств из банков на брокерские счета, " +
                                     "которая может быть документально "+
                                     "подтверждена (руб.)."}
                              arrow={true}
                              placement="right">
                              <TextField
                                id="transferFees"
                                label="Комиссии за перевод"
                                value={this.state.transferFees}
                                onChange={this.handleChange('transferFees')}
                                type="number"
                                className={classes.textField}
                                margin="normal"/>
                            </HtmlTooltip>
                          </td>
                          <td rowspan="2">
                            <HtmlTooltip
                              title={"Сумма расходов на управление брокерским "+
                                     "счетом инвестиционным консультантом, " +
                                     "(финансовым советником) которая может "+
                                     "быть документально подтверждена (руб.)."}
                              arrow={true}
                              placement="right">
                              <TextField
                                id="managementFees"
                                label="Комиссия за управление"
                                value={this.state.managementFees}
                                onChange={this.handleChange('managementFees')}
                                type="number"
                                className={classes.textField216}
                                margin="normal"/>
                            </HtmlTooltip>
                          </td>
                          <td>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <b>Дата фактически произведенных расходов</b> -<br/>
                                  добавлять +2 дня к дате по сделкам с<br/>
                                  акциями, облигациями или +1 по опционам,<br/>
                                  фьючерсам, валютам.
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="left">
                              <FormControlLabel
                                className={this.textField}
                                control={
                                  <Switch
                                    checked={this.state.tPlus}
                                    onChange={this.handleCheckboxChange}
                                    name="tPlus"
                                    color="primary"/>
                                }
                                label={<div>
                                         <Typography>
                                           Время сделки (Т+)
                                         </Typography>
                                       </div>}/>
                            </HtmlTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <b>Произвести группировку сделок по дням</b> -<br/>
                                  все сделки по итогам каждого дня в году группируются
                                  в одну с указанием торгуемых инструментов.
                                  Таким образом, максимальное количество сделок с
                                  ЦБ не может превышать <b>365</b>, аналогично с ПФИ.
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="left">
                              <FormControlLabel
                                className={this.textField}
                                control={
                                  <Switch
                                    checked={this.state.groupByDays}
                                    onChange={this.handleCheckboxChange}
                                    name="groupByDays"
                                    color="primary"/>
                                }
                                label={<div>
                                         <Typography>
                                           Сгруппировать по дням
                                         </Typography>
                                       </div>}/>
                            </HtmlTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  Считать фьючерсы для отчетов
                                  Interactive Brokers на основе
                                  вариационной маржи.
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="top">
                              <FormControlLabel
                                className={this.textField}
                                control={
                                  <Switch
                                    checked={this.state.futuresBasedOnVariationMargin}
                                    onChange={this.handleCheckboxChange}
                                    name="futuresBasedOnVariationMargin"
                                    color="primary"/>
                                }
                                label={<div>
                                         <Typography>
                                           Считать фьючерсы на основе
                                           вариационной маржи
                                         </Typography>
                                       </div>}/>
                            </HtmlTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  Округлять цифры до целых рублей в
                                  отчете о движении денежных средств
                                  вместо отбрасывания дробной части.
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="top">
                              <FormControlLabel
                                className={this.textField}
                                control={
                                  <Switch
                                    checked={this.state.roundNumbersForCFR}
                                    onChange={this.handleCheckboxChange}
                                    name="roundNumbersForCFR"
                                    color="primary"/>
                                }
                                label={<div>
                                         <Typography>
                                           Округлять цифры в отчете
                                           о движении денежных средств
                                         </Typography>
                                       </div>}/>
                            </HtmlTooltip>
                          </td>
                        </tr>
                        <tr hidden={true}>
                          <td colspan="3">
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  В соответствии с Указом N 585 с
                                  08.08.2023 приостанавливается
                                  действие отдельных положений
                                  соглашений об избежании двойного
                                  налогообложения (далее - СОИДН).
                                  При этом, приостановление действия
                                  отдельных положений СОИДН относится
                                  к выплатам, произведенным с даты
                                  официального опубликования Указа N
                                  585, с 08.08.2023 и не затрагивает
                                  выплаты, произведенные до даты
                                  официального опубликования.
                                </React.Fragment>
                              }
                              arrow={true}
                              placement="top">
                              <FormControlLabel
                                className={this.textField}
                                control={
                                  <Switch
                                    checked={this.state.takeIntoAccountSuspensionSIODN}
                                    onChange={this.handleCheckboxChange}
                                    name="takeIntoAccountSuspensionSIODN"
                                    color="primary"/>
                                }
                                label={<div>
                                         <Typography>
                                           Учитывать приостановку соглашений об
                                           избежании двойного налогообложения
                                         </Typography>
                                       </div>}/>
                            </HtmlTooltip>
                          </td>
                        </tr>
                      </table>
                    </CardContent>
                  </AccordionDetails>
                </Accordion>
              </Card>

              <Card className={classes.cardWithPaddingBottom}>
                <Accordion onChange={(event, expanded) => {setCookie("expandReduce", expanded); this.forceUpdate();}}
                           expanded={getCookieBoolean("expandReduce")}>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        Инвестиционные убытки прошлых лет (для зачета),
                        имущественные и социальные вычеты.
                      </React.Fragment>
                    }
                    arrow={true}
                    placement="top">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header" >
                      <Typography>
                        Вычеты
                      </Typography>
                    </AccordionSummary>
                  </HtmlTooltip>
                  <AccordionDetails>
                    <CardContent className={classes.cardContentWithoutPadding}>
                      <table className="cleanTable">
                        <tr>
                          <td>
                            <table className="cleanTable">
                              <tr>
                                <td colspan="3">
                                  Инвестиционные убытки прошлых лет (для зачета)
                                </td>
                                <td>
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        Добавить убыток за прошлый период.<p/>
                                        В случае, если требуется учесть убытки
                                        за несколько прошлых лет и/или на
                                        нескольких брокерских/банковских счетах.
                                      </React.Fragment>
                                    }
                                    arrow={true}
                                    placement="left" >
                                    <Button className={classes.buttonPlusNoMargin}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.deductionByPrevious.length >= 10}
                                            onClick={this.addDeductionByPrevious}>
                                      <AddIcon/>
                                    </Button>
                                  </HtmlTooltip>
                                </td>
                              </tr>
                              {this.state.deductionByPrevious.map(d => (
                                <><tr>
                                    <td colspan="3">
                                      <TextField
                                        label="Источник (брокерский/банковский счет)"
                                        value={d.brokerName}
                                        onChange={this.handleItemChange(d, 'brokerName')}
                                        className={classes.textMax2T}
                                        margin="normal" />
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td colspan="3">
                                      <TextField
                                        select
                                        error={this.state.taxYearError &&
                                               (d.deductionForSecurities !== 0 ||
                                                d.deductionForDerivatives !== 0 )}
                                        helperText={(d.deductionForSecurities !== 0 ||
                                                     d.deductionForDerivatives !== 0 )
                                                    ? this.state.taxYearErrorText
                                                    : ""}
                                        label="Год"
                                        value={d.year}
                                        onChange={this.handleItemChange(d, 'year')}
                                        className={classes.textFieldSmall}
                                        margin="normal"
                                        SelectProps={{
                                          MenuProps: {
                                            className: classes.menu,
                                          },
                                        }}>
                                        {years.map(option => (
                                          <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                      <HtmlTooltip
                                        title={"Убыток за указанный год по "+
                                               "обращаемым ценным бумагам к " +
                                               "зачету в данный налоговый год (руб.)."}
                                        arrow={true}
                                        placement="top">
                                        <TextField
                                          label="Убыток по операциям с ЦБ"
                                          value={d.deductionForSecurities}
                                          onChange={this.handleItemChange(d, 'deductionForSecurities')}
                                          type="number"
                                          className={classes.textField25}
                                          margin="normal"/>
                                      </HtmlTooltip>
                                      <HtmlTooltip
                                        title={"Убыток за указанный год по "+
                                               "обращаемым производным финансовым " +
                                               "инструментам к зачету в данный " +
                                               "налоговый год (руб.)."}
                                        arrow={true}
                                        placement="top">
                                        <TextField
                                          label="Убыток по операциям с ПФИ"
                                          value={d.deductionForDerivatives}
                                          onChange={this.handleItemChange(d, 'deductionForDerivatives')}
                                          type="number"
                                          className={classes.textField25}
                                          margin="normal"/>
                                      </HtmlTooltip>
                                    </td>
                                    <td>
                                      <Button className={classes.buttonPlusNoMargin}
                                              variant="contained"
                                              color="secondary"
                                              onClick={this.delDeductionByPrevious(d.deductionIndex)}>
                                        <RemoveIcon />
                                      </Button>
                                    </td>
                                  </tr></>
                              ))}
                            </table>
                          </td></tr>
                        <tr><td>
                            </td></tr>
                      </table>
                    </CardContent>
                  </AccordionDetails>
                </Accordion>
              </Card>

              <div>
                <Button className={classes.buttonBottom}
                        variant="contained"
                        color="primary"
                        disabled={!this.props.loginOk.value}
                        onClick={this.sendDataToCreateDeclaration}>
                  Сгенерировать отчеты
                </Button>
              </div>
            </form>)
         : (<div className={classes.loader}>
             {this.state.documentGeneration
              ? <Progress
                  parent={this}
                  context={this.context}/>
              : <CircularProgress size={90}/>
             }
           </div>)
        }
      </Center>
    );
  }
}

function mapStateToProps(state) {
  const loginOk = state.loginOk;
  const language = state.language;
  return {
    loginOk,
    language
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginOk: (value) => dispatch(setLoginOk(value)),
  };
};

TaxFormRu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TaxFormRu));
