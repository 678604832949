import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HelpToContentsLink from './helpToContentsLink';
import SupportEmail from '../common/supportEmail';
import { text } from "../languageSelection";
import { connect } from "react-redux";

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    }
});

class HelpCyberSecurity extends React.Component {
    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>

                <div className={classes.text}>
                  <p/>
                  {text(language,
                        {"ru":
                         <span>
                           Ряд вопросов, связанных с безопасностью решается за
                           счет использования протокола HTTPS. Это обеспечивает
                           защиту от прослушивания сетевого трафика. Таким
                           образом, вы сможете безопасно передавать данные
                           брокерского отчета и получать готовую налоговую
                           декларацию.
                         </span>,
                         "en":
                         <span>
                           A number of security issues are addressed through the
                           use of the HTTPS protocol. This provides protection
                           against eavesdropping on network traffic. Thus, you
                           can securely transfer brokerage report data and
                           receive a ready-made tax return.
                         </span>,
                         "es":
                         <span>
                           Una serie de problemas de seguridad se abordan
                           mediante el uso del protocolo HTTPS. Esto proporciona
                           protección contra escuchas en el tráfico de la
                           red. Por lo tanto, puede transferir de forma segura
                           los datos del informe de corretaje y recibir una
                           declaración de impuestos preparada.
                         </span>})}
                  <p/>
                  {text(language,
                        {"ru":
                         <span>
                           При желании, можно удалить все данные, относящиеся к
                           пользователю такие как имя, фамилия и номер счета из
                           отчета  брокера перед отправкой  файла и запросить
                           декларацию для "Иванова Ивана", а уже у себя на
                           компьютере добавить правильные пользовательские
                           данные. Для сервиса это не важно. На самом деле, для
                           безопасности данных это не требуется, но если полная
                           анонимность является первостепенной зачадей, такая
                           возможность существует. Для этого необходимо открыть
                           файл отчета брокера в простом текстовом редакторе,
                           например, в Блокноте, внести необходимые правки и
                           сохранить файл с расширением <b>CSV</b>.
                           Использование для этой цели Excel,
                           вероятнее всего, сделает невозможным последующее
                           корректное чтение файла данным сервисом.
                         </span>,
                         "en":
                         <span>
                           If desired, you can remove all data related to the
                           user such as first name, last name and account number
                           from the broker's report before sending the file and
                           request a declaration for "Ivanov Ivan", and add the
                           correct user data on your computer. It doesn't matter
                           to the service. In fact, this is not required for
                           data security, but if complete anonymity is
                           paramount, this possibility exists. To do this, you
                           need to open the broker report file in a simple text
                           editor, such as Notepad, make the necessary changes
                           and save the file with the <b>CSV</b> extension.
                           Using Excel for this purpose, most likely,
                           will make it impossible for this service to
                           subsequently correctly read the file.
                         </span>,
                         "es":
                         <span>
                           Si lo desea, puede eliminar todos los datos
                           relacionados con el usuario, como nombre, apellido y
                           número de cuenta del informe del bróker antes de
                           enviar el archivo y solicitar una declaración para
                           "Ivanov Ivan", y agregar los datos de usuario
                           correctos en su computadora. No importa el
                           servicio. De hecho, esto no es necesario para la
                           seguridad de los datos, pero si el anonimato completo
                           es primordial, esta posibilidad existe. Para hacer
                           esto, debe abrir el archivo de informe del bróker
                           en un editor de texto simple, como el Bloc de notas,
                           realizar los cambios necesarios y guardar el archivo
                           con la extensión <b>CSV</b>. El uso de Excel para
                           este propósito, muy probablemente, imposibilitará que
                           este servicio lea correctamente el archivo
                           posteriormente.
                         </span>})}
                  <p/>
                  {text(language,
                        {"ru":
                         <span>
                           Декларация, как и отчет брокера, не сохраняется на
                           сервере по соображениям безопасности. Но в базе
                           данных остается хеш-код, вычисленный на основе
                           отчета. Это необратимый алгоритм. Никакая часть
                           исходного отчета брокера (и, разумеется, декларации)
                           не может быть получена на основе вычисленного и
                           сохраненного хеш-ключа. Но это позволяет понять, что
                           пользователь запросил уже оплаченный отчет и не
                           взимать плату за повторную генерацию. Сервис
                           осуществляет преобразование потоков данных, но не их
                           хранение. Так как данные из отчетов и полученные
                           декларации не хранятся на сервере (вообще) никакой
                           доступ к ним невозможен, в том числе к данным отчетов
                           нет доступа и у авторов сервиса, поэтому, если вам
                           необходимо что-то уточнить по вашему отчету,
                           присылайте его на почту поддержки:
                         </span>,
                         "en":
                         <span>
                           The declaration, like the broker's report, is not
                           stored on the server for security reasons. But the
                           hash code remains in the database, calculated on the
                           basis of the report. This is an irreversible
                           algorithm. No part of the broker's original report
                           (and, of course, the declaration) can be derived from
                           the computed and stored hash key. But this allows you
                           to understand that the user has requested an already
                           paid report and not charge for re-generation. The
                           service performs the transformation of data streams,
                           but not their storage. Since the data from the
                           reports and the received declarations are not stored
                           on the server (in general), no access to them is
                           possible, including the authors of the service have
                           no access to the report data, so if you need to
                           clarify something about your report, send it to
                           support email:
                         </span>,
                         "es":
                         <span>
                           La declaración, al igual que el informe del bróker,
                           no se almacena en el servidor por razones de
                           seguridad. Pero el código hash permanece en la base
                           de datos, calculado sobre la base del informe. Este
                           es un algoritmo irreversible. Ninguna parte del
                           informe original del bróker (y, por supuesto, la
                           declaración) puede derivarse de la clave hash
                           calculada y almacenada. Pero esto permite entender
                           que el usuario ha solicitado un informe ya pagado y
                           no cobra por la regeneración. El servicio realiza la
                           transformación de flujos de datos, pero no su
                           almacenamiento. Dado que los datos de los informes y
                           las declaraciones recibidas no se almacenan en el
                           servidor (en general), no es posible acceder a ellos,
                           incluso los autores del servicio no tienen acceso a
                           los datos del informe, por lo que si necesita aclarar
                           algo sobre su informe, envíelo al correo de soporte:
                         </span>})}&ensp;
                  <SupportEmail/>.
                </div>
              </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpCyberSecurity));
