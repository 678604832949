import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Center from 'react-center';
import {setCookie} from './cookieLib';
import { setResidence } from './state/residenceSlice';
import { buildNewUrl } from './common/urlLib';
import { text } from "./languageSelection";
import { connect } from "react-redux";
import { isMobile } from './common/deviceDetector';

const styles = {
    vcenter: {
        position: 'absolute', top: '50%',
        transform: 'translate(0%, -50%)'
    },
    root: {
        flexGrow: 1,
        placeContent: "center"
    },
    paper: {
        height: 200,
        width: 200,
    },
    mobilePaper: {
        height: 120,
        width: 120,
    },
    shadow: {
        marginTop: 40,
        boxShadow: "0 0 8px #777777"
    },
    mobileShadow: {
        marginTop: 2,
        boxShadow: "0 0 8px #777777"
    },
    countryName: {
        marginTop: 10,
    },
    residenceHeader: {
        marginTop: 40,
        marginBottom: 10,
    },
    mobileResidenceHeader: {
        marginTop: 10,
        marginBottom: 10,
    },
};

class CountryGrid extends React.Component {

    changeResidenceCountry = (residenceCountry) => {
        this.props.setResidence(residenceCountry);
        setCookie("residenceCountry", residenceCountry);
        if (this.props.residence.value != residenceCountry) {
          window.location.href = buildNewUrl({residenceCountry: residenceCountry});
        }
    }

    handleChangeResidenceCountry = (residenceCountry) => event => {
        this.changeResidenceCountry(residenceCountry);
    };

    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <Grid container className={classes.root} spacing={isMobile ? 1: 2}>
                <Typography variant="h5" align='center'
                            className={isMobile
                                       ? classes.mobileResidenceHeader
                                       : classes.residenceHeader}>
                  {text(language,
                        {"ru": "Выберите налоговое резидентство",
                         "en": "Select tax residence",
                         "es": "Seleccionar residencia fiscal"})}
                </Typography>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={isMobile ? 2: 3}>
                  <Grid key={0} item>
                    <Card className={isMobile ? classes.mobilePaper : classes.paper}>
                      <CardActionArea className={isMobile ? classes.mobilePaper : classes.paper}
                                      onClick={this.handleChangeResidenceCountry('earth')}>
                        <CardContent>
                          <Center>
                            <img src='/images/flags/International_Flag_of_Planet_Earth.svg'
                                 className={isMobile ? classes.mobileShadow: classes.shadow}
                                 width='100'
                                 alt=''/>
                          </Center>
                          <Typography variant="body2" align='center'
                                      className={classes.countryName}>
                            {text(language,
                                  {"ru": "Любое",
                                   "en": "Any",
                                   "es": "Cualquier"})}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid key={1} item>
                    <Card className={isMobile ? classes.mobilePaper : classes.paper}>
                      <CardActionArea className={isMobile ? classes.mobilePaper : classes.paper}
                                      onClick={this.handleChangeResidenceCountry('spain')}>
                        <CardContent>
                          <Center>
                            <img src='/images/flags/Bandera_de_España.svg'
                                 className={isMobile ? classes.mobileShadow: classes.shadow}
                                 width='100'
                                 alt=''/>
                          </Center>
                          <Typography variant="body2" align='center'
                                      className={classes.countryName}>
                            {text(language,
                                  {"ru": "Испания",
                                   "en": "Spain",
                                   "es": "España"})}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid key={2} item>
                    <Card className={isMobile ? classes.mobilePaper : classes.paper}>
                      <CardActionArea className={isMobile ? classes.mobilePaper : classes.paper}
                                      onClick={this.handleChangeResidenceCountry('russia')}>
                        <CardContent>
                          <Center>
                            <img src='/images/flags/Flag_of_Russia.svg'
                                 className={isMobile ? classes.mobileShadow: classes.shadow}
                                 width='100'
                                 alt=''/>
                          </Center>
                          <Typography variant="body2" align='center'
                                      className={classes.countryName}>
                            {text(language,
                                  {"ru": "Россия",
                                   "en": "Russia",
                                   "es": "Rusia"})}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid key={3} item>
                    <Card className={isMobile ? classes.mobilePaper : classes.paper}>
                      <CardActionArea className={isMobile ? classes.mobilePaper : classes.paper}
                                      onClick={this.handleChangeResidenceCountry('estonia')}>
                        <CardContent>
                          <Center>
                            <img src='/images/flags/Flag_of_Estonia.svg'
                                 className={isMobile ? classes.mobileShadow: classes.shadow}
                                 width='100'
                                 alt=''/>
                          </Center>
                          <Typography variant="body2" align='center'
                                      className={classes.countryName}>
                            {text(language,
                                  {"ru": "Эстония",
                                   "en": "Estonia",
                                   "es": "Estonia"})}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const residence = state.residence;
    const language = state.language;
    return {
        residence,
        language
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
      setResidence: (value) => dispatch(setResidence(value)),
  };
};

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(CountryGrid));
