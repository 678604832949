import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HelpToContentsLink from './helpToContentsLink';
import Typography from '@material-ui/core/Typography';
import { Teal } from '../colors';
import HelpHashLink from './helpHashLink';
import { text } from "../languageSelection";
import { connect } from "react-redux";

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    }
});

class HelpEstonia extends React.Component {

    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>
                <div className={classes.text}>
                  <HelpHashLink to="/help/HelpEstonia#1">
                    <Typography id="1" variant="h5" component="h2">
                      Срок подачи налоговой декларации и уплаты налогов
                    </Typography>
                  </HelpHashLink>
                  <br/>
                  Декларации подаются в&ensp;
                  <Link href="https://maasikas.emta.ee"
                        className={classes.link}
                        target="_blank">
                    Налогово-таможенный департамент</Link> с&ensp;
                  <Teal>15 февраля</Teal> до&ensp;
                  <Teal>30 апреля</Teal> года, следующего за годом получения
                  доходов, и подоходный налог должен быть уплачен на банковский
                  счёт Налогово-таможенного департамента до&ensp;
                  <Teal>1 октября</Teal> года подачи декларации.
                  <p/>
                  <HelpHashLink to="/help/HelpEstonia#2">
                    <Typography id="1" variant="h5" component="h2">
                      Система налогообложения доходов частного лица от инвестирования
                    </Typography>
                  </HelpHashLink>
                  <br/>
                  В Эстонии параллельно действуют две системы
                  налогообложения доходов частного лица от инвестирования:
                  <ul>
                    <li>обычная система, при которой необходимо
                      декларировать все сделки по  продаже ценных бумаг</li>
                    <li>система инвестиционного счета, при которой необходимо
                      декларировать только денежные взносы и выплаты,
                      произведенные на счете. </li>
                  </ul>
                  Инвестиционный счет — это обычный денежный счет, который
                  открыт в кредитном учреждении – резиденте государства –
                  участника Организации экономического сотрудничества и развития
                  (ОЭСР) или в постоянном месте деятельности кредитного
                  учреждения находящегося в государстве ОЭСР.
                  <p/>
                  Доход от ценных бумаг можно декларировать, используя обе
                  системы. Используя систему инвестиционного счета, частное лицо
                  может отложить уплату подоходного налога на доход от
                  финансового актива до тех пор, пока он не будет использован в
                  других целях. Для этого операции с финансовыми активами должны
                  осуществляться только через инвестиционный счет. Пока вы не
                  снимаете полученный доход с инвестиционного счета, налоговые
                  обязательства не возникают.
                  <p/>
                  Общее правило, в соответствии с которым для отсрочки
                  налогового обязательства финансовые активы должны быть
                  приобретены за счет денежных средств, находящихся на
                  инвестиционном счете, и деньги, полученные от продажи
                  финансовых активов или полученный от финансовых активов доход,
                  должны незамедлительно быть переведены на инвестиционный
                  счет. Это действует также в случае, когда для инвестирования
                  пользуются услугой инвестиционной компании (брокерской
                  фирмы).
                  <p/>
                  Хотя закон позволяет декларировать в качестве инвестиционного
                  счета иностранный счет, речь идет только о финансовых
                  учреждениях. Брокеры, такие как, Interactive Brokers -
                  таковыми не являются, поэтому счета брокеров нельзя указывать
                  в качестве инвестиционных. Таким образом, для того, чтобы
                  использовать преимущества инвестиционного счета,
                  все транзакции брокеру и от брокера должны начинаться и
                  заканчиваться на инвестиционном счете. Если вы хотите купить
                  ценные бумаги через иностранного брокера, необходимо
                  перевести сумму покупки с вашего инвестиционного на брокерский
                  счет, затем производится покупка. При продаже ценных бумаг,
                  необходимо сразу после продажи перевести деньги на
                  инвестиционный счет. Это обязательно сделать до совершения
                  новой покупки у брокера. Аналогично с дивидендами, которые
                  поступают на счет брокера. Если вы хотите
                  их реинвестировать, то необходимо сначала перевести их
                  на инвестиционный счет, а затем снова перевести на счет
                  брокера и только после этого совершать покупку. Тогда это не
                  считается выплатой с инвестиционного счета и нет обязанности
                  платить подоходный налог.
                  <p/>
                  По инвестиционному счету необходимо каждый год подавать отчет
                  в Налогово-таможенный департамент.
                  <p/>
                  В случае обычной системы все продажи ценных бумаг,
                  осуществлённые в течение календарного года, должны быть
                  заявлены в Налогово-таможенный департамент в следующем
                  году. По убыточным и прибыльным сделкам с ценными бумагами
                  можно производить взаимозачёт. Если убыточных сделок по
                  операциям с ценными бумагами в текущем году больше, чем
                  прибыльных, налог на прибыль не взимается. Убыточные операции
                  с ценными бумагами также могут быть перенесены на следующий
                  год в отчёте о прибылях и убытках. По обычной системе из
                  реализованного дохода можно вычесть платы за услуги, платы за
                  управление вычитать нельзя.
                  <br/>
                  Стоимостью приобретения ценных бумаг являются все
                  документально подтверждённые расходы, произведённые при
                  приобретении ценных бумаг.
                  При отчуждении ценных бумаг из цены продажи помимо стоимости
                  приобретения можно вычитать также непосредственно связанные с
                  продажей или обменом имущества документально подтверждённые
                  расходы, например плата за совершение сделок и пр.
                  <br/>
                  Как расходы не учитываются ни плата за облуживание счёта
                  ценных бумаг, ни прочие общие расходы, связанные с управлением
                  ценных бумаг. Эти расходы налогоплательщик должен был бы
                  произвести независимо от сделки по отчуждению ценных бумаг и
                  поэтому их нельзя вычитать из прибыли или добавлять к
                  убыткам.
                  <p/>
                  Предлагаемый сервисом механизм применим только к случаю
                  использования обычной системы и предполагает подачу налоговой
                  декларации от операций на брокерсоком счете (либо счете ценных
                  бумаг банка). Если же вы используете инвестиционный счет,
                  вам необходимо обратиться в финансовое учреждение, в котором
                  он открыт (обычно это эстонский банк) для получения сведений,
                  необходимых для декларирования отчета по инвестиционному
                  счету.
                  <p/>
                  Доход от отчуждения ценных бумаг (в том числе от
                  продажи паев инвестиционного фонда), полученный физическим
                  лицом, являющимся резидентом, облагается налогом по
                  ставке <b>20%</b>.
                  <p/>
                  <HelpHashLink to="/help/HelpEstonia#3">
                    <Typography id="1" variant="h5" component="h2">
                      Подача декларации
                    </Typography>
                  </HelpHashLink>
                  <br/>
                  Декларации Налогово-таможенному департаменту можно подать:
                  <ul>
                    <li>в среде&ensp;
                      <Link href="https://maasikas.emta.ee/login/"
                          className={classes.link}
                          target="_blank">
                      э-услуг</Link>&ensp;
                      Налогово-таможенного департамента e-MTA. Заполненную и
                      подписанную декларацию можно загрузить в пункте меню
                      «Общение».</li>
                    <li>дигитально подписанную отправить на адрес э-почты&ensp;
                      <Link href="mailto:emta@emta.ee"
                          className={classes.link}
                          target="_blank">
                      emta@emta.ee</Link></li>
                    <li>в&ensp;
                      <Link href="https://www.emta.ee/ru/biznes-klient/departament-novosti-i-kontakt/kontakty/mesta-obsluzhivaniya"
                          className={classes.link}
                          target="_blank">
                        бюро обслуживания</Link> Налогово-таможенного
                      департамента</li>
                  </ul>
                  <HelpHashLink to="/help/HelpEstonia#4">
                    <Typography id="1" variant="h5" component="h2">
                      Процедура подачи через э-услуги e-MTA
                    </Typography>
                  </HelpHashLink>
                  <br/>
                  <ol>
                    <li>Входите в&ensp;
                      {text(language,
                            {"ru": <span>
                                     <Link href="https://emta.ee/ru"
                                           className={classes.link}
                                           target="_blank">emta.ee</Link>.
                                     Нажимаете Войти в e-MTA.
                                   </span>,
                             "en": <span>
                                     <Link href="https://emta.ee/en"
                                           className={classes.link}
                                           target="_blank">emta.ee</Link>.
                                     Нажимаете Enter e-MTA.
                                   </span>})}
                      &ensp;Проходите аутентификацию выбранным способом.
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Выбираете Налоги. Далее - Подоходный и
                                     социальный налог - Декларация о
                                     доходах, выбираете год.
                                   </span>,
                             "en": <span>
                                     Select Income and social tax - Income tax
                                     return, choose tax year.
                                   </span>})}&ensp;
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Далее - Зарубежные доходы - 8.1 Заработная
                                     плата и прочий доход.
                                   </span>,
                             "en": <span>
                                     Next - Income received abroad - 8.1 Wages,
                                     salaries and other remuneration.
                                   </span>})}
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     В разделе 8.2 Отчуждение ценных бумаг.
                                   </span>,
                             "en": <span>
                                     In the secion 8.2 Transfer of securities.
                                   </span>})}
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     8.8 Доход, полученный в иностранном государстве, не облагаемый налогом в Эстонии
                                   </span>,
                             "en": <span>
                                     8.8 Income received in a foreign country and exempt from tax in Estonia
                                   </span>})}
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Вперед
                                   </span>,
                             "en": <span>
                                     Forward
                                   </span>})}
                    </li>
                  </ol>
                </div>
              </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpEstonia));
