import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import HelpHashLink from '../help/helpHashLink';
import { text } from "../languageSelection";
import { connect } from "react-redux";

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    }
});

class PrivacyPolicy extends React.Component {
  render() {
    const { classes } = this.props;
    let language = this.props.language.value;

    return (
      <Card className={classes.card}>
        <CardContent className={classes.text}>
          <HelpHashLink to="/privacyPolicy#1" >
            <Typography variant="h5" component="h2">
              {text(language,
                    {"ru": "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ",
                     "en": "PRIVACY POLICY",
                     "es": "POLÍTICA DE PRIVACIDAD"})}
            </Typography>
          </HelpHashLink>
          <p/>

          <HelpHashLink to="/privacyPolicy#2" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "1. Общая информация об обработке данных",
                     "en": "1. General information on data processing",
                     "es": "1. Información general sobre el tratamiento de datos"})}
            </Typography>
          </HelpHashLink>
          <HelpHashLink to="/privacyPolicy#3" >
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "1.1 Сфера обработки персональных данных",
                     "en": "1.1 Scope of the processing of personal data",
                     "es": "1.1 Alcance del tratamiento de datos personales"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Мы собираем и используем персональные
                           данные наших пользователей только в той
                           степени, в которой это необходимо для
                           предоставления нашего веб-сайта, нашего
                           контента и наших услуг.
                         </span>,
                   "en": <span>
                           We collect and use personal data of our
                           users only to the extent necessary to
                           provide our website, our content and our
                           services.
                         </span>,
                   "es": <span>
                           Recopilamos y utilizamos datos personales
                           de nuestros usuarios solo en la medida
                           necesaria para proporcionar nuestro sitio
                           web, nuestro contenido y nuestros
                           servicios.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#4" >
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "1.2 Правовая основа обработки персональных данных",
                     "en": "1.2 Legal basis for the processing of personal data",
                     "es": "1.2 Base jurídica para el tratamiento de datos personales"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Если мы получаем согласие субъекта данных
                           на обработку персональных данных, статья 6
                           (1) (a) Общего регламента ЕС по защите
                           данных (GDPR) служит правовой основой для
                           обработки персональных данных.
                         </span>,
                   "en": <span>
                           Insofar as we obtain the consent of the
                           data subject for processing operations
                           involving personal data, Article 6 (1) (a)
                           of the EU General Data Protection
                           Regulation (GDPR) serves as the legal basis
                           for the processing of personal data.
                         </span>,
                   "es": <span>
                           En la medida en que obtengamos el
                           consentimiento del interesado para
                           operaciones de procesamiento que involucren
                           datos personales, el Artículo 6 (1) (a) del
                           Reglamento General de Protección de Datos
                           de la UE (GDPR) sirve como base legal para
                           el procesamiento de datos personales.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           При обработке персональных данных,
                           необходимых для исполнения договора,
                           стороной которого является субъект данных,
                           применяется ст. 6 (1) (b) GDPR служит
                           правовой основой. Это также относится к
                           операциям обработки, которые необходимы для
                           выполнения преддоговорных мер.
                         </span>,
                   "en": <span>
                           When processing personal data that is
                           necessary for the performance of a contract
                           to which the data subject is a party,
                           Art. 6 (1) (b) GDPR serves as the legal
                           basis. This also applies to processing
                           operations that are necessary for the
                           performance of pre-contractual measures.
                         </span>,
                   "es": <span>
                           Cuando se traten datos personales que sean
                           necesarios para la ejecución de un contrato
                           en el que el interesado sea parte, el
                           art. La base jurídica es el artículo 6 (1)
                           (b) del RGPD. Esto también se aplica a las
                           operaciones de procesamiento que son
                           necesarias para la ejecución de medidas
                           precontractuales.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Поскольку обработка персональных данных
                           необходима для соблюдения юридического
                           обязательства, которому подчиняется наша
                           компания, ст. 6 (1) c GDPR служит правовой
                           основой.
                         </span>,
                   "en": <span>
                           Insofar as processing of personal data is
                           necessary for compliance with a legal
                           obligation to which our company is subject,
                           Art. 6 (1) c GDPR serves as the legal
                           basis.
                         </span>,
                   "es": <span>
                           En la medida en que el tratamiento de datos
                           personales sea necesario para el
                           cumplimiento de una obligación legal a la
                           que esté sujeta nuestra empresa, el art. La
                           base jurídica es el artículo 6 (1) c del
                           RGPD.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           В случае, если жизненно важные интересы
                           субъекта данных или иного физического лица
                           требуют обработки персональных данных,
                           ст. 6 (1) (d) GDPR служит правовой основой.
                         </span>,
                   "en": <span>
                           In the event that vital interests of the
                           data subject or another natural person make
                           it necessary to process personal data,
                           Art. 6 (1) (d) GDPR serves as the legal
                           basis.
                         </span>,
                   "es": <span>
                           En el caso de que intereses vitales del
                           interesado o de otra persona física hagan
                           necesario el tratamiento de datos
                           personales, el art. La base jurídica es el
                           artículo 6 (1) (d) del RGPD.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Если обработка необходима для защиты
                           законных интересов нашей компании или
                           третьей стороны и если интересы, основные
                           права и свободы субъекта данных не
                           преобладают над первым, ст. 6
                           (1) лит. f GDPR служит правовой основой для
                           обработки.
                         </span>,
                   "en": <span>
                           If the processing is necessary to protect a
                           legitimate interest of our company or a
                           third party and if the interests,
                           fundamental rights and freedoms of the data
                           subject do not override the former
                           interest, Art. 6 (1) lit. f GDPR serves as
                           the legal basis for the processing.
                         </span>,
                   "es": <span>
                           Si el procesamiento es necesario para
                           proteger un interés legítimo de nuestra
                           empresa o de un tercero y si los intereses,
                           derechos y libertades fundamentales del
                           interesado no prevalecen sobre el interés
                           anterior, el art. 6 (1) encendido. f GDPR
                           sirve como base legal para el
                           procesamiento.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#5" >
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "1.3 Удаление и срок хранения данных",
                     "en": "1.3 Data deletion and storage period",
                     "es": "1.3 Eliminación de datos y plazo de conservación"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Персональные данные субъекта данных будут
                           удалены или заблокированы, как только цель
                           хранения перестанет применяться. Кроме
                           того, хранение может иметь место, если это
                           предусмотрено европейским или национальным
                           законодательством в правилах, законах или
                           других положениях Союза, которым
                           подчиняется оператор. Данные также будут
                           заблокированы или удалены в случае
                           истечения срока хранения, предусмотренного
                           вышеупомянутыми стандартами, за исключением
                           случаев, когда существует необходимость в
                           дальнейшем хранении данных для заключения
                           или исполнения договора.
                         </span>,
                   "en": <span>
                           The personal data of the data subject will
                           be deleted or blocked as soon as the
                           purpose of storage ceases to apply. In
                           addition, storage may take place if this
                           has been provided for by the European or
                           national legislator in Union regulations,
                           laws or other provisions to which the
                           controller is subject. Data will also be
                           blocked or deleted if a storage period
                           prescribed by the aforementioned standards
                           expires, unless there is a need for further
                           storage of the data for the conclusion or
                           performance of a contract.
                         </span>,
                   "es": <span>
                           Los datos personales del interesado se
                           eliminarán o bloquearán tan pronto como
                           deje de aplicarse la finalidad del
                           almacenamiento. Además, el almacenamiento
                           podrá tener lugar si así lo ha previsto el
                           legislador europeo o nacional en
                           reglamentos, leyes u otras disposiciones de
                           la Unión a las que esté sujeto el
                           responsable del tratamiento. Los datos
                           también se bloquearán o eliminarán cuando
                           expire el período de almacenamiento
                           prescrito por las normas antes mencionadas,
                           a menos que sea necesario un almacenamiento
                           adicional de los datos para la celebración
                           o ejecución de un contrato.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#6" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "2. Использование файлов cookie",
                     "en": "2. Use of cookies",
                     "es": "2. Uso de cookies"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Мы используем файлы cookie на нашем
                           сайте. Файлы cookie — это текстовые файлы,
                           которые сохраняются в интернет-браузере или
                           в компьютерной системе пользователя. Когда
                           пользователь посещает веб-сайт, в
                           операционной системе пользователя может
                           сохраняться файл cookie. Этот файл cookie
                           содержит характерную строку символов,
                           которая позволяет однозначно
                           идентифицировать браузер при следующем
                           посещении веб-сайта. Некоторые функции
                           нашего сайта не могут быть доступны без
                           использования файлов cookie. Для этого
                           необходимо, чтобы браузер распознавался
                           даже после смены страницы. Пользовательские
                           данные, собранные с помощью технически
                           необходимых файлов cookie, не используются
                           для определения личности пользователя или
                           создания профилей пользователей. Правовой
                           основой обработки персональных данных с
                           использованием технически необходимых
                           файлов cookie является ст. 6 пункт. 1
                           лит. (е) GDPR. По технической необходимости
                           возможность отзыва отсутствует.
                         </span>,
                   "en": <span>
                           We use cookies on our website. Cookies are
                           text files that are stored in or by the
                           Internet browser on the user’s computer
                           system. When a user visits a website, a
                           cookie may be stored on the user’s
                           operating system. This cookie contains a
                           characteristic string of characters that
                           allows the browser to be uniquely
                           identified the next time the website is
                           accessed. Some functions of our website
                           cannot be offered without the use of
                           cookies. For these, it is necessary that
                           the browser is recognized even after a page
                           change. The user data collected with
                           technically necessary cookies are not used
                           to determine the identity of the user or to
                           create user profiles. The legal basis for
                           the processing of personal data using
                           technically necessary cookies is Art. 6
                           para. 1 lit. (f) GDPR. Due to the technical
                           necessity, there is no revocation option.
                         </span>,
                   "es": <span>
                           Utilizamos cookies en nuestro sitio
                           web. Las cookies son archivos de texto que
                           se almacenan en el navegador de Internet o
                           a través de él en el sistema informático
                           del usuario. Cuando un usuario visita un
                           sitio web, se puede almacenar una cookie en
                           el sistema operativo del usuario. Esta
                           cookie contiene una cadena característica
                           de caracteres que permite identificar de
                           forma única al navegador la próxima vez que
                           acceda al sitio web. Algunas funciones de
                           nuestro sitio web no se pueden ofrecer sin
                           el uso de cookies. Para ello es necesario
                           que el navegador sea reconocido incluso
                           después de cambiar de página. Los datos de
                           usuario recogidos con cookies técnicamente
                           necesarias no se utilizan para determinar
                           la identidad del usuario ni para crear
                           perfiles de usuario. La base legal para el
                           tratamiento de datos personales utilizando
                           cookies técnicamente necesarias es el
                           art. 6 párr. 1 encendido. (f) RGPD. Debido
                           a necesidades técnicas, no existe la opción
                           de revocación.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Поскольку мы используем файлы cookie,
                           которые не являются технически
                           необходимыми, соответствующую информацию вы
                           найдете в нашей политике использования
                           файлов cookie.
                         </span>,
                   "en": <span>
                           Insofar as we use cookies that are not
                           technically necessary, you will find the
                           relevant information in our cookie policy.
                         </span>,
                   "es": <span>
                           En la medida en que utilicemos cookies que
                           no sean técnicamente necesarias, encontrará
                           la información relevante en nuestra
                           política de cookies.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#7" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "3. Общие способы связи",
                     "en": "3. General contact",
                     "es": "3. Contacto general"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы можете связаться с нами через чат
                           поддержки клиентов на нашем сайте или по
                           электронной почте. В этом случае
                           информация, которую вы предоставляете в
                           своем запросе, включая предоставленные вами
                           там контактные данные, будет храниться нами
                           исключительно в целях обработки запроса и
                           на случай возникновения дополнительных
                           вопросов. В этом контексте данные не будут
                           переданы третьим лицам.
                         </span>,
                   "en": <span>
                           You can contact us via customer support
                           chat on our website or by e-mail. In this
                           case, the information you provide in your
                           inquiry, including the contact data you
                           provide there, will be stored by us solely
                           for the purpose of processing the inquiry
                           and in the event of follow-up questions. In
                           this context, the data will not be passed
                           on to third parties.
                         </span>,
                   "es": <span>
                           Puede contactarnos a través del chat de
                           atención al cliente en nuestro sitio web o
                           por correo electrónico. En este caso, la
                           información que proporcione en su consulta,
                           incluidos los datos de contacto que
                           proporcione allí, será almacenada por
                           nosotros únicamente con el fin de procesar
                           la consulta y en caso de preguntas de
                           seguimiento. En este contexto, los datos no
                           se transmitirán a terceros.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Правовой основой обработки данных является
                           ст. 6 пункт. 1 лит. е GDPR. Наши интересы в
                           ответе на ваш запрос перевешивают ваши
                           интересы; кроме того, поскольку вы пишете
                           нам, ответ также в ваших интересах, и вы
                           осознаете, что нам необходимо обработать
                           ваши данные, чтобы ответить на ваш запрос.
                         </span>,
                   "en": <span>
                           The legal basis for the processing of the
                           data is Art. 6 para. 1 lit. f GDPR. Our
                           interest in answering your inquiry
                           outweighs your interest; furthermore, since
                           you are writing to us, an answer is also in
                           your interest and you are aware that we
                           need to process your data to answer your
                           inquiry.
                         </span>,
                   "es": <span>
                           La base legal para el tratamiento de los
                           datos es el art. 6 párr. 1 encendido. f
                           RGPD. Nuestro interés en responder a su
                           consulta supera su interés; además, al
                           escribirnos, también es de su interés
                           recibir una respuesta y es consciente de
                           que necesitamos tratar sus datos para
                           responder a su consulta.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Если контакт по электронной почте направлен
                           на заключение договора, правовым основанием
                           для обработки является ст. 6 (1) лит. б
                           GDPR.
                         </span>,
                   "en": <span>
                           If the e-mail contact aims at the
                           conclusion of a contract, the legal basis
                           for the processing is Art. 6 (1) lit. b
                           GDPR.
                         </span>,
                   "es": <span>
                           Si el contacto por correo electrónico tiene
                           como objetivo la celebración de un
                           contrato, la base jurídica para el
                           tratamiento es el art. 6 (1) encendido. b
                           RGPD.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Данные будут удалены, как только они больше
                           не будут необходимы для достижения цели,
                           для которой они были собраны. Это тот
                           случай, когда соответствующее взаимодействие с
                           пользователем завершается. Взаимодействие
                           прекращается, когда из обстоятельств можно
                           сделать вывод, что соответствующие факты
                           окончательно выяснены.
                         </span>,
                   "en": <span>
                           The data will be deleted as soon as they
                           are no longer necessary to achieve the
                           purpose for which they were collected. This
                           is the case when the respective
                           conversation with the user has ended. The
                           conversation is terminated when it can be
                           inferred from the circumstances that the
                           facts concerned have been conclusively
                           clarified.
                         </span>,
                   "es": <span>
                           Los datos serán suprimidos tan pronto como
                           ya no sean necesarios para alcanzar la
                           finalidad para la que fueron
                           recogidos. Este es el caso cuando la
                           respectiva conversación con el usuario ha
                           finalizado. La conversación se dará por
                           terminada cuando de las circunstancias
                           pueda deducirse que los hechos de que se
                           trata han sido aclarados de manera
                           concluyente.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#8" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "4. Сервис веб-анализа и контроля",
                     "en": "4. Web analysis and control services",
                     "es": "4. Servicios de análisis y control web"})}
            </Typography>
          </HelpHashLink>

          <HelpHashLink to="/privacyPolicy#9" >
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "4.1 Google Tag Manager",
                     "en": "4.1 Google Tag Manager",
                     "es": "4.1 Google Tag Manager"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Если вы выразили свое согласие, на этом
                           веб-сайте используется Google Tag Manager
                           компании Google LLC. Нашим договорным
                           партнером является европейская дочерняя
                           компания Google Ireland Limited, Google
                           Building Gordon House, 4 Barrow St, Dublin,
                           D04 E5W5, Ирландия («Google»).
                         </span>,
                   "en": <span>
                           Insofar as you have declared your consent,
                           the Google Tag Manager of Google LLC is
                           used on this website. Our contractual
                           partner is the European subsidiary Google
                           Ireland Limited, Google Building Gordon
                           House, 4 Barrow St, Dublin, D04 E5W5,
                           Ireland (“Google”).
                         </span>,
                   "es": <span>
                           Si usted ha declarado su consentimiento, en
                           este sitio web se utiliza el Google Tag
                           Manager de Google LLC. Nuestro socio
                           contractual es la filial europea Google
                           Ireland Limited, Google Building Gordon
                           House, 4 Barrow St, Dublín, D04 E5W5,
                           Irlanda (“Google”).
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Google Tag Manager в основном используется
                           для работы с другими инструментами. Вместо
                           того, чтобы загружать инструмент напрямую,
                           он загружается Google Tag Manager. Google
                           Tag Manager использует файлы cookie
                           администратора и передает файлы cookie,
                           связанные с Tag Manager, в
                           Google. Информация, собранная с помощью
                           файлов cookie об использовании вами этого
                           веб-сайта, обычно передается на сервер
                           Google в США и хранится там.
                         </span>,
                   "en": <span>
                           The Google Tag Manager is basically used to
                           play out other tools. Instead of loading a
                           tool directly, it is loaded by the Google
                           Tag Manager. The Google Tag Manager uses
                           administrator cookies and transfers cookies
                           associated with the Tag Manager to
                           Google. The information collected by means
                           of the cookies about your use of this
                           website is usually transferred to a Google
                           server in the USA and stored there.
                         </span>,
                   "es": <span>
                           El Google Tag Manager se utiliza
                           básicamente para ejecutar otras
                           herramientas. En lugar de cargar una
                           herramienta directamente, la carga Google
                           Tag Manager. Google Tag Manager utiliza
                           cookies de administrador y transfiere
                           cookies asociadas con Tag Manager a
                           Google. La información recopilada mediante
                           las cookies sobre su uso de este sitio web
                           normalmente se transfiere a un servidor de
                           Google en EE. UU. y se almacena allí.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Благодаря серверным соединениям между вашим
                           интернет-соединением и серверами Google
                           также обрабатывается ваш IP-адрес, а также
                           такие сетевые данные, как следующие:
                         </span>,
                   "en": <span>
                           Due to the server connections between your
                           internet connection and Google’s servers,
                           your IP address is also processed, as well
                           as network data such as the following:
                         </span>,
                   "es": <span>
                           Debido a las conexiones de servidor entre
                           su conexión a Internet y los servidores de
                           Google, también se procesa su dirección IP,
                           así como datos de red como los siguientes:
                         </span>})}
          </p>
          {text(language,
                {"ru": <span>
                         <ul>
                           <li>примерное местоположение (регион)</li>
                           <li>техническая информация о браузере и
                             используемых конечных устройствах
                             (например, настройки языка, разрешение
                             экрана)</li>
                           <li>интернет провайдер</li>
                           <li>URL-адрес реферера (через какой
                             сайт/рекламный носитель пользователи
                             пришли на этот сайт)</li>
                         </ul>
                         </span>,
                 "en": <span>
                         <ul>
                           <li>approximate location (region)</li>
                           <li>technical information about the browser
                             and the end devices used (e.g. language
                             setting, screen resolution)</li>
                           <li>Internet provider</li>
                           <li>the referrer URL (via which
                             website/advertising medium users came to
                             this website)</li>
                         </ul>
                       </span>,
                 "es": <span>
                         <ul>
                           <li>ubicación aproximada (región)</li>
                           <li>información técnica sobre el navegador
                             y los dispositivos finales utilizados
                             (p. ej. configuración de idioma,
                             resolución de pantalla)</li>
                           <li>proveedor de Internet</li>
                           <li>la URL de referencia (a través de qué
                             sitio web/medio publicitario los usuarios
                             llegaron a este sitio web)</li>
                         </ul>
                   </span>})}
          <p>
            {text(language,
                  {"ru": <span>
                           Правовой основой для обработки данных
                           Google Tag Manager является ваше
                           согласие в соответствии со статьей 6, пункт
                           1, пункт 1, пункт a GDPR.
                         </span>,
                   "en": <span>
                           The legal basis for data processing by
                           Google Tag Manager is your consent pursuant
                           to Art.6 para.1 p.1 lit.a GDPR.
                         </span>,
                   "es": <span>
                           La base legal para el procesamiento de
                           datos por parte de Google Tag Manager es su
                           consentimiento de conformidad con el art.6
                           párrafo 1 p.1 letra a del RGPD.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы также можете изначально запретить
                           сохранение файлов cookie, настроив
                           соответствующим образом программное
                           обеспечение браузера. Однако если вы
                           настроите свой браузер на отклонение всех
                           файлов cookie, это может ограничить
                           функциональность этого и других
                           веб-сайтов. Вы также можете запретить сбор
                           данных, сгенерированных файлом cookie и
                           связанных с использованием вами веб-сайта
                           (включая ваш IP-адрес), в Google и
                           обработку этих данных Google, не давая
                           своего согласия на настройку файлов cookie.
                         </span>,
                   "en": <span>
                           You can also prevent the storage of cookies
                           from the outset by configuring your browser
                           software accordingly. However, if you
                           configure your browser to reject all
                           cookies, this may restrict functionality on
                           this and other websites. You can also
                           prevent the collection of data generated by
                           the cookie and related to your use of the
                           website (including your IP address) to
                           Google and the processing of this data by
                           Google by not giving your consent to the
                           cookie setting.
                         </span>,
                   "es": <span>
                           También puede impedir el almacenamiento de
                           cookies desde el principio configurando el
                           software de su navegador en
                           consecuencia. Sin embargo, si configura su
                           navegador para rechazar todas las cookies,
                           esto puede restringir la funcionalidad de
                           este y otros sitios web. También puede
                           impedir la recopilación de datos generados
                           por la cookie y relacionados con su uso del
                           sitio web (incluida su dirección IP) a
                           Google y el procesamiento de estos datos
                           por parte de Google al no dar su
                           consentimiento para la configuración de
                           cookies.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Для получения дополнительной информации об
                           условиях использования Google Tag Manager и
                           защите данных в Google посетите
                           https://marketingplatform.google.com/about/tag-manager/
                           и https://policies.google.com.
                         </span>,
                   "en": <span>
                           For more information on the terms of use of
                           Google Tag Manager and data protection at
                           Google, please visit
                           https://marketingplatform.google.com/about/tag-manager/
                           and https://policies.google.com.
                         </span>,
                   "es": <span>
                           Para obtener más información sobre las
                           condiciones de uso de Google Tag Manager y
                           la protección de datos en Google, visite
                           https://marketingplatform.google.com/about/tag-manager/
                           y https://policies.google.com.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#10" >
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "4.2 Google Analytics",
                     "en": "4.2 Google Analytics",
                     "es": "4.2 Google Analytics"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Если вы дали свое согласие, этот веб-сайт
                           использует Google Analytics 4, службу
                           веб-аналитики, предоставляемую Google
                           LLC. Нашим договорным партнером является
                           европейская дочерняя компания Google
                           Ireland Limited, Google Building Gordon
                           House, 4 Barrow St, Dublin, D04 E5W5,
                           Ирландия («Google»).
                         </span>,
                   "en": <span>
                           If you have given your consent, this
                           website uses Google Analytics 4, a web
                           analytics service provided by Google
                           LLC. Our contractual partner is the
                           European subsidiary Google Ireland Limited,
                           Google Building Gordon House, 4 Barrow St,
                           Dublin, D04 E5W5, Ireland (“Google”).
                         </span>,
                   "es": <span>
                           Si ha dado su consentimiento, este sitio
                           web utiliza Google Analytics 4, un servicio
                           de análisis web proporcionado por Google
                           LLC. Nuestro socio contractual es la filial
                           europea Google Ireland Limited, Google
                           Building Gordon House, 4 Barrow St, Dublín,
                           D04 E5W5, Irlanda (“Google”).
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Google Analytics использует файлы cookie,
                           которые позволяют анализировать
                           использование вами наших
                           веб-сайтов. Информация, собранная с помощью
                           файлов cookie об использовании вами этого
                           веб-сайта, обычно передается на сервер
                           Google в США и хранится там.
                         </span>,
                   "en": <span>
                           Google Analytics uses cookies that enable
                           an analysis of your use of our
                           websites. The information collected by
                           means of the cookies about your use of this
                           website is generally transferred to a
                           Google server in the USA and stored there.
                         </span>,
                   "es": <span>
                           Google Analytics utiliza cookies que
                           permiten un análisis de su uso de nuestros
                           sitios web. La información recopilada
                           mediante las cookies sobre su uso de este
                           sitio web se transfiere generalmente a un
                           servidor de Google en EE. UU. y se almacena
                           allí.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           В Google Analytics 4 анонимизация
                           IP-адресов активирована по
                           умолчанию. Благодаря анонимизации IP ваш
                           IP-адрес сокращается Google в
                           государствах-членах Европейского Союза или
                           в других договаривающихся государствах
                           Соглашения о Европейской экономической
                           зоне. Лишь в исключительных случаях полный
                           IP-адрес будет перенесен на сервер Google в
                           США и сокращен там. По данным Google,
                           IP-адрес, передаваемый вашим браузером как
                           часть Google Analytics, не будет объединен
                           с другими данными Google.
                         </span>,
                   "en": <span>
                           In Google Analytics 4, the anonymization of
                           IP addresses is activated by default. Due
                           to IP anonymization, your IP address is
                           shortened by Google within member states of
                           the European Union or in other contracting
                           states of the Agreement on the European
                           Economic Area. Only in exceptional cases
                           will the full IP address be transferred to
                           a Google server in the USA and shortened
                           there. According to Google, the IP address
                           transmitted by your browser as part of
                           Google Analytics will not be merged with
                           other Google data.
                         </span>,
                   "es": <span>
                           En Google Analytics 4, la anonimización de
                           las direcciones IP está activada por
                           defecto. Debido a la anonimización de la
                           IP, Google acorta su dirección IP dentro de
                           los estados miembros de la Unión Europea o
                           en otros estados parte del Acuerdo sobre el
                           Espacio Económico Europeo. Sólo en casos
                           excepcionales se transmitirá la dirección
                           IP completa a un servidor de Google en
                           EE. UU. y se acortará allí. Según Google,
                           la dirección IP transmitida por su
                           navegador en el marco de Google Analytics
                           no se combinará con otros datos de Google.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Во время вашего посещения веб-сайта ваше
                           поведение пользователя записывается в форме
                           «событий». События могут быть:
                         </span>,
                   "en": <span>
                           During your website visit, your user
                           behavior is recorded in the form of
                           “events”. Events can be:
                         </span>,
                   "es": <span>
                           Durante su visita al sitio web, su
                           comportamiento de usuario se registra en
                           forma de "eventos". Los eventos pueden ser:
                         </span>})}
          </p>
          {text(language,
                {"ru": <span>
                         <ul>
                           <li>Просмотры страниц</li>
                           <li>Первое посещение сайта</li>
                           <li>Начало пользовательской сессии</li>
                           <li>Ваш «путь клика», взаимодействие с сайтом</li>
                           <li>Прокрутка (каждый раз, когда
                             пользователь прокручивает страницу вниз
                             (90%))</li>
                           <li>переходы по внешним ссылкам</li>
                           <li>внутренние поисковые запросы</li>
                           <li>взаимодействие с видео</li>
                           <li>загрузка файлов</li>
                           <li>просмотренные/нажатые объявления</li>
                           <li>настройка языка</li>
                         </ul>
                       </span>,
                 "en": <span>
                         <ul>
                           <li>Page views</li>
                           <li>First visit to the website</li>
                           <li>Start of session</li>
                           <li>Your “click path”, interaction with the website</li>
                           <li>Scrolls (whenever a user scrolls to the
                             bottom of the page (90%))</li>
                           <li>clicks on external links</li>
                           <li>internal search queries</li>
                           <li>interaction with videos</li>
                           <li>file downloads</li>
                           <li>seen / clicked ads</li>
                           <li>language setting</li>
                         </ul>
                       </span>,
                 "es": <span>
                         <ul>
                           <li>Vistas de página</li>
                           <li>Primera visita al sitio web</li>
                           <li>Inicio de sesión</li>
                           <li>Su “ruta de clic”, interacción con el sitio web</li>
                           <li>Se desplaza (cada vez que un usuario se
                             desplaza hasta el final de la página
                             (90%))</li>
                           <li>clics en enlaces externos</li>
                           <li>consultas de búsqueda interna</li>
                           <li>interacción con vídeos</li>
                           <li>descargas de archivos</li>
                           <li>anuncios vistos/en los que se hizo clic</li>
                           <li>configuración de idioma</li>
                         </ul>
                       </span>})}
          {text(language,
                {"ru": <span>
                         Также записывается:
                       </span>,
                 "en": <span>
                         Also recorded:
                       </span>,
                 "es": <span>
                         También grabado:
                       </span>})}
          {text(language,
                {"ru": <span>
                         <ul>
                           <li>Ваше приблизительное местоположение (регион)</li>
                           <li>ваш IP-адрес (в сокращенной форме)</li>
                           <li>техническая информация о вашем браузере
                             и используемых вами конечных устройствах
                             (например, настройки языка, разрешение
                             экрана)</li>
                           <li>ваш интернет-провайдер</li>
                           <li>URL-адрес реферера (через какой
                             веб-сайт/рекламный носитель вы пришли на
                             этот веб-сайт)</li>
                         </ul>
                       </span>,
                 "en": <span>
                         <ul>
                           <li>Your approximate location (region)</li>
                           <li>your IP address (in shortened form)</li>
                           <li>technical information about your
                             browser and the end devices you use
                             (e.g. language setting, screen
                             resolution)</li>
                           <li>your internet service provider</li>
                           <li>the referrer URL (via which
                             website/advertising medium you came to
                             this website)</li>
                         </ul>
                       </span>,
                 "es": <span>
                         <ul>
                           <li>Su ubicación aproximada (región)</li>
                           <li>su dirección IP (en forma abreviada)</li>
                           <li>información técnica sobre su navegador
                             y los dispositivos finales que utiliza
                             (por ejemplo, configuración de idioma,
                             resolución de pantalla)</li>
                           <li>su proveedor de servicios de internet</li>
                           <li>la URL de referencia (a través de qué
                             sitio web/medio publicitario llegó a este
                             sitio web)</li>
                         </ul>
                       </span>})}
          <p>
            {text(language,
                  {"ru": <span>
                           Google будет использовать эту информацию от
                           нашего имени с целью оценки вашего
                           анонимного использования веб-сайта и
                           составления отчетов о деятельности
                           веб-сайта (анализ с использованием
                           идентификатора пользователя не является
                           псевдонимным). Отчеты, предоставляемые
                           Google Analytics, используются для анализа
                           эффективности нашего веб-сайта.
                         </span>,
                   "en": <span>
                           Google will use this information on our
                           behalf for the purpose of evaluating your
                           anonymous use of the website and compiling
                           reports on website activity (analysis using
                           user ID is not pseudonymous). The reports
                           provided by Google Analytics are used to
                           analyze the performance of our website.
                         </span>,
                   "es": <span>
                           Google utilizará esta información en
                           nuestro nombre con el fin de evaluar su uso
                           anónimo del sitio web y compilar informes
                           sobre la actividad del sitio web (el
                           análisis utilizando ID de usuario no es
                           seudónimo). Los informes proporcionados por
                           Google Analytics se utilizan para analizar
                           el rendimiento de nuestro sitio web.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Получателями данных помимо нас являются/могут быть:
                         </span>,
                   "en": <span>
                           Recipients of the data besides us are/may be:
                         </span>,
                   "es": <span>
                           Los destinatarios de los datos además de
                           nosotros son/pueden ser:
                         </span>})}
          </p>
          <ul>
            <li>
              Google Ireland Limited, Gordon House, Barrow Street,
              Dublin 4, Ireland (as processor according to Art. 28
              GDPR).
            </li>
            <li>
              Google LLC, 1600 Amphitheatre Parkway Mountain View, CA
              94043, USA, Alphabet Inc., 1600 Amphitheatre Parkway
              Mountain View, CA 94043, USA
            </li>
          </ul>
          <p>
            {text(language,
                  {"ru": <span>
                           Поскольку данные обрабатываются за
                           пределами ЕС/ЕЭЗ и не существует уровня
                           защиты данных, соответствующего
                           европейскому стандарту, компания Google
                           Ireland Limited заключила стандартные
                           договорные положения ЕС с компанией Google
                           LLC, базирующейся в Калифорнии, США, с
                           целью установления соответствующие гарантии
                           в соответствии со ст. 46 GDPR. Копию
                           договорных положений можно найти здесь:
                           https://policies.google.com/privacy/frameworks. Нельзя
                           исключать передачу данных в США и доступ
                           властей США к данным, хранящимся в
                           Google. США в настоящее время считаются
                           третьей страной с точки зрения защиты
                           данных. Там у вас нет тех же прав, что и в
                           ЕС/ЕЭЗ. Если применимо, вы не имеете права
                           на какие-либо средства правовой защиты от
                           доступа органов власти.
                         </span>,
                   "en": <span>
                           Insofar as data is processed outside the
                           EU/EEA and there is no level of data
                           protection that corresponds to the European
                           standard, Google Ireland Limited has
                           concluded EU standard contractual clauses
                           with Google LLC, which is based in
                           California, USA, in order to establish
                           appropriate safeguards in accordance with
                           Art. 46 of the GDPR. A copy of the
                           contractual clauses can be found here:
                           https://policies.google.com/privacy/frameworks. A
                           transfer of data to the USA and access by
                           US authorities to the data stored by Google
                           cannot be ruled out. The USA is currently
                           considered a third country from a data
                           protection perspective. You do not have the
                           same rights there as within the EU /
                           EEA. If applicable, you are not entitled to
                           any legal remedies against access by
                           authorities.
                         </span>,
                   "es": <span>
                           En la medida en que los datos se procesen
                           fuera de la UE/EEE y no exista un nivel de
                           protección de datos que corresponda al
                           estándar europeo, Google Ireland Limited ha
                           celebrado cláusulas contractuales estándar
                           de la UE con Google LLC, con sede en
                           California, EE. UU., para establecer
                           salvaguardias apropiadas de conformidad con
                           el art. 46 del RGPD. Puede encontrar una
                           copia de las cláusulas contractuales aquí:
                           https://policies.google.com/privacy/frameworks. No
                           se puede descartar una transferencia de
                           datos a EE.UU. ni el acceso de las
                           autoridades estadounidenses a los datos
                           almacenados por Google. Actualmente,
                           Estados Unidos se considera un tercer país
                           desde el punto de vista de la protección de
                           datos. Allí no tiene los mismos derechos
                           que dentro de la UE/EEE. Si corresponde, no
                           tiene derecho a ningún recurso legal contra
                           el acceso por parte de las autoridades.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Данные, отправленные нами и связанные с
                           файлами cookie, будут автоматически удалены
                           через 14 месяцев. Удаление данных, срок
                           хранения которых истек, происходит
                           автоматически один раз в месяц.
                         </span>,
                   "en": <span>
                           The data sent by us and linked to cookies
                           will be automatically deleted after 14
                           months. The deletion of data whose
                           retention period has been reached takes
                           place automatically once a month.
                         </span>,
                   "es": <span>
                           Los datos enviados por nosotros y
                           vinculados a las cookies se eliminarán
                           automáticamente después de 14 meses. La
                           eliminación de los datos cuyo período de
                           conservación se haya alcanzado se realiza
                           automáticamente una vez al mes.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Правовой основой для обработки данных
                           является ваше согласие в соответствии со
                           статьей 6, пункт 1, пункт 1, пункт a
                           GDPR. Вы можете отозвать свое согласие в
                           любое время с эффектом на будущее, вызвав
                           настройки файлов cookie [внизу слева на
                           экране] и изменив там свой выбор. Там вы
                           также найдете информацию об обрабатываемых
                           файлах cookie. Законность обработки,
                           осуществляемой на основании согласия, до
                           момента его отзыва остается неизменной.
                         </span>,
                   "en": <span>
                           The legal basis for this data processing is
                           your consent pursuant to Art.6 para.1 p.1
                           lit.a GDPR. You can revoke your consent at
                           any time with effect for the future by
                           calling up the cookie settings [Bottom left
                           of the screen] and changing your selection
                           there. There you will also find information
                           about the cookies processed. The lawfulness
                           of the processing carried out on the basis
                           of the consent until the revocation remains
                           unaffected.
                         </span>,
                   "es": <span>
                           La base legal para este procesamiento de
                           datos es su consentimiento de conformidad
                           con el Art.6 párrafo 1 p.1 letra a del
                           RGPD. Puede revocar su consentimiento en
                           cualquier momento con efecto para el futuro
                           accediendo a la configuración de cookies
                           [abajo a la izquierda de la pantalla] y
                           cambiando su selección allí. Allí también
                           encontrará información sobre las cookies
                           procesadas. La licitud del tratamiento
                           realizado sobre la base del consentimiento
                           no se verá afectada hasta la revocación.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы также можете изначально запретить
                           сохранение файлов cookie, настроив
                           программное обеспечение своего браузера
                           соответствующим образом. Однако если вы
                           настроите свой браузер на отклонение всех
                           файлов cookie, это может привести к
                           ограничению функций этого и других
                           веб-сайтов. Вы также можете запретить сбор
                           данных, сгенерированных файлами cookie и
                           связанных с использованием вами веб-сайта
                           (включая ваш IP-адрес), в Google и
                           обработку этих данных Google, выбрав.
                         </span>,
                   "en": <span>
                           You can also prevent the storage of cookies
                           from the outset by setting your browser
                           software accordingly. However, if you
                           configure your browser to reject all
                           cookies, this may result in a restriction
                           of functionalities on this and other
                           websites. You can also prevent the
                           collection of data generated by the cookie
                           and related to your use of the website
                           (including your IP address) to Google and
                           the processing of this data by Google, by
                           selecting.
                         </span>,
                   "es": <span>
                           También puede impedir el almacenamiento de
                           cookies desde el principio configurando el
                           software de su navegador en
                           consecuencia. Sin embargo, si configura su
                           navegador para rechazar todas las cookies,
                           esto puede resultar en una restricción de
                           funcionalidades en este y otros sitios
                           web. También puede impedir la recopilación
                           de datos generados por la cookie y
                           relacionados con su uso del sitio web
                           (incluida su dirección IP) a Google y el
                           procesamiento de estos datos por parte de
                           Google, seleccionando.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           <ol>
                             <li>не давать свое согласие на установку
                               файлов cookie или</li>
                             <li>загрузите и установите надстройку
                               браузера для отключения Google
                               Analytics&ensp;
                               <Link
                                 href="https://tools.google.com/dlpage/gaoptout">
                                 ЗДЕСЬ
                               </Link>.</li>
                           </ol>
                         </span>,
                   "en": <span>
                           <ol>
                             <li>not give your consent to the setting
                               of the cookie or</li>
                             <li>download and install the browser
                               add-on to disable Google Analytics&ensp;
                               <Link
                                 href="https://tools.google.com/dlpage/gaoptout">
                                 HERE
                               </Link>.</li>
                           </ol>
                         </span>,
                   "es": <span>
                           <ol>
                             <li>no dar su consentimiento para la
                               configuración de la cookie o</li>
                             <li>Descargue e instale el complemento
                               del navegador para desactivar Google
                               Analytics&ensp;
                               <Link
                                 href="https://tools.google.com/dlpage/gaoptout">
                                 AQUÍ
                               </Link>.</li>
                           </ol>
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Для получения дополнительной информации об
                           условиях использования Google Analytics и
                           политике конфиденциальности Google посетите
                           https://marketingplatform.google.com/about/analytics/terms/us/
                           и https://policies.google.com.
                         </span>,
                   "en": <span>
                           For more information on Google Analytics’
                           terms of use and Google’s privacy policy,
                           please visit
                           https://marketingplatform.google.com/about/analytics/terms/us/
                           and https://policies.google.com.
                         </span>,
                   "es": <span>
                           Para obtener más información sobre los
                           términos de uso de Google Analytics y la
                           política de privacidad de Google, visite
                           https://marketingplatform.google.com/about/analytics/terms/us/
                           y https://policies.google.com.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#11" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "5. Маркетинговые услуги",
                     "en": "5. Marketing Services",
                     "es": "5. Servicios de marketing"})}
            </Typography>
          </HelpHashLink>
          <HelpHashLink to="/privacyPolicy#12" >
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "5.1 Facebook Custom Audiences (Pixel/Cookies)",
                     "en": "5.1 Facebook Custom Audiences (Pixel/Cookies)",
                     "es": "5.1 Facebook Custom Audiences (Pixel/Cookies)"})}
            </Typography>
          </HelpHashLink>

          <p>
            {text(language,
                  {"ru": <span>
                           Мы используем так называемый пиксель
                           отслеживания компании Meta Platforms
                           Ireland Ltd, 4 Grand Canal Square, Grand
                           Canal Harbour, Dublin 2, Ireland, дочерней
                           компании Meta Platforms Inc, 1601, Willow
                           Road Menlo Park, CA 94025, США («Facebook
                           ») на нашем сайте. Мы используем Facebook
                           Pixel, чтобы отслеживать успех наших
                           собственных рекламных кампаний Facebook и
                           оптимизировать показ рекламных кампаний
                           Facebook для заинтересованных целевых
                           групп.
                         </span>,
                   "en": <span>
                           We use a so-called tracking pixel of Meta
                           Platforms Ireland Ltd, 4 Grand Canal
                           Square, Grand Canal Harbour, Dublin 2,
                           Ireland, a subsidiary of Meta Platforms
                           Inc, 1601, Willow Road Menlo Park, CA
                           94025, USA, (“Facebook”) on our website. We
                           use Facebook Pixel to track the success of
                           our own Facebook advertising campaigns and
                           to optimize the playout of Facebook
                           advertising campaigns to interested target
                           groups.
                         </span>,
                   "es": <span>
                           Utilizamos el llamado píxel de seguimiento
                           de Meta Platforms Ireland Ltd, 4 Grand
                           Canal Square, Grand Canal Harbour, Dublin
                           2, Irlanda, una filial de Meta Platforms
                           Inc, 1601, Willow Road Menlo Park, CA
                           94025, EE. UU. (“Facebook ") en nuestra
                           página web. Utilizamos Facebook Pixel para
                           realizar un seguimiento del éxito de
                           nuestras propias campañas publicitarias de
                           Facebook y para optimizar la reproducción
                           de las campañas publicitarias de Facebook
                           para los grupos objetivo interesados.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           После нажатия на рекламу в Facebook или
                           посещения нашего веб-сайта на вашем
                           конечном устройстве сохраняется файл cookie
                           с использованием пикселя на нашем
                           веб-сайте. Файл cookie обрабатывает данные
                           о том, пришли ли вы на наш сайт через
                           рекламу в Facebook, и позволяет нам
                           анализировать поведение пользователя до
                           момента совершения покупки. Это позволяет
                           нам отслеживать успех наших рекламных
                           кампаний в Facebook. Кроме того, пиксель
                           обрабатывает данные о том, что вы посетили
                           наш сайт, и позволяет нам настроить
                           рекламу, воспроизводимую на Facebook, под
                           ваши интересы.
                         </span>,
                   "en": <span>
                           After clicking on a Facebook ad or visiting
                           our website, a cookie is stored on your
                           terminal device using the pixel on our
                           website. The cookie processes data about
                           whether you arrived at our website via a
                           Facebook ad and allows us to analyze the
                           user’s behavior until the purchase is
                           completed. This allows us to track the
                           success rate of our Facebook advertising
                           campaigns. In addition, the pixel processes
                           data about the fact that you have visited
                           our website and allows us to customize the
                           ads played on Facebook to your interests.
                         </span>,
                   "es": <span>
                           Después de hacer clic en un anuncio de
                           Facebook o visitar nuestro sitio web, se
                           almacena una cookie en su dispositivo
                           terminal utilizando el píxel de nuestro
                           sitio web. La cookie procesa datos sobre si
                           llegó a nuestro sitio web a través de un
                           anuncio de Facebook y nos permite analizar
                           el comportamiento del usuario hasta que se
                           completa la compra. Esto nos permite
                           realizar un seguimiento de la tasa de éxito
                           de nuestras campañas publicitarias en
                           Facebook. Además, el píxel procesa datos
                           sobre su visita a nuestro sitio web y nos
                           permite personalizar los anuncios mostrados
                           en Facebook según sus intereses.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Через Facebook pixel, интегрированный в
                           наш веб-сайт, при посещении нашего
                           веб-сайта устанавливается прямое соединение
                           с серверами Facebook. Информация,
                           генерируемая файлами cookie об
                           использовании вами этого веб-сайта (включая
                           ваш IP-адрес), передается в Facebook в США.
                         </span>,
                   "en": <span>
                           Via the Facebook pixel integrated on our
                           website, a direct connection to Facebook’s
                           servers is established when you visit our
                           website. The information generated by the
                           cookie about your use of this website
                           (including your IP address) is transmitted
                           to Facebook in the USA.
                         </span>,
                   "es": <span>
                           A través del píxel de Facebook integrado en
                           nuestro sitio web se establece una conexión
                           directa con los servidores de Facebook
                           cuando visita nuestro sitio web. La
                           información generada por la cookie sobre su
                           uso de este sitio web (incluida su
                           dirección IP) se transmite a Facebook en
                           EE. UU.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Помимо нас, получателями данных являются:
                         </span>,
                   "en": <span>
                           In addition to us, the recipients of the data are:
                         </span>,
                   "es": <span>
                           Además de nosotros, los destinatarios de
                           los datos son:
                         </span>})}
          </p>
          <ul>
            <li>Meta Platforms Ireland Ltd, 4 Grand Canal Square,
              Grand Canal Harbour, Dublin 2, Ireland (as processor
              according to Art. 28 GDPR).</li>
            <li>Meta Platforms Inc, 1601, Willow Road Menlo Park, CA
              94025, USA (as sub-processor according to Art. 28
              GDPR).</li>
          </ul>
          <p>
            {text(language,
                  {"ru": <span>
                           Компания Meta Platforms Ireland
                           Ltd. заключила стандартные договорные
                           положения ЕС с компанией Meta Platforms
                           Inc., базирующейся в Калифорнии, США, с
                           целью установления соответствующих гарантий
                           по смыслу ст. 46 GDPR. Копию стандартных
                           договорных положений можно найти здесь:
                           https://www.facebook.com/legal/EU_data_transfer_addendum.
                           Нельзя исключать передачу данных в США и
                           доступ властей США к данным, хранящимся в
                           Facebook. США в настоящее время считаются
                           третьей страной с точки зрения защиты
                           данных. Там у вас нет тех же прав, что и в
                           ЕС/ЕЭЗ. У вас может не быть никаких
                           правовых средств защиты от доступа со
                           стороны властей.
                         </span>,
                   "en": <span>
                           Meta Platforms Ireland Ltd. has concluded
                           EU standard contractual clauses with Meta
                           Platforms Inc., which is based in
                           California, USA, in order to establish
                           appropriate safeguards within the meaning
                           of Art. 46 GDPR. A copy of the standard
                           contractual clauses can be found here:
                           https://www.facebook.com/legal/EU_data_transfer_addendum.
                           A transfer of data to the USA and access by
                           US authorities to the data stored by
                           Facebook cannot be ruled out. The USA is
                           currently considered a third country from a
                           data protection perspective. You do not
                           have the same rights there as within the
                           EU/EEA. You may not have any legal remedies
                           against access by authorities.
                         </span>,
                   "es": <span>
                           Meta Platforms Ireland Ltd. ha celebrado
                           cláusulas contractuales estándar de la UE
                           con Meta Platforms Inc., con sede en
                           California, EE. UU., para establecer las
                           garantías adecuadas en el sentido del
                           art. 46 RGPD. Puede encontrar una copia de
                           las cláusulas contractuales tipo aquí:
                           https://www.facebook.com/legal/EU_data_transfer_addendum.
                           No se puede descartar una transferencia de
                           datos a EE.UU. y el acceso de las
                           autoridades estadounidenses a los datos
                           almacenados por Facebook. Actualmente,
                           Estados Unidos se considera un tercer país
                           desde el punto de vista de la protección de
                           datos. Allí no tiene los mismos derechos
                           que dentro de la UE/EEE. Es posible que no
                           tenga ningún recurso legal contra el acceso
                           por parte de las autoridades.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Собранные данные являются для нас
                           анонимными и не позволяют нам делать
                           какие-либо выводы о пользователе. Если вы
                           зарегистрированы в Facebook, Facebook может
                           привязать собранную информацию к вашей
                           учетной записи. Даже если у вас нет учетной
                           записи Facebook или вы не вошли в систему
                           при посещении нашего веб-сайта, Facebook
                           может обрабатывать и хранить ваш IP-адрес и
                           другие идентификационные данные.
                         </span>,
                   "en": <span>
                           The collected data is anonymous for us and
                           does not allow us to draw any conclusions
                           about the user. If you are registered with
                           Facebook, Facebook can assign the collected
                           information to your account. Even if you do
                           not have a Facebook account or are not
                           logged in when you visit our website, it is
                           possible for Facebook to process and store
                           your IP address and other identification
                           data.
                         </span>,
                   "es": <span>
                           Los datos recopilados son anónimos para
                           nosotros y no nos permiten sacar
                           conclusiones sobre el usuario. Si está
                           registrado en Facebook, Facebook puede
                           asignar la información recopilada a su
                           cuenta. Incluso si no tiene una cuenta de
                           Facebook o no ha iniciado sesión cuando
                           visita nuestro sitio web, es posible que
                           Facebook procese y almacene su dirección IP
                           y otros datos de identificación.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Правовой основой обработки данных является
                           ваше согласие в соответствии со ст. 6
                           пункт. 1 а) GDPR. Вы можете отозвать свое
                           согласие в любое время с эффектом на
                           будущее, вызвав настройки файлов cookie
                           [внизу слева на экране] и изменив там свой
                           выбор. Там вы также найдете информацию об
                           обрабатываемых файлах cookie. Законность
                           обработки, осуществляемой на основании
                           согласия, до момента его отзыва остается
                           неизменной.
                         </span>,
                   "en": <span>
                           The legal basis for the data processing is
                           your consent according to Art. 6 para. 1 a)
                           GDPR.  You can revoke your consent at any
                           time with effect for the future by calling
                           up the cookie settings [Bottom left of the
                           screen] and changing your selection
                           there. There you will also find information
                           about the cookies processed. The legality
                           of the processing carried out on the basis
                           of the consent until the revocation remains
                           unaffected.
                         </span>,
                   "es": <span>
                           La base legal para el procesamiento de
                           datos es su consentimiento según el art. 6
                           párr. 1 a) RGPD. Puede revocar su
                           consentimiento en cualquier momento con
                           efecto para el futuro accediendo a la
                           configuración de cookies [abajo a la
                           izquierda de la pantalla] y cambiando su
                           selección allí. Allí también encontrará
                           información sobre las cookies
                           procesadas. La legalidad del tratamiento
                           realizado sobre la base del consentimiento
                           hasta la revocación no se verá afectada.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Кроме того, вы можете запретить установку
                           файлов cookie, изменив соответствующие
                           настройки в своей учетной записи Facebook
                           по адресу
                           https://www.facebook.com/settings?tab=ads.
                         </span>,
                   "en": <span>
                           Furthermore, you can prevent the setting of
                           cookies by adjusting the corresponding
                           settings in your Facebook account at
                           https://www.facebook.com/settings?tab=ads.
                         </span>,
                   "es": <span>
                           Además, puede evitar la instalación de
                           cookies ajustando la configuración
                           correspondiente en su cuenta de Facebook en
                           https://www.facebook.com/settings?tab=ads.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#13" >
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "5.2 Google Ads",
                     "en": "5.2 Google Ads",
                     "es": "5.2 Google Ads"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           На нашем веб-сайте мы используем Google
                           Ads, программу онлайн-рекламы компании
                           Google LLC. Нашим договорным партнером
                           является европейская дочерняя компания
                           Google Ireland Limited, Google Building
                           Gordon House, 4 Barrow St, Dublin, D04
                           E5W5, Ирландия («Google»).
                         </span>,
                   "en": <span>
                           We use Google Ads, an online advertising
                           program of Google LLC, on our website. Our
                           contractual partner is the European
                           subsidiary Google Ireland Limited, Google
                           Building Gordon House, 4 Barrow St, Dublin,
                           D04 E5W5, Ireland (“Google”).
                         </span>,
                   "es": <span>
                           Utilizamos Google Ads, un programa de
                           publicidad en línea de Google LLC, en
                           nuestro sitio web. Nuestro socio
                           contractual es la filial europea Google
                           Ireland Limited, Google Building Gordon
                           House, 4 Barrow St, Dublín, D04 E5W5,
                           Irlanda (“Google”).
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Здесь работает так называемое отслеживание
                           конверсий. Если вы нажмете на объявление,
                           размещенное Google, будет установлен файл
                           cookie. Этот файл cookie теряет свою силу
                           через 30 дней и не используется для
                           идентификации личности пользователя. Если
                           пользователь посещает определенные страницы
                           этого веб-сайта и срок действия файла
                           cookie еще не истек, Google и мы можем
                           распознать, что пользователь нажал на
                           рекламу и был перенаправлен на эту
                           страницу.
                         </span>,
                   "en": <span>
                           Here, so-called conversion tracking is
                           operated. If you click on an ad placed by
                           Google, a cookie is set. This cookie loses
                           its validity after 30 days and is not used
                           to personally identify the user. If the
                           user visits certain pages of this website
                           and the cookie has not yet expired, Google
                           and we can recognize that the user clicked
                           on the ad and was redirected to this page.
                         </span>,
                   "es": <span>
                           Aquí se utiliza el llamado seguimiento de
                           conversiones. Si hace clic en un anuncio
                           colocado por Google, se establece una
                           cookie. Esta cookie pierde su validez a los
                           30 días y no se utiliza para identificar
                           personalmente al usuario. Si el usuario
                           visita determinadas páginas de este sitio
                           web y la cookie aún no ha caducado, Google
                           y nosotros podemos reconocer que el usuario
                           hizo clic en el anuncio y fue redirigido a
                           esta página.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Данные, собранные с помощью файла cookie
                           конверсий, используются для создания
                           статистики для клиентов Ads, которые
                           согласились на отслеживание конверсий.
                         </span>,
                   "en": <span>
                           The data collected using the conversion
                           cookie is used to generate statistics for
                           Ads customers who have agreed to conversion
                           tracking.
                         </span>,
                   "es": <span>
                           Los datos recopilados mediante la cookie de
                           conversión se utilizan para generar
                           estadísticas para los clientes de anuncios
                           que aceptaron el seguimiento de
                           conversiones.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Получателями данных помимо нас являются/могут быть:
                         </span>,
                   "en": <span>
                           Recipients of the data besides us are/may be:
                         </span>,
                   "es": <span>
                           Los destinatarios de los datos además de
                           nosotros son/pueden ser:
                         </span>})}
          </p>
          <ul>
            <li>Google Ireland Limited, Gordon House, Barrow Street,
              Dublin 4, Ireland (as processor according to Art. 28
              GDPR).</li>
            <li>Google LLC, 1600 Amphitheatre Parkway Mountain View,
              CA 94043, USA, Alphabet Inc, 1600 Amphitheatre Parkway
              Mountain View, CA 94043, USA.</li>
          </ul>
          <p>
            {text(language,
                  {"ru": <span>
                           Поскольку данные обрабатываются за
                           пределами ЕС/ЕЭЗ и не существует уровня
                           защиты данных, соответствующего
                           европейскому стандарту, компания Google
                           Ireland Limited заключила стандартные
                           договорные положения ЕС с компанией Google
                           LLC, базирующейся в Калифорнии, США, с
                           целью установления соответствующие гарантии
                           по смыслу ст. 46 GDPR. Копию договорных
                           положений можно найти здесь:
                           https://policies.google.com/privacy/frameworks. Нельзя
                           исключать передачу данных в США и доступ
                           властей США к данным, хранящимся в
                           Google. США в настоящее время считаются
                           третьей страной с точки зрения защиты
                           данных. Там у вас нет тех же прав, что и в
                           ЕС/ЕЭЗ. Вы не можете иметь право на
                           какие-либо средства правовой защиты от
                           доступа со стороны властей.
                         </span>,
                   "en": <span>
                           Insofar as data is processed outside the
                           EU/EEA and there is no level of data
                           protection that corresponds to the European
                           standard, Google Ireland Limited has
                           concluded EU standard contractual clauses
                           with Google LLC, which is based in
                           California, USA, in order to establish
                           suitable guarantees within the meaning of
                           Art. 46 GDPR. A copy of the contractual
                           clauses can be found here:
                           https://policies.google.com/privacy/frameworks. A
                           transfer of data to the USA and access by
                           US authorities to the data stored by Google
                           cannot be ruled out. The USA is currently
                           considered a third country from a data
                           protection perspective. You do not have the
                           same rights there as within the EU/EEA. You
                           may not be entitled to any legal remedies
                           against access by authorities.
                         </span>,
                   "es": <span>
                           En la medida en que los datos se procesen
                           fuera de la UE/EEE y no exista un nivel de
                           protección de datos que corresponda al
                           estándar europeo, Google Ireland Limited ha
                           celebrado cláusulas contractuales estándar
                           de la UE con Google LLC, con sede en
                           California, EE. UU., para establecer
                           garantías adecuadas en el sentido del
                           art. 46 RGPD. Puede encontrar una copia de
                           las cláusulas contractuales aquí:
                           https://policies.google.com/privacy/frameworks. No
                           se puede descartar una transferencia de
                           datos a EE.UU. ni el acceso de las
                           autoridades estadounidenses a los datos
                           almacenados por Google. Actualmente,
                           Estados Unidos se considera un tercer país
                           desde el punto de vista de la protección de
                           datos. Allí no tiene los mismos derechos
                           que dentro de la UE/EEE. Es posible que no
                           tenga derecho a ningún recurso legal contra
                           el acceso de las autoridades.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Правовой основой для обработки данных
                           является ваше согласие в соответствии со
                           статьей 6, пункт 1, пункт 1, пункт a
                           GDPR. Вы можете отозвать свое согласие в
                           любое время с эффектом на будущее, вызвав
                           настройки файлов cookie и изменив там свой
                           выбор. Там вы также найдете информацию об
                           обрабатываемых файлах cookie. Законность
                           обработки, осуществляемой на основании
                           согласия, до момента его отзыва остается
                           неизменной.
                         </span>,
                   "en": <span>
                           The legal basis for this data processing is
                           your consent pursuant to Art.6 para.1 p.1
                           lit.a GDPR. You can revoke your consent at
                           any time with effect for the future by
                           calling up the cookie settings and changing
                           your selection there. There you will also
                           find information about the cookies
                           processed. The lawfulness of the processing
                           carried out on the basis of the consent
                           until the revocation remains unaffected.
                         </span>,
                   "es": <span>
                           La base legal para este procesamiento de
                           datos es su consentimiento de conformidad
                           con el Art.6 párrafo 1 p.1 letra a del
                           RGPD. Puede revocar su consentimiento en
                           cualquier momento con efecto para el futuro
                           accediendo a la configuración de cookies y
                           cambiando su selección allí. Allí también
                           encontrará información sobre las cookies
                           procesadas. La licitud del tratamiento
                           realizado sobre la base del consentimiento
                           no se verá afectada hasta la revocación.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы также можете изначально запретить
                           сохранение файлов cookie, настроив
                           программное обеспечение своего браузера
                           соответствующим образом. Однако если вы
                           настроите свой браузер на отклонение всех
                           файлов cookie, это может привести к
                           ограничению функций этого и других
                           веб-сайтов. Вы также можете запретить сбор
                           данных, сгенерированных файлом cookie и
                           связанных с использованием вами веб-сайта
                           (включая ваш IP-адрес), в Google и
                           обработку этих данных Google,
                         </span>,
                   "en": <span>
                           You can also prevent the storage of cookies
                           from the outset by setting your browser
                           software accordingly. However, if you
                           configure your browser to reject all
                           cookies, this may result in a restriction
                           of functionalities on this and other
                           websites. You can also prevent the
                           collection of data generated by the cookie
                           and related to your use of the website
                           (including your IP address) to Google and
                           the processing of this data by Google by
                         </span>,
                   "es": <span>
                           También puede impedir el almacenamiento de
                           cookies desde el principio configurando el
                           software de su navegador en
                           consecuencia. Sin embargo, si configura su
                           navegador para rechazar todas las cookies,
                           esto puede resultar en una restricción de
                           funcionalidades en este y otros sitios
                           web. También puede impedir la recopilación
                           de datos generados por la cookie y
                           relacionados con su uso del sitio web
                           (incluida su dirección IP) a Google y el
                           procesamiento de estos datos por parte de
                           Google mediante
                         </span>})}
          </p>
          <ul>
            <li>{text(language,
                  {"ru": <span>
                           не давать свое согласие на установку файлов cookie или
                         </span>,
                   "en": <span>
                           not giving your consent to the setting of the cookie or
                         </span>,
                   "es": <span>
                           no dar su consentimiento para la instalación de la cookie o
                         </span>})}
            </li>
            <li>{text(language,
                  {"ru": <span>
                           загрузка и установка дополнения для
                           браузера для отключения Google Analytics&ensp;
                           <Link
                             href="https://tools.google.com/dlpage/gaoptout">
                             ЗДЕСЬ
                           </Link>.
                         </span>,
                   "en": <span>
                           downloading and installing the browser
                           add-on to disable Google Analytics&ensp;
                           <Link
                             href="https://tools.google.com/dlpage/gaoptout">
                             HERE
                           </Link>.
                         </span>,
                   "es": <span>
                           descargando e instalando el complemento del
                           navegador para desactivar Google Analytics&ensp;
                           <Link
                             href="https://tools.google.com/dlpage/gaoptout">
                             AQUÍ
                           </Link>.
                         </span>})}
            </li>
          </ul>
          <p>
            {text(language,
                  {"ru": <span>
                           Для получения дополнительной информации об
                           условиях использования Google Рекламы и
                           политике конфиденциальности Google посетите
                           https://ads.google.com/home/ и
                           https://policies.google.com.
                         </span>,
                   "en": <span>
                           For more information on Google Ads’ terms
                           of use and Google’s privacy policy, please
                           visit https://ads.google.com/home/ and
                           https://policies.google.com.
                         </span>,
                   "es": <span>
                           Para obtener más información sobre los
                           términos de uso de Google Ads y la política
                           de privacidad de Google, visite
                           https://ads.google.com/home/ y
                           https://policies.google.com.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#14" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "6. Ссылки на социальные сети",
                     "en": "6. Social media links",
                     "es": "6. Enlaces de redes sociales"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Мы поддерживаем онлайн-присутствие в
                           социальных сетях и на платформах, чтобы
                           общаться с клиентами, потенциальными
                           клиентами и пользователями, которые активны
                           в этих сетях, а также иметь возможность
                           информировать клиентов, потенциальных
                           клиентов и пользователей о наших услугах.
                         </span>,
                   "en": <span>
                           We maintain online presences in social
                           networks and platforms in order to
                           communicate with customers, prospects and
                           users who are active in these networks and
                           to be able to inform customers, prospects
                           and users about our services.
                         </span>,
                   "es": <span>
                           Mantenemos presencia en línea en redes
                           sociales y plataformas para comunicarnos
                           con clientes, prospectos y usuarios que
                           están activos en estas redes y para poder
                           informar a los clientes, prospectos y
                           usuarios sobre nuestros servicios.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Таким образом, наш веб-сайт ссылается на
                           веб-сайт Instagram, которым управляет Meta
                           Platforms Inc, 1 Hacker Way, Menlo Park, CA
                           94025, США, или, если вы являетесь
                           резидентом ЕС, Meta Platforms Ireland Ltd,
                           4 Grand Canal Square, Grand Canal Harbour,
                           Дублин 2, Ирландия. Если иное не указано в
                           настоящем уведомлении о конфиденциальности,
                           обмен данными с Instagram через наш
                           веб-сайт не осуществляется.
                         </span>,
                   "en": <span>
                           Our website therefore links to the
                           Instagram website, operated by Meta
                           Platforms Inc, 1 Hacker Way, Menlo Park, CA
                           94025, U.S.A., or, if you are an EU
                           resident, Meta Platforms Ireland Ltd, 4
                           Grand Canal Square, Grand Canal Harbour,
                           Dublin 2, Ireland. Unless otherwise
                           described in this privacy notice, no data
                           is exchanged with Instagram through our
                           website.
                         </span>,
                   "es": <span>
                           Por lo tanto, nuestro sitio web enlaza con
                           el sitio web de Instagram, operado por Meta
                           Platforms Inc, 1 Hacker Way, Menlo Park, CA
                           94025, EE. UU. o, si es residente de la UE,
                           Meta Platforms Ireland Ltd, 4 Grand Canal
                           Square, Grand Canal Harbour, Dublín 2,
                           Irlanda. Salvo que se indique lo contrario
                           en este aviso de privacidad, no se
                           intercambian datos con Instagram a través
                           de nuestro sitio web.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Наш веб-сайт также связан с веб-сайтом
                           LinkedIn, которым управляет LinkedIn Corp,
                           1000 W Maude Ave, Sunnyvale, CA,
                           94085-2810, США, или, если вы являетесь
                           резидентом ЕС, LinkedIn Ireland Unlimited
                           Company, Wilton Place, Dublin. 2,
                           Ирландия. Если иное не указано в настоящем
                           уведомлении о конфиденциальности, обмен
                           данными с LinkedIn через наш веб-сайт не
                           осуществляется.
                         </span>,
                   "en": <span>
                           Our website also links to the website of
                           LinkedIn, operated by LinkedIn Corp, 1000 W
                           Maude Ave, Sunnyvale, CA, 94085-2810, USA,
                           or, if you are a resident of the EU,
                           LinkedIn Ireland Unlimited Company, Wilton
                           Place, Dublin 2, Ireland. Unless otherwise
                           described in this privacy notice, no data
                           is exchanged with LinkedIn via our website.
                         </span>,
                   "es": <span>
                           Nuestro sitio web también enlaza con el
                           sitio web de LinkedIn, operado por LinkedIn
                           Corp, 1000 W Maude Ave, Sunnyvale, CA,
                           94085-2810, EE. UU. o, si es residente de
                           la UE, LinkedIn Ireland Unlimited Company,
                           Wilton Place, Dublín. 2, Irlanda. A menos
                           que se indique lo contrario en este aviso
                           de privacidad, no se intercambian datos con
                           LinkedIn a través de nuestro sitio web.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Когда вы получаете доступ к вышеупомянутым
                           сетям или платформам, применяются условия и
                           политики обработки данных компаний,
                           управляющих этими сетями или
                           платформами. Если иное не предусмотрено в
                           нашей политике конфиденциальности, мы
                           обрабатываем данные о пользователях, когда
                           они общаются с нами через социальные сети
                           или платформы, например, когда они
                           публикуют сообщения на наших страницах в
                           Facebook или отправляют нам сообщения.
                         </span>,
                   "en": <span>
                           When you access the aforementioned networks
                           or platforms, the terms and conditions and
                           data processing policies of the companies
                           operating these networks or platforms
                           apply. Unless otherwise provided in our
                           privacy policy, we process data about users
                           when they communicate with us via social
                           networks or platforms, for example, when
                           they post on our Facebook pages or send us
                           messages.
                         </span>,
                   "es": <span>
                           Cuando accede a las redes o plataformas
                           antes mencionadas, se aplican los términos
                           y condiciones y políticas de procesamiento
                           de datos de las empresas que operan estas
                           redes o plataformas. A menos que se indique
                           lo contrario en nuestra política de
                           privacidad, procesamos datos sobre los
                           usuarios cuando se comunican con nosotros a
                           través de redes o plataformas sociales, por
                           ejemplo, cuando publican en nuestras
                           páginas de Facebook o nos envían mensajes.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#15" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "7. Права",
                     "en": "7. Rights",
                     "es": "7. Derechos"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Если вы обрабатываете персональные данные,
                           вы являетесь субъектом данных по смыслу
                           GDPR и имеете следующие права в отношении
                           оператора:
                         </span>,
                   "en": <span>
                           If personal data is processed by you, you
                           are a data subject within the meaning of
                           the GDPR and you have the following rights
                           against the controller:
                         </span>,
                   "es": <span>
                           Si usted procesa datos personales, usted es
                           un interesado en el sentido del RGPD y
                           tiene los siguientes derechos frente al
                           responsable del tratamiento:
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#16" >
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "7.1 Право доступа",
                     "en": "7.1 Right of access",
                     "es": "7.1 Derecho de acceso"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы можете запросить у оператора
                           подтверждение того, обрабатываются ли нами
                           личные данные, касающиеся вас.
                         </span>,
                   "en": <span>
                           You may request confirmation from the
                           controller as to whether personal data
                           concerning you is being processed by us.
                         </span>,
                   "es": <span>
                           Puede solicitar al responsable del
                           tratamiento que confirme si estamos
                           procesando datos personales que le
                           conciernen.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Если такая обработка имеет место, вы можете
                           запросить у оператора информацию о
                           следующем:
                         </span>,
                   "en": <span>
                           If such processing is taking place, you may
                           request information from the controller
                           about the following:
                         </span>,
                   "es": <span>
                           Si se lleva a cabo dicho procesamiento,
                           podrá solicitar información al responsable
                           del tratamiento sobre lo siguiente:
                         </span>})}
          </p>
          <ol>
            <li>
              {text(language,
                    {"ru": <span>
                             цели, для которых обрабатываются
                             персональные данные;
                           </span>,
                     "en": <span>
                             the purposes for which the personal data
                             are processed;
                           </span>,
                     "es": <span>
                             los fines para los cuales se procesan los
                             datos personales;
                           </span>})}
            </li>
            <li>{text(language,
                      {"ru": <span>
                               категории персональных данных, которые
                               обрабатываются;
                           </span>,
                       "en": <span>
                               the categories of personal data which
                               are processed;
                             </span>,
                       "es": <span>
                               las categorías de datos personales que
                               se procesan;
                             </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             получатели или категории получателей,
                             которым были или будут раскрыты касающиеся
                             вас персональные данные;
                           </span>,
                     "en": <span>
                             the recipients or categories of recipients
                             to whom the personal data concerning you
                             have been or will be disclosed;
                           </span>,
                     "es": <span>
                             los destinatarios o categorías de
                             destinatarios a quienes se han comunicado o
                             se comunicarán los datos personales que le
                             conciernen;
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             планируемая продолжительность хранения
                             касающихся вас персональных данных или,
                             если конкретная информация об этом
                             невозможна, критерии определения срока
                             хранения;
                           </span>,
                     "en": <span>
                             the planned duration of the storage of
                             the personal data concerning you or, if
                             concrete information on this is not
                             possible, criteria for determining the
                             storage period;
                           </span>,
                     "es": <span>
                             la duración prevista del almacenamiento
                             de los datos personales que le conciernen
                             o, si no es posible obtener información
                             concreta al respecto, los criterios para
                             determinar el período de almacenamiento;
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             наличие права на исправление или удаление
                             касающихся вас персональных данных, права
                             на ограничение обработки оператором или
                             права возражать против такой обработки;
                           </span>,
                     "en": <span>
                             the existence of a right to rectification
                             or erasure of the personal data
                             concerning you, a right to restriction of
                             processing by the controller or a right
                             to object to such processing;
                           </span>,
                     "es": <span>
                             la existencia de un derecho de
                             rectificación o supresión de los datos
                             personales que le conciernen, un derecho
                             de limitación del tratamiento por parte
                             del responsable del tratamiento o un
                             derecho de oposición a dicho tratamiento;
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             наличие права апелляции в надзорный орган;
                           </span>,
                     "en": <span>
                             the existence of a right of appeal to a
                             supervisory authority;
                           </span>,
                     "es": <span>
                             la existencia de un derecho de recurso
                             ante una autoridad de control;
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             любую доступную информацию о
                             происхождении данных, если персональные
                             данные не собираются от субъекта данных;
                           </span>,
                     "en": <span>
                             any available information on the origin
                             of the data, if the personal data are not
                             collected from the data subject;
                           </span>,
                     "es": <span>
                             cualquier información disponible sobre el
                             origen de los datos, si los datos
                             personales no se recopilan del
                             interesado;
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             наличие автоматизированного принятия
                             решений, включая профилирование, в
                             соответствии со статьей 22(1) и (4) GDPR
                             и, по крайней мере, в этих случаях,
                             значимой информации о задействованной
                             логике, а также объеме и предполагаемых
                             эффектах такой обработки для субъект
                             данных.
                           </span>,
                     "en": <span>
                             the existence of automated
                             decision-making, including profiling,
                             pursuant to Article 22(1) and (4) of the
                             GDPR and, at least in those cases,
                             meaningful information about the logic
                             involved and the scope and intended
                             effects of such processing for the data
                             subject.
                           </span>,
                     "es": <span>
                             la existencia de una toma de decisiones
                             automatizada, incluida la elaboración de
                             perfiles, de conformidad con el artículo
                             22, apartados 1 y 4, del RGPD y, al menos
                             en esos casos, información significativa
                             sobre la lógica involucrada y el alcance
                             y los efectos previstos de dicho
                             procesamiento para el interesado.
                           </span>})}
            </li>
          </ol>

          <p>
            {text(language,
                  {"ru": <span>
                           Вы имеете право запросить информацию о том,
                           передаются ли касающиеся вас персональные
                           данные в третью страну или международную
                           организацию. В этом контексте вы можете
                           запросить информацию о соответствующих
                           гарантиях в соответствии со ст. 46 GDPR в
                           связи с передачей.
                         </span>,
                   "en": <span>
                           You have the right to request information
                           about whether personal data concerning you
                           is transferred to a third country or to an
                           international organization. In this
                           context, you may request to be informed
                           about the appropriate safeguards pursuant
                           to Art. 46 GDPR in connection with the
                           transfer.
                         </span>,
                   "es": <span>
                           Tiene derecho a solicitar información sobre
                           si los datos personales que le conciernen
                           se transfieren a un tercer país o a una
                           organización internacional. En este
                           contexto, puede solicitar que se le informe
                           sobre las garantías adecuadas de
                           conformidad con el art. 46 GDPR en relación
                           con la transferencia.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#17">
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "7.2 Право на исправление",
                     "en": "7.2 Right to rectification",
                     "es": "7.2 Derecho de rectificación"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы имеете право на исправление и/или
                           дополнение по отношению к оператору, если
                           обработанные о вас персональные данные
                           являются неточными или неполными. Оператор
                           должен внести исправления без неоправданной
                           задержки.
                         </span>,
                   "en": <span>
                           You have a right to rectification and/or
                           completion vis-à-vis the controller if the
                           personal data processed concerning you is
                           inaccurate or incomplete. The controller
                           shall carry out the rectification without
                           undue delay.
                         </span>,
                   "es": <span>
                           Tiene derecho a rectificación y/o
                           finalización frente al responsable del
                           tratamiento si los datos personales
                           tratados que le conciernen son inexactos o
                           están incompletos. El responsable del
                           tratamiento llevará a cabo la rectificación
                           sin dilación indebida.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#18">
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "7.3 Право на ограничение обработки",
                     "en": "7.3 Right to restriction of processing",
                     "es": "7.3 Derecho a la limitación del tratamiento"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы можете запросить ограничение обработки
                           касающихся вас персональных данных при
                           следующих условиях:
                         </span>,
                   "en": <span>
                           You may request the restriction of the
                           processing of personal data concerning you
                           under the following conditions:
                         </span>,
                   "es": <span>
                           Podrá solicitar la limitación del
                           tratamiento de los datos personales que le
                           conciernen en las siguientes condiciones:
                         </span>})}
          </p>
          <ol>
            <li>
              {text(language,
                    {"ru": <span>
                             если вы оспариваете точность касающихся
                             вас персональных данных в течение
                             периода, позволяющего оператору
                             проверить точность персональных данных;
                           </span>,
                     "en": <span>
                             if you contest the accuracy of the
                             personal data concerning you for a period
                             enabling the controller to verify the
                             accuracy of the personal data;
                           </span>,
                     "es": <span>
                             si impugna la exactitud de los datos
                             personales que le conciernen durante un
                             período que permita al responsable del
                             tratamiento verificar la exactitud de los
                             datos personales;
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             обработка является незаконной, и вы
                             возражаете против удаления персональных
                             данных и вместо этого требуете
                             ограничения использования персональных
                             данных;
                           </span>,
                     "en": <span>
                             the processing is unlawful and you object
                             to the erasure of the personal data and
                             request instead the restriction of the
                             use of the personal data;
                           </span>,
                     "es": <span>
                             el procesamiento es ilegal y usted se
                             opone a la supresión de los datos
                             personales y solicita en su lugar la
                             restricción del uso de los datos
                             personales;
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             оператору больше не нужны персональные
                             данные для целей обработки, но они нужны
                             вам для предъявления, осуществления или
                             защиты юридических претензий; или
                           </span>,
                     "en": <span>
                             the controller no longer needs the
                             personal data for the purposes of the
                             processing, but you need it for the
                             establishment, exercise or defense of
                             legal claims; or
                           </span>,
                     "es": <span>
                             el responsable del tratamiento ya no
                             necesita los datos personales para los
                             fines del tratamiento, pero usted los
                             necesita para el establecimiento,
                             ejercicio o defensa de reclamaciones
                             legales; o
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             если вы возражали против обработки в
                             соответствии со статьей 21 (1) GDPR, и
                             еще не ясно, имеют ли законные основания
                             контролера преимущественную силу над
                             вашими основаниями.
                           </span>,
                     "en": <span>
                             if you have objected to the processing
                             pursuant to Article 21(1) of the GDPR and
                             it is not yet clear whether the
                             controller’s legitimate grounds override
                             your grounds.
                           </span>,
                     "es": <span>
                             si se ha opuesto al procesamiento de
                             conformidad con el artículo 21, apartado
                             1 del RGPD y aún no está claro si los
                             motivos legítimos del responsable
                             prevalecen sobre los suyos.
                           </span>})}
            </li>
          </ol>

          <p>
            {text(language,
                  {"ru": <span>
                           Если обработка касающихся вас персональных
                           данных была ограничена, такие данные могут
                           – помимо хранения – обрабатываться только с
                           вашего согласия или для установления,
                           осуществления или защиты юридических
                           претензий или для защиты прав другого
                           физического или юридического лица. лицу или
                           по причинам важных общественных интересов
                           Союза или государства-члена.
                         </span>,
                   "en": <span>
                           If the processing of personal data
                           concerning you has been restricted, such
                           data may – apart from being stored – only
                           be processed with your consent or for the
                           establishment, exercise or defense of legal
                           claims or for the protection of the rights
                           of another natural or legal person or for
                           reasons of important public interest of the
                           Union or a Member State.
                         </span>,
                   "es": <span>
                           Si se ha restringido el procesamiento de
                           datos personales que le conciernen, dichos
                           datos, además de almacenarse, solo podrán
                           procesarse con su consentimiento o para el
                           establecimiento, ejercicio o defensa de
                           reclamaciones legales o para la protección
                           de los derechos de otra persona física o
                           jurídica. persona o por razones de interés
                           público importante de la Unión o de un
                           Estado miembro.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Если ограничение обработки было ограничено
                           в соответствии с вышеуказанными условиями,
                           контролер сообщит вам об этом до того, как
                           ограничение будет снято.
                         </span>,
                   "en": <span>
                           If the restriction of processing has been
                           restricted in accordance with the above
                           conditions, you will be informed by the
                           controller before the restriction is
                           lifted.
                         </span>,
                   "es": <span>
                           Si la restricción del procesamiento se ha
                           restringido de acuerdo con las condiciones
                           anteriores, el controlador le informará
                           antes de que se levante la restricción.
                         </span>})}
          </p>

          <HelpHashLink to="/privacyPolicy#19">
            <Typography variant="h7" component="h4">
              {text(language,
                    {"ru": "7.4 Право на удаление",
                     "en": "7.4 Right to deletion",
                     "es": "7.4 Derecho de supresión"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": "7.4.1 Обязательство удаления",
                   "en": "7.4.1 Obligation to delete",
                   "es": "7.4.1 Obligación de suprimir"})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы можете потребовать от контролера удалить
                           касающиеся вас персональные данные без
                           неоправданной задержки, и контролер обязан
                           удалить такие данные без неоправданной
                           задержки, если применима одна из следующих
                           причин:
                         </span>,
                   "en": <span>
                           You may request the controller to erase the
                           personal data concerning you without undue
                           delay, and the controller is obliged to
                           erase such data without undue delay, if one
                           of the following reasons applies:
                         </span>,
                   "es": <span>
                           Puede solicitar al controlador que borre
                           los datos personales que le conciernen sin
                           demora indebida, y el controlador está
                           obligado a borrar dichos datos sin demora
                           indebida, si se aplica una de las
                           siguientes razones:
                         </span>})}
          </p>
          <ol style={{"list-style": "lower-alpha"}}>
            <li>
              {text(language,
                    {"ru": <span>
                             Касающиеся вас персональные данные больше
                             не нужны для целей, для которых они были
                             собраны или иным образом обработаны.
                           </span>,
                     "en": <span>
                             The personal data concerning you are no
                             longer necessary for the purposes for
                             which they were collected or otherwise
                             processed.
                           </span>,
                     "es": <span>
                             Los datos personales que le conciernen ya
                             no son necesarios para los fines para los
                             que fueron recopilados o procesados ​​de
                             otro modo.
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             Вы отзываете свое согласие, на котором
                             была основана обработка в соответствии со
                             ст. 6(1)(а) или ст. 9(2)(a) GDPR, и для
                             обработки нет другой правовой основы.
                           </span>,
                     "en": <span>
                             You revoke your consent on which the
                             processing was based pursuant to
                             Art. 6(1)(a) or Art. 9(2)(a) GDPR and
                             there is no other legal basis for the
                             processing.
                           </span>,
                     "es": <span>
                             Usted revoca su consentimiento en el que
                             se basó el procesamiento de conformidad
                             con el art. 6(1)(a) o el art. 9(2)(a)
                             GDPR y no existe otra base legal para el
                             procesamiento.
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             Вы возражаете против обработки в
                             соответствии со ст. 21 (1) GDPR, и нет
                             существенных законных оснований для
                             обработки, или вы возражаете против
                             обработки в соответствии со ст. 21 (2)
                             GDPR.
                           </span>,
                     "en": <span>
                             You object to the processing pursuant to
                             Art. 21 (1) GDPR and there are no
                             overriding legitimate grounds for the
                             processing, or you object to the
                             processing pursuant to Art. 21 (2) GDPR.
                           </span>,
                     "es": <span>
                             Usted se opone al procesamiento de
                             conformidad con el art. 21 (1) del RGPD y
                             no existen motivos legítimos imperiosos
                             para el procesamiento, o usted se opone
                             al procesamiento de conformidad con el
                             art. 21 (2) RGPD.
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             Персональные данные, касающиеся вас, были
                             обработаны незаконно.
                           </span>,
                     "en": <span>
                             The personal data concerning you have
                             been processed unlawfully.
                           </span>,
                     "es": <span>
                             Los datos personales que le conciernen
                             han sido tratados de forma ilícita.
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             Удаление касающихся вас персональных
                             данных необходимо для соблюдения
                             юридического обязательства в соответствии
                             с законодательством Союза или
                             государства-члена, которому подчиняется
                             контролер.
                           </span>,
                     "en": <span>
                             The erasure of the personal data
                             concerning you is necessary for
                             compliance with a legal obligation under
                             Union or Member State law to which the
                             controller is subject.
                           </span>,
                     "es": <span>
                             La eliminación de los datos personales
                             que le conciernen es necesaria para el
                             cumplimiento de una obligación legal en
                             virtud del derecho de la Unión o de los
                             Estados miembros a la que está sujeto el
                             responsable del tratamiento.
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             Касающиеся вас персональные данные были
                             собраны в связи с услугами
                             информационного общества, предлагаемыми в
                             соответствии со статьей 8 (1) GDPR.
                           </span>,
                     "en": <span>
                             The personal data concerning you has been
                             collected in relation to information
                             society services offered pursuant to
                             Article 8(1) of the GDPR.
                           </span>,
                     "es": <span>
                             Los datos personales que le conciernen se
                             han recopilado en relación con servicios
                             de la sociedad de la información
                             ofrecidos de conformidad con el artículo
                             8, apartado 1, del RGPD.
                           </span>})}
            </li>
          </ol>

          <p>
            {text(language,
                  {"ru": "7.4.2 Информация для третьих лиц",
                   "en": "7.4.2 Information to third parties",
                   "es": "7.4.2 Información a terceros"})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Если контролер сделал личные данные,
                           касающиеся вас, общедоступными и обязан
                           удалить их в соответствии со статьей 17 (1)
                           GDPR, он должен принять разумные меры,
                           включая технические меры, с учетом
                           доступных технологий и стоимости
                           внедрения. информировать контролеров
                           данных, которые обрабатывают персональные
                           данные, о том, что вы, как субъект данных,
                           потребовали, чтобы они удалили все ссылки,
                           копии или репликации таких персональных
                           данных.
                         </span>,
                   "en": <span>
                           If the controller has made the personal
                           data concerning you public and is obliged
                           to erase it pursuant to Article 17(1) of
                           the GDPR, it shall take reasonable steps,
                           including technical measures, having regard
                           to the available technology and the cost of
                           implementation, to inform data controllers
                           which process the personal data that you,
                           as the data subject, have requested that
                           they erase all links to or copies or
                           replications of such personal data.
                         </span>,
                   "es": <span>
                           Si el responsable del tratamiento ha hecho
                           públicos los datos personales que le
                           conciernen y está obligado a borrarlos de
                           conformidad con el artículo 17, apartado 1
                           del RGPD, deberá tomar medidas razonables,
                           incluidas medidas técnicas, teniendo en
                           cuenta la tecnología disponible y el coste
                           de implementación. para informar a los
                           controladores de datos que procesan los
                           datos personales que usted, como
                           interesado, ha solicitado que borren todos
                           los enlaces o copias o replicaciones de
                           dichos datos personales.
                         </span>})}
          </p>

          <p>
            {text(language,
                  {"ru": "7.4.3 Исключения",
                   "en": "7.4.3 Exceptions",
                   "es": "7.4.3 Excepciones"})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Право на удаление не существует в той
                           степени, в которой обработка необходима
                         </span>,
                   "en": <span>
                           The right to erasure does not exist to the
                           extent that the processing is necessary
                         </span>,
                   "es": <span>
                           El derecho de supresión no existe en la
                           medida en que el tratamiento sea necesario
                         </span>})}
          </p>
          <ol style={{"list-style": "lower-alpha"}}>
            <li>
              {text(language,
                    {"ru": <span>
                             для осуществления права на свободу
                             выражения мнений и информации;
                           </span>,
                     "en": <span>
                             for the exercise of the right to freedom
                             of expression and information;
                           </span>,
                     "es": <span>
                             para el ejercicio del derecho a la
                             libertad de expresión e información;
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             для соблюдения юридического
                             обязательства, которое требует обработки
                             в соответствии с законодательством Союза
                             или государства-члена, которому
                             подчиняется контролер, или для выполнения
                             задачи, выполняемой в общественных
                             интересах или при осуществлении
                             официальных полномочий, возложенных на
                             опреатора;
                           </span>,
                     "en": <span>
                             for compliance with a legal obligation
                             which requires processing under Union or
                             Member State law to which the controller
                             is subject, or for the performance of a
                             task carried out in the public interest
                             or in the exercise of official authority
                             vested in the controller;
                           </span>,
                     "es": <span>
                             para el cumplimiento de una obligación
                             legal que requiere un tratamiento en
                             virtud del Derecho de la Unión o de los
                             Estados miembros a la que está sujeto el
                             responsable del tratamiento, o para el
                             desempeño de una tarea realizada en
                             interés público o en el ejercicio de
                             poderes oficiales conferidos al
                             responsable del tratamiento;
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             по соображениям общественного интереса в
                             сфере общественного здравоохранения в
                             соответствии со статьей 9(2)(h) и (i) и
                             статьей 9(3) GDPR;
                           </span>,
                     "en": <span>
                             for reasons of public interest in the
                             area of public health pursuant to Article
                             9(2)(h) and (i) and Article 9(3) of the
                             GDPR;
                           </span>,
                     "es": <span>
                             por razones de interés público en el
                             ámbito de la salud pública de conformidad
                             con el artículo 9, apartado 2, letras (h)
                             e (i), y el artículo 9, apartado 3, del
                             RGPD;
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             в целях архивирования в общественных
                             интересах, в целях научных или
                             исторических исследований или в
                             статистических целях в соответствии со
                             ст. 89(1) GDPR, если право, указанное в
                             разделе (a), может сделать невозможным
                             или серьезно нанести ущерб достижению
                             целей такой обработки; или
                           </span>,
                     "en": <span>
                             for archiving purposes in the public
                             interest, scientific or historical
                             research purposes or statistical purposes
                             pursuant to Art. 89(1) GDPR, insofar as
                             the right referred to in section (a) is
                             likely to render impossible or seriously
                             prejudice the achievement of the purposes
                             of such processing; or
                           </span>,
                     "es": <span>
                             para fines de archivo de interés público,
                             fines de investigación científica o
                             histórica o fines estadísticos de
                             conformidad con el art. 89(1) GDPR, en la
                             medida en que el derecho mencionado en la
                             sección (a) pueda imposibilitar o
                             perjudicar gravemente el logro de los
                             fines de dicho procesamiento; o
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             для предъявления, осуществления или
                             защиты юридических претензий.
                           </span>,
                     "en": <span>
                             for the assertion, exercise or defense of
                             legal claims.
                           </span>,
                     "es": <span>
                             para la afirmación, ejercicio o defensa
                             de reclamaciones legales.
                           </span>})}
            </li>
          </ol>

          <p>
            {text(language,
                  {"ru": "7.4.4 Право на информацию",
                   "en": "7.4.4 Right to information",
                   "es": "7.4.4 Derecho de información"})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Если вы заявили о праве на исправление,
                           удаление или ограничение обработки
                           контролера, контролер обязан
                           проинформировать всех получателей, которым
                           были раскрыты касающиеся вас персональные
                           данные, об этом исправлении или удалении
                           данных или ограничении обработки, за
                           исключением случаев, когда это оказывается
                           невозможным или требует непропорциональных
                           усилий.
                         </span>,
                   "en": <span>
                           If you have asserted the right to
                           rectification, erasure or restriction of
                           processing against the controller, the
                           controller is obliged to inform all
                           recipients to whom the personal data
                           concerning you have been disclosed of this
                           rectification or erasure of the data or
                           restriction of processing, unless this
                           proves impossible or involves a
                           disproportionate effort.
                         </span>,
                   "es": <span>
                           Si ha hecho valer el derecho de
                           rectificación, supresión o limitación del
                           tratamiento frente al responsable del
                           tratamiento, el responsable del tratamiento
                           está obligado a informar de esta
                           rectificación o supresión de los datos o de
                           la limitación del tratamiento a todos los
                           destinatarios a quienes se hayan comunicado
                           los datos personales que le conciernen, a
                           menos que esto resulta imposible o implica
                           un esfuerzo desproporcionado.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы имеете право получить от контролера
                           информацию об этих получателях.
                         </span>,
                   "en": <span>
                           You have the right against the controller
                           to be informed about these recipients.
                         </span>,
                   "es": <span>
                           Usted tiene derecho frente al responsable
                           del tratamiento a ser informado sobre estos
                           destinatarios.
                         </span>})}
          </p>

          <p>
            {text(language,
                  {"ru": "7.4.5 Право на переносимость данных",
                   "en": "7.4.5 Right to data portability",
                   "es": "7.4.5 Derecho a la portabilidad de los datos"})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы имеете право получить касающиеся вас
                           персональные данные, которые вы
                           предоставили контролеру, в
                           структурированном, общепринятом и
                           машиночитаемом формате. Вы также имеете
                           право передать эти данные другому
                           контролеру без каких-либо препятствий со
                           стороны контролера, которому были
                           предоставлены персональные данные, при
                           условии, что.
                         </span>,
                   "en": <span>
                           You have the right to receive the personal
                           data concerning you that you have provided
                           to the controller in a structured, common
                           and machine-readable format. You also have
                           the right to transfer this data to another
                           controller without hindrance from the
                           controller to whom the personal data was
                           provided, provided that.
                         </span>,
                   "es": <span>
                           Tiene derecho a recibir los datos
                           personales que le conciernen y que haya
                           proporcionado al responsable del
                           tratamiento en un formato estructurado,
                           común y legible por máquina. También tienes
                           derecho a transferir estos datos a otro
                           responsable del tratamiento sin
                           impedimentos por parte del responsable del
                           tratamiento a quien se proporcionaron los
                           datos personales, siempre que así sea.
                         </span>})}
          </p>

          <ol>
            <li>
              {text(language,
                    {"ru": <span>
                             обработка основана на согласии в
                             соответствии со ст. 6(1)(a) GDPR или
                             ст. 9(2)(a) GDPR или по договору в
                             соответствии со ст. 6(1)(b) GDPR и
                           </span>,
                     "en": <span>
                             the processing is based on consent
                             pursuant to Art. 6(1)(a) GDPR or
                             Art. 9(2)(a) GDPR or on a contract
                             pursuant to Art. 6(1)(b) GDPR and
                           </span>,
                     "es": <span>
                             el procesamiento se basa en el
                             consentimiento de conformidad con el
                             art. 6(1)(a) del RGPD o art. 9, apartado
                             2, letra (a) del RGPD o en un contrato
                             según el art. 6(1)(b) RGPD y
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             обработка осуществляется с помощью
                             автоматизированных процедур.
                           </span>,
                     "en": <span>
                             the processing is carried out with the
                             help of automated procedures.
                           </span>,
                     "es": <span>
                             el tratamiento se lleva a cabo con la
                             ayuda de procedimientos automatizados.
                           </span>})}
            </li>
          </ol>

          <p>
            {text(language,
                  {"ru": <span>
                           Осуществляя это право, вы также имеете
                           право на передачу касающихся вас
                           персональных данных непосредственно от
                           одного контролера к другому, если это
                           технически осуществимо. Это не должно
                           затрагивать свободы и права других лиц.
                         </span>,
                   "en": <span>
                           In exercising this right, you also have the
                           right to obtain that the personal data
                           concerning you be transferred directly from
                           one controller to another controller,
                           insofar as this is technically
                           feasible. Freedoms and rights of other
                           persons must not be affected by this.
                         </span>,
                   "es": <span>
                           En el ejercicio de este derecho, también
                           tiene derecho a obtener que los datos
                           personales que le conciernen sean
                           transferidos directamente de un responsable
                           del tratamiento a otro responsable, en la
                           medida en que sea técnicamente
                           posible. Esto no debe afectar a las
                           libertades y derechos de otras personas.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Право на переносимость данных не
                           распространяется на обработку персональных
                           данных, необходимую для выполнения задачи,
                           выполняемой в общественных интересах или
                           при осуществлении официальных полномочий,
                           возложенных на контролера.
                         </span>,
                   "en": <span>
                           The right to data portability does not
                           apply to processing of personal data
                           necessary for the performance of a task
                           carried out in the public interest or in
                           the exercise of official authority vested
                           in the controller.
                         </span>,
                   "es": <span>
                           El derecho a la portabilidad de los datos
                           no se aplica al procesamiento de datos
                           personales necesarios para el desempeño de
                           una tarea realizada en interés público o en
                           el ejercicio de poderes oficiales
                           conferidos al controlador.
                         </span>})}
          </p>

          <p>
            {text(language,
                  {"ru": "7.4.6 Право на возражение",
                   "en": "7.4.6 Right to object",
                   "es": "7.4.6 Derecho a oponerse"})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы имеете право в любое время возразить по
                           причинам, связанным с вашей конкретной
                           ситуацией, против обработки касающихся вас
                           персональных данных, которая осуществляется
                           на основании статьи 6(1)(e) или (f) GDPR;
                           это также относится к профилированию на
                           основе этих положений.
                         </span>,
                   "en": <span>
                           You have the right to object at any time,
                           on grounds relating to your particular
                           situation, to the processing of personal
                           data concerning you which is carried out on
                           the basis of Article 6(1)(e) or (f) GDPR;
                           this also applies to profiling based on
                           these provisions.
                         </span>,
                   "es": <span>
                           Tiene derecho a oponerse en cualquier
                           momento, por motivos relacionados con su
                           situación particular, al procesamiento de
                           sus datos personales que se lleva a cabo
                           sobre la base del artículo 6(1)(e) o (f)
                           del RGPD; Esto también se aplica a la
                           elaboración de perfiles basados ​​en estas
                           disposiciones.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Контролер больше не будет обрабатывать
                           касающиеся вас персональные данные, если он
                           не сможет продемонстрировать убедительные
                           законные основания для обработки, которые
                           имеют приоритет над вашими интересами,
                           правами и свободами, или если обработка не
                           служит цели утверждения, осуществления или
                           защиты юридических претензий.
                         </span>,
                   "en": <span>
                           The controller shall no longer process the
                           personal data concerning you unless it can
                           demonstrate compelling legitimate grounds
                           for the processing which override your
                           interests, rights and freedoms, or the
                           processing serves the purpose of asserting,
                           exercising or defending legal claims.
                         </span>,
                   "es": <span>
                           El responsable del tratamiento ya no
                           procesará los datos personales que le
                           conciernen a menos que pueda demostrar
                           motivos legítimos imperiosos para el
                           procesamiento que prevalezcan sobre sus
                           intereses, derechos y libertades, o que el
                           procesamiento sirva para hacer valer,
                           ejercer o defender reclamaciones legales.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Если касающиеся вас персональные данные
                           обрабатываются в целях прямого маркетинга,
                           вы имеете право в любое время возразить
                           против обработки касающихся вас
                           персональных данных в целях такого
                           маркетинга; это также относится к
                           профилированию, если оно связано с таким
                           прямым маркетингом.
                         </span>,
                   "en": <span>
                           If the personal data concerning you is
                           processed for the purposes of direct
                           marketing, you have the right to object at
                           any time to processing of the personal data
                           concerning you for the purposes of such
                           marketing; this also applies to profiling,
                           insofar as it is related to such direct
                           marketing.
                         </span>,
                   "es": <span>
                           Si los datos personales que le conciernen
                           se procesan con fines de marketing directo,
                           tiene derecho a oponerse en cualquier
                           momento al procesamiento de los datos
                           personales que le conciernen con fines de
                           dicho marketing; Esto también se aplica a
                           la elaboración de perfiles, en la medida en
                           que esté relacionada con dicho marketing
                           directo.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Если вы возражаете против обработки в целях
                           прямого маркетинга, касающиеся вас
                           персональные данные больше не будут
                           обрабатываться для этих целей.
                         </span>,
                   "en": <span>
                           If you object to the processing for direct
                           marketing purposes, the personal data
                           concerning you will no longer be processed
                           for these purposes.
                         </span>,
                   "es": <span>
                           Si se opone al procesamiento con fines de
                           marketing directo, los datos personales que
                           le conciernen ya no se procesarán para
                           estos fines.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           В связи с использованием услуг
                           информационного общества у вас есть
                           возможность, несмотря на Директиву
                           2002/58/EC, реализовать свое право на
                           возражение посредством автоматизированных
                           процедур с использованием технических
                           спецификаций.
                         </span>,
                   "en": <span>
                           You have the possibility, in connection
                           with the use of information society
                           services, notwithstanding Directive
                           2002/58/EC, to exercise your right to
                           object by means of automated procedures
                           using technical specifications.
                         </span>,
                   "es": <span>
                           Tiene la posibilidad, en relación con el
                           uso de servicios de la sociedad de la
                           información, sin perjuicio de la Directiva
                           2002/58/CE, de ejercer su derecho de
                           oposición mediante procedimientos
                           automatizados que utilicen especificaciones
                           técnicas.
                         </span>})}
          </p>

          <p>
            {text(language,
                  {"ru": "7.4.7 Право на отзыв заявления о согласии в"
                   + " соответствии с законом о защите данных",
                   "en": "7.4.7 Right to revoke the declaration of"
                   + " consent under data protection law",
                   "es": "7.4.7 Derecho a revocar la declaración de"
                   + " consentimiento según la ley de protección de"
                   + " datos"})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы имеете право в любое время отозвать свое
                           заявление о согласии в соответствии с
                           законом о защите данных. Отзыв согласия не
                           влияет на законность обработки,
                           осуществляемой на основании согласия до
                           момента отзыва.
                         </span>,
                   "en": <span>
                           You have the right to revoke your
                           declaration of consent under data
                           protection law at any time. The revocation
                           of consent does not affect the lawfulness
                           of the processing carried out on the basis
                           of the consent until the revocation.
                         </span>,
                   "es": <span>
                           Tiene derecho a revocar su declaración de
                           consentimiento según la ley de protección
                           de datos en cualquier momento. La
                           revocación del consentimiento no afecta a
                           la licitud del tratamiento realizado sobre
                           la base del consentimiento hasta la
                           revocación.
                         </span>})}
          </p>

          <p>
            {text(language,
                  {"ru": "7.4.8 Автоматизированное решение в отдельных"
                   + " случаях, включая профилирование",
                   "en": "7.4.8 Automated decision in individual cases"
                   + " including profiling",
                   "es": "7.4.8 Decisión automatizada en casos"
                   + " individuales, incluida la elaboración de"
                   + " perfiles."})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы имеете право не подчиняться решению,
                           основанному исключительно на
                           автоматизированной обработке, включая
                           профилирование, которое влечет за собой
                           правовые последствия или аналогичным
                           образом существенно влияет на вас.
                         </span>,
                   "en": <span>
                           You have the right not to be subject to a
                           decision based solely on automated
                           processing – including profiling – which
                           produces legal effects concerning you or
                           similarly significantly affects you.
                         </span>,
                   "es": <span>
                           Tiene derecho a no estar sujeto a una
                           decisión basada únicamente en un
                           tratamiento automatizado (incluida la
                           elaboración de perfiles) que produzca
                           efectos jurídicos sobre usted o le afecte
                           significativamente de forma similar.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": "Это не применяется, если решение",
                   "en": "This does not apply if the decision",
                   "es": "Esto no se aplica si la decisión"})}
          </p>
          <ol>
            <li>
              {text(language,
                    {"ru": <span>
                             необходимо для заключения или исполнения
                             договора между вами и оператором,
                           </span>,
                     "en": <span>
                             is necessary for the conclusion or
                             performance of a contract between you and
                             the controller,
                           </span>,
                     "es": <span>
                             es necesario para la celebración o
                             ejecución de un contrato entre usted y el
                             responsable del tratamiento
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             разрешено правовыми положениями Союза или
                             государств-членов, которым подчиняется
                             контролер, и эти правовые положения
                             содержат соответствующие меры для защиты
                             ваших прав и свобод, а также ваших
                             законных интересов; или
                           </span>,
                     "en": <span>
                             is permitted by legal provisions of the
                             Union or the Member States to which the
                             controller is subject and these legal
                             provisions contain appropriate measures
                             to protect your rights and freedoms as
                             well as your legitimate interests; or
                           </span>,
                     "es": <span>
                             está permitido por las disposiciones
                             legales de la Unión o de los Estados
                             miembros a los que está sujeto el
                             responsable del tratamiento y estas
                             disposiciones legales contienen medidas
                             adecuadas para proteger sus derechos y
                             libertades, así como sus intereses
                             legítimos; o
                           </span>})}
            </li>
            <li>
              {text(language,
                    {"ru": <span>
                             производится с вашего явного согласия.
                           </span>,
                     "en": <span>
                             is made with your express consent.
                           </span>,
                     "es": <span>
                             se realiza con su expreso consentimiento.
                           </span>})}
            </li>
          </ol>
          <p>
            {text(language,
                  {"ru": <span>
                           Однако эти решения не могут основываться на
                           особых категориях персональных данных в
                           соответствии со статьей 9(1) GDPR, за
                           исключением случаев, когда применяется
                           статья 9(2)(a) или (g) и не приняты
                           соответствующие меры для защиты прав и
                           свободы, а также ваши законные интересы.
                         </span>,
                   "en": <span>
                           However, these decisions may not be based
                           on special categories of personal data
                           pursuant to Article 9(1) of the GDPR,
                           unless Article 9(2)(a) or (g) applies and
                           appropriate measures have been taken to
                           protect the rights and freedoms as well as
                           your legitimate interests.
                         </span>,
                   "es": <span>
                           Sin embargo, estas decisiones no pueden
                           basarse en categorías especiales de datos
                           personales de conformidad con el artículo
                           9, apartado 1, del RGPD, a menos que se
                           aplique el artículo 9, apartado 2, letras
                           (a) o (g), y se hayan tomado las medidas
                           adecuadas para proteger los derechos y
                           libertades, así como sus intereses
                           legítimos.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Что касается случаев, указанных в (1) и
                           (3), контролер должен принять разумные меры
                           для защиты прав и свобод, а также ваших
                           законных интересов, включая, по крайней
                           мере, право на вмешательство лица со
                           стороны контролера, выразить свою точку
                           зрения и оспорить решение.
                         </span>,
                   "en": <span>
                           With regard to the cases referred to in (1)
                           and (3), the controller shall take
                           reasonable steps to safeguard the rights
                           and freedoms as well as your legitimate
                           interests, including at least the right to
                           obtain the intervention of a person on the
                           part of the controller, to express his or
                           her point of view and to contest the
                           decision.
                         </span>,
                   "es": <span>
                           Con respecto a los casos mencionados en (1)
                           y (3), el responsable del tratamiento
                           adoptará medidas razonables para
                           salvaguardar los derechos y libertades, así
                           como sus intereses legítimos, incluido al
                           menos el derecho a obtener la intervención
                           de una persona por parte del responsable
                           del tratamiento, expresar su punto de vista
                           y impugnar la decisión.
                         </span>})}
          </p>

          <p>
            {text(language,
                  {"ru": "7.4.9 Право на подачу жалобы в надзорный орган",
                   "en": "7.4.9 Right to complain to a supervisory authority",
                   "es": "7.4.9 Derecho a presentar una reclamación"
                   + " ante una autoridad supervisora"})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Без ущерба для любого другого
                           административного или судебного средства
                           правовой защиты вы имеете право подать
                           жалобу в надзорный орган, в частности, в
                           государстве-члене вашего проживания, работы
                           или места предполагаемого нарушения, если
                           вы считаете, что обработка личных данных
                           данные, касающиеся вас, нарушают GDPR.
                         </span>,
                   "en": <span>
                           Without prejudice to any other
                           administrative or judicial remedy, you have
                           the right to lodge a complaint with a
                           supervisory authority, in particular in the
                           Member State of your residence, workplace
                           or the place of the alleged infringement,
                           if you consider that the processing of
                           personal data concerning you infringes the
                           GDPR.
                         </span>,
                   "es": <span>
                           Sin perjuicio de cualquier otro recurso
                           administrativo o judicial, tiene derecho a
                           presentar una reclamación ante una
                           autoridad de control, en particular en el
                           Estado miembro de su residencia, lugar de
                           trabajo o el lugar de la supuesta
                           infracción, si considera que el tratamiento
                           de sus datos personales Los datos que le
                           conciernen infringen el RGPD.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Надзорный орган, в который была подана
                           жалоба, должен проинформировать заявителя о
                           статусе и результатах жалобы, включая
                           возможность судебной защиты в соответствии
                           со статьей 78 GDPR.
                         </span>,
                   "en": <span>
                           The supervisory authority to which the
                           complaint has been lodged shall inform the
                           complainant of the status and outcome of
                           the complaint, including the possibility of
                           a judicial remedy under Article 78 GDPR.
                         </span>,
                   "es": <span>
                           La autoridad de control ante la que se haya
                           presentado la reclamación informará al
                           reclamante del estado y el resultado de la
                           reclamación, incluida la posibilidad de un
                           recurso judicial en virtud del artículo 78
                           del RGPD.
                         </span>})}
          </p>
          <p>
            <b>
            {text(language,
                  {"ru": "Февраль 2024",
                   "en": "February 2024",
                   "es": "Febrero 2024"})}
             </b>
          </p>

        </CardContent>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const language = state.language;
  return {
    language
  };
}

export default withStyles(styles)(
  connect(mapStateToProps)(PrivacyPolicy));
