import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PaymentRu from './ru/payment_ru';
import PaymentEs from './es/payment_es';
import { switchCountry } from "./countrySelection";
import { connect } from "react-redux";

const styles = theme => ({
});

class PaymentForm extends React.Component {

  render() {
    let residenceCountry = this.props.residence.value;
    return (<>
              {switchCountry(
                residenceCountry,
                {"spain": <PaymentEs
                            context={this.props.context}
                            parent={this.props.parent}/>,
                 "russia": <PaymentRu
                             context={this.props.context}
                             parent={this.props.parent}/>})}
            </>
           );
  }
}

function mapStateToProps(state) {
  const residence = state.residence;
  return {
    residence,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps)(PaymentForm));
