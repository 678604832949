import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import Center from 'react-center';
import AlertDialog from "./alertDialog";
import axios from 'axios';
import { connect } from "react-redux";
import { text } from "./languageSelection";
import { isMobile } from './common/deviceDetector';
import {range} from './miscLib';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formStyle: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'column',
  },
  cardMobile: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'column',
    marginBottom: '60px'
  },
  buttonBottom: {
    marginTop: '10px',
    float: 'right',
    width: 200
  },
  rightFields: {
    float: 'right',
  },
  leftFields: {
    float: 'left',
    marginTop: '10px'
  },
  loader: {
    padding: '200px 0',
  }
});

class LeaveFeedbackForm extends React.Component {
  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.sendFeedback = this.sendFeedback.bind(this);

    axios.get('/userData')
      .then((response) => {
        this.setState({
          name: response.data.firstName + " " + response.data.lastName,
          email: response.data.email
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  gotoHome() {
    window.location.hash = "";
  }

  sendFeedback() {
    this.setState({ inProgress: true });
    const config = { headers: { 'Content-Type': 'application/json',
                                'X-Requested-With': 'HttpRequest',
                                'Csrf-Token': 'nocheck'},
                     timeout: 0};
    const data = new FormData();
    data.append('userName', this.state['userName']);
    data.append('email', this.state['email']);
    data.append('stars', this.state['stars']);
    data.append('text', this.state['feedbackText']);
    axios.post("/feedback/send", data, config)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({ showFeedbackSentDialog: true });
        } else {
          this.gotoHome();
          this.forceUpdate();
        }
        this.setState({ inProgress: false });
      })
      .catch( (error) => {
        this.gotoHome();
        this.forceUpdate();
        this.setState({ inProgress: false });
      });
  }

  state = {
    inProgress: false,
    userName: '',
    email: '',
    stars: 0,
    feedbackText: '',
    showFeedbackSentDialog: false
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleStarClick = idx => event => {
    this.setState({
      stars: idx,
    });
  };

  render() {
    const { classes } = this.props;
    let language = this.props.language.value;

    return (
      <Center>
        {!this.state.inProgress
         ?
         <Card className={isMobile ? classes.cardMobile : classes.card}>
           <CardContent>
             <Container component="main">
               <div className={classes.paper}>
                 <Typography component="h1" variant="h5">
                   {text(
                     language,
                     {"ru": "Отзыв",
                      "en": "Feedback",
                      "es": "Comentario"})}
                 </Typography>
                 <form className={classes.formStyle} noValidate>
                   <div className={classes.rightFields}>
                     <TextField
                       style= {isMobile
                               ? {width: "100%"}
                               : {}}
                       variant="outlined"
                       margin="normal"
                       id="name"
                       label={text(language,
                                   {"ru": "Имя",
                                    "en": "Name",
                                    "es": "Nombre"})}
                       onChange={this.handleChange('userName')}
                       value={this.state.userName}/>
                     <TextField
                       style= {isMobile
                               ? {width: "100%"}
                               : {marginLeft: "8px"}}
                       variant="outlined"
                       margin="normal"
                       id="email"
                       label={text(language,
                                   {"ru": "Email",
                                    "en": "Email",
                                    "es": "Email"})}
                       onChange={this.handleChange('email')}
                       value={this.state.email}/>
                   </div>
                   <div className={classes.leftFields}>
                     <Typography variant="subtitle1">
                       {text(language,
                             {"ru": "Общее впечатление",
                              "en": "General impression",
                              "es": "Impresión general"})}:
                     </Typography>
                     {range(1, this.state.stars).map(s =>
                       <StarIcon
                         style={{ fontSize: 32 }}
                         onClick={this.handleStarClick(s)}/>
                     )}
                     {range(this.state.stars+1, 5).map(s =>
                       <StarOutlineIcon
                         style={{ fontSize: 32 }}
                         onClick={this.handleStarClick(s)}/>
                     )}
                   </div>
                   <TextField
                     style = {{width: "100%"}}
                     multiline
                     rows={20}
                     inputProps={{ maxLength: 3072 }}
                     variant="outlined"
                     margin="normal"
                     onChange={this.handleChange('feedbackText')}
                     value={this.state.feedbackText}/>
                   <Button
                     className={classes.buttonBottom}
                     onClick={this.sendFeedback}
                     variant='contained'
                     color="primary">
                     {text(language,
                           {"ru": "Отправить",
                            "en": "Send",
                            "es": "Enviar"})}
                   </Button>
                 </form>
               </div>
             </Container>
           </CardContent>
         </Card>
         : <div className={classes.loader}>
             <CircularProgress size={90} />
           </div>
        }
        <AlertDialog
          open={this.state.showFeedbackSentDialog}
          propertyName='showFeedbackSentDialog'
          parent={this}
          title={text(language,
                      {"ru": "Отзыв отправлен",
                       "en": "Feedback sent",
                       "es": "Comentarios enviados"})}
          message={text(language,
                        {"ru": "Спасибо",
                         "en": "Thank you",
                         "es": "Gracias"})}
          handler={this.gotoHome}/>
      </Center>
    );
  }
}

function mapStateToProps(state) {
  const language = state.language;
  return {
    language,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps)(LeaveFeedbackForm));
