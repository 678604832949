
export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

export function isEmpty(str) {
    return (!str || str.length === 0 );
}

export const range = (start, stop, step=1) =>
      Array.from({ length: (stop - start) / step + 1}, (_, i) =>
        start + (i * step));
