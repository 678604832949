import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ImageZoom from 'react-medium-image-zoom';
import Link from '@material-ui/core/Link';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    arrow: {
        marginBottom: '-6px'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    },
    imgStyle: {
        height: '300px'
    },
    imgStyleMobile: {
        width: '100%'
    }
});

class HelpRevolutReport extends React.Component {
    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>
                <div className={classes.text}>
                  <br/>
                  <HelpHashLink to="/help/RevolutReport#1">
                    <Typography id="1" variant="h5" component="h2">
                      {text(language,
                            {"ru": "Алгоритм получения отчета Revolut через браузер",
                             "en": "Instructions for getting Revolut report via browser",
                             "es": "Instrucciones para obtener el informe de Revolut a través del navegador"})}
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  {text(language,
                        {"ru": "Входите через браузер на компьютере",
                         "en": "Log in with a browser on your computer",
                         "es": "Inicie sesión con un navegador en su computadora"})}
                  &nbsp;
                  <Link href="https://www.revolut.com"
                        className={classes.link}
                        target="_blank">
                    revolut.com
                  </Link>.&nbsp;
                  {text(language,
                        {"ru": "Проходите авторизацию. Выбираете вкладку Stocks.",
                         "en": "Get authorized. Choose the Stocks tab.",
                         "es": "Obtener autorización. Elija la pestaña Stocks."})}
                  <br/>
                  <ImageZoom
                    image={{
                        src: '../images/revolut/revolut_create_statement1.png',
                        alt: '',
                        className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                    }}
                    zoomImage={{
                        src: '../images/revolut/revolut_create_statement1.png',
                        alt: ''
                    }}/>
                  <br/>
                  {text(language,
                        {"ru": "Нажимаете Statements. Выбираете Excel и указываете период за год.",
                         "en": "Click Statements. Choose Excel and specify the year as a period.",
                         "es": "Haga clic en Estados de cuenta. Elija Excel y especifique el período del año."})}
                  <br/>
                  <ImageZoom
                    image={{
                        src: '../images/revolut/revolut_create_statement2.png',
                        alt: '',
                        className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                    }}
                    zoomImage={{
                        src: '../images/revolut/revolut_create_statement2.png',
                        alt: ''
                    }}/>
                  <br/>
                  {text(language,
                        {"ru": "Нажимаете Generate и скачиваете полученный файл.",
                         "en": "Click Generate and download the resulting file.",
                         "es": "Haga clic en Generate y descargue el archivo resultante."})}
                  <p/>

                  <HelpHashLink to="/help/RevolutReport#2">
                    <Typography id="1" variant="h5" component="h2">
                      {text(language,
                            {"ru": "Алгоритм получения отчета Revolut через приложение в телефоне",
                             "en": "Instructions for obtaining a Revolut report through the application on the phone",
                             "es": "Instrucciones para obtener un informe Revolut a través de la aplicación en el teléfono"})}
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  {text(language,
                        {"ru": "Входите в приложение банка на телефоне. Выбираете вкладку Stocks.",
                         "en": "Log in to the bank app on your phone. Select the Stocks tab.",
                         "es": "Inicie sesión en la aplicación del banco en su teléfono. Seleccione la pestaña Acciones."})}
                  <br/>
                  <ImageZoom
                    image={{
                        src: '../images/revolut/revolut_phone1.jpg',
                        alt: '',
                        className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                    }}
                    zoomImage={{
                        src: '../images/revolut/revolut_phone1.jpg',
                        alt: ''
                    }}/>
                  <br/>
                  {text(language,
                        {"ru": "Далее:",
                         "en": "Than:",
                         "es": "Más:"})}
                  More
                  <ArrowForwardIcon className={classes.arrow}
                                    fontSize="small"/>
                  Statements
                  <ArrowForwardIcon className={classes.arrow}
                                    fontSize="small"/>
                  Account statement.
                  <br/>
                  <ImageZoom
                    image={{
                        src: '../images/revolut/revolut_phone2.jpg',
                        alt: '',
                        className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                    }}
                    zoomImage={{
                        src: '../images/revolut/revolut_phone2.jpg',
                        alt: ''
                    }}/>
                  <br/>
                  <ImageZoom
                    image={{
                        src: '../images/revolut/revolut_phone3.jpg',
                        alt: '',
                        className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                    }}
                    zoomImage={{
                        src: '../images/revolut/revolut_phone3.jpg',
                        alt: ''
                    }}/>
                  <br/>
                  {text(language,
                        {"ru": "Выбираете Excel и указываете период за год.",
                         "en": "Choose Excel and specify the year as a period.",
                         "es": "Elija Excel y especifique el período del año."})}
                  <br/>
                  <ImageZoom
                    image={{
                        src: '../images/revolut/revolut_phone4.jpg',
                        alt: '',
                        className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                    }}
                    zoomImage={{
                        src: '../images/revolut/revolut_phone4.jpg',
                        alt: ''
                    }}/>
                  <br/>
                  {text(language,
                        {"ru": "Нажимаете Get statement и скачиваете полученный файл.",
                         "en": "Click Get statement and download the resulting file",
                         "es": "Haga clic en Get statement de cuenta y descargue el archivo resultante"})}
                </div>
              </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpRevolutReport));
