import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ImageZoom from 'react-medium-image-zoom';
import Link from '@material-ui/core/Link';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';
import { Red } from '../colors';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    arrow: {
        marginBottom: '-6px'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    },
    imgStyle: {
        height: '300px'
    },
    imgStyleMobile: {
        width: '100%'
    }
});

class HelpCharlesSchwabReport extends React.Component {
    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>
                <div className={classes.text}>
                  <br/>
                  <HelpHashLink to="/help/CharlesSchwabReport#1">
                    <Typography id="1" variant="h5" component="h2">
                      {text(language,
                            {"ru": "Алгоритм получения отчета Charles Schwab",
                             "en": "Instructions for getting Charles Schwab report",
                             "es": "Instrucciones para obtener el informe de Charles Schwab"})}
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  <ol>
                    <li>
                      {text(language,
                            {"ru": "Перейдите на сайт",
                             "en": "Go to the website",
                             "es": "Ir al sitio web"})}&ensp;
                      <Link href="https://www.schwab.com/"
                            className={classes.link}
                            target="_blank">
                        schwab.com
                      </Link>.
                      <br/>
                      <ImageZoom
                        image={{
                          src: '../images/charlesSchwab/charlesSchwab_report1.png',
                          alt: '',
                          className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                        }}
                        zoomImage={{
                          src: '../images/charlesSchwab/charlesSchwab_report1.png',
                          alt: ''
                        }}>
                      </ImageZoom>
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Нажмите <Red>Log In</Red>, чтобы войти в
                                     свою учетную запись.
                                   </span>,
                             "en": <span>
                                     Click <Red>Log In</Red> to log into your
                                     account.
                                   </span>,
                             "es": <span>
                                     Haga clic en <Red>Log In</Red> para
                                     iniciar sesión en su cuenta.
                                   </span>})}
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Нажмите вкладку <Red>Accounts</Red> в
                                     левом верхнем углу.
                                   </span>,
                             "en": <span>
                                     Click <Red>Accounts</Red>&ensp;tab in the
                                     upper left corner.
                                   </span>,
                             "es": <span>Haga clic en la pestaña
                                     <Red>Accounts</Red> en la esquina
                                     superior izquierda.
                                   </span>})}
                      <br/>
                      <ImageZoom
                        image={{
                          src: '../images/charlesSchwab/charlesSchwab_report2.png',
                          alt: '',
                          className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                        }}
                        zoomImage={{
                          src: '../images/charlesSchwab/charlesSchwab_report2.png',
                          alt: ''
                        }}>
                      </ImageZoom>
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Когда откроется вкладка Accounts,
                                     щелкните элемент подменю&ensp;
                                     <Red>History</Red>, затем перейдите на
                                     вкладку <Red>Transactions</Red>.
                                   </span>,
                             "en": <span>
                                     When the Accounts tab is displaying,
                                     click <Red>History</Red> submenu item,
                                     then click the <Red>Transactions</Red>
                                     tab.
                                   </span>,
                             "es": <span>
                                     Cuando se muestre la pestaña Accounts,
                                     haga clic en el elemento del submenú&ensp;
                                     <Red>History</Red> y luego haga clic en
                                     la pestaña <Red>Transactions</Red>.
                                   </span>})}
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Выберите диапазон дат, в котором будут
                                     показаны транзакции, произошедшие в
                                     соответствующем налоговом году.
                                     (например, <Red>Previous Year</Red>).
                                   </span>,
                             "en": <span>
                                     Select a date range that will show
                                     transactions that occurred in the
                                     appropriate tax year
                                     (e.g. <Red>Previous Year</Red>).
                                   </span>,
                             "es": <span>
                                     Seleccione un rango de fechas que
                                     mostrará las transacciones que ocurrieron
                                     en el año fiscal correspondiente
                                     (por ejemplo, <Red>Previous Year</Red>).
                                   </span>})}
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Нажмите ссылку <Red>Export</Red> в правом
                                     верхнем углу.
                                   </span>,
                             "en": <span>
                                     Click the <Red>Export</Red> link in the
                                     upper right.
                                   </span>,
                             "es": <span>
                                     Haga clic en el enlace <Red>Export</Red>
                                     &ensp;en la esquina superior derecha.
                                   </span>})}
                      <br/>
                      <ImageZoom
                        image={{
                          src: '../images/charlesSchwab/charlesSchwab_report3.png',
                          alt: '',
                          className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                        }}
                        zoomImage={{
                          src: '../images/charlesSchwab/charlesSchwab_report3.png',
                          alt: ''
                        }}>
                      </ImageZoom>
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Выберите <Red>CSV</Red> в качестве&ensp;
                                     <Red>Export as type</Red> и сохраните
                                     данный файл.
                                   </span>,
                             "en": <span>
                                     Select <Red>CSV</Red> as&ensp;
                                     <Red>Export as type</Red> and save this
                                     file.
                                   </span>,
                             "es": <span>
                                     Seleccione <Red>CSV</Red> como&ensp;
                                     <Red>Export as type</Red> y guarde este
                                     archivo.
                                   </span>})}
                    </li>
                  </ol>
                </div>
              </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpCharlesSchwabReport));

