import React, { useState } from 'react';

import ReactFlow, {
  removeElements,
  addEdge,
} from 'react-flow-renderer';

import LabelEdge from './labelEdge';
import EmptyNode from './emptyNode';
import AllSidesNode from './allSidesNode';
const edgeTypes = {
  labelEdge: LabelEdge,
};
const nodeTypes = {
  emptyNode: EmptyNode,
  allSidesNode: AllSidesNode,
};

const onLoad = (reactFlowInstance) => {
  requestAnimationFrame(() => {
    reactFlowInstance.fitView();
  });
};

const FlowGraphEs = ({data}) => {
  const [elements, setElements] = useState(data);
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));
  const onConnect = (params) => setElements((els) => addEdge(params, els));

  return (
    <ReactFlow
      elements={elements}
      onElementsRemove={onElementsRemove}
      onConnect={onConnect}
      onLoad={onLoad}
      snapToGrid={true}
      snapGrid={[15, 15]}
      maxZoom={1.4}
      minZoom={1.4}
      preventScrolling={false}
      nodesDraggable={false}
      paneMoveable={false}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
    >
    </ReactFlow>
  );
};

export default FlowGraphEs;
