import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ImageZoom from 'react-medium-image-zoom';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';
import { text } from "../languageSelection";
import { connect } from "react-redux";
import { isMobile } from '../common/deviceDetector';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    arrow: {
        marginBottom: '-6px'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    },
    olMobile: {
        paddingInlineStart: "14px"
    },
    imgStyle: {
        width: '700px'
    },
    imgStyleMobile: {
        width: '100%'
    },
});

class HelpTradeStationReport extends React.Component {

  scrollTo = (elementName) => (event) => {
    if (event != null) {
      event.preventDefault();
    }
    var element = document.getElementById(elementName);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
    this.forceUpdate();
  }

    render() {
        const { classes } = this.props;
        let language = this.props.language.value;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>
                <div className={classes.text}>
                  <HelpHashLink to="/help/TradeStationReport#TradeStationReport_1">
                    <Typography variant="h5" component="h2"
                                id="TradeStationReport_1">
                      {text(language,
                            {"ru": "Алгоритм получения отчета TradeStation",
                             "en": "How to get TradeStation report",
                             "es": "Cómo obtener el informe de TradeStation"})}
                    </Typography>
                  </HelpHashLink>
                  <br/>
                  <h3>{text(language,
                            {"ru": "Оглавление",
                             "en": "Table of contents",
                             "es": "Tabla de contenido"})}</h3>
                  <ul>
                    <li>
                      <Link href="#" component={NavLink}
                            to="/help/TradeStationReport#TradeStationReport_2"
                            onClick={this.scrollTo("TradeStationReport_2")}>
                        {text(language,
                              {"ru": "Общие действия",
                               "en": "General actions",
                               "es": "Acciones generales"})}
                      </Link>
                    </li>
                    <li><Link href="#" component={NavLink}
                              to="/help/TradeStationReport#TradeStationReport_3"
                              onClick={this.scrollTo("TradeStationReport_3")} >
                          {text(language,
                                {"ru": "Отчет по сделкам с ценными бумагами и дивидендами",
                                 "en": "Report on transactions with securities and dividends",
                                 "es": "Informe sobre transacciones con valores y dividendos."})}
                        </Link></li>
                    <li><Link href="#" component={NavLink}
                              to="/help/TradeStationReport#TradeStationReport_4"
                              onClick={this.scrollTo("TradeStationReport_4")} >
                          {text(language,
                                {"ru": "Отчет по фьючерсам",
                                 "en": "Futures report",
                                 "es": "Informe de futuros"})}
                        </Link></li>
                  </ul>
                  <br/>
                  <HelpHashLink to="/help/TradeStationReport#TradeStationReport_2">
                    <Typography variant="h6" component="h3"
                                id="TradeStationReport_2">
                      {text(language,
                            {"ru": "Общие действия",
                             "en": "General actions",
                             "es": "Acciones generales"})}
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  <ul className={isMobile ? classes.olMobile : ""}>
                    <li>
                      {text(language,
                            {"ru": "Входите на сайт брокера через браузер",
                             "en": "Log in to the broker's website through a browser",
                             "es": "Inicie sesión en el sitio web del bróker a través de un navegador"})}
                      &ensp;(<Link href="https://www.tradestation.com/"
                                   className={classes.link}
                                   target="_blank">
                               tradestation.com
                             </Link>),
                      Log In
                      <ArrowForwardIcon className={classes.arrow}
                                        fontSize="small"/>
                      Client Center
                      <ArrowForwardIcon className={classes.arrow}
                                        fontSize="small"/>
                      Login.
                      <br/>
                      <ImageZoom
                        image={{
                            src: '../images/tradeStation/tradeStation_create_statement1.png',
                            alt: '',
                            className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                        }}
                        zoomImage={{
                            src: '../images/tradeStation/tradeStation_create_statement1.png',
                            alt: ''
                        }}/>
                      <br/>
                    </li>
                    <li>
                      {text(language,
                            {"ru": "Далее выбираете Accounts",
                             "en": "Next select Accounts",
                             "es": "A continuación, seleccione Accounts"})}
                      <br/>
                      <ImageZoom
                        image={{
                            src: '../images/tradeStation/tradeStation_create_statement2.png',
                            alt: '',
                            className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                        }}
                        zoomImage={{
                            src: '../images/tradeStation/tradeStation_create_statement2.png',
                            alt: ''
                        }}/>
                      <br/>
                    </li>
                  </ul>
                  <HelpHashLink to="/help/TradeStationReport#TradeStationReport_3">
                    <Typography variant="h6" component="h3"
                                id="TradeStationReport_3">
                      {text(language,
                            {"ru": "Отчет по сделкам с ценными бумагами и дивидендами",
                             "en": "Report on transactions with securities and dividends",
                             "es": "Informe sobre transacciones con valores y dividendos."})}
                    </Typography>
                  </HelpHashLink>
                  <ul>
                    <li>
                      {text(language,
                            {"ru": "Далее - Equities Accounts",
                             "en": "Next - Equities Accounts",
                             "es": "Más - Equities Accounts"})}
                      <br/>
                      <ImageZoom
                        image={{
                            src: '../images/tradeStation/tradeStation_create_statement3.png',
                            alt: '',
                            className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                        }}
                        zoomImage={{
                            src: '../images/tradeStation/tradeStation_create_statement3.png',
                            alt: ''
                        }}/>
                      <br/>
                    </li>
                    <li>
                      {text(language,
                            {"ru": "Выберите Trades & Other Transactions",
                             "en": "Select - Trades & Other Transactions",
                             "es": "Seleccionar - Trades & Other Transactions"})}.
                      <br/>
                      {text(language,
                            {"ru": "Введите диапазон дат и выберите формат Microsoft Excel",
                             "en": "Provide dates range and select Microsoft Excel format",
                             "es": "Ingrese el rango de fechas y seleccione el formato de Microsoft Excel"})}.
                      <br/>
                      <ImageZoom
                        image={{
                            src: '../images/tradeStation/tradeStation_create_statement4.png',
                            alt: '',
                            className: isMobile
                              ? classes.imgStyleMobile
                              : classes.imgStyle
                        }}
                        zoomImage={{
                            src: '../images/tradeStation/tradeStation_create_statement4.png',
                            alt: ''
                        }}/>
                      <br/>
                      {text(language,
                            {"ru": "Нажмите View / Download",
                             "en": "Click View / Download",
                             "es": "Hacer clic View / Download"})}.
                    </li>
                  </ul>
                  <HelpHashLink to="/help/TradeStationReport#TradeStationReport_4">
                    <Typography variant="h6" component="h3"
                                id="TradeStationReport_4">
                      {text(language,
                            {"ru": "Отчет по фьючерсам",
                             "en": "Futures report",
                             "es": "Informe de futuros"})}
                    </Typography>
                  </HelpHashLink>
                  <ul className={isMobile ? classes.olMobile : ""}>
                    <li>
                      Futures Accounts
                      <br/>
                      <ImageZoom
                        image={{
                          src: '../images/tradeStation/tradeStation_create_futures1.png',
                          alt: '',
                          className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                        }}
                        zoomImage={{
                          src: '../images/tradeStation/tradeStation_create_futures1.png',
                          alt: ''}}>
                      </ImageZoom>
                    </li>
                    <li>
                      Trades & Other Transactions
                      <br/>
                      <ImageZoom
                        image={{
                          src: '../images/tradeStation/tradeStation_create_futures2.png',
                          alt: '',
                          className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                        }}
                        zoomImage={{
                          src: '../images/tradeStation/tradeStation_create_futures2.png',
                          alt: ''}}>
                      </ImageZoom>
                    </li>
                    <li>
                      Transaction type
                      <ArrowForwardIcon className={classes.arrow}
                                        fontSize="small"/>
                      Transactions
                      <br/>
                      <ImageZoom
                        image={{
                          src: '../images/tradeStation/tradeStation_create_futures3.png',
                          alt: '',
                          className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                        }}
                        zoomImage={{
                          src: '../images/tradeStation/tradeStation_create_futures3.png',
                          alt: ''}}>
                      </ImageZoom>
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Скачайте требуемое количество
                                     отчетов за весь год с учетом
                                     того, что диапазон дат каждого
                                     отчета не может превышать 3
                                     месяца.
                                   </span>,
                             "en": <span>
                                     Download the required number of
                                     reports for the entire year,
                                     keeping in mind that the date
                                     range of each report cannot
                                     exceed 3 months.
                                   </span>,
                             "es": <span>
                                     Guarde las opciones de selección
                                     que desee con nosotros, pero no
                                     podrá hacerlo antes de 3 meses.
                                   </span>})}
                      <br/>
                      <ImageZoom
                        image={{
                          src: '../images/tradeStation/tradeStation_create_futures4.png',
                          alt: '',
                          className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                        }}
                        zoomImage={{
                          src: '../images/tradeStation/tradeStation_create_futures4.png',
                          alt: ''}}>
                      </ImageZoom>
                    </li>
                    <li>
                      Transaction type
                      <ArrowForwardIcon className={classes.arrow}
                                        fontSize="small"/>
                      Trade Blotter
                      <br/>
                      <ImageZoom
                        image={{
                          src: '../images/tradeStation/tradeStation_create_futures5.png',
                          alt: '',
                          className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                        }}
                        zoomImage={{
                          src: '../images/tradeStation/tradeStation_create_futures5.png',
                          alt: ''}}>
                      </ImageZoom>
                    </li>
                    <li>
                      {text(language,
                            {"ru": <span>
                                     Аналогично предыдущему, скачайте
                                     требуемое количество отчетов за весь год
                                     с учетом того, что диапазон дат каждого
                                     отчета не может превышать 3 месяца.
                                   </span>,
                             "en": <span>
                                     Similar to the previous one, download the
                                     required number of reports for the entire
                                     year, taking into account that the date
                                     range of each report cannot exceed 3
                                     months.
                                   </span>,
                             "es": <span>
                                     Al igual que el anterior, descarga la
                                     cantidad requerida de informes para todo
                                     el año, teniendo en cuenta que el rango
                                     de fechas de cada informe no puede
                                     exceder los 3 meses.
                                   </span>})}
                      <br/>
                      <ImageZoom
                        image={{
                          src: '../images/tradeStation/tradeStation_create_futures6.png',
                          alt: '',
                          className: isMobile
                            ? classes.imgStyleMobile
                            : classes.imgStyle
                        }}
                        zoomImage={{
                          src: '../images/tradeStation/tradeStation_create_futures6.png',
                          alt: ''}}>
                      </ImageZoom>
                    </li>
                  </ul>
                </div>
              </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
     const language = state.language;
     return {
         language,
     };
}

export default withStyles(styles)(
    connect(mapStateToProps)(HelpTradeStationReport));
