import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Center from 'react-center';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import PhoneIcon from '@material-ui/icons/Phone';
import AlertDialog from './alertDialog';

const styles = {
    table: {
        minWidth: 650,
    },
    loader: {
        padding: '200px 0',
    }
};

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

class AdminConsoleUsers extends React.Component {
    constructor(props) {
        super(props);
        this.refreshUserList();
    }
    
    state = {
        rows: null,
        inProgress: false,
        userName: '',
        userId: null,
        showAlertDialog: false,
        alertTitle: '',
        alertText: ''
    };

    refreshUserList = () => {
        axios.get('/api/users')
        .then((response) => {
            this.setState({
                rows: response.data
            }, () => {
                this.setState({ inProgress: false });
                this.forceUpdate();
            });
        })
        .catch((error) => {
            this.setState({ inProgress: false });
            console.log(error);
        });
    }

    formatIsActive(isActive) {
        return (isActive ? "✔" : "");
    }

    render() {
        const { classes } = this.props;
        
        return (
           <Center>
                <table>
                    <tr><td>
                    </td></tr>
                    <tr><td>
                        {(this.state.rows == null || this.state.inProgress)
                        ? <div className={classes.loader}>
                            <CircularProgress size={90} />
                          </div>
                        : <Table className={classes.table} 
                                 size="small" 
                                 aria-label="Пользователи">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Логин</TableCell>
                                    <TableCell>Имя</TableCell>
                                    <TableCell>Фамилия</TableCell>
                                    <TableCell>Отчество</TableCell>
                                    <TableCell>Почта</TableCell>
                                    <TableCell>Дата регистрации</TableCell>
                                    <TableCell>Активирован</TableCell>
                                    <TableCell>Денег</TableCell>
                                    <TableCell>Бонусов</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {(this.state.rows.length > 0) 
                            ? this.state.rows.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <TableCell>{row.login}</TableCell>                        
                                    <TableCell>{row.firstName}</TableCell>                        
                                    <TableCell>{row.lastName}</TableCell>                        
                                    <TableCell>{row.middleName}</TableCell>                        
                                    <TableCell>{row.email}</TableCell>                        
                                    <TableCell>{row.registerDate}</TableCell>                        
                                    <TableCell>{this.formatIsActive(row.isActive)}</TableCell>                        
                                    <TableCell>
                                      {row.balanceList.map(
                                        b => b.amount + " " + b.currencySymbol + "\n"
                                      )}
                                    </TableCell>
                                    <TableCell>{row.bonus}</TableCell>
                                </StyledTableRow>
                            ))
                            : ""}
                            </TableBody>
                          </Table>}
                        </td></tr>
                  </table>
                  <AlertDialog
                     open={this.state.showAlertDialog}
                     propertyName='showAlertDialog'
                     parent={this}
                     title={this.state.alertTitle}
                     message={this.state.alertText}/>
            </Center>
        );
    }
}

export default withStyles(styles)(AdminConsoleUsers);
