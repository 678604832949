// -*- mode: rjsx-mode; js2-basic-offset: 4; -*-
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import IconButton from '@material-ui/core/IconButton';
import { Input, InputLabel, InputAdornment } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { isChrome } from './browserDetectionLib';

const styles = {
    uploadField: {
        width: 200,
        cursor: 'pointer',
    },
    uploadInner: {
        display: 'flex',
        flexWrap: 'wrap',
        height: '100%',
        cursor: 'pointer',
        top: 0,
        right: 0,
        opacity: 0,
        position: 'absolute',
        zIndex: 4,
    },
    uploadButton: {
        display: 'inline-flex',
        boxSizing: 'border-box',
        marginBottom: '-8px',
        border: 0,
        verticalAlign: 'middle',
        justifyContent: 'center',
        textAlign: 'center',
        flex: '0 0 auto',
        minWidth: 1,
        width: 48,
        height: 48,
        padding: 0,
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
            '&$disabled': {
                backgroundColor: 'transparent',
            },
        },
        label: {
            width: '100%',
            display: 'flex',
            alignItems: 'inherit',
            justifyContent: 'inherit',
        },
    }
};

class Upload extends React.Component {
    state = {
        fileName: this.props.dataFromParent || '',
    }

    handleChange = name => event => {
        if (event.target.files.length > 1) {
            this.props.handleMultipleUpload(event);
        }
        this.setState({
            [name]: event.target.value,
        });
        if (this.props.handleChange != null) {
            this.props.handleChange(event);
        } else {
            this.props.parent.setState({
                [this.props.propertyName]: {
                    name: event.target.files[0].name,
                    file: event.target.files[0]
                }
            });
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <FormControl className={classes.uploadField}>
              <InputLabel
                error={this.props.error != null
                       ? this.props.error
                       : false}>
                {this.props.label}
              </InputLabel>
              <Input
                type='text'
                error={this.props.error != null
                       ? this.props.error
                       : false}
                readOnly={true}
                value={this.state.fileName.split('\\').pop()}
                endAdornment= {isChrome() 
                    ?      <InputAdornment position="end">
                              <IconButton
                                 variant="fab"
                                 aria-label="edit"
                                 className={classes.button}>
                                 <AttachFileIcon/>
                                 <input
                                    type='file'
                                    name="files[]" multiple directory webkitdirectory
                                    accept={this.props.accept}
                                    className={classes.uploadInner}
                                    onChange={this.handleChange('fileName')}/>
                              </IconButton>
                           </InputAdornment>
                    :      <InputAdornment
                              position="end"
                              className={classes.uploadButton}>
                              <AttachFileIcon/>
                              <input
                                 type='file'
                                 name="files[]" multiple directory webkitdirectory
                                 accept={this.props.accept}
                                 className={classes.uploadInner}
                                 onChange={this.handleChange('fileName')}/>
                           </InputAdornment>
                }>
              </Input>
              {this.props.helperText != null
               ? <FormHelperText
                   error={this.props.error != null
                          ? this.props.error
                          : false}>
                   {this.props.helperText}
                 </FormHelperText>
               : ''}
            </FormControl>
        );
    }
}

export default withStyles(styles)(Upload);
