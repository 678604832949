import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Red, Teal} from '../colors';
import HelpToContentsLink from './helpToContentsLink';
import HelpHashLink from './helpHashLink';

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    }
});

class HelpGenericRF extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>
                <div className={classes.text}>
                  <HelpHashLink to="/help/GenericRF#1" >
                    <Typography id="1" variant="h5" component="h2">
                      Кто должен подавать налоговую декларацию
                    </Typography>
                  </HelpHashLink>
                  <br/>
                  Налоговый агент – это лицо, которое обязано
                  исчислить, удержать и уплатить налог.
                  Инвестирование осуществляется через различные финансовые
                  компании. Данные компании являются посредниками.
                  Такими посредниками могут быть:
                  <ul>
                    <li>Банки</li>
                    <li>Брокеры</li>
                    <li>Страховые компании</li>
                  </ul>
                  Кроме того, посредник может быть:
                  <ul>
                    <li>Российским</li>
                    <li>Зарубежным</li>
                  </ul>
                  Российские посредники являются налоговыми агентами РФ, то есть
                  сами исчисляют, удерживают налоги у налогоплательщика и
                  перечисляют их в бюджет (хотя налог от прибыли от получения
                  дивидендов по акциям и купонам по облигациям зарубежных
                  компаний, приобретенных через российского посредника должен
                  быть задекларирован и уплачен инвестором самостоятельно).
                  <p/>
                  Для всех случаев получения прибыли при инвестировании через
                  зарубежных посредников налоговым агентом является сам инвестор.
                  <p/>
                  <HelpHashLink to="/help/GenericRF#2" >
                    <Typography id="2" variant="h5" component="h2">
                      Действия инвестора
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  При инвестировании через зарубежного брокера необходимо:
                  <ul>
                    <li>Направить в налоговую <Red>уведомление об
                      открытии брокерского счета за рубежом</Red> в течение&ensp;
                      <Teal>1 месяца</Teal> после
                      открытия (аналогично и при закрытии счета).
                      Форму уведомления об открытии
                      зарубежного брокерского счета можно найти в&ensp;
                      Приложении 1&ensp;
                      <Link href="https://www.nalog.ru/rn77/related_activities/accounting/bank_account/9868652/"
                          className={classes.link}
                          target="_blank">
                      на сайте налоговой инспекции</Link>, либо бесплатно 
                      сгенерировать с помощью FinParser
                    </li>
                    <li>Предоставлять&ensp;
                      <Red>отчет о движении денежных средств</Red>
                      &ensp;по брокерским счетам в срок не позднее&ensp;
                      <Teal>1 июня</Teal>&ensp; года, следующего за отчетным.
                      При закрытии счета уведомление об закрытии счета податеся 
                      в течение месяца, как обычно, а отчет о движении денежных 
                      одновременно с уведомлением.&ensp;
                      <Link href="https://www.nalog.ru/html/sites/www.new.nalog.ru/docs/forms/knd1112520fl.pdf"
                          className={classes.link}
                          target="_blank">
                      Шаблон документа
                      </Link>, также можно сгенерировать с помощью FinParser</li>
                    <li>В срок <Teal>с 1 января до 30 апреля</Teal>
                      &ensp;подать&ensp;<Red>налоговую декларацию</Red>
                      &ensp;3-НДФЛ (до 30 апреля важно подать первый вариант
                      3-НДФЛ, после вы можете подавать корректировки к декларации)
                      и оплатить налоги до&ensp;<Teal>15 июля</Teal></li>
                  </ul>

                  <HelpHashLink to="/help/GenericRF#3">
                    <Typography id="3" variant="h5" component="h2">
                      Общие правила
                    </Typography>
                  </HelpHashLink>
                  <ul>
                    <li>Налог на доходы физических лиц (НДФЛ) составляет&ensp;
                      <b>13%</b>. C 2021 года&ensp;
                      <b>13%</b> для доходов до 5 000 000 руб.
                      и <b>15%</b> для доходов сыше 5 000 000
                      &ensp;руб.</li>
                    <li>Можно осуществлять сальдирование по
                      прибылям/убыткам прошлых периодов с горизонтом 10 лет и 
                      по различным брокерским/банковским счетам</li>
                    <li>По бумагам, обращающимся на российском фондовом рынке при
                      владении более 3-х лет можно не платить налоги
                      с прибыли при продаже</li>
                    <li>Доходы от операций с ценными бумагами можно уменьшить на
                      суммы налоговых вычетов</li>
                    <li>Кроме того, доходы от операций с ценными бумагами можно
                      уменьшить на суммы&ensp;
                      расходов, связанных с приобретением ценных бумаг,
                        переводом денежных средств, и других комиссий</li>
                    <li>Ни один из налоговых вычетов не применяются в
                      отношении доходов в виде дивидендов</li>
                  </ul>

                  <HelpHashLink to="/help/GenericRF#4">
                    <Typography id="4" variant="h5" component="h2">
                      Налогообложение резидентов РФ при инвестировании через
                      зарубежного посредника
                    </Typography>
                  </HelpHashLink>
                  <ul>
                    <li>Декларация подается&ensp;
                      по факту поступления на счет какого-либо дохода
                      (независимо от того был ли вывод средств)</li>
                    <li>Необходимо учитывать курсовую разницу при
                      покупке/продаже</li>
                    <li>При покупке/продаже расчет происходит согласно
                      &ensp;<b>FIFO</b> (первая купленная бумага
                      считается первой проданной)</li>
                    <li>Необходимо доплачивать
                      &ensp;разницу по налогам с дивидендов&ensp;
                      между уже удержанным налогом в юрисдикции посредника и
                      ставкой НДФЛ в России</li>
                    <li>Учитывается соглашение об избежании двойного
                      налогообложения</li>
                  </ul>

                  <HelpHashLink to="/help/GenericRF#5">
                    <Typography id="5" variant="h5" component="h2">
                      Заполнение и подача декларации
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  Декларация может подаваться:
                  <ul>
                    <li>В бумажном виде (перед печатью подготавливается на основе
                      шаблона <b>PDF</b> файла) лично в отделении ФНС или
                      почтой</li>
                    <li>Можно заполнить онлайн на сайте <Link href="https://www.nalog.ru"
                          className={classes.link}
                          target="_blank">
                      nalog.ru
                    </Link></li>
                    <li>Отправить заполненную декларацию в электронном виде
                      (<b>XML</b> файл) также через сайт&ensp;
                      <Link href="https://www.nalog.ru"
                          className={classes.link}
                          target="_blank">
                      nalog.ru
                    </Link></li>
                  </ul>

                  <HelpHashLink to="/help/GenericRF#6">
                    <Typography id="6" variant="h5" component="h2">
                      Коды доходов и принципы их зачета
                    </Typography>
                  </HelpHashLink>
                  <p/>
                  Для налоговой декларации включающей в себя доходы от 
                  инвестирования испльзуются следующие коды доходов:
                  <ul>
                    <li>1010 - Дивиденды</li>
                    <li>1520 - Доходы от реализации имущества (доли), кроме 
                      ценных бумаг</li>
                    <li>2900 - Доходы, полученные от операций с иностранной 
                      валютой</li>
                    <li>1530 - Доходы, полученные по операциям с ценными
                      бумагами, обращающимися на организованном рынке ценных 
                      бумаг</li>
                    <li>1532 - Доходы по операциям с производными финансовыми 
                      инструментами, которые обращаются на организованном рынке
                      и базисным активом которых являются ценные бумаги,
                      фондовые индексы или иные производные финансовые
                      инструменты, базисным активом которых являются ценные
                      бумаги или фондовые индексы</li>         
                    <li>1533 - Доходы, полученные от продажи и погашения
                      инвестиционных паев паевых инвестиционных фондов</li>
                    <li>1535 - Доходы по операциям с производными финансовыми
                      инструментами, которые обращаются на организованном рынке
                      и базисным активом которых не являются ценные бумаги, 
                      фондовые индексы или иные производные финансовые 
                      инструменты, базисным активом которых являются ценные 
                      бумаги или фондовые индексы</li>
                    <li>1011 - Проценты (за исключением процентов по 
                      облигациям с ипотечным покрытием, эмитированным до
                      01.01.2007, доходов в виде процентов, получаемых по 
                      вкладам в банках, и доходов, получаемых при погашении
                      векселя), включая дисконт, полученный по долговому 
                      обязательству любого вида, за исключением сумм дохода в
                      виде процента (купона), получаемого налогоплательщиком 
                      по обращающимся облигациям российских организаций,
                      номинированным в рублях и эмитированным после 
                      1 января 2017 года</li>
                  </ul>
                  Коды расходов, снижающие прибыль по соответсвующим кодам 
                  доходов (в скобках указан код дохода соответствующий
                  данному коду расхода):
                  <ul>
                    <li>201 (1530) - Расходы по операциям с ценными бумагами,
                      обращающимися на организованном рынке ценных бумаг</li>
                    <li>202 - Расходы по операциям с ценными
                      бумагами, не обращающимися на организованном рынке ценных 
                      бумаг</li>
                    <li>206 (1532) - Расходы по операциям с
                      производными финансовыми инструментами, которые 
                      обращаются на организованном рынке и базисным активом
                      которых являются ценные бумаги, фондовые индексы или
                      иные производные финансовые инструменты, базисным 
                      активом которых являются ценные бумаги или фондовые 
                      индексы</li>
                    <li>207 (1535) - Расходы по операциям с производными
                      финансовыми  инструментами, которые обращаются на
                      организованном рынке и базисным активом которых не
                      являются ценные бумаги, фондовые индексы или иные
                      производные финансовые инструменты, базисным активом
                      которых являются ценные бумаги или фондовые индексы</li>
                    <li>220 (1533) - Сумма расхода по операциям с производными
                      финансовыми инструментами, не обращающимися на
                      организованном рынке</li>
                    <li>906 - Продажа имущества, находившегося в собственности
                      менее 3 лет (в пределах 250 000 рублей)</li>
                    <li>903 (1520) - В сумме документально подтвержденных 
                      расходов</li>
                  </ul>
                  Зачет расходов и доходов производится следующим образом:
                  <ul>
                    <li>Убыток по 1530 (201), превышающий доход 1530, снижает 
                      прибыль по 1532, потом по 1535</li>
                    <li>Убыток по 1532 (206), превышающий доход 1532, снижает 
                      прибыль по 1535, потом  по 1530</li>
                    <li>Убыток по 1535 (207), превышающий доход 1535, снижает 
                      прибыль по 1532, но прибыль по 1530 не затрагивает</li>
                    <li>Убытки по кодам доходов 1530 (201), 1532 (206), 
                      1535 (207) не снижают прибыли по 1011</li>
                    <li>Убыток по 1533 (220) не снижает прибыли по другим 
                      доходам, кроме собственно 1533</li>
                    <li>По коду дохода 2900 нет релевантного кода расхода,
                      соответственно, весь полученный доход по нему является
                      прибылью</li>
                  </ul>
                </div>
              </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(HelpGenericRF);
