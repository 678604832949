import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Center from 'react-center';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
    table: {
        minWidth: 650,
    },
    loader: {
        padding: '200px 0',
    },
};

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

class AdminConsolePayments extends React.Component {
    constructor(props) {
        super(props);

        axios.get('/api/payments/list/alltime')
            .then((response) => {
                this.setState({
                    rows: response.data
                }, () => {
                    this.forceUpdate();
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    
    state = {
        rows: null
    };

    formatIsPaid(isPaid) {
        return (isPaid ? "✔" : "");
    }

    render() {
        const { classes } = this.props;
        
        return (
           <Center>
                <table>
                    <tr><td>
                    </td></tr>
                    <tr><td>
                        {(this.state.rows == null)
                        ? <div className={classes.loader}>
                            <CircularProgress size={90} />
                          </div>
                        : <Table className={classes.table} 
                                 size="small" 
                                 aria-label="Платежи">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Дата чека</TableCell>
                                    <TableCell>Дата оплаты</TableCell>
                                    <TableCell>Оплачено</TableCell>
                                    <TableCell>Логин</TableCell>
                                    <TableCell>Почта</TableCell>
                                    <TableCell>Сумма</TableCell>
                                    <TableCell>Описание</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {(this.state.rows.length > 0) 
                            ? this.state.rows.map((row) => (
                                <StyledTableRow key={row.id}>
                                <TableCell>{row.createDate}</TableCell>                        
                                <TableCell>{row.payDate}</TableCell>                        
                                <TableCell>{this.formatIsPaid(row.isPaid)}</TableCell>                        
                                <TableCell>{row.user != null ? row.user.login : ""}</TableCell>
                                <TableCell>{row.user != null ? row.user.email : ""}</TableCell>
                                <TableCell>{row.amount}</TableCell>
                                <TableCell>
                                    {row.description}
                                    {row.uuid != null ? <><br/>{row.uuid}</> : ''}
                                </TableCell>
                                </StyledTableRow>
                            ))
                            : ""}
                            </TableBody>
                          </Table>}
                        </td></tr>
                  </table>
            </Center>
        );
    }
}

export default withStyles(styles)(AdminConsolePayments);
