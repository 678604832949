import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import HelpHashLink from '../help/helpHashLink';
import SupportEmail from '../common/supportEmail';
import { text } from "../languageSelection";
import { connect } from "react-redux";

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    text: {
      maxWidth: '800pt',
      textAlign: 'justify'
    }
});

class CookiePolicy extends React.Component {
  cookiesDataEssential(language){
    return [{
      name: <span>
              twk_uuid_#<br/>
              <Divider variant="fullWidth"/>
              TawkConnectionTime
            </span>,
      purpose: text(
        language,
        {"ru": ("Эти файлы cookie используются Tawk.io для инструмента"
                + " живого чата, который позволяет вам общаться с нами. Этот"
                + " файл cookie не сохраняет ваши личные данные. Однако это"
                + " может помочь веб-сайтам запомнить ваши предпочтения и"
                + " создать более персонализированный пользовательский"
                + " опыт."),
         "en": ("These cookies are used by Tawk.io for the live chat"
                + " tool that allows you to talk with us. This cookie does not"
                + " store your personal details. However, it can help websites"
                + " remember your preferences and create a more personalized"
                + " user experience."),
         "es": ("Tawk.io utiliza estas cookies para la herramienta de chat"
                + " en vivo que le permite hablar con nosotros. Esta cookie no"
                + " almacena sus datos personales. Sin embargo, puede ayudar a"
                + " los sitios web a recordar sus preferencias y crear una"
                + " experiencia de usuario más personalizada.")}),
      provider: "Tawk.io",
      expiresIn: text(language,
                      {"ru": <span>6&nbsp;месяцев<br/>
                               <Divider variant="fullWidth"/>
                               Session</span>,
                       "en": <span>6&nbsp;months<br/>
                               <Divider variant="fullWidth"/>
                               Session</span>,
                       "es": <span>6&nbsp;meses<br/>
                               <Divider variant="fullWidth"/>
                               Session</span>})
    }, {
      name: <span>
              _ga_#<br/>
              <Divider variant="fullWidth"/>
              _ga
            </span>,
      purpose: text(
        language,
        {"ru": <span>
                 Google Analytics — это сторонний инструмент
                 мониторинга веб-сайтов, который позволяет
                 пользователям видеть количество посетителей
                 веб-сайта, их источник, а также анализировать, как
                 просматривается и перемещается контент их веб-сайта.
               </span>,
         "en": <span>
                 Google Analytics is a third-party website monitoring
                 tool that allows users to see volumes of website
                 visitors, their source, and to analyse how the
                 content of their website is viewed and navigated.
               </span>,
         "es": <span>
                 Google Analytics es una herramienta de seguimiento de
                 sitios web de terceros que permite a los usuarios ver
                 los volúmenes de visitantes del sitio web, su fuente
                 y analizar cómo se ve y navega el contenido de su
                 sitio web.
               </span>}),
      provider: "Google Analytics",
      expiresIn: text(language,
                      {"ru": <span>1&nbsp;год</span>,
                       "en": <span>1&nbsp;year</span>,
                       "es": <span>1&nbsp;año</span>})
    }, {
      name: <span>
              PLAY_SESSION
            </span>,
      purpose: text(
        language,
        {"ru": <span>
                 Содержит индикатор веб-сессии любого пользователя.
               </span>,
         "en": <span>
                 Contains a web session indicator for any user.
               </span>,
         "es": <span>
                 Contiene un indicador de sesión web para cualquier usuario.
               </span>}),
      provider: "FinParser",
      expiresIn: text(language,
                      {"ru": <span>Session</span>,
                       "en": <span>Session</span>,
                       "es": <span>Session</span>})
    }, {
      name: <span>
              SESSION
            </span>,
      purpose: text(
        language,
        {"ru": <span>
                 Содержит индикатор веб-сессии авторизованного пользователя.
               </span>,
         "en": <span>
                 Contains an indicator of an authorized user's web session.
               </span>,
         "es": <span>
                 Contiene un indicador de la sesión web de un usuario autorizado.
               </span>}),
      provider: "FinParser",
      expiresIn: text(language,
                      {"ru": <span>Session</span>,
                       "en": <span>Session</span>,
                       "es": <span>Session</span>})
    }, {
      name: <span>
              acceptCookies
            </span>,
      purpose: text(
        language,
        {"ru": <span>
                 Содержит информацию о согласии или об отказе пользователя
                 использовать опциональные cookie.
               </span>,
         "en": <span>
                 Contains information about the user's consent or refusal
                 to use optional cookies.
               </span>,
         "es": <span>
                 Contiene información sobre el consentimiento o negativa
                 del usuario a utilizar cookies opcionales.
               </span>}),
      provider: "FinParser",
      expiresIn: text(language,
                      {"ru": <span>Session</span>,
                       "en": <span>Session</span>,
                       "es": <span>Session</span>})
    }];
  }

  cookiesDataOptional(language){
    return [
      {
        name:"ignoreForex",
        purpose: text(
          language,
          {"ru": "Игнорировать расчеты с операциями Forex",
           "en": "Ignore settlements with Forex transactions",
           "es": "Ignorar liquidaciones con transacciones Forex"}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"editablePDFs",
        purpose: text(
          language,
          {"ru": "При генерации Отчета о движении денежных средств и"
           + " Уведомления об открытии и закрытии брокерского счета в"
           + " формате PDF, полученный PDF-файл будет"
           + " редактируемым. Однако, это значительно увеличит размер"
           + " файла.",
           "en": "When generating the Cash Flow Statement and the"
           + " Notice of Opening and Closing of a Brokerage Account in"
           + " PDF format, the resulting PDF file will be"
           + " editable. However, this will significantly increase the"
           + " file size.",
           "es": "Al generar el Estado de Flujo de Caja y el Aviso de"
           + " Apertura y Cierre de Cuenta de Corretaje en formato"
           + " PDF, el archivo PDF resultante será editable. Sin"
           + " embargo, esto aumentará significativamente el tamaño"
           + " del archivo."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"roundNumbersForCFR",
        purpose: text(
          language,
          {"ru": "Округлять цифры до целых рублей в отчете о движении"
           + " денежных средств вместо отбрасывания дробной части.",
           "en": "Round numbers to the nearest whole ruble in the cash"
           + " flow statement instead of discarding the fractional"
           + " part.",
           "es": "Redondea los números al rublo entero más cercano en"
           + " el estado de flujo de efectivo en lugar de descartar la"
           + " parte fraccionaria."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"takeIntoAccountSuspensionSIODN",
        purpose: text(
          language,
          {"ru": "Учитывать приостановку соглашений об избежании"
           + " двойного налогообложения.",
           "en": "Take into account the suspension of double tax treaties.",
           "es": "Tener en cuenta la suspensión de tratados de doble"
           + " imposición."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputAccountNotice",
        purpose: text(
          language,
          {"ru": "Генерировать уведомление об открытии и закрытии"
           + " брокерского счета.",
           "en": "Generate notification of opening and closing of a"
           + " brokerage account.",
           "es": "Generar notificación de apertura y cierre de cuenta"
           + " de corretaje."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputOpenNotice",
        purpose: text(
          language,
          {"ru": "Признак открытия или закрытия счета.",
           "en": "Sign of opening or closing an account.",
           "es": "Señal de apertura o cierre de cuenta."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputXLSX",
        purpose: text(
          language,
          {"ru": "Генерировать пояснительную записку к налоговой"
           + " декларации в формате Excel.",
           "en": "Generate an explanatory note for a tax return in"
           + " Excel format.",
           "es": "Generar una nota explicativa para una declaración de"
           + " impuestos en formato Excel."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputExpalnationNotePDF",
        purpose: text(
          language,
          {"ru": "Генерировать пояснительную записку к налоговой"
           + " декларации в формате PDF.",
           "en": "Generate an explanatory note for a tax return in PDF"
           + " format.",
           "es": "Generar una nota explicativa para una declaración de"
           + " impuestos en formato PDF."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputXML",
        purpose: text(
          language,
          {"ru": "Генерировать налоговую декларацию в формате XML.",
           "en": "Generate tax return in XML format.",
           "es": "Generar declaración de impuestos en formato XML."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputDCX",
        purpose: text(
          language,
          {"ru": "Генерировать файл для программы Декларация в формате"
           + " DCX.",
           "en": "Generate a file for the Declaration program in DCX"
           + " format.",
           "es": "Generar un archivo para el programa de Declaración"
           + " en formato DCX."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputFinParser",
        purpose: text(
          language,
          {"ru": "Генерировать файл во внутреннем формате FinParser.",
           "en": "Generate a file in the internal FinParser format.",
           "es": "Generar un archivo en el formato interno de FinParser."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"cfrXML",
        purpose: text(
          language,
          {"ru": "Генерировать Отчет о движении денежных средств в"
           + " формате XML.",
           "en": "Generate Cash Flow Report in XML format.",
           "es": "Generar Informe de Flujo de Caja en formato"
           + " XML."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"cfrExcel",
        purpose: text(
          language,
          {"ru": "Генерировать Отчет о движении денежных средств в"
           + " формате Excel.",
           "en": "Generate Cash Flow Report in Excel format.",
           "es": "Generar Informe de Flujo de Caja en formato"
           + " Excel."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"cfrPDF",
        purpose: text(
          language,
          {"ru": "Генерировать Отчет о движении денежных средств в"
           + " формате PDF.",
           "en": "Generate Cash Flow Report in PDF format.",
           "es": "Generar Informe de Flujo de Caja en formato PDF."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"taxOptimizeXLSX",
        purpose: text(
          language,
          {"ru": "Генерировать Налоговую оптимизацию в формате Excel.",
           "en": "Generate Tax Optimization in Excel format.",
           "es": "Generar Optimización Fiscal en formato Excel."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"expandReduce",
        purpose: text(
          language,
          {"ru": "Раскрыть секцию вычетов.",
           "en": "Expand the deductions section.",
           "es": "Expande la sección de deducciones."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"futuresBasedOnVariationMargin",
        purpose: text(
          language,
          {"ru": "Считать фьючерсы для отчетов Interactive Brokers на"
           + " основе вариационной маржи.",
           "en": "Calculate futures for Interactive Brokers reports"
           + " based on variation margin.",
           "es": "Calcule futuros para informes de Interactive Brokers"
           + " en función del margen de variación."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"enable2MonthsRule",
        purpose: text(
          language,
          {"ru": "Учитывать правило 2 месяцев",
           "en": "Consider the 2 month rule",
           "es": "Considere la regla de 2 meses"}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"enable2MonthsRuleShorts",
        purpose: text(
          language,
          {"ru": "Учитывать короткие продажи для правила 2 месяцев",
           "en": "Account for short sales for the 2 month rule",
           "es": "Cuenta para ventas cortas para la regla de 2 meses"}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"consider2or12MonthsDiff",
        purpose: text(
          language,
          {"ru": "Учитывать разницу для 2 и 12 месяцев в зависимости"
           + " от биржи, на которой произошла сделка (только для"
           + " отчетов IB).",
           "en": "Take into account the difference for 2 and 12 months"
           + " depending on the exchange on which the transaction"
           + " occurred (only for IB reports).",
           "es": "Tenga en cuenta la diferencia entre 2 y 12 meses"
           + " dependiendo del intercambio en el que se realizó la"
           + " transacción (solo para informes IB)"}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"useLastTradingDayExRates",
        purpose: text(
          language,
          {"ru": "Использовать обменный курс последнего торгового дня для всех сделок и дивидендов",
           "en": "Use the last trading day's exchange rate for all trades and dividends",
           "es": "Utilice el tipo de cambio del último día de negociación para todas las operaciones y dividendos."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"useOnlyECBExRates",
        purpose: text(
          language,
          {"ru": "Использовать только обменные курсы, публикуемые ЕЦБ",
           "en": "Use only exchange rates published by the ECB",
           "es": "Utilice únicamente los tipos de cambio publicados"
           + " por el BCE"}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputDetailXLSX",
        purpose: text(
          language,
          {"ru": "Детализированный отчет по доходам для налоговой"
           + " декларации в формате Excel.",
           "en": "Detailed income report for tax return in Excel"
           + " format.",
           "es": "Informe detallado de ingresos para declaración de"
           + " impuestos en formato Excel."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputExpalnationNoteDetailPDF",
        purpose: text(
          language,
          {"ru": "Детализированный отчет по доходам для налоговой"
           + " декларации в формате PDF",
           "en": "Detailed income report for tax return in PDF"
           + " format",
           "es": "Informe detallado de ingresos para declaración de"
           + " impuestos en formato PDF"}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputForm_720",
        purpose: text(
          language,
          {"ru": "Форма 720. Информационная налоговая декларация.",
           "en": "Form 720. Informative Tax Return.",
           "es": "Modelo 720. Declaración Informativa."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"outputForm_721",
        purpose: text(
          language,
          {"ru": "Форма 721. Информационное уведомление о виртуальных валютах, находящихся за рубежом.",
           "en": "Form 721. Information statement on virtual currencies located abroad.",
           "es": "Modelo 721. Declaración informativa sobre monedas virtuales situadas en el extranjero."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"expandTaxpayerDataSection",
        purpose: text(
          language,
          {"ru": "Раскрыть секцию данных налогоплательщика",
           "en": "Expand taxpayer data section",
           "es": "Ampliar sección de datos del contribuyente"}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"expandIgnoresSection",
        purpose: text(
          language,
          {"ru": "Раскрыть секцию дополнительных настроек",
           "en": "Expand the additional settings section",
           "es": "Expandir la sección de configuración adicional"}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"language",
        purpose: text(
          language,
          {"ru": "Текущий язык сайта сервиса и генерируемых форматов.",
           "en": "Current language of the service website and generated formats.",
           "es": "Idioma actual del sitio web del servicio y formatos generados."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }, {
        name:"residenceCountry",
        purpose: text(
          language,
          {"ru": "Страна налогового резидентства.",
           "en": "Country of tax residence.",
           "es": "País de residencia fiscal."}),
        provider: "FinParser",
        expiresIn: text(language,
                        {"ru": <span>1&nbsp;год</span>,
                         "en": <span>1&nbsp;year</span>,
                         "es": <span>1&nbsp;año</span>})
      }];
  }

  render() {
    const { classes } = this.props;
    let language = this.props.language.value;

    return (
      <Card className={classes.card}>
        <CardContent className={classes.text}>
          <HelpHashLink to="/cookiePolicy#1" >
            <Typography variant="h5" component="h2">
              {text(language,
                    {"ru": "ПОЛИТИКА ИСПОЛЬЗОВАНИЯ ФАЙЛОВ COOKIE",
                     "en": "COOKIE POLICY",
                     "es": "POLÍTICA DE COOKIES"})}
            </Typography>
          </HelpHashLink>
          <p/>
          <b>{text(language,
                   {"ru": "Последнее обновление: 25 января 2024 г.",
                    "en": "Last updated January 25, 2024",
                    "es": "Última actualización 25 de enero de 2024"})}</b>
          <p>
            {text(language,
                  {"ru": <span>
                           В настоящей Политике использования файлов
                           cookie объясняется, как finparser.com
                           («сервис», «мы», «нас» и «наш») использует
                           файлы cookie и аналогичные технологии,
                           чтобы узнавать вас, когда вы их
                           используете. В нем объясняется, что это за
                           технологии и почему мы их используем, а
                           также ваши права контролировать их
                           использование.
                         </span>,
                   "en": <span>
                           This Cookie Policy explains how
                           finparser.com ("service," "we", "us," and
                           "our") uses cookies and similar
                           technologies to recognize you when you use
                           it. It explains what these technologies are
                           and why we use them, as well as your rights
                           to control our use of them.
                         </span>,
                   "es": <span>
                           Esta Política de cookies explica cómo
                           finparser.com ("servicio", "nosotros" y
                           "nuestro") utiliza cookies y tecnologías
                           similares para reconocerlo cuando las
                           utiliza. Explica qué son estas tecnologías
                           y por qué las usamos, así como sus derechos
                           para controlar nuestro uso de ellas.
                         </span>})}
            <p/>
            {text(language,
                  {"ru": <span>
                           В некоторых случаях мы можем использовать
                           файлы cookie для сбора личной информации,
                           или она становится личной информацией, если
                           мы объединяем ее с другой информацией.
                         </span>,
                   "en": <span>
                           In some cases we may use cookies to collect
                           personal information, or that becomes
                           personal information if we combine it with
                           other information.
                         </span>,
                   "es": <span>
                           En algunos casos podemos utilizar cookies
                           para recopilar información personal, o que
                           se convierta en información personal si la
                           combinamos con otra información.
                         </span>})}
          </p>

          <HelpHashLink to="/cookiePolicy#2" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "Что такое файлы cookie?",
                     "en": "What are cookies?",
                     "es": "¿Qué son las galletas?"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Файлы cookie — это небольшие файлы данных,
                           которые сохраняются на вашем компьютере или
                           мобильном устройстве при посещении
                           веб-сайта. Файлы cookie широко используются
                           владельцами веб-сайтов для обеспечения
                           работы или повышения эффективности их
                           веб-сайтов, а также для предоставления
                           отчетной информации.
                         </span>,
                   "en": <span>
                           Cookies are small data files that are
                           placed on your computer or mobile device
                           when you visit a website. Cookies are
                           widely used by website owners in order to
                           make their websites work, or to work more
                           efficiently, as well as to provide
                           reporting information.
                         </span>,
                   "es": <span>
                           Las cookies son pequeños archivos de datos
                           que se colocan en su computadora o
                           dispositivo móvil cuando visita un sitio
                           web. Los propietarios de sitios web
                           utilizan ampliamente las cookies para que
                           sus sitios web funcionen o para que
                           funcionen de manera más eficiente, así como
                           para proporcionar información sobre
                           informes.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Файлы cookie, установленные владельцем
                           веб-сайта finparser, называются «основными
                           файлами cookie». Файлы cookie,
                           установленные сторонами, не являющимися
                           владельцем веб-сайта, называются
                           «сторонними файлами cookie». Сторонние
                           файлы cookie позволяют предоставлять
                           сторонние функции или функции на веб-сайте
                           или через него (например, интерактивный
                           контент и аналитику). Стороны,
                           устанавливающие эти сторонние файлы cookie,
                           могут распознавать ваш компьютер как при
                           посещении соответствующего веб-сайта, так и
                           при посещении некоторых других веб-сайтов.
                         </span>,
                   "en": <span>
                           Cookies set by the website owner finparser
                           are called "first-party cookies." Cookies
                           set by parties other than the website owner
                           are called "third-party cookies."
                           Third-party cookies enable third-party
                           features or functionality to be provided on
                           or through the website (e.g., interactive
                           content, and analytics). The parties that
                           set these third-party cookies can recognize
                           your computer both when it visits the
                           website in question and also when it visits
                           certain other websites.
                         </span>,
                   "es": <span>
                           Las cookies establecidas por el propietario
                           del sitio web, finparser, se denominan
                           "cookies de origen". Las cookies
                           establecidas por terceros distintos del
                           propietario del sitio web se denominan
                           "cookies de terceros". Las cookies de
                           terceros permiten que se proporcionen
                           características o funcionalidades de
                           terceros en el sitio web o a través de él
                           (por ejemplo, contenido interactivo y
                           análisis). Las partes que configuran estas
                           cookies de terceros pueden reconocer su
                           computadora tanto cuando visita el sitio
                           web en cuestión como cuando visita otros
                           sitios web determinados.
                         </span>})}
          </p>

          <HelpHashLink to="/cookiePolicy#3" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "Почему мы используем файлы cookie?",
                     "en": "Why do we use cookies?",
                     "es": "¿Por qué utilizamos cookies?"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Мы используем собственные и сторонние файлы
                           cookie по нескольким причинам. Некоторые
                           файлы cookie необходимы по техническим
                           причинам для работы нашего веб-сайта, и мы
                           называем их «необходимыми» или «строго
                           необходимыми» файлами cookie. Другие файлы
                           cookie также позволяют нам отслеживать и
                           ориентироваться на интересы наших
                           пользователей, чтобы улучшить качество
                           наших услуг. Третьи лица используют файлы
                           cookie через наш веб-сайт для
                           взаимодействия, анализа и других
                           целей. Более подробно это описано ниже.
                         </span>,
                   "en": <span>
                           We use first- and third-party cookies for
                           several reasons. Some cookies are required
                           for technical reasons in order for our
                           Website to operate, and we refer to these
                           as "essential" or "strictly necessary"
                           cookies. Other cookies also enable us to
                           track and target the interests of our users
                           to enhance the experience on our
                           service. Third parties serve cookies
                           through our Website for interaction,
                           analytics, and other purposes. This is
                           described in more detail below.
                         </span>,
                   "es": <span>
                           Utilizamos cookies propias y de terceros
                           por varios motivos. Algunas cookies son
                           necesarias por razones técnicas para que
                           nuestro sitio web funcione, y nos referimos
                           a ellas como cookies "esenciales" o
                           "estrictamente necesarias". Otras cookies
                           también nos permiten rastrear y orientar
                           los intereses de nuestros usuarios para
                           mejorar la experiencia en nuestro
                           servicio. Los terceros utilizan cookies a
                           través de nuestro sitio web para
                           interacción, análisis y otros fines. Esto
                           se describe en más detalle a continuación.
                         </span>})}
          </p>

          <HelpHashLink to="/cookiePolicy#4" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "Как я могу контролировать файлы cookie?",
                     "en": "How can I control cookies?",
                     "es": "¿Cómo puedo controlar las cookies?"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Вы имеете право решать, принимать или
                           отклонять файлы cookie. Вы можете
                           воспользоваться своими правами на
                           использование файлов cookie, установив свои
                           предпочтения в настройках файлов
                           cookie. Настройки файлов cookie позволяют
                           вам выбрать, какие категории файлов cookie
                           вы принимаете или отклоняете. Основные
                           файлы cookie не могут быть отклонены,
                           поскольку они строго необходимы для
                           предоставления вам услуг.
                         </span>,
                   "en": <span>
                           You have the right to decide whether to
                           accept or reject cookies. You can exercise
                           your cookie rights by setting your
                           preferences in the Cookie settings. The
                           Cookie settings allows you to select which
                           categories of cookies you accept or
                           reject. Essential cookies cannot be
                           rejected as they are strictly necessary to
                           provide you with services.
                         </span>,
                   "es": <span>
                           Usted tiene derecho a decidir si acepta o
                           rechaza las cookies. Puede ejercer sus
                           derechos sobre cookies configurando sus
                           preferencias en la configuración de
                           cookies. La configuración de cookies le
                           permite seleccionar qué categorías de
                           cookies acepta o rechaza. Las cookies
                           esenciales no se pueden rechazar ya que son
                           estrictamente necesarias para
                           proporcionarle servicios.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Настройки файлов cookie можно найти в
                           главном меню нашего сайта. Если вы решите
                           отказаться от файлов cookie, вы все равно
                           сможете использовать наш веб-сайт, хотя у
                           вас будет доступ к некоторым функциям. Вы
                           также можете установить или изменить
                           элементы управления вашего веб-браузера,
                           чтобы принимать или отклонять файлы cookie.
                         </span>,
                   "en": <span>
                           The Cookie settings can be found in the
                           main menu on our website. If you choose to
                           reject cookies, you may still use our
                           website though your access to some
                           functionality. You may also set or amend
                           your web browser controls to accept or
                           refuse cookies.
                         </span>,
                   "es": <span>
                           La configuración de Cookies se puede
                           encontrar en el menú principal de nuestro
                           sitio web. Si elige rechazar las cookies,
                           aún podrá utilizar nuestro sitio web aunque
                           acceda a algunas funciones. También puede
                           configurar o modificar los controles de su
                           navegador web para aceptar o rechazar
                           cookies.
                         </span>})}
          </p>
          <p>
            {text(language,
                  {"ru": <span>
                           Конкретные типы основных и сторонних файлов
                           cookie, обслуживаемых через наш Веб-сайт, и
                           цели, которые они выполняют, описаны в
                           таблице ниже (обратите внимание, что
                           конкретные используемые файлы cookie могут
                           различаться в зависимости от конкретных
                           онлайн-ресурсов, которые вы посещаете):
                         </span>,
                   "en": <span>
                           The specific types of first- and
                           third-party cookies served through our
                           Website and the purposes they perform are
                           described in the table below (please note
                           that the specific cookies served may vary
                           depending on the specific Online Properties
                           you visit):
                         </span>,
                   "es": <span>
                           Los tipos específicos de cookies propias y
                           de terceros que se sirven a través de
                           nuestro sitio web y los propósitos que
                           realizan se describen en la siguiente tabla
                           (tenga en cuenta que las cookies
                           específicas que se sirven pueden variar
                           según las Propiedades en línea específicas
                           que visite):
                         </span>})}
          </p>

          <HelpHashLink to="/cookiePolicy#5" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "Необходимые файлы cookie веб-сайта",
                     "en": "Essential website cookies",
                     "es": "Cookies esenciales del sitio web"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Эти файлы cookie строго необходимы для
                           предоставления вам услуг, доступных через
                           наш веб-сайт, и для использования некоторых
                           его функций, таких как доступ к защищенным
                           областям.
                         </span>,
                   "en": <span>
                           These cookies are strictly necessary to
                           provide you with services available through
                           our Website and to use some of its
                           features, such as access to secure areas.
                         </span>,
                   "es": <span>
                           Estas cookies son estrictamente necesarias
                           para brindarle los servicios disponibles a
                           través de nuestro sitio web y para utilizar
                           algunas de sus funciones, como el acceso a
                           áreas seguras.
                         </span>})}
          </p>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {text(language,
                          {"ru": "Название",
                           "en": "Name",
                           "es": "Nombre"})}
                  </TableCell>
                  <TableCell align="left">
                    {text(language,
                          {"ru": "Назначение",
                           "en": "Purpose",
                           "es": "Cita"})}
                  </TableCell>
                  <TableCell align="left">
                    {text(language,
                          {"ru": "Провайдер",
                           "en": "Provider",
                           "es": "Proveedor"})}
                  </TableCell>
                  <TableCell align="left">
                    {text(language,
                          {"ru": <span>Истекает</span>,
                           "en": <span>Expires&nbsp;in</span>,
                           "es": <span>Expira&nbsp;en</span>})}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.cookiesDataEssential(language).map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="left"
                               className={classes.imgCell}>
                      {row.name}
                    </TableCell>
                    <TableCell align="left"
                               className={classes.imgCell}>
                      {row.purpose}
                    </TableCell>
                    <TableCell align="left"
                               className={classes.imgCell}>
                      {row.provider}
                    </TableCell>
                    <TableCell align="left"
                               className={classes.imgCell}>
                      {row.expiresIn}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p/>

          <HelpHashLink to="/cookiePolicy#6" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": <span>Опциональные файлы cookie</span>,
                     "en": <span>Optional cookies</span>,
                     "es": <span>Cookies opcionales</span>})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Эти файлы cookie не являются обязательными,
                           но могут улучшить удобство использования за
                           счет сохранения предпочтений выбора.
                         </span>,
                   "en": <span>
                           These cookies are not necessary but can improve user
                           experience by perisiting selection preferences.
                         </span>,
                   "es": <span>
                           Estas cookies no son necesarias pero pueden
                           mejorar la experiencia del usuario al
                           consultar las preferencias de selección.
                         </span>})}
          </p>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {text(language,
                          {"ru": "Название",
                           "en": "Name",
                           "es": "Nombre"})}
                  </TableCell>
                  <TableCell align="left">
                    {text(language,
                          {"ru": "Назначение",
                           "en": "Purpose",
                           "es": "Cita"})}
                  </TableCell>
                  <TableCell align="left">
                    {text(language,
                          {"ru": "Провайдер",
                           "en": "Provider",
                           "es": "Proveedor"})}
                  </TableCell>
                  <TableCell align="left">
                    {text(language,
                          {"ru": <span>Истекает</span>,
                           "en": <span>Expires&nbsp;in</span>,
                           "es": <span>Expira&nbsp;en</span>})}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.cookiesDataOptional(language).map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="left"
                               className={classes.imgCell}>
                      {row.name}
                    </TableCell>
                    <TableCell align="left"
                               className={classes.imgCell}>
                      {row.purpose}
                    </TableCell>
                    <TableCell align="left"
                               className={classes.imgCell}>
                      {row.provider}
                    </TableCell>
                    <TableCell align="left"
                               className={classes.imgCell}>
                      {row.expiresIn}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p/>

          <HelpHashLink to="/cookiePolicy#7" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "Как я могу контролировать файлы cookie в своем браузере?",
                     "en": "How can I control cookies on my browser?",
                     "es": "¿Cómo puedo controlar las cookies en mi navegador?"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Поскольку способы отказа от файлов cookie с
                           помощью элементов управления веб-браузера
                           различаются в зависимости от браузера, вам
                           следует посетить меню справки вашего
                           браузера для получения дополнительной
                           информации.
                         </span>,
                   "en": <span>
                           As the means by which you can refuse
                           cookies through your web browser controls
                           vary from browser to browser, you should
                           visit your browser's help menu for more
                           information.
                         </span>,
                   "es": <span>
                           Como los medios por los cuales puede
                           rechazar las cookies a través de los
                           controles de su navegador web varían de un
                           navegador a otro, debe visitar el menú de
                           ayuda de su navegador para obtener más
                           información.
                         </span>})}
          </p>

          <HelpHashLink to="/cookiePolicy#7" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "Вы размещаете таргетированную рекламу?",
                     "en": "Do you serve targeted advertising?",
                     "es": "¿Ofrecen publicidad dirigida?"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Третьи лица могут использовать файлы cookie
                           на вашем компьютере или мобильном
                           устройстве для показа рекламы через наш
                           веб-сайт. Эти компании могут использовать
                           информацию о ваших посещениях этого и
                           других веб-сайтов, чтобы предоставлять
                           соответствующую рекламу товаров и услуг,
                           которые могут вас заинтересовать. Они также
                           могут использовать технологию, которая
                           используется для измерения эффективности
                           рекламы. Они могут добиться этого,
                           используя файлы cookie или веб-маяки для
                           сбора информации о ваших посещениях этого и
                           других сайтов, чтобы предоставлять
                           соответствующую рекламу о товарах и
                           услугах, которые могут вас
                           заинтересовать. Информация, собранная в
                           ходе этого процесса, не позволяет нам или
                           им идентифицировать ваше имя, контактные
                           данные или другие данные, которые
                           непосредственно идентифицируют вас, если
                           только вы не решите их предоставить.
                         </span>,
                   "en": <span>
                           Third parties may serve cookies on your
                           computer or mobile device to serve
                           advertising through our Website. These
                           companies may use information about your
                           visits to this and other websites in order
                           to provide relevant advertisements about
                           goods and services that you may be
                           interested in. They may also employ
                           technology that is used to measure the
                           effectiveness of advertisements. They can
                           accomplish this by using cookies or web
                           beacons to collect information about your
                           visits to this and other sites in order to
                           provide relevant advertisements about goods
                           and services of potential interest to
                           you. The information collected through this
                           process does not enable us or them to
                           identify your name, contact details, or
                           other details that directly identify you
                           unless you choose to provide these.
                         </span>,
                   "es": <span>
                           Los terceros pueden colocar cookies en su
                           computadora o dispositivo móvil para
                           mostrar publicidad a través de nuestro
                           sitio web. Estas empresas pueden utilizar
                           información sobre sus visitas a este y
                           otros sitios web para proporcionar anuncios
                           relevantes sobre bienes y servicios que
                           puedan interesarle. También pueden emplear
                           tecnología que se utiliza para medir la
                           eficacia de los anuncios. Pueden lograr
                           esto mediante el uso de cookies o balizas
                           web para recopilar información sobre sus
                           visitas a este y otros sitios con el fin de
                           proporcionar anuncios relevantes sobre
                           bienes y servicios de posible interés para
                           usted. La información recopilada a través
                           de este proceso no nos permite a nosotros
                           ni a ellos identificar su nombre, datos de
                           contacto u otros detalles que lo
                           identifiquen directamente a menos que usted
                           elija proporcionarlos.
                         </span>})}
          </p>

          <HelpHashLink to="/cookiePolicy#7" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "Как часто вы будете обновлять настоящую"
                     + " Политику использования файлов cookie?",
                     "en": "How often will you update this Cookie Policy?",
                     "es": "¿Con qué frecuencia actualizaréis esta"
                     + " Política de Cookies?"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Мы можем время от времени обновлять
                           настоящую Политику в отношении файлов
                           cookie, чтобы отражать, например, изменения
                           в используемых нами файлах cookie или по
                           другим эксплуатационным, юридическим или
                           нормативным причинам. Поэтому, пожалуйста,
                           регулярно просматривайте настоящую Политику
                           в отношении файлов cookie, чтобы быть в
                           курсе использования нами файлов cookie и
                           связанных с ними технологий.
                         </span>,
                   "en": <span>
                           We may update this Cookie Policy from time
                           to time in order to reflect, for example,
                           changes to the cookies we use or for other
                           operational, legal, or regulatory
                           reasons. Please therefore revisit this
                           Cookie Policy regularly to stay informed
                           about our use of cookies and related
                           technologies.
                         </span>,
                   "es": <span>
                           Es posible que actualicemos esta Política
                           de cookies de vez en cuando para reflejar,
                           por ejemplo, cambios en las cookies que
                           utilizamos o por otros motivos operativos,
                           legales o regulatorios. Por lo tanto,
                           revise esta Política de cookies
                           periódicamente para mantenerse informado
                           sobre nuestro uso de cookies y tecnologías
                           relacionadas.
                         </span>})}
            <br/>
            {text(language,
                  {"ru": <span>
                           Дата в верхней части настоящей Политики
                           использования файлов cookie указывает,
                           когда она обновлялась в последний раз.
                         </span>,
                   "en": <span>
                           The date at the top of this Cookie Policy
                           indicates when it was last updated.
                         </span>,
                   "es": <span>
                           La fecha que aparece en la parte superior
                           de esta Política de Cookies indica cuándo
                           se actualizó por última vez.
                         </span>})}
          </p>

          <HelpHashLink to="/cookiePolicy#8" >
            <Typography variant="h6" component="h3">
              {text(language,
                    {"ru": "Где я могу получить дополнительную информацию?",
                     "en": "Where can I get further information?",
                     "es": "¿Dónde puedo obtener más información?"})}
            </Typography>
          </HelpHashLink>
          <p>
            {text(language,
                  {"ru": <span>
                           Если у вас есть какие-либо вопросы об
                           использовании нами файлов cookie или других
                           технологий, напишите нам по адресу:
                         </span>,
                   "en": <span>
                           If you have any questions about our use of
                           cookies or other technologies, please email
                           us at
                         </span>,
                   "es": <span>
                           Si tiene alguna pregunta sobre nuestro uso
                           de cookies u otras tecnologías, envíenos un
                           correo electrónico a
                         </span>})}&ensp;
            <SupportEmail/>
          </p>
        </CardContent>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const language = state.language;
  return {
    language
  };
}

export default withStyles(styles)(
  connect(mapStateToProps)(CookiePolicy));
